.swap-box-9 {
  height: 600px;
  min-width: 368px;
  position: relative;
  border-radius: 25px;
  margin-top: 5px;  
  margin-bottom: 0;  
  overflow-y: auto;  
  
}

.frame-container-9 {
  align-items: flex-start;
  border-radius: 22px;
  display: flex;
  flex-direction: column;
  padding: 1px 1px;
  position: relative;
  width: 100%;
  height: 100%;
}

.frame-container-9 .wert-internal-class {
  border: none;
  outline: none;

  }