.privacy-d .privacy-policy {
  background-position: initial;
  background-size: cover;
  border: 1px none;
  flex-direction: column;
  align-items: center;
  gap: 115px;
  min-width: 1440px;
  padding: 81px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.privacy-d .frame-3414 {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  display: flex;
  position: relative;
}

.privacy-d .nav {
  justify-content: space-between;
  align-self: center;
  align-items: center;
  min-width: 1278px;
  height: 46px;
  padding: 10px 50px 10px 40px;
  display: flex;
  position: absolute;
}

.privacy-d .logo {
  height: 45.81px;
  margin-top: -0.2px;
  width: 246.25px;
}

.privacy-d .img-wrapper {
  --webkit-backdrop-filter: blur(10px) brightness(100%);
  -webkit-backdrop-filter: blur(10px) brightness();
  backdrop-filter: blur(10px) brightness();
  border-radius: 10px;
  width: 33px;
  height: 33px;
  position: relative;
  left: 600px;
}

.privacy-d .my-custom-wrapper {
  border-radius: 12px;
  display: inline-block;
  overflow: hidden;
}

.privacy-d .my-custom-class {
  cursor: pointer;
  font-family: var(--font-family-bebas_neue_pro-regular) !important;
  font-weight: bold;
  position: relative;
}

.privacy-d .group-2 {
  z-index: 9;
  width: 44px;
  height: 44px;
  position: relative;
  top: -6px;
  right: 10px;
  cursor: pointer;
}

.group-2:hover {
  animation: shake 5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}

@keyframes shake {

  10%,
  90% {
    transform: translateX(-5px);
    /* Move left */
  }

  20%,
  80% {
    transform: translateX(5px);
    /* Move right */
  }

  30%,
  50%,
  70% {
    transform: translateX(-3px);
    /* Move left */
  }

  40%,
  60% {
    transform: translateX(3px);
    /* Move right */
  }
}

.privacy-d .terms-content {
  margin-top: 150px;
  margin-bottom: 50px;
  width: 678px;
  margin-left: 70px;
}

.privacy-d .terms-content h1 {
  font: var(--font-family-bebas_neue_pro-regular);
  font-size: 45px;
  color: white;
}

.privacy-d .terms-content p {
  font: var(--font-family-bebas_neue_pro-regular);
  color: #fff;
  font-size: 18px;
  margin-top: 20px;
}


.privacy-d .footer {
  background-color: #0000;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 37.6px;
  min-width: 1194.56px;
  height: 104.22px;
  display: flex;
  position: relative;
  top: 45px;
}

.privacy-d .line-34 {
  height: 1px;
  margin-top: -0.4px;
  width: 1194.56px;
}

.privacy-d .frame-3399 {
  align-items: flex-start;
  display: flex;
  gap: 984px;
  height: 16px;
  justify-content: center;
  margin-left: -1px;
  position: relative;
  width: 1187px;
}

.privacy-d .copyright {
  font-size: 14px;
  font-family: "Bebas Neue Pro-Regular", Helvetica;
  color: #f9f9f9;
  height: 16px;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
  width: 107px;
}

.privacy-d .about-us-blog-t-cs-privacy-policy {
  letter-spacing: 0;
  text-align: right;
  width: 96px;
  margin-top: -1px;
  font-size: 14px;
  line-height: normal;
  position: relative;
  right: 20px;
}

.privacy-d .terms-link,
.privacy-d .privacy-link {
  color: #f9f9f9;
  cursor: pointer;
  min-width: 125px;
  margin-top: 3px;
  margin-left: -14px;
  font-family: "Bebas Neue Pro-Light", Helvetica;
  font-size: 12px;
  transition: color .3s;
}

.privacy-d .terms-link:hover,
.privacy-d .privacy-link:hover {
  color: #35cbfd;
  /* Change color on hover */
}