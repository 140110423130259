.fee-box-d-expanded {
  background-color: #0000;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-top: 60px;
  margin-bottom: -130px;
  display: flex;
  z-index: 9;
}

.fee-box-d-expanded .divbox {
  border: 3px solid #35cbfd;
  border-radius: 22px;
  width: 675px;
  height: 300px;
  position: relative;
}

.fee-box-d-expanded .path {
  height: 11px;
  left: 630px;
  position: absolute;
  top: 29px;
  width: 17px;
  cursor: pointer;
}

.fee-box-d-expanded .line {
  height: 2px;
  left: 25px;
  position: absolute;
  top: 60px;
  width: 623px;
}

.fee-box-d-expanded .maximize {
  color: #f9f9f9;
  font-family: "Titillium Web", Helvetica;
  font-size: 16px;
  font-weight: 700;
  left: 26px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 21px;
}

.fee-box-d-expanded .overlap-group {
  width: 621px;
  height: 20px;
  position: absolute;
  top: 75px;
  left: 27px;
}

.fee-box-d-expanded .FROM {
  color: #f9f9f9;
  font-family: "Titillium Web", Helvetica;
  font-size: 13px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  width: 311px;
}

.fee-box-d-expanded .text-wrapper {
  color: #f9f9f9;
  font-family: "Titillium Web", Helvetica;
  font-size: 13px;
  font-weight: 400;
  left: 310px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: right;
  top: 0;
  width: 311px;
}

.fee-box-d-expanded .overlap {
  height: 20px;
  left: 27px;
  position: absolute;
  top: 97px;
  width: 621px;
}

.fee-box-d-expanded .overlap-2 {
  width: 621px;
  height: 20px;
  position: absolute;
  top: 142px;
  left: 27px;
}

.fee-box-d-expanded .overlap-3 {
  width: 621px;
  height: 20px;
  position: absolute;
  top: 166px;
  left: 27px;
}

.fee-box-d-expanded .overlap-4 {
  width: 621px;
  height: 20px;
  position: absolute;
  top: 190px;
  left: 27px;
}

.fee-box-d-expanded .overlap-6 {
  width: 621px;
  height: 20px;
  position: absolute;
  top: 119px;
  left: 27px;
}

.fee-box-d-expanded .overlap-7 {
  width: 621px;
  height: 20px;
  position: absolute;
  top: 214px;
  left: 27px;
}

.fee-box-d-expanded .overlap-8 {
  width: 621px;
  height: 20px;
  position: absolute;
  top: 450px;
  left: 27px;
}

.fee-box-d-expanded .info-popup {
  background-color: #000;
  background-size: 100% 100%;
  border: 3px solid #35cbfd;
  border-radius: 18px;
  width: 284px;
  height: 143px;
  margin-top: -19px;
  margin-left: 361px;
}

.fee-box-d-expanded .info-text {
  color: #f9f9f9;
  width: 238px;
  margin-left: 7px;
  padding-top: 10px;
  padding-left: 11px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 400;
}

.fee-box-d-expanded .info-close {
  margin-left: 5px;
}


.fee-box-d-expanded .info-content {
  width: 250px;
}

.fee-box-d-expanded .learn-more {
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 400;
  color: #35cbfd;
  cursor: pointer;
}

.fee-box-d-expanded .information {
  color: #f9f9f9;
  width: 374px;
  height: 20px;
  margin-top: -200px;
  margin-left: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 400;
}


.fee-box-d-minimised {
  background-color: #0000;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-top: 60px;
  display: flex;
  margin-bottom: -320px;
  z-index: 9;
}

.fee-box-d-minimised .overlap-group-wrapper {
  height: 245px;
  width: 675px;
}

.fee-box-d-minimised .overlap-group {
  border: 3px solid #35cbfd;
  border-radius: 18px;
  width: 675px;
  height: 63px;
  position: relative;
}

.fee-box-d-minimised .path {
  width: 17px;
  height: 11px;
  position: absolute;
  top: 26px;
  left: 629px;
  cursor: pointer;
}

.fee-box-d-minimised .minimize {
  color: #f9f9f9;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 18px;
  left: 25px;
}