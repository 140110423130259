.swap-box-4 {
  height: 547px;
  min-width: 787px;
  position: relative;
  border-radius: 25px;
  margin-top: 75px;  
  margin-bottom: 0;  
  overflow-y: auto;  
  
}

.frame-container-4 {
  align-items: flex-start;
  border-radius: 22px;
  display: flex;
  flex-direction: column;
  padding: 1px 1px;
  position: relative;
  width: 100%;
  height: 100%;
}

.frame-container-4 .wert-internal-class {
  border: none;
  outline: none;

  }