 .mobile-swap-box {
   height: 547px;
   margin-left: -9.00px;
   margin-right: -9.00px;
   min-width: 358px;
   position: relative;
 }

 .mobile-swap-box .frame-container-1 {
   border: 1px solid;
   border-color: var(--scooter);
   border-radius: 16px;
   flex-direction: column;
   align-items: center;
   width: 338px;
   min-height: 547px;
   padding: 22px 0;
   display: flex;
   position: relative;
   left: 17px;
 }

 .mobile-swap-box .frame-1-1 {
   align-items: center;
   display: flex;
   gap: 14px;
   margin-right: 2.0px;
   padding: 0px 0px 0px 27px;
   position: relative;
   width: 338px;
 }

 .mobile-swap-box .place-1 {
   letter-spacing: 0;
   line-height: normal;
   position: relative;
   width: 54px;
 }

 .mobile-swap-box .bnb-3 {
   height: 45px;
   min-width: 239px;
   position: relative;
   margin-right: 5%;
 }

 .mobile-swap-box .overlap-group-61 {
   border: 1px solid;
   border-color: var(--cerulean);
   border-radius: 7px;
   align-items: center;
   min-width: 235px;
   height: 45px;
   margin-right: 15px;
   padding: 5.8px 4.4px;
   display: flex;
   margin-left: -9px;
 }

 .ramp-widget {
   width: 368px;
   height: 600px;
   margin-top: 5px;
 }

 @media (min-width: 368px) {
   .ramp-widget {
     width: 340px;
   }
 }

 @media (min-width: 1025px) {
   .ramp-widget {
     width: 500px;
   }
 }

 .mobile-swap-box .path-535-1 {
   width: 12px;
   height: 6px;
   margin-top: 1.78px;
   margin-left: 50px;
 }

 .mobile-swap-box .path-535 {
   width: 12px;
   height: 6px;
   margin-top: 1.78px;
   margin-left: 50px;
 }

 .mobile-swap-box .overlap-group-17 {
   align-items: flex-start;
   border: 1px solid;
   border-color: var(--cerulean);
   border-radius: 7px;
   display: flex;
   gap: 6px;
   height: 45px;
   min-width: 237px;
   padding: 5.8px 10.6px;
   cursor: pointer;
 }

 .mobile-swap-box .path-5 {
   height: 31px;
   width: 32px;
 }

 .mobile-swap-box .overlap-group1-7 {
   align-self: center;
   height: 21px;
   margin-bottom: 0%;
   position: relative;
   width: 166px;
 }

 .mobile-swap-box .path-535-3 {
   height: 6px;
   left: 154px;
   position: absolute;
   top: 8px;
   width: 12px;
 }

 .mobile-swap-box .bnb-chain-3 {
   left: 0;
   letter-spacing: 0;
   line-height: normal;
   position: absolute;
   top: 0;
   width: 154px;
 }

 .mobile-swap-box .frame-3409 {
   align-items: center;
   display: flex;
   justify-content: center;
   margin-right: 2.0px;
   margin-top: 19px;
   position: relative;
   width: 326px;
 }

 .mobile-swap-box .tb-1,
 .mobile-swap-box .bb-1 {
   height: 113px;
   margin-right: -4.00px;
   min-width: 330px;
   position: relative;
 }

 .mobile-swap-box .flex-row-2 {
   align-items: center;
   background-color: var(--black-rock);
   border-radius: 12px;
   display: flex;
   gap: 11px;
   height: 113px;
   min-width: 326px;
   padding: 2px 17px;
 }

 .mobile-swap-box .flex-col-2 {
   align-items: flex-start;
   align-self: flex-end;
   display: flex;
   flex-direction: column;
   gap: 18px;
   min-height: 94px;
   width: 182px;
 }

 .mobile-swap-box .send-1 {
   letter-spacing: 0;
   line-height: normal;
   margin-left: 1.38px;
   min-height: 19px;
   width: 102px;
 }

 .mobile-swap-box .text-1 {
   letter-spacing: 0;
   width: 182px;
   min-height: 57px;
   line-height: normal;
   margin-bottom: 50px;
   font-size: 28px;
   white-space: nowrap;
   border: none;
   background-color: transparent;
 }

 .mobile-swap-box .text-1::-webkit-inner-spin-button,
 .mobile-swap-box .text-1::-webkit-outer-spin-button {
   -webkit-appearance: none;
   margin: 0;
 }

 .mobile-swap-box .text-1 {
   -moz-appearance: textfield;
   /* Firefox */
 }

 .mobile-swap-box .flex-col-3 {
   flex-direction: column;
   align-items: flex-start;
   gap: 33px;
   width: 94px;
   min-height: 83px;
   margin-top: -60.63px;
   display: flex;
 }

 .mobile-swap-box .overlap-group-container {
   align-items: flex-start;
   gap: 5px;
   min-width: 94px;
   margin-top: -15px;
   margin-left: 14.04px;
   display: flex;
 }

 .mobile-swap-box .overlap-group1-8 {
   align-items: flex-start;
   background-size: 100% 100%;
   display: flex;
   height: 19px;
   justify-content: flex-end;
   min-width: 45px;
   padding: 2.1px 11.7px;
 }

 .mobile-swap-box .name-1 {
   letter-spacing: 0;
   line-height: normal;
   min-height: 14px;
   text-align: center;
   width: 21px;
 }

 .mobile-swap-box .overlap-group-18 {
   align-items: flex-start;
   align-self: flex-end;
   background-size: 100% 100%;
   display: flex;
   height: 19px;
   justify-content: flex-end;
   min-width: 45px;
   padding: 1.9px 9.9px;
 }

 .mobile-swap-box .half-1 {
   letter-spacing: 0;
   line-height: normal;
   min-height: 14px;
   text-align: center;
   width: 23px;
 }

 .mobile-swap-box .frame-3404 {
   align-items: center;
   min-width: 94px;
   margin-top: 14px;
   margin-left: 36px;
   display: flex;
 }

 .mobile-swap-box .usdvalue {
   position: absolute;
   top: 113px;
   left: 0px;
 }

 .mobile-swap-box .price-1 {
   letter-spacing: 0;
   line-height: normal;
   margin-bottom: 2.95px;
   margin-left: 6px;
   min-height: 21px;
   width: 43px;
 }

 .mobile-swap-box .path-530-1 {
   height: 7px;
   margin-left: 1px;
   margin-top: 3.03px;
   width: 12px;
 }

 .mobile-swap-box .frame-3407 {
   height: 34px;
   margin-right: 2.0px;
   margin-top: 19px;
   width: 35px;
   z-index: 9;
 }

 .mobile-swap-box .frame-4 {
   width: 690px;
   height: 34px;
   margin-left: 0.35px;
   margin-top: 15px;
 }

 .mobile-swap-box .titilliumweb-normal-hint-of-red-14px {
   color: var(--hint-of-red);
   font-family: var(--font-family-titillium_web);
   font-size: var(--font-size-xxxl);
   font-style: normal;
   font-weight: 400;
   margin-left: 3px;
 }

 .mobile-swap-box .token {
   letter-spacing: 0;
   width: 41px;
   min-height: 21px;
   margin-top: -158px;
   margin-left: 6px;
   line-height: normal;
 }

 .mobile-swap-box .frame-2-1 {
   align-items: center;
   display: flex;
   gap: 14px;
   height: 45px;
   margin-right: 2.0px;
   margin-top: 19px;
   padding: 0px 0px 0px 27px;
   position: relative;
   width: 338px;
 }

 .mobile-swap-box .to-1 {
   letter-spacing: 0;
   line-height: normal;
   position: relative;
   width: 55px;
 }

 .mobile-swap-box .walletbalance {
   position: absolute;
   top: 114px;
   right: 15px;
 }

 .mobile-swap-box .usdt-6 {
   height: 45px;
   min-width: 238px;
   position: relative;
   margin-right: 5%;
 }

 .mobile-swap-box .path-530 {
   /* width: 12px; */
   /* height: 6px; */
   margin-top: -17px;
   margin-bottom: 53px;
   margin-left: 67px;
   cursor: pointer;
 }

 .mobile-swap-box .overlap-group-19 {
   align-items: flex-start;
   border: 1px solid;
   border-color: var(--cerulean);
   border-radius: 7px;
   display: flex;
   gap: 5px;
   height: 45px;
   min-width: 236px;
   padding: 6.8px 11.6px;
 }

 .mobile-swap-box .path-529 {
   position: absolute;
   width: 31px;
   height: 31px;
   margin-top: -20px;
   margin-left: -31px;
   top: -143px;
 }

 .mobile-swap-box .path-538-3 {
   height: 31px;
   width: 31px;
 }

 .mobile-swap-box .usdt-7 {
   align-self: center;
   height: 21px;
   margin-top: 5%;
   position: relative;
   width: 166px;
 }

 .mobile-swap-box .frame-3410 {
   align-items: center;
   display: flex;
   justify-content: center;
   margin-right: 2.0px;
   margin-top: 22px;
   position: relative;
   width: 326px;
 }

 .mobile-swap-box .overlap-group-20 {
   align-items: flex-start;
   background-color: var(--black-rock);
   border-radius: 12px;
   display: flex;
   flex-direction: column;
   gap: 18px;
   min-height: 113px;
   padding: 2px 17px;
   width: 326px;
 }

 .mobile-swap-box .send-2 {
   letter-spacing: 0;
   line-height: normal;
   margin-left: 1px;
   margin-top: 15px;
   min-height: 19px;
   width: 150px;
 }

 .mobile-swap-box .frame-6-3 {
   align-items: center;
   cursor: pointer;
   display: flex;
   gap: 4px;
   justify-content: center;
   margin-right: 1.0px;
   margin-top: 24px;
   position: relative;
   transition: all 0.2s ease;
   width: fit-content;
 }

 .mobile-swap-box .frame-6-3:hover {
   transform: scale(1.1);
 }

 .mobile-swap-box .ab-3 {
   height: 40.39px;
   margin-right: -2.00px;
   min-width: 289px;
   position: relative;
 }

 .mobile-swap-box .overlap-group-21 {
   align-items: flex-start;
   background-size: 100% 100%;
   display: flex;
   height: 40px;
   min-width: 287px;
   padding: 7.0px 0;
 }

 .mobile-swap-box .approve-3 {
   letter-spacing: 0;
   line-height: normal;
   min-height: 25px;
   text-align: center;
   width: 287px;
 }

 .mobile-swap-box .frame-3417 {
   display: flex;
   height: 551px;
   justify-content: center;
   min-width: 334px;
   position: relative;
 }

 .mobile-swap-box .sub-1 {
   align-items: center;
   display: flex;
   flex-direction: column;
   gap: 42px;
   height: 547px;
   margin-left: -2px;
   position: relative;
   width: 318px;
 }

 .mobile-swap-box .sub-box-1 {
   align-items: center;
   align-self: stretch;
   display: flex;
   flex-direction: column;
   gap: 10px;
   padding: 20px 0px 0px;
   position: relative;
 }

 .mobile-swap-box .squid {
  width: 15px;
  position: absolute;
  bottom: 7px;
  right: 23px;
}

.mobile-swap-box .powered-by {
  font-family: var(--font-family-titillium_web);
  color: #fff;
  width: 100px;
  padding-right: 10px;
  font-size: 8px;
  font-style: normal;
  position: absolute;
  bottom: 15px;
  right: -18px;
}