.token-box {
  align-items: center;
  border: 1px none;
  display: flex;
  justify-content: center;
  height: 649px;
  min-width: 623px;
  overflow-x: hidden;
  background-color: rgba(0, 0, 0, 0);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); 
  z-index: 99;
}

.overlap-group45 {
  align-items: flex-end;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  min-height: 649px;
  padding: 21.6px 23.0px;
  width: 624px;
}

.flex-row27 {
  align-items: flex-start;
  display: flex;
  gap: 213px;
  height: 40px;
  min-width: 338px;
}

.select-source-token {
  color: #f9f9f9;
  font-family: var(--font-family-titillium_web);
  font-size: var(--font-size-s);
  letter-spacing: 0;
  align-self: flex-end;
  min-width: 99px;
  min-height: 24px;
  font-weight: 700;
  line-height: normal;
  margin-right: -410px;
}

.x {
  height: 25px;
  width: 26px;
  cursor: pointer; /* Add cursor pointer */
}

.overlap-group205 {
  align-items: flex-start;
  border: 1px solid;
  border-color: #33a4d8;
  border-radius: 7px;
  display: flex;
  height: 45px;
  margin-right: 0.01px;
  margin-top: 29px;
  min-width: 577px;
  padding: 11px 17px;
}

.search-token-by-name-or-address {
  background-color: transparent;
  border: 0;
  color: white;
  font-family: var(--font-family-titillium_web);
  font-size: var(--font-size-xs);
  font-weight: 400;
  height: 21px;
  letter-spacing: 0;
  line-height: normal;
  padding: 0;
  width: 542px;
}

.search-token-by-name-or-address::placeholder {
  color: #7b7b7b99;
}

.overlap-group58 {
  align-items: center;
  align-self: center;
  background-color: #212124;
  border-radius: 7px;
  display: flex;
  gap: 27px;
  height: 62px;
  margin-left: 0.04px;
  margin-top: 26px;
  min-width: 576px;
  padding: 11.0px 22.0px;
}

.group-299 {
  height: 22px;
  margin-top: 4.35px;
  width: 19px;
}

.below-is-the-support {
  align-self: flex-end;
  display: flex;
  height: 36px;
  width: 372px;
}

.below-is-the-support-1 {
  color: white;
  flex: 1;
  font-family: var(--font-family-titillium_web);
  font-size: var(--font-size-m);
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18.4px;
  width: 370px;
  font-size: 12px;
}

.scroll-group-10::-webkit-scrollbar {
  display: none;
  width: 0;
}

.scroll-group-10 {
  align-items: flex-start;
  align-self: center;
  display: flex;
  flex-direction: column;
  gap: 13px;
  height: 387px;
  margin-top: 14px;
  overflow: hidden;
  overflow-y: scroll;
  position: relative;
  width: 576px;
}

.scroll-group-10-item { 
  height: 62px;
  margin-right: -4.00px;
  min-width: 576px;
  position: relative;
  cursor: pointer; /* Add cursor pointer */
}

.overlap-group-181 {
  align-items: center;
  background-color: var(--onyx);
  border-radius: 7px;
  display: flex;
  height: 62px;
  min-width: 576px;
  padding: 11.1px 13.1px;
}

.overlap-group-181.active {
  background-color: #727278; /* Lighter tone of your choice */
}

.overlap-group-181.active  {
  transition: background-color 0.3s ease;
}

.overlap-group-181:hover {
  background-color: #727278; /* Lighter tone for hover, same as the active state */
}

.group {
  align-self: flex-start;
  height: 38px;
  width: 38px;
}

.tether-usd,
.wrapped-matic {
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: 1.17px;
  margin-left: 16px;
  min-height: 25px;
  width: 227px;
}

.usdt1 {
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: 2.96px;
  margin-left: 97px;
  min-height: 25px;
  text-align: right;
  width: 159px;
}

.overlap-group172 {
  align-items: center;
  background-color: var(--onyx);
  border-radius: 7px;
  display: flex;
  height: 62px;
  min-width: 576px;
  padding: 11.1px 13.7px;
}

.xgt,
.usd {
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: 1.17px;
  margin-left: 15px;
  min-height: 25px;
  width: 227px;
}

.xgt-1 {
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: 0.96px;
  margin-left: 123px;
  min-height: 25px;
  text-align: right;
  width: 133px;
}

.overlap-group206 {
  align-items: center;
  background-color: var(--onyx);
  border-radius: 7px;
  display: flex;
  height: 62px;
  min-width: 576px;
  padding: 11.0px 13.9px;
}

.usdc {
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: 0.96px;
  margin-left: 129px;
  min-height: 25px;
  text-align: right;
  width: 127px;
}

.overlap-group399 {
  align-items: center;
  background-color: var(--onyx);
  border-radius: 7px;
  display: flex;
  height: 62px;
  min-width: 576px;
  padding: 11.3px 13.4px;
}

.w-matic {
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: 0.96px;
  margin-left: 137px;
  min-height: 25px;
  text-align: right;
  width: 119px;
}


.token-box .info-selection {
  color: #f9f9f9;
  font-family: var(--font-family-titillium_web);
  letter-spacing: 0;
  width: 307px;
  margin-top: 46px;
  margin-right: -100px;
  font-size: 9px;
}
