:root { 
  --alto: #d9d9d9;
  --black: #000000;
  --black-rock: #0a0930;
  --ceramic: #fcfcfc;
  --cerulean: #33a4d8;
  --cerulean-2: #29a5dc;
  --desert-storm: #f7f8f9;
  --electric-violet: #6300ff;
  --havelock-blue: #3f89d5;
  --hint-of-red: #f9f9f9;
  --kingfisher-daisy: #41009e;
  --scooter: #35cbfd;
  --shark: #2a2a2a;
  --white: #ffffff;
 
  --font-size-l: 10.8px;
  --font-size-m: 9.2px;
  --font-size-xl: 12px;
  --font-size-xxl: 12.6px;
  --font-size-xxxl: 14px;
  --font-size-xxxxl: 15px;
  --font-size-xxxxxl: 23px;
 
  --font-family-bebas_neue_pro-bold: "Bebas Neue Pro-Bold", Helvetica;
  --font-family-bebas_neue_pro-book: "Bebas Neue Pro-Book", Helvetica;
  --font-family-bebas_neue_pro-light: "Bebas Neue Pro-Light", Helvetica;
  --font-family-bebas_neue_pro-regular: "Bebas Neue Pro-Regular", Helvetica;
  --font-family-oswald: "Oswald", Helvetica;
  --font-family-roboto: "Roboto", Helvetica;
  --font-family-titillium_web: "Titillium Web", Helvetica;
}
.titilliumweb-normal-white-9-2px {
  color: var(--white);
  font-family: var(--font-family-titillium_web);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.titilliumweb-normal-hint-of-red-12-6px {
  color: var(--hint-of-red);
  font-family: var(--font-family-titillium_web);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 400;
}

.titilliumweb-normal-havelock-blue-14px {
  color: var(--havelock-blue);
  font-family: var(--font-family-titillium_web);
  font-size: var(--font-size-xxxl);
  font-style: normal;
  font-weight: 400;
}

.titilliumweb-bold-desert-storm-37-6px {
  color: var(--desert-storm);
  font-family: var(--font-family-titillium_web);
  font-size: 37.6px;
  font-style: normal;
  font-weight: 700;
}

.bebasneuepro-book-normal-white-29px {
  color: var(--white);
  font-family: var(--font-family-bebas_neue_pro-book);
  font-size: 29px;
  font-style: normal;
  font-weight: 400;
}

.titilliumweb-normal-hint-of-red-7-3px {
  color: var(--hint-of-red);
  font-family: var(--font-family-titillium_web);
  font-size: 7.3px;
  font-style: normal;
  font-weight: 400;
}

.titilliumweb-bold-cerulean-9-5px {
  color: var(--cerulean-2);
  font-family: var(--font-family-titillium_web);
  font-size: 9.5px;
  font-style: normal;
  font-weight: 700;
}

.titilliumweb-normal-ceramic-9-5px {
  color: var(--ceramic);
  font-family: var(--font-family-titillium_web);
  font-size: 9.5px;
  font-style: normal;
  font-weight: 400;
}

.titilliumweb-bold-havelock-blue-14px {
  color: var(--havelock-blue);
  font-family: var(--font-family-titillium_web);
  font-size: var(--font-size-xxxl);
  font-style: normal;
  font-weight: 700;
}

.titilliumweb-bold-ceramic-18-8px {
  color: var(--ceramic);
  font-family: var(--font-family-titillium_web);
  font-size: 18.8px;
  font-style: normal;
  font-weight: 700;
}

.roboto-normal-shark-19-2px {
  color: var(--shark);
  font-family: var(--font-family-roboto);
  font-size: 19.2px;
  font-style: normal;
  font-weight: 400;
}

.titilliumweb-normal-white-19-2px {
  color: var(--white);
  font-family: var(--font-family-titillium_web);
  font-size: 19.2px;
  font-style: normal;
  font-weight: 400;
}

.bebasneuepro-bold-cerulean-29px {
  color: var(--cerulean-2);
  font-family: var(--font-family-bebas_neue_pro-bold);
  font-size: 29px;
  font-style: normal;
  font-weight: 700;
}

.bebasneuepro-bold-cerulean-19px {
  color: var(--cerulean-2);
  font-family: var(--font-family-bebas_neue_pro-bold);
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
}

.titilliumweb-normal-electric-violet-10-8px {
  color: var(--electric-violet);
  font-family: var(--font-family-titillium_web);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 400;
}

.titilliumweb-normal-hint-of-red-10-8px {
  color: var(--hint-of-red);
  font-family: var(--font-family-titillium_web);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 400;
}

.bebasneuepro-book-normal-white-19px {
  color: var(--white);
  font-family: var(--font-family-bebas_neue_pro-book);
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
}

.titilliumweb-normal-white-14px {
  font-family: var(--font-family-titillium_web);
  font-size: var(--font-size-xxxl);
  font-style: normal;
  font-weight: 400;
}

.titilliumweb-normal-hint-of-red-14px {
  color: var(--hint-of-red);
  font-family: var(--font-family-titillium_web);
  font-size: var(--font-size-xxxl);
  font-style: normal;
  font-weight: 400;
}

.oswald-normal-ceramic-25px {
  color: var(--ceramic);
  font-family: var(--font-family-oswald);
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
}

.titilliumweb-bold-hint-of-red-15px {
  color: var(--hint-of-red);
  font-family: var(--font-family-titillium_web);
  font-size: var(--font-size-xxxxl);
  font-style: normal;
  font-weight: 700;
}
