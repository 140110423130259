@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");
@import url("https://fonts.googleapis.com/css?family=Titillium+Web:400,700|Roboto:400|Oswald:400");
/* The following line is used to measure usage of this code. You can remove it if you want. */
@import url("https://px.animaapp.com/647b47800355d4480479b720.647b47823710fef232e24ad8.GVjCGqt.rcp.png");

@font-face {
  font-family: "Bebas Neue Pro-Regular";
  font-style: normal;
  font-weight: 400;
  src: url("static/fonts/Dharma Type - Bebas Neue Pro Regular.otf") format("opentype");
}
@font-face {
  font-family: "Bebas Neue Pro-Light";
  font-style: normal;
  font-weight: 300;
  src: url("static/fonts/Dharma Type - Bebas Neue Pro Light.otf") format("opentype");
}
@font-face {
  font-family: "Bebas Neue Pro-Bold";
  font-style: normal;
  font-weight: 700;
  src: url("static/fonts/Dharma Type - Bebas Neue Pro Bold.otf") format("opentype");
}
@font-face {
  font-family: "Bebas Neue Pro-Book";
  font-style: normal;
  font-weight: 400;
  src: url("static/fonts/Dharma Type - Bebas Neue Pro Book.otf") format("opentype");
}


.screen a {
  display: contents;
  text-decoration: none;
}

input:focus {
  outline: none;
}

* {
  box-sizing: border-box;
}
