.ipad-3 {
  background-position: initial;
  background-size: cover;
  border: 1px;
  flex-direction: column;
  align-items: center;
  gap: 80px;
  min-width: 768px;
  padding: 75px 45px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.ipad-3 .overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 98;
}

.ipad-3 .open-widget-button,
.ipad-3 .close-widget-button {
  color: #fff;
  text-align: center;
  cursor: pointer;
  z-index: 101;
  background-color: #0000;
  border: 1px solid #f0f;
  border-radius: 10px;
  width: 125px;
  height: 44px;
  font-family: Oswald, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 41px;
  transition: background-color 0.3s, transform 0.3s;
  position: absolute;
  top: -1175px;
  right: 270px;
}

.ipad-3 .open-widget-button:hover,
.ipad-3 .close-widget-button:hover {
  background-color: #f0f;
  transform: scale(1.05);
}

.ipad-3 .widget-container {
  z-index: 99;
  width: 400px;
  position: absolute;
  top: -1250px;
  left: 122px;
}

.ipad-3 .alphalaunchbanner {
  margin-top: -20px;
  margin-bottom: -99px;
  position: relative;
}

.ipad-3 .alphaimg {
  position: relative;
  width: 650px;
}

.ipad-3 .frame-3416-2 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 75px;
  position: relative;
}

.ipad-3 .send {
  letter-spacing: 0;
  align-self: flex-end;
  width: 232px;
  min-height: 6px;
  line-height: normal;
  margin-left: -4px;
}

.ipad-3 .overlap-group1 {
  justify-content: space-between;
  min-width: 350px;
  height: 19px;
  padding: 2.5px 11.2px;
  display: flex;
  margin-bottom: -85px;
}

.ipad-3 .usdvalue {
  letter-spacing: 0;
  width: 20px;
  min-height: 14px;
  margin-left: -244px;
  line-height: normal;
}

.ipad-3 .usdvaluerecieve {
  letter-spacing: 0;
  width: 233px;
  line-height: normal;
  margin-top: -25px;
  margin-left: 20px;
}

.ipad-3 .nav-3 {
  justify-content: space-between;
  align-self: center;
  align-items: center;
  min-width: 688px;
  height: 46px;
  padding: 10px 105px;
  display: flex;
  position: relative;
}

.ipad-3 .ramp-widget {
  width: 781px;
  height: 541px;
  margin-top: 80px;
  margin-bottom: -200px;
}

@media (min-width: 768px) {
  .ramp-widget {
    width: 400px;
  }
}

@media (min-width: 1025px) {
  .ramp-widget {
    width: 500px;
  }
}

.ipad-3 .logo-3 {
  width: 178px;
  height: 33px;
  margin-top: -1px;
  margin-left: -80px;
}

.ipad-3 .my-custom-wrapper {
  border-radius: 12px;
  display: inline-block;
  overflow: hidden;
}

.ipad-3 .my-custom-class {
  cursor: pointer;
  font-family: var(--font-family-bebas_neue_pro-regular) !important;
  /* Replace this with the actual font family value */
  font-weight: bold;
  position: relative;
}

.ipad-3 .img-wrapper {
  --webkit-backdrop-filter: blur(10px) brightness(100%);
  -webkit-backdrop-filter: blur(10px) brightness();
  backdrop-filter: blur(10px) brightness();
  border-radius: 10px;
  width: 44px;
  height: 44px;
  position: absolute;
  right: 35px;
  cursor: pointer;
}

.img-wrapper:hover {
  animation: shake 5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}

@keyframes shake {
  10%,
  90% {
    transform: translateX(-5px);
    /* Move left */
  }

  20%,
  80% {
    transform: translateX(5px);
    /* Move right */
  }

  30%,
  50%,
  70% {
    transform: translateX(-3px);
    /* Move left */
  }

  40%,
  60% {
    transform: translateX(3px);
    /* Move right */
  }
}

.ipad-3 .overlap-b-group.open .burger-menu-container {
  z-index: 9;
  background-color: #d9d9d9;
  border-radius: 1px 1px 10px 10px;
  width: 250px;
  height: 410px;
  position: absolute;
  top: -5px;
  right: 0px;
}

.ipad-3 .overlap-b-group.open .burger-2 {
  -webkit-backdrop-filter: blur(10px) brightness();
  backdrop-filter: blur(10px) brightness();
  z-index: 9;
  border-radius: 10px;
  width: 33px;
  height: 33px;
  position: absolute;
  top: 16px;
  left: 233px;
}

.ipad-3 .frame-3413-4 {
  min-width: 677px;
  height: 342px;
  display: flex;
  position: relative;
  top: 10px;
}

.ipad-3 .flex-row-4 {
  align-items: center;
  gap: 205px;
  min-width: 559px;
  height: 57px;
  display: flex;
}

.ipad-3 .swap-box-2 {
  min-width: 690.69px;
  height: 535.6px;
  position: relative;
  top: 50px;
}

.ipad-3 .swap-box-2 .frame-container {
  border: 3px solid;
  border-color: var(--scooter);
  border-radius: 22px;
  flex-direction: column;
  align-items: center;
  gap: 26px;
  width: 768px;
  min-height: 636px;
  padding: 47.4px 58.7px;
  display: flex;
  position: relative;
  top: 50px;
}

.ipad-3 .buy {
  letter-spacing: 0px;
  text-align: center;
  cursor: pointer;
  flex: 1 1 0%;
  width: 41px;
  margin-left: 41.5%;
  margin-right: 38%;
  line-height: normal;
}

.ipad-3 .swap-box-4 {
  border-radius: 25px;
  min-width: 787px;
  height: 547px;
  margin-top: 75px;
  margin-bottom: -210px;
  position: relative;
  overflow-y: auto;
}

.ipad-3 .heromenu-box-3 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 55px;
  height: 300px;
  margin-left: 0.5px;
  position: relative;
  width: 677px;
}

.ipad-3 .hero-box-3 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 58px;
  height: 205px;
  padding: 20px 0px;
  position: relative;
}

.ipad-3 .frame-3413-5 {
  height: 185px;
  margin-bottom: -20px;
  min-width: 676px;
  position: relative;
}

.ipad-3 .menu-box {
  border: 1px solid;
  border-color: var(--cerulean);
  border-radius: 9px;
  justify-content: center;
  min-width: 614px;
  height: 43px;
  display: flex;
  position: relative;
  top: 120px;
}

.flex-col-9 {
  flex-direction: column;
  align-items: center;
  width: 676px;
  min-height: 158px;
  display: flex;
  position: relative;
}

.ipad-3 .frame-container-2 {
  border: 2px solid;
  border-color: var(--scooter);
  border-radius: 22px;
  flex-direction: column;
  align-items: flex-start;
  width: 690px;
  min-height: 636px;
  padding: 48px 44px;
  display: flex;
  position: relative;
  top: 110px;
}

.title-3 {
  color: var(--ceramic);
  font-family: var(--font-family-bebas_neue_pro-regular);
  font-size: 96px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 85.4px;
  min-height: 83px;
  text-align: center;
  white-space: nowrap;
  width: 676px;
  margin-bottom: 15px;
}

.fees-so-low-it-will-3 {
  color: var(--ceramic);
  font-family: var(--font-family-bebas_neue_pro-light);
  font-size: 46px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 41.4px;
  margin-right: 1px;
  margin-top: 1px;
  min-height: 43px;
  text-align: center;
  white-space: nowrap;
  width: 675px;
}

.vector-1-3 {
  height: 3px;
  margin-top: 28px;
  width: 281px;
}

.frame-3415-1 {
  justify-content: center;
  align-self: stretch;
  min-width: 688px;
  height: 415.52px;
  display: flex;
  position: relative;
  top: 0px;
}

.sub-3 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 56px;
  height: 415.52px;
  padding: 21px 0px 0px;
  position: relative;
  width: 686px;
}

.sub-box-3 {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 13px;
  padding: 20px 0 0;
  display: flex;
  position: relative;
  top: 160px;
}

.join-a-global-community-3 {
  align-self: stretch;
  height: 53px;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.line-33-3 {
  min-width: 173.83px;
  height: 1.44px;
  position: relative;
  margin-right: 1px;
}

.learn-more-about-xio-3 {
  align-self: stretch;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
}

.email-box-2 {
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 15px;
  display: flex;
  position: relative;
  top: 160px;
}

.social-box-3 {
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 33px;
  display: flex;
  position: relative;
  top: 175px;
}

.footer-3 {
  background-color: #0000;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 30.4px;
  min-width: 763px;
  height: 54px;
  margin-left: -37.5px;
  margin-right: -37.5px;
  display: flex;
  position: relative;
  top: 180px;
}

.line-34-3 {
  height: 1px;
  margin-top: -0.5px;
  width: 690px;
}

.ipad-3 .text-1 {
  letter-spacing: 0;
  width: 232px;
  min-height: 57px;
  line-height: normal;
  margin-bottom: 50px;
  white-space: nowrap;
  border: none;
  background-color: transparent;
}

.ipad-3 .text-1::-webkit-inner-spin-button,
.ipad-3 .text-1::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.ipad-3 .text-1 {
  -moz-appearance: textfield;
  /* Firefox */
}

.ipad-3 .text-2 {
  letter-spacing: 0;
  width: 300px;
  min-height: 57px;
  margin-top: -20px;
  line-height: normal;
}

.ipad-3 .walletbalance {
  letter-spacing: 0;
  text-align: center;
  min-height: 14px;
  line-height: normal;
  margin-right: -70px;
}

.frame-3399-3 {
  align-items: flex-start;
  display: flex;
  gap: 482px;
  height: 23px;
  justify-content: center;
  margin-left: 1px;
  position: relative;
  width: 685px;
}

.ipad-3 .frame-3403 {
  cursor: pointer;
  justify-content: center;
  align-items: flex-end;
  min-width: 91px;
  margin-bottom: 130.06px;
  display: flex;
}

.ipad-3 .copyright-3 {
  color: #fff;
  letter-spacing: 0;
  width: 107px;
  margin-top: -1px;
  font-family: "Bebas Neue Pro-Light", Helvetica;
  font-size: 14px;
  line-height: normal;
  position: relative;
}

.ipad-3 .about-us-blog-t-cs-privacy-policy-3 {
  letter-spacing: 0;
  color: #fff;
  text-align: right;
  width: 96px;
  font-size: 12px;
  margin-top: -1px;
  line-height: normal;
  position: relative;
  right: 3px;
}

.ipad-3 .overlap-group1-9 {
  align-items: flex-start;
  background-image: url(/static/img/path-532-2@2x.png);
  background-size: 100% 100%;
  justify-content: flex-end;
  align-items: flex-start;
  min-width: 46px;
  height: 19px;
  margin-bottom: 0.22px;
  margin-left: 212px;
  padding: 2px 12px;
  display: flex;
}

.ipad-3 .overlap-group-25 {
  align-items: flex-start;
  background-image: url(/static/img/path-531-2@2x.png);
  background-size: 100% 100%;
  justify-content: flex-end;
  align-items: flex-start;
  min-width: 46px;
  height: 19px;
  margin-left: 5px;
  padding: 2px 10.2px;
  display: flex;
}

.ipad-3 .overlap-group-12 {
  background-size: 100% 100%;
  align-items: flex-start;
  min-width: 334px;
  height: 47px;
  padding: 13px 0;
  display: flex;
}

.ipad-3 .frame-34 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  gap: 55px;
  justify-content: space-around;
  position: relative;
  width: 1034px;
}

.ipad-3 .b-2 {
  align-self: stretch;
  position: relative;
  width: 1033px;
}

.ipad-3 .XGT-MARKET-4 {
  color: #35cbfd;
  letter-spacing: 0;
  text-align: center;
  width: 1025px;
  font-family: "Bebas Neue Pro-Regular", Helvetica;
  font-size: 26px;
  font-weight: 400;
  line-height: 23.1px;
  position: absolute;
  top: 30px;
  left: 8px;
}

.ipad-3 .text-wrapper-62 {
  color: #35cbfd;
  font-family: "Bebas Neue Pro-Regular", Helvetica;
  font-size: 26px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 23.1px;
}

.ipad-3 .text-wrapper-63 {
  font-family: "Bebas Neue Pro-Light", Helvetica;
  font-weight: 300;
}

.ipad-3 .terms-link,
.ipad-3 .privacy-link {
  font-family: "Bebas Neue Pro-Light", Helvetica;
  color: #f9f9f9;
  cursor: pointer;
  min-width: 125px;
  margin-left: -30px;
  font-size: 12px;
  transition: color 0.3s;
  margin-top: 3px;
}

.ipad-3 .terms-link:hover,
.ipad-3 .privacy-link:hover {
  color: #35cbfd;
  /* Change color on hover */
}

.ipad-3 .feebox-ipad {
  position: relative;
  margin-top: 10px;
}

.ipad-3 .fee-box-d-expanded {
  z-index: 9;
  background-color: #0000;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-top: 97px;
  margin-bottom: -200px;
  display: flex;
}

.ipad-3 .fee-box-d-minimised {
  z-index: 9;
  background-color: #0000;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-top: 97px;
  margin-bottom: -390px;
  display: flex;
}

.ipad-3 .fee-box-d-expanded .div {
  border: 2px solid;
  border-color: #35cbfd;
  border-radius: 22px;
  height: 245px;
  overflow-x: hidden;
  position: relative;
  width: 675px;
}

.ipad-3 .fee-box-d-minimised .overlap-group {
  border: 3px solid #35cbfd;
  border-radius: 18px;
  width: 675px;
  height: 63px;
  position: relative;
}

.ipad-3 .footer-section {
  width: 768px;
  height: 208px;
  margin-top: 250px;
}

.ipad-3 .footer-section .frame {
  justify-content: space-between;
  align-items: flex-start;
  width: 768px;
  display: flex;
  position: relative;
  top: 31px;
}

.ipad-3 .footer-section .line {
  width: 768px;
  height: 1px;
  margin-top: -0.5px;
}
