.overlap-b-group.open {
  .overlap-b-group-wrapper {
    height: 464px;
    left: 1144px;
    position: relative;
    top: 24px;
    width: 230px;
  }

  .MENU {
    flex-direction: column;
    justify-content: center;
    width: 100%;
    display: flex;
    align-content: space-around;
    flex-wrap: wrap;
  }

  .MENU .div {
    background-image: url(../../../static/img/rectangle-2920.png);
    background-size: 100% 100%;
    width: 293.53px;
    height: 485.73px;
    position: relative;
    top: -5px;
  }

  .MENU .xionpay {
    height: 24px;
    left: 148px;
    object-fit: cover;
    position: absolute;
    top: 141px;
    width: 113px;
    cursor: pointer;
  }

  .MENU .overlap-group {
    height: 319px;
    left: -89px;
    position: absolute;
    top: 198px;
    width: 350px;
  }

  .MENU .frame {
    height: 240px;
    left: 123px;
    position: absolute;
    top: 0;
    width: 227px;
  }

  .MENU .text-wrapper {
    color: #ffffff;
    font-family: "Titillium Web", Helvetica;
    font-size: 22.4px;
    font-weight: 600;
    height: 34px;
    left: 106px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: right;
    top: -1px;
  }

  .MENU .vector {
    cursor: pointer;
    width: 150px;
    height: 26px;
    position: absolute;
    top: 89px;
    left: 198px;
  }

  .MENU .group-42 {
    height: 31px;
    left: 107px;
    position: absolute;
    top: 46px;
    width: 116px;
    cursor: pointer;
  }

  .MENU .imgmenu {
    height: 26px;
    left: 106px;
    position: absolute;
    top: 131px;
    width: 116px;
    cursor: pointer;
  }

  .MENU .group-21 {
    height: 27px;
    left: 119px;
    position: absolute;
    top: 172px;
    width: 103px;
    cursor: pointer;
  }

  .MENU .line {
    height: 1px;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 24px;
    width: 99px;
  }

  .MENU .vector-2 {
    cursor: pointer;
    width: 157px;
    height: 26px;
    position: absolute;
    top: 219px;
    left: 67px;
  }

  .MENU .spaceman {
    height: 239px;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 80px;
    width: 195px;
  }

  .MENU .group-3 {
    height: 34px;
    left: 70px;
    position: absolute;
    top: 74px;
    width: 193px;
  }

  .MENU .text-wrapper-2 {
    color: #6300ff;
    font-family: "Titillium Web", Helvetica;
    font-size: 22.4px;
    font-weight: 600;
    height: 34px;
    left: 26px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: right;
    top: 0;
  }

  .MENU .nftimage {
    color: #6300ff;
    z-index: 9;
    width: 21px;
    height: 24px;
    position: absolute;
    top: 6px;
    left: -3px;
  }

  .burger {
    cursor: pointer;
    z-index: 9;
    width: 14px;
    height: 13px;
    position: absolute;
    top: -5px;
    left: 10px;
  }


  .burger-menu-container {
    z-index: 9;
    position: absolute;
    top: 0px;
    right: -70px;
  }

  .overlap-b-group {
    background-color: #d9d9d9;
    height: 488px;
    position: relative;
    top: -24px;
    width: 295px;
  }

  .burger-2 {
    -webkit-backdrop-filter: blur(10px) brightness();
    backdrop-filter: blur(10px) brightness();
    border-radius: 10px;
    width: 33px;
    height: 33px;
    position: absolute;
    top: 16px;
    left: 255px;
    z-index: 9;
  }

  .burger-outline {
    width: 30px;
    height: 30px;
    margin-top: -14px;
    margin-left: 2px;
  }

  .text-wrapper-2 {
    color: #74639f;
    letter-spacing: -1.46px;
    text-align: right;
    cursor: pointer;
    height: 34px;
    font-family: Titillium Web, Helvetica;
    font-size: 22.4px;
    font-weight: 600;
    line-height: normal;
    position: absolute;
    top: 143px;
    left: 66px;
  }

  .xionpay-2 {
    object-fit: cover;
    cursor: pointer;
    width: 113px;
    height: 24px;
    position: absolute;
    top: 197px;
    left: 32px;
  }

  .frame-4 {
    height: 196px;
    left: 79px;
    position: absolute;
    top: 242px;
    width: 155px;
  }

  .vector-5 {
    z-index: 9;
    cursor: pointer;
    width: 123px;
    height: 33px;
    position: absolute;
    top: 0px;
    left: -50px;
    right: 0;
  }

  .vector-6 {
    cursor: pointer;
    width: 189px;
    height: 33px;
    position: absolute;
    top: 50px;
    left: -49px;
  }

  .vector-7 {
    cursor: pointer;
    width: 171px;
    height: 25px;
    position: absolute;
    top: 100px;
    left: -47px;
  }

  .vector-8 {
    cursor: pointer;
    width: 153px;
    height: 22px;
    position: absolute;
    top: 203px;
    left: -48px;
  }

  .bscscan-2 {
    object-fit: cover;
    cursor: pointer;
    width: 140px;
    height: 32px;
    position: absolute;
    top: 158px;
    left: -50px;
  }

  .frame-5 {
    cursor: pointer;
    width: 26px;
    height: 29px;
    position: relative;
    top: 147px;
    left: 28px;
  }

  .line-2 {
    object-fit: cover;
    width: 130px;
    height: 1px;
    position: relative;
    top: 4px;
    left: -150px;
  }

  display: contents;
}


/* Add a CSS class for the closed state */
.overlap-b-group {
  display: none;
  /* Hide the element when it's closed */
}