.nft-i .ipad-nft {
  align-items: center;
  background-position: initial;
  background-size: cover;
  border: 1px none;
  display: flex;
  flex-direction: column;
  gap: 75px;
  min-width: 768px;
  padding: 81px;
  position: relative;
  overflow: hidden;
}

.nft-i .frame-3416-2 {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 75px;
  display: flex;
  position: relative;
  bottom: 30px;
}

.nft-i .nav-3 {
  justify-content: space-between;
  align-self: center;
  align-items: center;
  min-width: 688px;
  height: 46px;
  padding: 10px 105px;
  display: flex;
  position: relative;
}

.nft-i .logo-3 {
  width: 178px;
  height: 33px;
  margin-top: -1px;
  margin-left: -80px;
}

.nft-i .my-custom-wrapper {
  border-radius: 12px;
  display: inline-block;
  overflow: hidden;
}

.nft-i .my-custom-class {
  cursor: pointer;
  font-family: var(--font-family-bebas_neue_pro-regular) !important;
  /* Replace this with the actual font family value */
  font-weight: bold;
  position: relative;
}

.nft-i .img-wrapper {
  --webkit-backdrop-filter: blur(10px) brightness(100%);
  -webkit-backdrop-filter: blur(10px) brightness();
  backdrop-filter: blur(10px) brightness();
  border-radius: 10px;
  width: 44px;
  height: 44px;
  position: absolute;
  right: 35px;
  cursor: pointer;
}

.nft-i .overlap-b-group.open .burger-menu-container {
  z-index: 9;
  background-color: #d9d9d9;
  border-radius: 1px 1px 10px 10px;
  width: 250px;
  height: 410px;
  position: absolute;
  top: -5px;
  right: 0px;
}

.nft-i .overlap-b-group.open .burger-2 {
  -webkit-backdrop-filter: blur(10px) brightness();
  backdrop-filter: blur(10px) brightness();
  z-index: 9;
  border-radius: 10px;
  width: 33px;
  height: 33px;
  position: absolute;
  top: 16px;
  left: 233px;
}

.nft-i .frame-3413-4 {
  min-width: 677px;
  height: 342px;
  display: flex;
  position: relative;
  top: 10px;
}

.nft-i .heromenu-box-3 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 55px;
  height: 300px;
  margin-left: 0.5px;
  position: relative;
  width: 677px;
}

.nft-i .hero-box-3 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 58px;
  height: 205px;
  padding: 20px 0px;
  position: relative;
}

.nft-i .frame-3413-5 {
  height: 185px;
  margin-bottom: -20.00px;
  min-width: 676px;
  position: relative;
}

.nft-i .flex-col-9 {
  flex-direction: column;
  align-items: center;
  width: 676px;
  min-height: 158px;
  display: flex;
  position: relative;
}

.nft-i .frame-container-2 {
  border: 2px solid;
  border-color: var(--scooter);
  border-radius: 22px;
  flex-direction: column;
  align-items: flex-start;
  width: 690px;
  min-height: 636px;
  padding: 48px 44px;
  display: flex;
  position: relative;
  top: 110px;
}

.nft-i .title-3 {
  color: var(--ceramic);
  font-family: var(--font-family-bebas_neue_pro-regular);
  font-size: 96px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 85.4px;
  min-height: 83px;
  text-align: center;
  white-space: nowrap;
  width: 676px;
  margin-bottom: 15px;
}

.nft-i .vector-1-3 {
  height: 3px;
  margin-top: 28px;
  width: 281px;
}

.nft-i .fees-so-low-it-will-3 {
  color: var(--ceramic);
  font-family: var(--font-family-bebas_neue_pro-light);
  font-size: 46px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 41.4px;
  margin-right: 1.0px;
  margin-top: 1px;
  min-height: 43px;
  text-align: center;
  white-space: nowrap;
  width: 675px;
}

.nft-i .WAXANF-ts-2 {
  height: 471px;
  margin-left: -81px;
  margin-right: -81px;
  position: relative;
  width: 768px;
}

.nft-i .sub-7 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 56px;
  left: 0;
  position: absolute;
  top: -50px;
  width: 768px;
}

.nft-i .sub-box-6 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 13px;
  margin-left: -255px;
  margin-right: -255px;
  padding: 20px 0px 0px;
  position: relative;
  width: 1278px;
}

.nft-i .JOIN-a-GLOBAL-26 {
  color: #fcfcfc;
  font-family: "Bebas Neue Pro-Regular", Helvetica;
  font-size: 39px;
  font-weight: 400;
  height: 53px;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: 1102px;
}

.nft-i .line-6 {
  height: 1.44px;
  position: relative;
  width: 173.83px;
}

.nft-i .learn-more-about-6 {
  align-self: stretch;
  color: #f9f9f9;
  font-family: "Bebas Neue Pro-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 1.8px;
  line-height: normal;
  position: relative;
  text-align: center;
}

.nft-i .frame-17 {
  height: 347px;
  left: 0;
  position: absolute;
  top: 138px;
  width: 768px;
}

.nft-i .mysterybox {
  width: 459px;
  height: 408px;
  position: absolute;
  top: -15px;
  left: 0;
}

.nft-i .rectangle-12 {
  height: 316px;
  left: 0;
  position: absolute;
  top: 10px;
  width: 269px;
}

.nft-i .rectangle-13 {
  height: 316px;
  left: 499px;
  position: absolute;
  top: 10px;
  width: 269px;
}

.nft-i .rectangle-14 {
  height: 347px;
  left: 236px;
  position: absolute;
  top: 0;
  width: 296px;
}

.nft-i .text-wrapper-36 {
  color: #885bea;
  font-family: "Titillium Web", Helvetica;
  font-size: 11px;
  font-style: italic;
  font-weight: 600;
  left: 33px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 218px;
  width: 204px;
}

.nft-i .element-unique-avatars-3 {
  color: transparent;
  font-family: "Titillium Web", Helvetica;
  font-size: 9px;
  font-weight: 400;
  left: 278px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 218px;
  width: 213px;
}

.nft-i .text-wrapper-37 {
  color: #885bea;
  font-style: italic;
  font-weight: 600;
  font-size: 11px;
}

.nft-i .text-wrapper-38 {
  color: #3f89d5;
  font-style: italic;
  font-weight: 700;
}

.nft-i .text-wrapper-39 {
  color: #3f89d5;
  font-style: italic;
  font-weight: 600;
}

.nft-i .each-astronaut-2 {
  color: #885bea;
  font-family: "Titillium Web", Helvetica;
  font-size: 11px;
  font-style: italic;
  font-weight: 600;
  left: 531px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 218px;
  width: 204px;
}

.nft-i .chillville-small-3 {
  height: 10px;
  left: 541px;
  position: absolute;
  top: 278px;
  width: 50px;
}

.nft-i .scottybeam-small-3 {
  height: 15px;
  left: 603px;
  position: absolute;
  top: 276px;
  width: 63px;
}

.nft-i .pawworld-small-3 {
  height: 11px;
  left: 677px;
  position: absolute;
  top: 278px;
  width: 51px;
}

.nft-i .NF-tcards-5 {
  width: 768px;
  height: 616px;
  margin-left: -81px;
  margin-right: -81px;
  position: relative;
  margin-top: 40px;
}

.nft-i .JOIN-a-GLOBAL-27 {
  align-self: stretch;
  color: #fcfcfc;
  font-family: "Bebas Neue Pro-Regular", Helvetica;
  font-size: 39px;
  font-weight: 400;
  height: 53px;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.nft-i .cards-3 {
  height: 431px;
  left: 0;
  position: absolute;
  top: 124px;
  width: 771px;
}

.nft-i .overlap-14 {
  height: 444px;
  left: 31px;
  position: absolute;
  top: -1px;
  width: 708px;
}

.nft-i .overlap-group-12 {
  width: 708px;
  height: 444px;
  position: absolute;
  top: auto;
  left: 0;
  bottom: 10px;
}

.nft-i .the-legend-astronaut-3 {
  color: #885bea;
  letter-spacing: 0;
  text-align: center;
  width: 231px;
  font-family: Titillium Web, Helvetica;
  font-size: 9px;
  font-style: italic;
  font-weight: 600;
  line-height: normal;
  position: absolute;
  top: 380px;
  left: 239px;
}

.nft-i .vector-29 {
  width: 299px;
  height: 350px;
  position: absolute;
  top: 17px;
  left: 205px;
  background: black;
  border-radius: 10px;
}

.nft-i .vector-30 {
  height: 27px;
  left: 205px;
  position: absolute;
  top: 417px;
  width: 1px;
}

.nft-i .vector-31 {
  height: 51px;
  left: 179px;
  position: absolute;
  top: 366px;
  width: 51px;
}

.nft-i .vector-32 {
  height: 51px;
  left: 478px;
  position: absolute;
  top: 366px;
  width: 51px;
}

.nft-i .rectangle-15 {
  height: 92px;
  left: 0;
  position: absolute;
  top: 146px;
  width: 92px;
}

.nft-i .rectangle-16 {
  height: 128px;
  left: 68px;
  position: absolute;
  top: 76px;
  width: 127px;
}

.nft-i .rectangle-17 {
  height: 159px;
  left: 47px;
  position: absolute;
  top: 190px;
  width: 158px;
}

.nft-i .rectangle-18 {
  height: 96px;
  left: 612px;
  position: absolute;
  top: 149px;
  width: 96px;
}

.nft-i .rectangle-19 {
  height: 125px;
  left: 513px;
  position: absolute;
  top: 82px;
  width: 125px;
}

.nft-i .rectangle-20 {
  height: 158px;
  left: 503px;
  position: absolute;
  top: 193px;
  width: 158px;
}

.nft-i .rectangle-21 {
  width: 366px;
  height: 326px;
  position: absolute;
  top: 15px;
  left: 130px;
}

.nft-i .arrow-3 {
  height: 37px;
  left: 186px;
  position: absolute;
  top: 373px;
  width: 37px;
  cursor: pointer;
}

.nft-i .icon-arrow-circle-3 {
  height: 31px;
  left: 488px;
  position: absolute;
  top: 376px;
  width: 31px;
  cursor: pointer;
}

.nft-i .vector-33 {
  height: 27px;
  left: 503px;
  position: absolute;
  top: 417px;
  width: 1px;
}

.nft-i .button {
  all: unset;
  background-color: #ff00ff;
  border-radius: 6px;
  box-sizing: border-box;
  height: 34px;
  left: 184px;
  position: absolute;
  top: 458px;
  width: 196px;
  cursor: pointer;
}

.nft-i .get-started-6 {
  color: #ffffff;
  font-family: "Opinion Pro Extended-Light", Helvetica;
  font-size: 15px;
  font-weight: 300;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 9px;
  width: 196px;
}

.nft-i .CS-button-3 {
  border: 1px solid;
  border-color: #ff00ff;
  border-radius: 6px;
  height: 34px;
  left: 387px;
  position: absolute;
  top: 458px;
  width: 196px;
  cursor: pointer;
}

.nft-i .get-started-7 {
  color: #ff00ff;
  font-family: "Opinion Pro Extended-Light", Helvetica;
  font-size: 15px;
  font-weight: 300;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 9px;
  width: 196px;
}

.nft-i .NF-tcards-6 {
  height: 426px;
  margin-left: -80.5px;
  margin-right: -80.5px;
  position: relative;
  width: 767px;
  margin-top: 50px;
}

.nft-i .every-successful-3 {
  color: #f9f9f9;
  font-family: "Bebas Neue Pro-Regular", Helvetica;
  font-size: 22px;
  font-weight: 400;
  left: 10px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 399px;
  width: 748px;
}

.nft-i .sub-8 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 56px;
  left: 0;
  position: absolute;
  top: -50px;
  width: 767px;
}

.nft-i .sub-box-7 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 13px;
  margin-left: -164.5px;
  margin-right: -164.5px;
  padding: 20px 0px 0px;
  position: relative;
  width: 1096px;
}

.nft-i .frame-18 {
  align-items: flex-start;
  display: flex;
  gap: 24px;
  height: 199px;
  justify-content: center;
  left: 82px;
  position: absolute;
  top: 150px;
  width: 602px;
}

.nft-i .POWER {
  height: 199px;
  margin-left: -5px;
  position: relative;
  width: 188px;
}

.nft-i .overlap-group-13 {
  height: 199px;
  left: 14px;
  position: relative;
  width: 161px;
}

.nft-i .p-block-5 {
  border-radius: 24px;
  height: 160px;
  left: 0;
  position: absolute;
  top: 15px;
  width: 160px;
}

.nft-i .vector-34 {
  height: 46px;
  left: 42px;
  position: absolute;
  top: 153px;
  width: 77px;
}

.nft-i .JOIN-a-GLOBAL-28 {
  color: #fcfcfc;
  font-family: "Bebas Neue Pro-Regular", Helvetica;
  font-size: 18px;
  font-weight: 400;
  height: 26px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 107px;
  width: 161px;
}

.nft-i .JOIN-a-GLOBAL-29 {
  color: #f0f;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 161px;
  height: 26px;
  font-family: Bebas Kai-Regular, Helvetica;
  font-size: 33px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 77px;
  left: 0;
}

.nft-i .JOIN-a-GLOBAL-30 {
  color: #ff00ff;
  font-family: "Bebas Kai-Regular", Helvetica;
  font-size: 7px;
  font-weight: 400;
  height: 26px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 161px;
  width: 161px;
}

.nft-i .text-wrapper-40 {
  color: #ff00ff;
  font-family: "Bebas Kai-Regular", Helvetica;
  font-size: 7px;
  font-weight: 400;
  letter-spacing: 0;
}

.nft-i .text-wrapper-41 {
  font-size: 1px;
}

.nft-i .text-wrapper-42 {
  font-size: 21px;
}

.nft-i .vector-35 {
  height: 53px;
  left: 60px;
  position: absolute;
  top: 0;
  width: 41px;
}

.nft-i .ROYALTY-2 {
  height: 199px;
  position: relative;
  width: 188px;
}

.nft-i .overlap-15 {
  height: 199px;
  left: 13px;
  position: relative;
  width: 162px;
}

.nft-i .p-block-6 {
  border-radius: 24px;
  height: 160px;
  left: 1px;
  position: absolute;
  top: 15px;
  width: 160px;
}

.nft-i .JOIN-a-GLOBAL-31 {
  color: #fcfcfc;
  font-family: "Bebas Neue Pro-Regular", Helvetica;
  font-size: 18px;
  font-weight: 400;
  height: 26px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 108px;
  width: 162px;
}

.nft-i .JOIN-a-GLOBAL-32 {
  color: #ff00ff;
  font-family: "Bebas Kai-Regular", Helvetica;
  font-size: 33px;
  font-weight: 400;
  height: 26px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 77px;
  white-space: nowrap;
  width: 162px;
}

.nft-i .JOIN-a-GLOBAL-33 {
  color: #ff00ff;
  font-family: "Bebas Kai-Regular", Helvetica;
  font-size: 21px;
  font-weight: 400;
  height: 26px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 161px;
  width: 162px;
}

.nft-i .vector-36 {
  height: 53px;
  left: 47px;
  position: absolute;
  top: 0;
  width: 69px;
}

.nft-i .LOYALTY {
  height: 199px;
  margin-right: -5px;
  position: relative;
  width: 188px;
}

.nft-i .overlap-16 {
  height: 199px;
  left: -11px;
  position: relative;
  width: 210px;
}

.nft-i .p-block-7 {
  border-radius: 24px;
  height: 160px;
  left: 25px;
  position: absolute;
  top: 15px;
  width: 160px;
}

.nft-i .vector-37 {
  height: 46px;
  left: 67px;
  position: absolute;
  top: 153px;
  width: 77px;
}

.nft-i .JOIN-a-GLOBAL-34 {
  color: #fcfcfc;
  font-family: "Bebas Neue Pro-Regular", Helvetica;
  font-size: 18px;
  font-weight: 400;
  height: 26px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 107px;
  width: 210px;
}

.nft-i .JOIN-a-GLOBAL-35 {
  color: #ff00ff;
  font-family: "Bebas Kai-Regular", Helvetica;
  font-size: 33px;
  font-weight: 400;
  height: 26px;
  left: 25px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 77px;
  white-space: nowrap;
  width: 161px;
}

.nft-i .JOIN-a-GLOBAL-36 {
  height: 15px;
  left: 93px;
  position: absolute;
  top: 167px;
  width: 24px;
}

.nft-i .vector-38 {
  height: 53px;
  left: 81px;
  position: absolute;
  top: 0;
  width: 48px;
}

.nft-i .MYSTERY-BOX-3 {
  height: 669px;
  margin-left: -78px;
  margin-right: -78px;
  position: relative;
  width: 762px;
  margin-top: 50px;
}

.nft-i .sub-9 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 56px;
  left: -1px;
  position: absolute;
  top: -50px;
  width: 763px;
}

.nft-i .sub-box-8 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 13px;
  width: 1278px;
  margin-left: -257.5px;
  margin-right: -257.5px;
  padding: 20px 0 0;
  display: flex;
  position: relative;
  margin-top: 50px;
}

.nft-i .learn-more-about-7 {
  align-self: stretch;
  color: #f9f9f9;
  font-family: "Bebas Neue Pro-Regular", Helvetica;
  font-size: 23px;
  font-weight: 400;
  letter-spacing: 1.72px;
  line-height: normal;
  position: relative;
  text-align: center;
}

.nft-i .MBOX-3 {
  height: 525px;
  left: 152px;
  position: absolute;
  top: 183px;
  width: 458px;
}

.nft-i .overlap-group-14 {
  height: 526px;
  left: -1px;
  position: relative;
  width: 459px;
}

.nft-i .vector-39 {
  height: 408px;
  left: 0;
  position: absolute;
  top: 0;
  width: 459px;
}

.nft-i .vector-40 {
  height: 45px;
  left: 393px;
  position: absolute;
  top: 20px;
  width: 47px;
  cursor: pointer;
}

.nft-i .vector-41 {
  height: 98px;
  left: 0;
  position: absolute;
  top: 428px;
  width: 459px;
}

.nft-i .MI-nt-3 {
  background-color: #ff00ff;
  border-radius: 10px;
  height: 39px;
  left: 118px;
  position: absolute;
  top: 400px;
  width: 226px;
  cursor: pointer;
}

.nft-i .get-started-8 {
  color: #ffffff;
  font-family: "Opinion Pro Extended-Light", Helvetica;
  font-size: 18px;
  font-weight: 300;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 11px;
  width: 226px;
}

.nft-i .JOIN-a-GLOBAL-37 {
  color: #fcfcfc;
  font-family: "Bebas Neue Pro-Regular", Helvetica;
  font-size: 25px;
  font-weight: 400;
  left: 1px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 486px;
  width: 229px;
}

.nft-i .JOIN-a-GLOBAL-38 {
  color: #fcfcfc;
  font-family: "Bebas Neue Pro-Regular", Helvetica;
  font-size: 25px;
  font-weight: 400;
  left: 230px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 486px;
  width: 229px;
}

.nft-i .JOIN-a-GLOBAL-39 {
  color: #ff00ff;
  font-family: "Bebas Neue Pro-SemiExpandedBold", Helvetica;
  font-size: 30px;
  font-weight: 700;
  left: 1px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 446px;
  width: 229px;
}

.nft-i .JOIN-a-GLOBAL-40 {
  color: #ff00ff;
  font-family: "Bebas Neue Pro-SemiExpandedBold", Helvetica;
  font-size: 30px;
  font-weight: 700;
  left: 230px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 446px;
  width: 229px;
}

.nft-i .fees-so-low-it-will-3 {
  color: var(--ceramic);
  font-family: var(--font-family-bebas_neue_pro-light);
  font-size: 46px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 41.4px;
  margin-right: 1.0px;
  margin-top: 1px;
  min-height: 43px;
  text-align: center;
  white-space: nowrap;
  width: 675px;
}

.nft-i .vector-1-3 {
  height: 3px;
  margin-top: 28px;
  width: 281px;
}

.nft-i .frame-3415-1 {
  justify-content: center;
  align-self: stretch;
  min-width: 688px;
  height: 620px;
  display: flex;
  position: relative;
}

.nft-i .sub-3 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 56px;
  height: 415.52px;
  padding: 21px 0px 0px;
  position: relative;
  width: 686px;
}

.nft-i .sub-3> :first-child {
  top: 370px;
  /* Adjust this value to reduce the top spacing */
}

.nft-i .sub-box-3 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 13px;
  padding: 20px 0px 0px;
  position: relative;
}

.nft-i .sub-box-3> :first-child {
  margin-top: -300px;
  /* Adjust this value to reduce the top spacing */
}

.nft-i .join-a-global-community-3 {
  align-self: stretch;
  height: 53px;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.nft-i .line-33-3 {
  height: 1.44px;
  min-width: 173.83px;
  position: relative;
}

.nft-i .learn-more-about-xio-3 {
  align-self: stretch;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
}

.nft-i .email-box-2 {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 15px;
  justify-content: center;
  position: relative;
  bottom: 25px;
}

.nft-i .social-box-3 {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 33px;
  justify-content: center;
  position: relative;
}

.nft-i .footer-3 {
  background-color: #0000;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 30.4px;
  min-width: 763px;
  height: 84px;
  margin-left: -37.5px;
  margin-right: -37.5px;
  display: flex;
  position: absolute;
}

.nft-i .line-34-3 {
  height: 1px;
  margin-top: -0.5px;
  width: 690px;
}

.nft-i .frame-3399-3 {
  align-items: flex-start;
  display: flex;
  gap: 482px;
  height: 23px;
  justify-content: center;
  margin-left: 1px;
  position: relative;
  width: 685px;
}

.nft-i .copyright-3 {
  color: #fff;
  font-size: 14px;
  height: 16px;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
  width: 185px;
}

.nft-i .tcs {
  color: #fff;
  font-size: 12px;
  height: 16px;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
  text-align: right;
  width: 96px;
}

.email-link {
  color: inherit;
  /* Use the color defined by the parent container */
  text-decoration: none;
  /* Remove underline by default */
  transition: color 0.3s ease;
  /* Smooth color transition */
}

.email-link:hover {
  color: #33a4d8;
  /* Change to the desired hover color */
}

.nft-i .frame-34 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  gap: 55px;
  justify-content: space-around;
  position: relative;
  width: 1034px;
}

.nft-i .b-2 {
  align-self: stretch;
  position: relative;
  width: 1033px;
}

.nft-i .XGT-MARKET-4 {
  color: #35cbfd;
  letter-spacing: 0;
  text-align: center;
  width: 1025px;
  font-family: Bebas Neue Pro-Regular, Helvetica;
  font-size: 26px;
  font-weight: 400;
  line-height: 23.1px;
  position: absolute;
  top: 30px;
  left: 8px;
}

.nft-i .text-wrapper-62 {
  color: #35cbfd;
  font-family: "Bebas Neue Pro-Regular", Helvetica;
  font-size: 26px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 23.1px;
}

.nft-i .text-wrapper-63 {
  font-family: "Bebas Neue Pro-Light", Helvetica;
  font-weight: 300;
}

.nft-i .terms-link,
.nft-i .privacy-link {
  font-family: "Bebas Neue Pro-Light", Helvetica;
  color: #f9f9f9;
  cursor: pointer;
  min-width: 125px;
  margin-left: -30px;
  font-size: 12px;
  transition: color .3s;
  margin-top: 3px;
}

.nft-i .terms-link:hover,
.nft-i .privacy-link:hover {
  color: #35cbfd;
  /* Change color on hover */
}

.nft-i .footer-section {
  width: 768px;
  height: 208px;
  margin-top: -50px;
}

.nft-i .footer-section .frame {
  justify-content: space-between;
  align-items: flex-start;
  width: 768px;
  display: flex;
  position: relative;
  top: 31px;
}

.nft-i .footer-section .line {
  width: 768px;
  height: 1px;
  margin-top: -0.5px;
}