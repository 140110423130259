:root { 
    --black: #000000;
    --onyx: #333338;
    --white: #ffffff;
   
    --font-size-m: 17px;
    --font-size-s: 16px;
    --font-size-xs: 14px;
   
    --font-family-titillium_web: "Titillium Web", Helvetica;
  }
  .titilliumweb-normal-white-17px {
    color: var(--white);
    font-family: var(--font-family-titillium_web);
    font-size: 17px(--font-size-m);
    font-style: normal;
    font-weight: 400;
  }