.telegram {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 126px;
  min-width: 128px;
  transition: all 0.2s ease;
}

.telegram:hover {
  transform: scale(1.1);
}

.overlap-group-22 {
  align-items: flex-end;
  background-size: 100% 100%;
  display: flex;
  height: 126px;
  min-width: 126px;
  padding: 6.1px 5.3px;
}

.join-the-xion-community-1 {
  letter-spacing: 0;
  line-height: normal;
  min-height: 30px;
  text-align: center;
  width: 114px;
  font-size: 8px;
}

.telegram.telegram-1,
.telegram.telegram-2 {
  align-items: unset;
  display: unset;
  height: 125.52px;
  min-width: 127.89px;
  position: relative;
}

