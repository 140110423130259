/* ConsentComponent.css */
.button {
    border: 1px solid;
    border-radius: 10px;
    height: 35px;
    position: relative;
    width: 150px;
    cursor: pointer;
    background: transparent;
    color: #14a5ed;
    font-family: Opinion Pro Extended-Light, Helvetica;
    font-size: 12px;
    font-weight: 300;
    line-height: normal;
    text-align: center;
    margin-left: 10px;
  }
  
  .button:hover {
    border-color: green;
    color: green;
  }
  
  @media (max-width: 768px) {
    .button {
      width: 125px; /* Adjust as needed */
      font-size: 12px; /* Adjust as needed */
    }
  }
  
  @media (max-width: 480px) {
    .button {
      width: 100px; /* Adjust as needed */
      font-size: 10px; /* Adjust as needed */
    }
  }
  
  .declineButton {
    border: 1px solid;
    border-radius: 10px;
    height: 35px;
    position: relative;
    width: 150px;
    cursor: pointer;
    background: transparent;
    color: #14a5ed;
    font-family: Opinion Pro Extended-Light, Helvetica;
    font-size: 12px;
    font-weight: 300;
    line-height: normal;
    text-align: center;
  }
  
  .declineButton:hover {
    border-color: red;
    color: red;
  }
  