.swap-box-2 {
  height: 535.6px;
  min-width: 690.69px;
  position: relative;
}

.frame-container-2 {
  align-items: flex-start;
  border: 2px solid;
  border-color: var(--scooter);
  border-radius: 22px;
  display: flex;
  flex-direction: column;
  min-height: 636px;
  padding: 48px 44.0px;
  position: relative;
  top: -50px;
  width: 690px;
}

.frame-1-2 {
  align-items: center;
  display: flex;
  gap: 30px;
  padding: 0px 0px 0px 20px;
  position: relative;
  width: 597px;
}

.place-2 {
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: 54px;
}

.frame-3406 {
  align-items: center;
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-top: 42px;
  position: relative;
  width: fit-content;
}

.tb-2,
.bb-2 {
  height: 113px;
  margin-right: -4.00px;
  min-width: 601px;
  position: relative;
}

.flex-col-7 {
  align-items: flex-start;
  background-color: var(--black-rock);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 18px;
  min-height: 113px;
  padding: 2px 17.6px;
  width: 597px;
}

.flex-row-3 {
  align-items: flex-end;
  align-self: flex-end;
  display: flex;
  height: 20px;
  margin-right: 1.21px;
  margin-top: 14px;
  min-width: 557px;
}

.send-3 {
  align-self: flex-start;
  letter-spacing: 0;
  line-height: normal;
  min-height: 19px;
  width: 248px;
}

.overlap-group1-9 {
  align-items: flex-start;
  background-image: url(/static/img/path-532-2@2x.png);
  background-size: 100% 100%;
  display: flex;
  height: 19px;
  justify-content: flex-end;
  margin-bottom: 0.22px;
  margin-left: 212px;
  min-width: 46px;
  padding: 2.1px 12.0px;
}

.name-2 {
  letter-spacing: 0;
  line-height: normal;
  min-height: 14px;
  text-align: center;
  width: 21px;
}

.overlap-group-25 {
  align-items: flex-start;
  background-image: url(/static/img/path-531-2@2x.png);
  background-size: 100% 100%;
  display: flex;
  height: 19px;
  justify-content: flex-end;
  margin-left: 5px;
  min-width: 46px;
  padding: 1.9px 10.2px;
}

.half-2 {
  letter-spacing: 0;
  line-height: normal;
  min-height: 14px;
  text-align: center;
  width: 24px;
}

.flex-row-4 {
  align-items: center;
  display: flex;
  gap: 210px;
  height: 57px;
  min-width: 559px;
}

.text-5 {
  letter-spacing: 0;
  line-height: normal;
  min-height: 57px;
  width: 248px;
}

.frame-3404-1 {
  align-items: center;
  display: flex;
  margin-top: 5.94px;
  min-width: 97px;
  padding: 0 0.0px;
}

.path-529-1 {
  align-self: flex-start;
  height: 31px;
  margin-top: 0;
  width: 33px;
}

.price-2 {
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: 2.94px;
  margin-left: 6px;
  min-height: 21px;
  width: 44px;
}

.path-530-2 {
  height: 6px;
  margin-left: 10px;
  margin-top: 3.01px;
  width: 13px;
}

.frame-3407-1 {
  align-self: center;
  height: 34px;
  margin-right: 4.0px;
  margin-top: 26px;
  width: 35px;
}

.frame-2-2 {
  align-items: center;
  display: flex;
  gap: 28px;
  height: 45px;
  margin-top: 26px;
  padding: 0px 0px 0px 21px;
  position: relative;
  width: 597px;
}

.to-2 {
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: 55px;
}

.frame-3405 {
  align-items: center;
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-top: 25px;
  position: relative;
  width: fit-content;
}

.overlap-group-26 {
  align-items: flex-start;
  background-color: var(--black-rock);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 19px;
  min-height: 113px;
  padding: 2px 20px;
  width: 597px;
}

.send-4 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: 14px;
  min-height: 19px;
  width: 229px;
}

.text-6 {
  letter-spacing: 0;
  line-height: normal;
  min-height: 57px;
  width: 229px;
}

.swap-box-2.swap-box-3 {
  margin-left: -1.35px;
  margin-right: -1.35px;
}

.powered-by {
  font-family: var(--font-family-titillium_web);
  color: #fff;
  width: 100px;
  padding-right: 10px;
  font-size: 8px;
  font-style: normal;
  position: absolute;
  bottom: 27px;
  right: 199px;
}

.squid {
  width: 15px;
  position: absolute;
  bottom: 19px;
  right: 239px;
}