.fdpopup {
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  z-index: 99;
}

.fdpopup .div {
  background-image: url(../../../static/img/rectangle-2921.png);
  background-size: 100% 100%;
  width: 311px;
  height: 311px;
  margin-top: -278px;
  margin-left: -3px;
  position: absolute;
}

.fdpopup .contact-details {
  width: 172px;
  height: 18px;
  position: absolute;
  top: 220px;
  left: 65px;
}

.fdpopup .transaction-needs {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 232px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 0;
  left: -27px;
}

.fdpopup .group {
  width: 154px;
  height: 154px;
  position: absolute;
  top: 50px;
  left: 76px;
}

.fdpopup .RB-button {
  height: 10px;
  left: 26px;
  position: absolute;
  top: 94px;
  width: 118px;
}

.fdpopup .overlap-group-wrapper {
  height: 10px;
  left: 0;
  position: absolute;
  top: 0;
  width: 60px;
}

.fdpopup .overlap-group {
  background-color: #3a9300;
  border-radius: 2px;
  height: 10px;
  position: relative;
  width: 58px;
}

.fdpopup .vew-transaction {
  color: #ffffff;
  font-family: "Titillium Web", Helvetica;
  font-size: 5px;
  font-style: italic;
  font-weight: 700;
  height: 10px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 0;
  width: 58px;
}

.fdpopup .overlap-wrapper {
  height: 10px;
  left: 60px;
  position: absolute;
  top: 0;
  width: 60px;
}

.fdpopup .overlap {
  border: 0.5px solid;
  border-color: #cf5159;
  border-radius: 2px;
  height: 10px;
  position: relative;
  width: 58px;
}

.fdpopup .increase-gas {
  color: #cf5159;
  font-family: "Titillium Web", Helvetica;
  font-size: 5px;
  font-style: italic;
  font-weight: 700;
  height: 10px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 0;
  width: 58px;
}

.fdpopup .text-wrapper {
  color: #ffffff;
  font-family: "Titillium Web", Helvetica;
  font-size: 5px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 112px;
  width: 170px;
}

.sfpopup {
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  z-index: 99;
}

.sfpopup .div {
  background-image: url(../../../static/img/rectangle-2921.png);
  background-size: 100% 100%;
  width: 311px;
  height: 311px;
  margin-top: -278px;
  margin-left: -3px;
  position: absolute;
}

.sfpopup .contact-details {
  width: 172px;
  height: 18px;
  position: absolute;
  top: 195px;
  left: 71px;
}

.sfpopup .transaction {
  color: #ffffff;
  font-family: "Titillium Web", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 0;
  width: 170px;
}

.sfpopup .group {
  width: 154px;
  height: 154px;
  position: absolute;
  top: 32px;
  left: 79px;
}

.sfpopup .RB-button {
  /* width: 140px; */
  /* height: 10px; */
  position: absolute;
  top: 226px;
  left: 93px;
}

.sfpopup .overlap-group-wrapper {
  height: 10px;
  left: 0;
  position: absolute;
  top: 0;
  width: 60px;
}

.sfpopup .overlap-group {
  width: 116px;
  height: 20px;
  position: relative;
  right: 69px;
}

.sfpopup .view-transaction {
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
  width: 115px;
  height: 10px;
  font-family: Titillium Web, Helvetica;
  font-size: 12px;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 0;
  left: -1px;
  cursor: pointer;
}

.sfpopup .overlap-wrapper {
  width: 60px;
  height: 10px;
  position: absolute;
  top: 0;
  left: 85px;
}

.sfpopup .overlap {
  width: 125px;
  height: 19px;
  margin-left: -20px;
  position: relative;
}

.sfpopup .element-recived {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 91px;
  height: 10px;
  font-family: Titillium Web, Helvetica;
  font-size: 12px;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 6px;
}


.pspopup {
  z-index: 99;
  justify-content: center;
  width: 100%;
  display: flex;
}

.pspopup .div {
  background-image: url(../../../static/img/rectangle-2921.png);
  background-size: 100% 100%;
  width: 311px;
  height: 311px;
  margin-top: -278px;
  margin-left: -3px;
  position: absolute;
}


.pspopup .contact-details {
  width: 172px;
  height: 18px;
  position: absolute;
  top: 216px;
  left: 72px;
}

.pspopup .transaction {
  color: #ffffff;
  font-family: "Titillium Web", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: -10px;
  width: 170px;
}

.pspopup .group {
  width: 154px;
  height: 154px;
  position: absolute;
  top: 36px;
  left: 80px;
}

.pspopup .element-sec-wrapper {
  width: 60px;
  height: 10px;
  position: absolute;
  top: 240px;
  left: 124px;
}

.pspopup .element-sec {
  color: #ffffff;
  font-family: "Titillium Web", Helvetica;
  font-size: 12px;
  font-style: italic;
  font-weight: 700;
  height: 10px;
  left: -59px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 24px;
  width: 180px;
}

.pspopup .close {
  cursor: pointer;
  z-index: 9;
  width: 14px;
  height: 13px;
  position: absolute;
  top: 13px;
  left: 284px;
}

.fdpopup .close {
  cursor: pointer;
  z-index: 99;
  width: 14px;
  height: 13px;
  position: absolute;
  top: 392px;
  left: 530px;
}

.sfpopup .close {
  cursor: pointer;
  z-index: 99;
  width: 14px;
  height: 13px;
  position: absolute;
  top: -205px;
  left: 101px;
}

.sfpopup .usdt-logo {
  width: 20px;
  height: 20px;
  margin-top: -2px;
  margin-left: 1px;
}

.fdpopup .failed-reason {
  color: #fff;
  width: 295px;
  margin-top: 29px;
  margin-left: -47px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 400;
}

/* New styles for the link */
.fdpopup .failed-reason a {
  color: #35cbfd
    /* Inherit color from .failed-reason */
}

.fdpopup .failed-reason a:hover {
  color: #35cbfd;
  /* Change color on hover */
}

.sfpopup .twitter-share-button {
  color: #fff;
  padding: 10px 20px;
  display: inline-block;
  margin-top: 33px;
  margin-left: -11px;
  font-family: "Titillium Web", Helvetica;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
}

@keyframes shake {
  0% { transform: translateX(0); }
  25% { transform: translateX(-5px); }
  75% { transform: translateX(5px); }
  100% { transform: translateX(0); }
}

.sfpopup .twitter-share-button:hover {
  animation: shake 0.5s ease-in-out;
}

.sfpopup .share-icon {
  z-index: 99;
  width: 20px;
  margin-top: 0px;
  margin-left: 77px;
  position: absolute;
  cursor: pointer;
}

.sfpopup .link-icon {
  z-index: 99;
  width: 20px;
  margin-top: 0;
  margin-left: 102px;
  position: absolute;
  cursor: pointer;
}

.pspopup .onway {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 170px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 13px;
  left: 0;
}