.nft-d .desktop-nft {
  align-items: center;
  background-position: initial;
  background-size: cover;
  border: 1px none;
  display: flex;
  flex-direction: column;
  gap: 115px;
  min-width: 1440px;
  padding: 81px;
  position: relative;
  overflow: hidden;
}

.nft-d .frame-3414 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 78px;
  position: relative;
}

.nft-d .nav {
  justify-content: space-between;
  align-self: center;
  align-items: center;
  min-width: 1278px;
  height: 46px;
  padding: 10px 50px 10px 40px;
  display: flex;
  position: absolute;
}

.nft-d .logo {
  height: 45.81px;
  margin-top: -0.2px;
  width: 246.25px;
}

.nft-d .my-custom-wrapper {
  border-radius: 12px;
  display: inline-block;
  overflow: hidden;
}

.nft-d .my-custom-class {
  cursor: pointer;
  font-family: var(--font-family-bebas_neue_pro-regular) !important;
  /* Replace this with the actual font family value */
  font-weight: bold;
  position: relative;
}

.nft-d .img-wrapper {
  --webkit-backdrop-filter: blur(10px) brightness(100%);
  -webkit-backdrop-filter: blur(10px) brightness();
  backdrop-filter: blur(10px) brightness();
  border-radius: 10px;
  width: 33px;
  height: 33px;
  position: relative;
  left: 600px;
  cursor: pointer;
}

.nft-d .group-2 {
  z-index: 9;
  width: 44px;
  height: 44px;
  position: relative;
  top: -6px;
  right: 10px;
  cursor: pointer;
}

.nft-d .frame-3411 {
  min-width: 1278px;
  height: 413px;
  display: flex;
  position: relative;
  margin-top: 150px;
}

.nft-d .heromenu-box {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 55px;
  height: 413px;
  position: relative;
  width: 1278px;
}

.nft-d .menu-box {
  border: 1px solid;
  border-color: var(--cerulean);
  border-radius: 9px;
  justify-content: center;
  min-width: 614px;
  height: 43px;
  display: flex;
  position: relative;
  top: 130px;
}


.nft-d .hero-box {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 58px;
  height: 277px;
  padding: 20px 0px;
  position: relative;
}

.nft-d .frame-3413 {
  height: 296px;
  margin-bottom: -59.00px;
  min-width: 1278px;
  position: relative;
}

.flex-col {
  flex-direction: column;
  align-items: center;
  width: 1278px;
  min-height: 268px;
  display: flex;
  position: relative;
}

.title {
  color: var(--ceramic);
  font-family: var(--font-family-bebas_neue_pro-regular);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 1278px;
  min-height: 152px;
  margin-bottom: 15px;
  font-size: 170px;
  font-weight: 400;
  line-height: 151.3px;
}

.fees-so-low-it-will {
  color: var(--ceramic);
  font-family: var(--font-family-bebas_neue_pro-light);
  font-size: 76px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 68.4px;
  min-height: 64px;
  text-align: center;
  white-space: nowrap;
  width: 1278px;
  margin-bottom: 5px;
}

.vector-1 {
  height: 3px;
  margin-top: 30px;
  width: 384px;
}

.swap-box {
  height: 536px;
  min-width: 690px;
  position: relative;
}

.frame-container {
  border: 3px solid;
  border-color: var(--scooter);
  border-radius: 22px;
  flex-direction: column;
  align-items: flex-start;
  gap: 26px;
  width: 814px;
  min-height: 636px;
  padding: 47.4px 58.7px;
  display: flex;
  position: relative;
  top: 50px;
}

.frame-1 {
  align-items: center;
  display: flex;
  gap: 30px;
  margin-left: 0.35px;
  padding: 0px 0px 0px 20px;
  position: relative;
  width: 690px;
}

.nft-d .overlap-wrapper {
  width: 1278px;
  height: 607px;
  position: relative;
  margin-bottom: 85px;
}

.nft-d .overlap-6 {
  height: 689px;
  position: relative;
  top: -50px;
}

.nft-d .sub-5 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 56px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1278px;
}

.nft-d .sub-box-5 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 13px;
  padding: 20px 0px 0px;
  position: relative;
  width: 1278px;
}

.nft-d .text-wrapper-15 {
  align-self: stretch;
  color: #fcfcfc;
  font-family: "Bebas Neue Pro-Regular", Helvetica;
  font-size: 39px;
  font-weight: 400;
  height: 53px;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.nft-d .line-4 {
  width: 173.83px;
  height: 1.44px;
  position: relative;
  top: 0px;
}

.nft-d .learn-more-about-5 {
  align-self: stretch;
  color: #f9f9f9;
  font-family: "Bebas Neue Pro-Regular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 1.8px;
  line-height: normal;
  position: relative;
  text-align: center;
}

.nft-d .frame-8 {
  flex: 0 0 auto;
  position: relative;
  width: 1278px;
}

.nft-d .frame-9 {
  height: 515px;
  left: 78px;
  position: absolute;
  top: 174px;
  width: 1139px;
}

.nft-d .overlap-group-5 {
  height: 515px;
  position: relative;
}

.nft-d .rectangle-2 {
  height: 468px;
  left: 0;
  position: absolute;
  top: 15px;
  width: 399px;
}

.nft-d .rectangle-3 {
  height: 468px;
  left: 740px;
  position: absolute;
  top: 15px;
  width: 399px;
}

.nft-d .rectangle-4 {
  height: 515px;
  left: 350px;
  position: absolute;
  top: 0;
  width: 439px;
}

.nft-d .text-wrapper-16 {
  color: #885bea;
  font-family: "Titillium Web", Helvetica;
  font-size: 16px;
  font-style: italic;
  font-weight: 600;
  left: 49px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 321px;
  width: 303px;
}

.nft-d .element-unique-avatars-2 {
  color: transparent;
  font-family: "Titillium Web", Helvetica;
  font-size: 13px;
  font-weight: 400;
  left: 418px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 321px;
  width: 303px;
}

.nft-d .text-wrapper-17 {
  color: #885bea;
  font-style: italic;
  font-weight: 600;
  font-size: 16px;
}

.nft-d .text-wrapper-18 {
  color: #3f89d5;
  font-style: italic;
  font-weight: 700;
}

.nft-d .text-wrapper-19 {
  color: #3f89d5;
  font-style: italic;
  font-weight: 600;
}

.nft-d .each-d-astronaut {
  color: #885bea;
  font-family: "Titillium Web", Helvetica;
  font-size: 16px;
  font-style: italic;
  font-weight: 600;
  left: 788px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 321px;
  width: 303px;
}

.nft-d .chillville-small-2 {
  height: 15px;
  left: 803px;
  position: absolute;
  top: 413px;
  width: 74px;
}

.nft-d .scottybeam-small-2 {
  height: 22px;
  left: 894px;
  position: absolute;
  top: 409px;
  width: 93px;
}

.nft-d .pawworld-small-2 {
  height: 16px;
  left: 1004px;
  position: absolute;
  top: 412px;
  width: 75px;
}

.nft-d .NF-tcards-3 {
  height: 974px;
  position: relative;
  width: 1278px;
}

.nft-d .overlap-7 {
  height: 876px;
  position: relative;
  top: -50px;
}

.nft-d .cards-2 {
  height: 702px;
  left: 10px;
  position: absolute;
  top: 174px;
  width: 1258px;
}

.nft-d .overlap-8 {
  height: 721px;
  left: 20px;
  position: absolute;
  top: 0;
  width: 1223px;
}

.nft-d .overlap-group-6 {
  height: 687px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1223px;
}

.nft-d .the-legend-astronaut-2 {
  color: #885bea;
  letter-spacing: 0;
  text-align: center;
  width: 368px;
  font-family: Titillium Web, Helvetica;
  font-size: 12.4px;
  font-style: italic;
  font-weight: 600;
  line-height: normal;
  position: absolute;
  top: 610px;
  left: 425px;
}

.nft-d .vector-16 {
  width: 488px;
  height: 569px;
  position: absolute;
  top: 26px;
  left: 366px;
  background: black;
}

.nft-d .vector-17 {
  height: 82px;
  left: 324px;
  position: absolute;
  top: 594px;
  width: 82px;
}

.nft-d .vector-18 {
  height: 82px;
  left: 811px;
  position: absolute;
  top: 594px;
  width: 82px;
}

.nft-d .rectangle-5 {
  height: 164px;
  left: 0;
  position: absolute;
  top: 226px;
  width: 164px;
}

.nft-d .rectangle-6 {
  height: 228px;
  left: 121px;
  position: absolute;
  top: 102px;
  width: 227px;
}

.nft-d .rectangle-7 {
  height: 282px;
  left: 83px;
  position: absolute;
  top: 305px;
  width: 282px;
}

.nft-d .rectangle-8 {
  height: 175px;
  left: 1048px;
  position: absolute;
  top: 228px;
  width: 175px;
}

.nft-d .rectangle-9 {
  height: 226px;
  left: 870px;
  position: absolute;
  top: 107px;
  width: 226px;
}

.nft-d .rectangle-10 {
  height: 285px;
  left: 852px;
  position: absolute;
  top: 309px;
  width: 285px;
}

.nft-d .rectangle-11 {
  width: 596px;
  height: 537px;
  position: absolute;
  top: 30px;
  left: 311px;
}

.nft-d .arrow-2 {
  height: 61px;
  left: 335px;
  position: absolute;
  top: 605px;
  width: 61px;
  cursor: pointer;
}

.nft-d .icon-arrow-circle-2 {
  height: 51px;
  left: 827px;
  position: absolute;
  top: 610px;
  width: 51px;
  cursor: pointer;
}

.nft-d .vector-19 {
  height: 44px;
  left: 852px;
  position: absolute;
  top: 677px;
  width: 1px;
}

.nft-d .vector-20 {
  height: 44px;
  left: 365px;
  position: absolute;
  top: 677px;
  width: 1px;
}

.nft-d .get-started-wrapper {
  all: unset;
  background-color: #ff00ff;
  border-radius: 10px;
  box-sizing: border-box;
  height: 39px;
  left: 385px;
  position: absolute;
  top: 730px;
  width: 226px;
}

.nft-d .get-started-4 {
  color: #ffffff;
  font-family: "Opinion Pro Extended-Light", Helvetica;
  font-size: 18px;
  font-weight: 300;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 11px;
  width: 226px;
  cursor: pointer;
}

.nft-d .CS-button-2 {
  border: 1px solid;
  border-color: #ff00ff;
  border-radius: 10px;
  height: 39px;
  left: 648px;
  position: absolute;
  top: 730px;
  width: 226px;
}

.nft-d .get-started-5 {
  color: #ff00ff;
  font-family: "Opinion Pro Extended-Light", Helvetica;
  font-size: 18px;
  font-weight: 300;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 11px;
  width: 226px;
  cursor: pointer;
}

.nft-d .NF-tcards-4 {
  height: 605px;
  position: relative;
  width: 1278px;
}

.nft-d .every-successful-2 {
  color: #f9f9f9;
  letter-spacing: 0;
  text-align: center;
  font-family: Bebas Neue Pro-Regular, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 480px;
  left: 326px;
}

.nft-d .overlap-9 {
  height: 508px;
  left: 0;
  position: absolute;
  top: -50px;
  width: 1278px;
}

.nft-d .frame-10 {
  justify-content: center;
  align-items: flex-start;
  gap: 48px;
  display: inline-flex;
  position: absolute;
  top: 150px;
  left: 224px;
}

.nft-d .overlap-group-wrapper-3 {
  height: 311px;
  position: relative;
  width: 245px;
}

.nft-d .overlap-group-7 {
  height: 301px;
  position: relative;
  top: 10px;
}

.nft-d .p-block-4 {
  border-radius: 24px;
  width: 245px;
  height: 285px;
  position: absolute;
  top: 23px;
  left: 0;
}

.nft-d .vector-21 {
  height: 69px;
  left: 64px;
  position: absolute;
  top: 232px;
  width: 117px;
}

.nft-d .JOIN-a-GLOBAL-17 {
  color: #fcfcfc;
  font-family: "Bebas Neue Pro-Regular", Helvetica;
  font-size: 25px;
  font-weight: 400;
  height: 39px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 153px;
  width: 245px;
}

.nft-d .JOIN-a-GLOBAL-18 {
  color: #f0f;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 245px;
  height: 39px;
  font-family: Bebas Kai-Regular, Helvetica;
  font-size: 46px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 111px;
  left: 0;
}

.nft-d .JOIN-a-GLOBAL-19 {
  color: #ff00ff;
  font-family: "Bebas Kai-Regular", Helvetica;
  font-size: 11px;
  font-weight: 400;
  height: 39px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 244px;
  width: 245px;
}

.nft-d .text-wrapper-20 {
  color: #ff00ff;
  font-family: "Bebas Kai-Regular", Helvetica;
  font-size: 11px;
  font-weight: 400;
  letter-spacing: 0;
}

.nft-d .text-wrapper-21 {
  font-size: 1px;
}

.nft-d .text-wrapper-22 {
  font-size: 37px;
}

.nft-d .vector-22 {
  height: 80px;
  left: 91px;
  position: absolute;
  top: 0;
  width: 62px;
}

.nft-d .JOIN-a-GLOBAL-20 {
  color: #f0f;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 245px;
  height: 39px;
  font-family: Bebas Kai-Regular, Helvetica;
  font-size: 28px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 250px;
  left: 0;
}

.nft-d .vector-23 {
  height: 80px;
  left: 71px;
  position: absolute;
  top: 0;
  width: 104px;
}

.nft-d .JOIN-a-GLOBAL-21 {
  height: 27px;
  left: 101px;
  position: absolute;
  top: 251px;
  width: 43px;
}

.nft-d .vector-24 {
  height: 80px;
  left: 86px;
  position: absolute;
  top: 0;
  width: 73px;
}

.nft-d .MYSTERY-BOX-2 {
  height: 736px;
  position: relative;
  width: 1278px;
}

.nft-d .overlap-10 {
  height: 758px;
  position: relative;
  top: -65px;
}

.nft-d .text-wrapper-23 {
  align-self: stretch;
  color: #f9f9f9;
  font-family: "Bebas Neue Pro-Regular", Helvetica;
  font-size: 23px;
  font-weight: 400;
  letter-spacing: 1.72px;
  line-height: normal;
  position: relative;
  text-align: center;
}

.nft-d .MBOX-2 {
  width: 458px;
  height: 525px;
  position: absolute;
  top: 210px;
  left: 410px;
}

.nft-d .overlap-group-8 {
  height: 526px;
  position: relative;
  width: 459px;
}

.nft-d .vector-25 {
  height: 408px;
  left: 0;
  position: absolute;
  top: 0;
  width: 459px;
}

.nft-d .vector-26 {
  height: 45px;
  left: 393px;
  position: absolute;
  top: 20px;
  width: 47px;
  cursor: pointer;
}

.nft-d .vector-27 {
  height: 98px;
  left: 0;
  position: absolute;
  top: 428px;
  width: 459px;
}

.nft-d .MI-nt-2 {
  background-color: #ff00ff;
  border-radius: 10px;
  height: 39px;
  left: 118px;
  position: absolute;
  top: 400px;
  width: 226px;
}

.nft-d .JOIN-a-GLOBAL-22 {
  color: #fcfcfc;
  font-family: "Bebas Neue Pro-Regular", Helvetica;
  font-size: 25px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 486px;
  width: 229px;
}

.nft-d .JOIN-a-GLOBAL-23 {
  color: #fcfcfc;
  font-family: "Bebas Neue Pro-Regular", Helvetica;
  font-size: 25px;
  font-weight: 400;
  left: 230px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 486px;
  width: 229px;
}

.nft-d .JOIN-a-GLOBAL-24 {
  color: #ff00ff;
  font-family: "Bebas Neue Pro-SemiExpandedBold", Helvetica;
  font-size: 30px;
  font-weight: 700;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 446px;
  width: 229px;
}

.nft-d .JOIN-a-GLOBAL-25 {
  color: #ff00ff;
  font-family: "Bebas Neue Pro-SemiExpandedBold", Helvetica;
  font-size: 30px;
  font-weight: 700;
  left: 230px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 446px;
  width: 229px;
}


.nft-d .place {
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: 54px;
}

.nft-d .frame-3 {
  align-items: center;
  display: flex;
  gap: 10px;
  justify-content: center;
  position: relative;
  width: fit-content;
}

.nft-d .tb {
  height: 129px;
  margin-right: -4.00px;
  min-width: 694.69px;
  position: relative;
}

.nft-d .flex-col-1 {
  align-items: flex-end;
  background-color: var(--black-rock);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 29px;
  min-height: 129px;
  padding: 8px 21.5px;
  width: 691px;
}

.nft-d .flex-row {
  align-items: flex-end;
  display: flex;
  height: 20px;
  margin-top: 7px;
  min-width: 646px;
}

.nft-d .send {
  align-self: flex-end;
  letter-spacing: 0;
  line-height: normal;
  min-height: 19px;
  width: 232px;
}

.nft-d .overlap-group1 {
  align-items: flex-start;
  background-size: 100% 100%;
  display: flex;
  height: 19px;
  justify-content: flex-end;
  margin-left: 324px;
  min-width: 43px;
  padding: 5px 11.2px;
}

.nft-d .name {
  letter-spacing: 0;
  line-height: normal;
  min-height: 14px;
  text-align: center;
  width: 20px;
}

.nft-d .overlap-group-1 {
  align-items: flex-start;
  background-size: 100% 100%;
  display: flex;
  height: 19px;
  justify-content: flex-end;
  margin-left: 5px;
  margin-top: 0.22px;
  min-width: 43px;
  padding: 5px 9.5px;
}

.nft-d .half {
  letter-spacing: 0;
  line-height: normal;
  min-height: 14px;
  text-align: center;
  width: 22px;
}

.nft-d .flex-row-1 {
  align-self: center;
  align-items: center;
  gap: 400px;
  min-width: 644px;
  height: 57px;
  margin-right: .67px;
  display: flex;
}

.nft-d .text-1 {
  letter-spacing: 0;
  line-height: normal;
  min-height: 57px;
  width: 232px;
}

.nft-d .frame-3403 {
  align-items: flex-end;
  display: flex;
  justify-content: center;
  margin-bottom: 5.06px;
  min-width: 91px;
  cursor: pointer;
}

.nft-d .path-529 {
  align-self: flex-start;
  height: 31px;
  margin-top: 0;
  width: 31px;
}

.nft-d .token {
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: 2.94px;
  margin-left: 6px;
  min-height: 21px;
  width: 41px;
}

.nft-d .path-530 {
  width: 12px;
  height: 6px;
  margin-top: 0;
  margin-bottom: 12px;
  margin-left: 20px;
}

.nft-d .frame-4 {
  height: 34px;
  margin-left: 0.35px;
  width: 690px;
}

.nft-d .frame-2 {
  align-items: center;
  display: flex;
  gap: 30px;
  height: 45px;
  margin-left: 0.85px;
  padding: 0px 0px 0px 21px;
  position: relative;
  width: 689px;
}

.nft-d .to {
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: 55px;
}

.nft-d .frame-5 {
  align-items: center;
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-left: 0.02px;
  position: relative;
  width: fit-content;
}

.nft-d .bb {
  height: 106.6px;
  margin-right: -4.00px;
  min-width: 694.65px;
  position: relative;
}

.nft-d .overlap-group-2 {
  align-items: flex-start;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  gap: 18px;
  min-height: 107px;
  padding: 1.5px 21.8px;
  width: 691px;
}

.nft-d .receive-estimated {
  letter-spacing: 0;
  line-height: normal;
  margin-left: 1.0px;
  margin-top: 10px;
  min-height: 19px;
  width: 232px;
}

.nft-d .text-2 {
  letter-spacing: 0;
  line-height: normal;
  min-height: 57px;
  width: 233px;
}

.nft-d .frame-3412 {
  min-width: 1278px;
  height: 445.18px;
  display: flex;
  position: relative;
  top: -45px;
}

.nft-d .sub {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 56px;
  height: 446.18px;
  position: relative;
  width: 1278px;
}

.nft-d .sub-box {
  flex-direction: column;
  align-items: center;
  gap: 13px;
  width: 1278px;
  padding: 20px 0 0;
  display: flex;
  position: relative;
  top: 75px;
}

.nft-d .join-a-global-community {
  align-self: stretch;
  color: var(--ceramic);
  font-family: var(--font-family-bebas_neue_pro-regular);
  font-size: 39px;
  font-weight: 400;
  height: 53px;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
}

.nft-d .line-33 {
  height: 1.44px;
  min-width: 173.83px;
  position: relative;
}

.nft-d .learn-more-about-xio {
  align-self: stretch;
  color: var(--hint-of-red);
  font-family: var(--font-family-bebas_neue_pro-regular);
  font-size: var(--font-size-xxxxxl);
  font-weight: 400;
  letter-spacing: 1.72px;
  line-height: normal;
  position: relative;
  text-align: center;
}

.nft-d .frame-262 {
  justify-content: center;
  align-items: center;
  gap: 15px;
  width: 1278px;
  display: flex;
  position: relative;
  margin-top: 75px;
}

.nft-d .frame-3395 {
  align-items: center;
  background-color: var(--white);
  border: 1.2px solid;
  border-color: var(--kingfisher-daisy);
  border-radius: 8px;
  display: flex;
  height: 50.07px;
  padding: 14.37px;
  position: relative;
  width: 359.15px;
}

.nft-d .eg-bethe1sttoknow {
  background-color: transparent;
  border: 0;
  height: 22.75px;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.90px;
  min-width: 330.42px;
  opacity: 0.3;
  padding: 0;
  position: relative;
  white-space: nowrap;
}

.eg-bethe1sttoknow::placeholder {
  color: #2a2a2a99;
}

.nft-d .frame-3396 {
  align-items: center;
  background-color: var(--kingfisher-daisy);
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  gap: 7.18px;
  height: 49.61px;
  justify-content: center;
  padding: 15px 20px;
  position: relative;
  transition: all 0.2s ease;
  width: 220px;
}

.nft-d .frame-3396:hover,
.social-box-item:hover,
.social-box-item-1:hover {
  transform: scale(1.1);
}

.nft-d .submit {
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: -3.50px;
  margin-top: -5.89px;
  position: relative;
  text-align: center;
  width: fit-content;
}

.nft-d .social-box {
  align-items: center;
  display: flex;
  gap: 51px;
  justify-content: center;
  position: relative;
  width: 1278px;
}

.nft-d .social-box-item {
  cursor: pointer;
  height: 157.11px;
  min-width: 161.56px;
  position: relative;
  transition: all 0.2s ease;
}

.nft-d .overlap-group {
  background-size: 100% 100%;
  height: 157px;
  position: relative;
  width: 158px;
}

.nft-d .join-the-xion-community {
  left: 32px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 112px;
  width: 94px;
}

.nft-d .for-updates-and-news {
  left: 37px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 123px;
  width: 84px;
  font-size: 9px;
}

.nft-d .chat-in-real-time-with-the {
  left: 30px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 113px;
  width: 97px;
  font-size: 9px;
}

.nft-d .xion-community {
  left: 47px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 124px;
  width: 63px;
  font-size: 9px;
}

.nft-d .social-box-item-1 {
  cursor: pointer;
  height: 157.1px;
  min-width: 161.56px;
  position: relative;
  transition: all 0.2s ease;
}

.nft-d .follow-xionfinance-for {
  left: 36px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 113px;
  width: 87px;
  font-size: 9px;
}

.nft-d .updates-and-news {
  left: 44px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 124px;
  width: 71px;
  font-size: 9px;
}

.nft-d .follow-xionfinance-for-1 {
  left: 32px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 113px;
  width: 94px;
  font-size: 9px;
}

.nft-d .footer {
  background-color: #0000;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 37.6px;
  min-width: 1194.56px;
  height: 54.22px;
  display: flex;
  position: relative;
  margin-top: 600px;
}

.nft-d .line-34 {
  height: 1px;
  margin-top: -0.4px;
  width: 1194.56px;
}

.nft-d .frame-3399 {
  justify-content: center;
  align-items: flex-start;
  gap: 984px;
  width: 1187px;
  height: 16px;
  margin-left: -1px;
  display: flex;
  position: relative;
}

.nft-d .copyright {
  font-size: 14px;
  font-family: "Bebas Neue Pro-Regular", Helvetica;
  color: #f9f9f9;
  letter-spacing: 0;
  width: 117px;
  height: 16px;
  margin-top: -1px;
  line-height: normal;
  position: relative;
  right: 530px;
}

.nft-d .tcs {
  letter-spacing: 0;
  font-size: 14px;
  text-align: right;
  width: 95px;
  height: 16px;
  margin-top: -15px;
  line-height: normal;
  position: relative;
  right: -1070px;
}

.nft-d .approve {
  letter-spacing: 0;
  text-align: center;
  width: 334px;
  min-height: 29px;
  line-height: normal;
  margin-top: 5px;
}

.nft-d .b-wrapper {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  gap: 55px;
  justify-content: space-around;
  position: relative;
  width: 1034px;
}

.nft-d .frame-34 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  gap: 55px;
  justify-content: space-around;
  position: relative;
  width: 1034px;
}

.nft-d .b-2 {
  align-self: stretch;
  position: relative;
  width: 1033px;
}

.nft-d .XGT-MARKET-4 {
  color: #35cbfd;
  letter-spacing: 0;
  text-align: center;
  width: 1025px;
  font-family: Bebas Neue Pro-Regular, Helvetica;
  font-size: 26px;
  font-weight: 400;
  line-height: 23.1px;
  position: absolute;
  top: 60px;
  left: 8px;
}

.nft-d .text-wrapper-62 {
  color: #35cbfd;
  font-family: "Bebas Neue Pro-Regular", Helvetica;
  font-size: 26px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 23.1px;
}

.nft-d .text-wrapper-63 {
  font-family: "Bebas Neue Pro-Light", Helvetica;
  font-weight: 300;
}


.nft-d .mysterybox {
  height: 408px;
  left: 0;
  position: absolute;
  top: 0;
  width: 459px;
}

.nft-d .about-us-blog-t-cs-privacy-policy {
  letter-spacing: 0;
  text-align: right;
  width: 96px;
  height: 16px;
  margin-top: -1px;
  line-height: normal;
  position: relative;
}

.nft-d .terms-link,
.nft-d .privacy-link {
  color: #f9f9f9;
  cursor: pointer;
  min-width: 125px;
  margin-top: 3px;
  margin-left: -14px;
  font-family: "Bebas Neue Pro-Light", Helvetica;
  font-size: 12px;
  transition: color .3s;
}

.nft-d .terms-link:hover,
.nft-d .privacy-link:hover {
  color: #35cbfd;
  /* Change color on hover */
}

.nft-d .footer-section {
  width: 1194px;
  height: 208px;
  margin-top: 600px;
}