.privacy-m .privacy-policy {
  background-position: initial;
  background-size: cover;
  border: 1px;
  flex-direction: column;
  align-items: center;
  gap: 28px;
  min-width: 358px;
  padding: 66px 8px 100px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.privacy-m .frame-3420 {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 75px;
  padding: 0 0 0 2px;
  display: flex;
  position: relative;
  bottom: 25px;
}

.privacy-m .nav-1 {
  align-items: center;
  align-self: center;
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  height: 46px;
  min-width: 340px;
  position: relative;
}

.privacy-m .logo-1 {
  width: 123px;
  height: 32px;
  margin-left: 20px;
}


.privacy-m .my-custom-wrapper {
  border-radius: 12px;
  display: inline-block;
  overflow: hidden;
  margin-right: 110px;
}

.privacy-m .my-custom-class {
  cursor: pointer;
  font-family: var(--font-family-bebas_neue_pro-regular) !important;
  /* Replace this with the actual font family value */
  font-weight: bold;
  position: relative;
}

.privacy-m .img-wrapper {
  --webkit-backdrop-filter: blur(10px) brightness(100%);
  -webkit-backdrop-filter: blur(10px) brightness();
  backdrop-filter: blur(10px) brightness();
  border-radius: 10px;
  width: 44px;
  height: 44px;
  position: absolute;
  right: 65px;
}

.privacy-m .group-2 {
  z-index: 9;
  width: 44px;
  height: 44px;
  cursor: pointer;
}

.privacy-m .overlap-b-group.open .burger-menu-container {
  z-index: 9;
  background-color: #d9d9d9;
  border-radius: 1px 1px 10px 10px;
  width: 250px;
  height: 410px;
  position: absolute;
  top: -5px;
  right: 70px;
}

.privacy-m .overlap-b-group.open .burger-2 {
  -webkit-backdrop-filter: blur(10px) brightness();
  backdrop-filter: blur(10px) brightness();
  z-index: 9;
  border-radius: 10px;
  width: 33px;
  height: 33px;
  position: absolute;
  top: 16px;
  left: 233px;
}

.privacy-m .terms-content {
  width: 300px;
  margin-top: 5px;
  margin-bottom: -50px;
}

.privacy-m .terms-content h1 {
  font: var(--font-family-bebas_neue_pro-regular);
  font-size: 25px;
  color: white;
}

.privacy-m .terms-content p {
  font: var(--font-family-bebas_neue_pro-regular);
  color: #fff;
  font-size: 12px;
  margin-top: 20px;
}

.privacy-m .footer-1 {
  background-color: #0000;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 20.4px;
  min-width: 334px;
  height: 57px;
  display: flex;
  position: relative;
  top: 50px;
}

.privacy-m .line-34-1 {
  height: 1px;
  margin-top: 21.6px;
  width: 337px;
}

.privacy-m .frame-3399-1 {
  align-items: flex-start;
  display: flex;
  gap: 140px;
  height: 23px;
  justify-content: center;
  margin-left: -2px;
  position: relative;
  width: 329px;
}

.privacy-m .copyright-1 {
  font-family: "Bebas Neue Pro-Light", Helvetica;
  color: #f9f9f9;
  font-size: 13px;
  font-weight: 400;
  height: 16px;
  letter-spacing: 0;
  line-height: normal;
  margin-left: -4.50px;
  margin-top: -1.00px;
  position: relative;
  width: 105px;
}

.privacy-m .about-us-blog-t-cs-privacy-policy-1 {
  color: #fff;
  font-family: var(--font-family-titillium_web);
  font-size: 12px;
  font-weight: 400;
  height: 16px;
  letter-spacing: 0;
  line-height: normal;
  margin-right: -4.50px;
  margin-top: -1.00px;
  position: relative;
  text-align: right;
  width: 93px;
}

.privacy-m .terms-link,
.privacy-m .privacy-link {
  font-family: "Bebas Neue Pro-Light", Helvetica;
  color: #f9f9f9;
  cursor: pointer;
  min-width: 125px;
  margin-left: -30px;
  font-size: 12px;
  transition: color .3s;
  margin-top: 3px;
}

.privacy-m .terms-link:hover,
.privacy-m .privacy-link:hover {
  color: #35cbfd;
  /* Change color on hover */
}