.usdt,
.usdt-2,
.usdt-4 {
  height: 45px;
  min-width: 180.43px;
  position: relative;
}

.overlap-group-9,
.overlap-group-10,
.overlap-group-11 {
  align-items: flex-start;
  border: 1px solid;
  border-color: var(--cerulean);
  border-radius: 7px;
  display: flex;
  gap: 2px;
  height: 45px;
  min-width: 176px;
  padding: 6.0px 5.5px;
}

.path-538,
.path-538-1,
.path-538-2 {
  height: 31px;
  width: 31px;
}

.overlap-group1-4,
.overlap-group1-5,
.overlap-group1-6 {
  align-self: center;
  height: 26px;
  margin-top: 1.0px;
  position: relative;
  width: 71px;
}

.usdt-1,
.usdt-3,
.usdt-5 {
  left: 1px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  width: 71px;
}

.polygon,
.polygon-1,
.polygon-2 {
  left: 2px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 17px;
  width: 46px;
}

