.chain-box-desktop {
  align-items: center;
  border: 1px none;
  justify-content: center;
  height: 649px;
  min-width: 623px;
  background-color: rgba(0, 0, 0, 0);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow-x: hidden;
  z-index: 9999;
}

.chain-box-desktop .overlap-group-189 {
  align-items: flex-end;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  gap: 29px;
  min-height: 649px;
  padding: 21.6px 23.0px;
  width: 624px;
  z-index: 1;
}

.chain-box-desktop .flex-row-112 {
  align-items: flex-start;
  display: flex;
  gap: 195px;
  height: 40px;
  min-width: 357px;
}

.chain-box-desktop .select-source-chain-5 {
  color: #f9f9f9;
  font-family: var(--font-family-titillium_web);
  font-size: var(--font-size-s);
  letter-spacing: 0;
  align-self: flex-end;
  min-width: 136px;
  min-height: 24px;
  font-weight: 700;
  line-height: normal;
  z-index: 103;
  margin-right: -393px;
}

.chain-box-desktop .x {
  height: 25px;
  width: 26px;
  cursor: pointer;
}

.chain-box-desktop .overlap-group-114 {
  align-items: flex-start;
  border: 1px solid;
  border-color: #33a4d8;
  border-radius: 7px;
  display: flex;
  height: 45px;
  margin-right: 0.01px;
  min-width: 577px;
  padding: 11px 17px;
}

.chain-box-desktop .search-chain-by-name-or-chain-id {
  background-color: transparent;
  border: 0;
  color: #ffffff;
  font-family: var(--font-family-titillium_web);
  font-size: var(--font-size-xs);
  font-weight: 400;
  height: 21px;
  letter-spacing: 0;
  line-height: normal;
  padding: 0;
  width: 542px;
}

.chain-box-desktop .search-chain-by-name-or-chain-id::placeholder {
  color: #7b7b7b99;
}

.chain-box-desktop .scroll-group-1::-webkit-scrollbar {
  display: none;
  width: 0;
}

.chain-box-desktop .scroll-group-1 {
  z-index: 2;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 17px;
  width: 577px;
  height: 467px;
  display: flex;
  position: fixed;
  top: 162px;
  left: 23px;
  overflow-y: scroll;
  align-content: flex-start;
}

.chain-box-desktop .overlap-group-container-115 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 13px;
  width: 280px;
}

.chain-box-desktop .overlap-group-116 {
  align-items: center;
  background-color: var(--onyx);
  border-radius: 7px;
  display: flex;
  gap: 16px;
  height: 62px;
  min-width: 280px;
  padding: 0 13px;
  cursor: pointer;
}

.chain-box-desktop .polygonLogo,
.chain-box-desktop .bnbLogo,
.chain-box-desktop .ethLogo,
.chain-box-desktop .optimismLogo,
.chain-box-desktop .path-5341 {
  height: 38px;
  width: 38px;
}

.chain-box-desktop .x-mainnet {
  letter-spacing: 0;
  line-height: normal;
  margin-top: 1.0px;
  min-height: 25px;
  width: 196px;
}

.chain-box-desktop .overlap-group210 {
  align-items: center;
  background-color: var(--onyx);
  border-radius: 7px;
  display: flex;
  gap: 14px;
  height: 62px;
  min-width: 280px;
  padding: 11px 13px;
  cursor: pointer;
}

.chain-box-desktop .avalancheLogo {
  align-self: center;
  height: 38px;
  width: 38px;
}

.chain-box-desktop .arbitrumLogo {
  align-self: center;
  height: 38px;
  width: 38px;
}

.chain-box-desktop .optimismLogo {
  align-self: center;
  height: 38px;
  width: 38px;
}

.chain-box-desktop .bnbLogo {
  align-self: center;
  height: 38px;
  width: 38px;
}

.chain-box-desktop .ethereumLogo {
  align-self: center;
  height: 38px;
  width: 38px;
}

.chain-box-desktop .lineaLogo {
  align-self: center;
  height: 38px;
  width: 38px;
}

.chain-box-desktop .avalanche-mainnet {
  letter-spacing: 0;
  line-height: normal;
  margin-top: 2.0px;
  min-height: 26px;
  width: 199px;
}

.chain-box-desktop .bnb-mainnet {
  letter-spacing: 0;
  line-height: normal;
  min-height: 26px;
  width: 195px;
}

.chain-box-desktop .overlap-group154 {
  align-items: center;
  background-color: var(--onyx);
  border-radius: 7px;
  display: flex;
  gap: 17px;
  height: 62px;
  min-width: 280px;
  padding: 0 12px;
  cursor: pointer;
}

.chain-box-desktop .overlap-group310 {
  align-items: center;
  background-color: var(--onyx);
  border-radius: 7px;
  display: flex;
  gap: 16px;
  height: 62px;
  min-width: 280px;
  padding: 0 12px;
  cursor: pointer;
}

.chain-box-desktop .optimism-mainnet {
  letter-spacing: 0;
  line-height: normal;
  min-height: 26px;
  width: 197px;
}

.chain-box-desktop .overlap-group555 {
  align-items: center;
  background-color: var(--onyx);
  border-radius: 7px;
  display: flex;
  gap: 16px;
  height: 62px;
  min-width: 280px;
  padding: 0 11.6px;
  cursor: pointer;
}

.chain-box-desktop .text-1 {
  letter-spacing: 0;
  line-height: normal;
  min-height: 26px;
  width: 146px;
}

.chain-box-desktop .info-token-selection {
  color: #f9f9f9;
  font-family: var(--font-family-titillium_web);
  font-size: 9px;
  letter-spacing: 0;
  margin-top: 48px;
  width: 300px;
  margin-right: -112px;
}

.overlap-group-116.active, .overlap-group210.active {
  background-color: #727278; /* Lighter tone of your choice */
}

.overlap-group-116, .overlap-group210 {
  transition: background-color 0.3s ease;
}

.overlap-group-116:hover, .overlap-group210:hover {
  background-color: #727278; /* Lighter tone for hover, same as the active state */
}
