.network-mobile {
  height: 45px;
  min-width: 180.43px;
  position: flex;
  cursor: pointer; /* Add cursor pointer */
}

.network-mobile .overlap-group-61 {
  align-items: center;
  border: 1px solid;
  border-color: var(--cerulean);
  border-radius: 7px;
  display: flex;
  height: 45px;
  min-width: 176px;
  padding: 5.8px 4.4px;
  margin-right: auto; /* Add this line to align to the left */
}

.network-mobile .path-534,
.network-mobile .path-534-1 {
  align-self: center;
  height: 31px;
  width: 31px;
}

.network-mobile .overlap-group1-1 {
  height: 26px;
  margin-left: 6px;
  margin-top: 1.0px;
  position: relative;
  width: 115px;
}

.network-mobile .mainnet {
  left: 1px;
  margin-top: 2px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 15px;
  width: 65px;
}

.network-mobile .chain {
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  width: 115px;
}

.network-mobile .path-535,
.network-mobile .path-535-1 {
  height: 6px;
  margin-left: -1px;
  margin-top: 1.78px;
  width: 12px;
}

