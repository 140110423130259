.mobile .menu-box-3 {
  border: 1px solid;
  border-color: var(--cerulean);
  border-radius: 7px;
  display: flex;
  height: 34px;
  justify-content: center;
  min-width: 314px;
  position: relative;
}


.mobile .frame-16 {
  align-items: flex-start;
  display: flex;
  gap: 5px;
  height: 30px;
  justify-content: center;
  margin-left: -2px;
  margin-top: 1px;
  position: relative;
  width: 322px;
}


.mobile .m-sb-line {
  background-color: var(--alto);
  border-radius: 7px;
  height: 28px;
  left: 1px;
  right: 2px;
  position: absolute;
  top: 1px;
  bottom: 2px;
  width: 102px;
}


.mobile .group-259-3 {
  height: 30px;
  min-width: 100.75px;
  position: relative;
}

.mobile .overlap-group-16 {
  height: 28x;
  position: absolute;
  width: 104px;
}

.mobile .swapbridge-3 {
  left: 10px;
  letter-spacing: -0.19px;
  line-height: normal;
  opacity: 0.4;
  position: absolute;
  text-align: center;
  top: 3px;
  transform: rotate(-0.14deg);
  white-space: nowrap;
  width: 88px;
  cursor: pointer;
}


.mobile .group-260-3 {
  display: flex;
  height: 30px;
  min-width: 99.84px;
  position: relative;
}

.mobile .buy-3 {
  left: 10px;
  letter-spacing: -0.19px;
  line-height: normal;
  opacity: 0.4;
  position: absolute;
  text-align: center;
  top: 3px;
  transform: rotate(-0.14deg);
  white-space: nowrap;
  width: 88px;
  cursor: pointer;
}

.mobile .group-261-3 {
  height: 30px;
  min-width: 104px;
  position: relative;
}

.mobile .withdraw-3 {
  letter-spacing: -.19px;
  opacity: .4;
  text-align: center;
  white-space: nowrap;
  width: 88px;
  line-height: normal;
  position: absolute;
  top: 3px;
  left: 10px;
  transform: rotate(-.14deg);
  cursor: pointer;
}

.mobile .menu-box.menu-box-3 {
  margin-bottom: -2.00px;
}

.mobile .active {
  opacity: 1;
}

.mobile .inactive {
  opacity: 0.5;
}