.footer-section {
  width: 1194px;
  height: 208px;
  margin-top: 150px;
}

.footer-section .frame {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  position: relative;
  top: 31px;
  width: 1194px;
}

.footer-section .group {
  height: 150.35px;
  position: relative;
  width: 210.75px;
}

.footer-section .LOGO-instance {
  height: 29px !important;
  left: 1px !important;
  width: 156px !important;
}

.footer-section .div-12 {
  height: 27px;
  left: 0;
  position: absolute;
  top: 123px;
  width: 211px;
}

.footer-section .text-wrapper-29 {
  color: #ffffff;
  font-family: "Bebas Neue Pro-Bold", Helvetica;
  font-size: 14px;
  font-weight: 700;
  left: 32px;
  letter-spacing: 0;
  line-height: 12.5px;
  position: absolute;
  top: 7px;
  width: 67px;
}

.footer-section .overlap-group-wrapper-65 {
  height: 27px;
  left: 99px;
  position: absolute;
  top: 0;
  width: 112px;
  cursor: pointer;
}

.footer-section .overlap-group-65 {
  height: 27px;
  position: relative;
  width: 110px;
}

.footer-section .rectangle {
  background-color: #3f89d5;
  border-radius: 27px;
  height: 26px;
  left: 0;
  position: absolute;
  top: 1px;
  width: 110px;
}

.footer-section .rectangle:hover:hover {
  transform: scale(1.1);
}

.footer-section .text-wrapper-2 {
  color: #fff;
  letter-spacing: .7px;
  text-align: center;
  width: 77px;
  height: 26px;
  font-family: Bebas Neue Pro-Bold, Helvetica;
  font-size: 18px;
  font-weight: 700;
  line-height: 12.5px;
  display: inline-flex;
  position: absolute;
  top: 6px;
  left: 15px;
}

.footer-section .next-next-arrow {
  height: 8px;
  left: 88px;
  position: absolute;
  top: 11px;
  width: 12px;
}

.footer-section .imgfooter {
  height: 26px;
  left: 0;
  position: absolute;
  top: 0;
  width: 26px;
}

.footer-section .element-mercx-group-LTD {
  color: rgb(99, 0, 255);
  letter-spacing: 0px;
  width: 107px;
  font-family: "Titillium Web", Helvetica;
  font-size: 10.8px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 30px;
}

.footer-section .sections {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 80px;
  position: relative;
}

.footer-section .div-2 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 15px;
  position: relative;
}

.footer-section .text-wrapper-3 {
  color: #ff00ff;
  font-family: "Titillium Web", Helvetica;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.footer-section .text-wrapper-4 {
  color: #e8e8e8;
  letter-spacing: 0;
  width: -moz-fit-content;
  width: fit-content;
  font-family: "Titillium Web", Helvetica;
  font-size: 8px;
  font-weight: 400;
  line-height: normal;
  position: relative;
  display: inline-flex;
  cursor: pointer;
}

.footer-section .line {
  width: 1200px;
  height: 1px;
  margin-top: -0.5px;
}