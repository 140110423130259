.desktop-1 .frame-6 {
  align-items: center;
  align-self: center;
  cursor: pointer;
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-right: 6.0px;
  position: relative;
  transition: all 0.2s ease;
  width: fit-content;
}

.desktop-1 .frame-6:hover {
  transform: scale(1.1);
}

.desktop-1 .frame-6 .ab {
  margin-right: -2.00px;
  min-width: 336px;
  position: relative;
}

.desktop-1 .frame-6 .overlap-group-12 {
  background-size: 100% 100%;
  min-width: 334px;
  height: 47px;
  margin-top: 15px;
  /* margin-left: 180px; */
  padding: 4.2px 0;
}

.desktop-1 .frame-6 .approve {
  letter-spacing: 0;
  line-height: normal;
  min-height: 29px;
  text-align: center;
  width: 334px;
}

.desktop-1 .frame-6 .frame-6.frame-6-1,
.desktop-1 .frame-6 .frame-6.frame-6-2 {
  margin-right: 4.0px;
  margin-top: 21px;
}



.ipad-3 .frame-6 {
  align-items: center;
  align-self: center;
  cursor: pointer;
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-right: 6.0px;
  position: relative;
  transition: all 0.2s ease;
  width: fit-content;
}


.ipad-3 .frame-6:hover {
  transform: scale(1.1);
}


.ipad-3 .frame-6 .ab {
  margin-right: -2.00px;
  min-width: 336px;
  position: relative;
}


.ipad-3 .frame-6 .overlap-group-12 {
  background-size: 100% 100%;
  min-width: 334px;
  height: 47px;
  margin-top: 15px;
  padding: 9.2px 0;
}


.ipad-3 .frame-6 .approve {
  letter-spacing: 0;
  line-height: normal;
  min-height: 29px;
  text-align: center;
  width: 334px;
}


.ipad-3 .frame-6 .frame-6.frame-6-1,
.ipad-3 .frame-6 .frame-6.frame-6-2 {
  margin-right: 4.0px;
  margin-top: 21px;
}


