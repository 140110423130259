.menu-box {
  border: 1px solid;
  border-color: var(--cerulean);
  border-radius: 9px;
  display: flex;
  height: 43px;
  justify-content: center;
  min-width: 614px;
  position: relative;
}

.frame-15 {
  align-items: flex-start;
  display: flex;
  gap: 6px;
  height: 40.44px;
  justify-content: center;
  margin-left: -3px;
  margin-top: 3px;
  position: relative;
  width: 606px;
}

.group-259 {
  height: 40.44px;
  min-width: 200px;
  position: relative;
}

.overlap-group-3 {
  height: 40px;
  position: relative;
  width: 198px;
}

.w-sb-line {
  background-color: var(--alto);
  border-radius: 7px;
  width: 195px;
  height: 35px;
  position: absolute;
  top: 0;
  left: 4px;
}

.swapbridge {
  left: 30px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 0;
  width: 138px;
  cursor: pointer;
}

.group-260 {
  display: flex;
  height: 37px;
  min-width: 200px;
  position: relative;
}

.buy {
  flex: 1;
  letter-spacing: 0;
  line-height: normal;
  margin-left: 41.5%;
  margin-right: 38%;
  text-align: center;
  width: 41px;
  cursor: pointer;
}

.group-261 {
  height: 38.12px;
  margin-right: -2.00px;
  min-width: 200px;
  position: relative;
}

.withdraw {
  letter-spacing: -.29px;
  text-align: center;
  cursor: pointer;
  width: 93px;
  line-height: normal;
  position: absolute;
  top: -1px;
  left: 53px;
  transform: rotate(-.12deg);
}

.menu-box.menu-box-1,
.menu-box.menu-box-2 {
  margin-bottom: -2.00px;
}

.active {
  opacity: 1;
}

.inactive {
  opacity: 0.2;
}