.error-m .error-404 {
  background-position: initial;
  background-size: cover;
  border: 1px;
  flex-direction: column;
  align-items: center;
  gap: 28px;
  min-width: 358px;
  padding: 66px 8px 100px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.error-m .frame-3420 {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 45px;
  padding: 0 0 0 2px;
  display: flex;
  position: relative;
}

.error-m .nav-1 {
  align-items: center;
  align-self: center;
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  height: 46px;
  min-width: 340px;
  position: relative;
}

.error-m .logo-1 {
  width: 123px;
  height: 32px;
  margin-left: 20px;
}


.error-m .my-custom-wrapper {
  border-radius: 12px;
  display: inline-block;
  overflow: hidden;
  margin-right: 110px;
}

.error-m .my-custom-class {
  border: 2px solid #33a4d8;
  border-radius: 10px;
  cursor: pointer;
  font-family: var(--font-family-bebas_neue_pro-regular) !important; /* Replace this with the actual font family value */
  font-weight: bold;
  position: relative;
}

.error-m .img-wrapper {
  --webkit-backdrop-filter: blur(10px) brightness(100%);
  -webkit-backdrop-filter: blur(10px) brightness();
  backdrop-filter: blur(10px) brightness();
  border-radius: 10px;
  width: 44px;
  height: 44px;
  position: absolute;
  right: 65px;
}

.error-m .group-2 {
  z-index: 9;
  width: 44px;
  height: 44px;
  cursor: pointer;
}

.error-m .overlap-b-group.open .burger-menu-container {
  z-index: 9;
  background-color: #d9d9d9;
  border-radius: 1px 1px 10px 10px;
  width: 250px;
  height: 410px;
  position: absolute;
  top: -65px;
  right: 60px;
}

.error-m .error-404-content { 
  width: 300px;
  height: 650px;
  position: relative;
}

.error-m .error-404-content h1 {
  font: var(--font-family-bebas_neue_pro-regular);
  font-size: 25px;
  color: white;
}

.error-m .error-404-content p {
  font: var(--font-family-bebas_neue_pro-regular);
  color: #fff;
  margin-top: 50px;
  margin-left: -10px;
  font-size: 18px;
  width: 300px;
}

.error-m .text-wrapper {
  color: #ffffff;
  font-variant: var(--font-family-bebas_neue_pro-regular);
  font-size: 17px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 32.4px;
  position: absolute;
  top: 211px;
  width: 620px;
}

.error-m .go-back-2 {
  cursor: pointer;
  background-color: #7f64fb;
  border: 0.5px solid #7f64fb;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 50px;
  padding: 10px 20px;
  transition: background-color .3s;
  display: flex;
  position: absolute !important;
  top: 320px !important;
  left: 100px !important;
}

.error-m .go-back-2:hover {
  background-color: #439be4; /* Hover background color */
}

.error-m .go-back-2-text {
  color: white;
  font-variant: var(--font-family-bebas_neue_pro-regular);
  font-size: 22px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
}

.error-m .overlap-group {
  width: 300px;
  height: 205px;
  position: absolute;
  top: -100px;
  left: -125px;
}

.error-m .div {
  color: #fff;
  letter-spacing: 0;
  font-variant: var(--font-family-bebas_neue_pro-regular);
  width: 300px;
  font-size: 20px;
  font-weight: 100;
  line-height: normal;
  position: absolute;
  top: 120px;
  left: 120px;
}

.error-m .ABOUT-US {
  color: #fcfcfc;
  letter-spacing: 6.36px;
  font-variant: var(--font-family-bebas_neue_pro-regular);
  width: 300px;
  font-size: 90px;
  font-weight: 400;
  line-height: 101.5px;
  position: absolute;
  top: 150px;
  left: 115px;
}

.error-m .asset {
  height: 148px;
  left: 0px;
  mix-blend-mode: color-dodge;
  object-fit: cover;
  position: absolute;
  top: 15px;
  width: 122px;
}

.error-m .wepik-export {
  object-fit: cover;
  width: 452px;
  height: 692px;
  position: absolute;
  top: 65px;
  left: 250px;
  z-index: 1;
}

.error-m .img {
  object-fit: cover;
  width: 155px;
  height: 244px;
  position: absolute;
  top: 380px;
  left: -54px;
}

.error-m .asset-x {
  height: 148px;
  left: 983px;
  mix-blend-mode: color-dodge;
  object-fit: cover;
  position: absolute;
  top: 111px;
  width: 122px;
}

.error-m .footer-1 {
  background-color: #0000;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 20.4px;
  min-width: 334px;
  height: 57px;
  display: flex;
  position: relative;
  top: 50px;
}

.error-m .line-34-1 {
  height: 1px;
  margin-top: 21.6px;
  width: 337px;
}

.error-m .frame-3399-1 {
  align-items: flex-start;
  display: flex;
  gap: 140px;
  height: 23px;
  justify-content: center;
  margin-left: -2px;
  position: relative;
  width: 329px;
}

.error-m .copyright-1 {
  color: var(--electric-violet);
  font-family: var(--font-family-titillium_web);
  font-size: 9.8px;
  font-weight: 400;
  height: 16px;
  letter-spacing: 0;
  line-height: normal;
  margin-left: -4.50px;
  margin-top: -1.00px;
  position: relative;
  width: 105px;
}

.error-m .about-us-blog-t-cs-privacy-policy-1 {
  color: var(--hint-of-red);
  font-family: var(--font-family-titillium_web);
  font-size: 9.8px;
  font-weight: 400;
  height: 16px;
  letter-spacing: 0;
  line-height: normal;
  margin-right: -4.50px;
  margin-top: -1.00px;
  position: relative;
  text-align: right;
  width: 93px;
}

.error-m .email-link {

}