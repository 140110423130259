.medium {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 126px;
  min-width: 128px;
  transition: all 0.2s ease;
}

.medium:hover,
.medium.social-box-item-2:hover,
.medium.social-box-item-3:hover {
  transform: scale(1.1);
}

.overlap-group2 {
  align-items: flex-end;
  background-size: 100% 100%;
  display: flex;
  height: 126px;
  min-width: 126px;
  padding: 7.5px 8.4px;
}

.follow-xionfinance-for-3 {
  letter-spacing: 0;
  line-height: normal;
  min-height: 28px;
  text-align: center;
  width: 105px;
  font-size: 8px;
}

.medium.discord .overlap-group2,
.medium.discord-1 .overlap-group2,
.medium.discord-2 .overlap-group2 {
  justify-content: flex-end;
  padding: 6.1px 7.5px;
}

.medium.discord .follow-xionfinance-for-3,
.medium.discord-1 .follow-xionfinance-for-3,
.medium.discord-2 .follow-xionfinance-for-3 {
  min-height: 29px;
  width: 110px;
}

.medium.discord-1,
.medium.discord-2 {
  align-items: unset;
  display: unset;
  height: 125.52px;
  min-width: 127.89px;
  position: relative;
}

.medium.social-box-item-2,
.medium.social-box-item-2-1,
.medium.social-box-item-3,
.medium.social-box-item-3-1 {
  align-items: unset;
  display: unset;
  height: 125.52px;
  min-width: 127.89px;
  position: relative;
}

.medium.social-box-item-2-1 .overlap-group2,
.medium.social-box-item-3-1 .overlap-group2 {
  justify-content: flex-end;
  padding: 7.1px 6.9px;
}

.medium.social-box-item-2-1 .follow-xionfinance-for-3,
.medium.social-box-item-3-1 .follow-xionfinance-for-3 {
  width: 112px;
}

.medium.social-box-item-2 .overlap-group2,
.medium.social-box-item-3 .overlap-group2 {
  justify-content: flex-end;
  padding: 6.1px 5.8px;
}

.medium.social-box-item-2 .follow-xionfinance-for-3,
.medium.social-box-item-3 .follow-xionfinance-for-3 {
  min-height: 29px;
  width: 113px;
}

