.frame-3396-1 {
  background-color: var(--kingfisher-daisy);
  cursor: pointer;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  gap: 7.18px;
  width: 236px;
  height: 35px;
  padding: 15px 20px;
  transition: all .2s;
  display: flex;
  position: relative;
  margin-left: 30px;
}

.frame-3396-1:hover {
  transform: scale(1.1);
}

.submit-1 {
  height: 26px;
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: -9.30px;
  margin-top: -11.70px;
  position: relative;
  text-align: center;
  width: 161px;
}

.frame-3396-1  {
  margin-bottom: unset;
}

/* Custom styles for mobile */
@media (max-width: 767px) {
  .frame-3396-1 {
    background-color: var(--kingfisher-daisy);
    cursor: pointer;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    gap: 7.18px;
    width: 150px;
    height: 35px;
    margin-top: 100px;
    margin-left: -260px;
    padding: 15px 20px;
    transition: all .2s;
    display: flex;
    position: relative;
}
}