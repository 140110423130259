.error-i .error-404 {
  background-position: initial;
  background-size: cover;
  border: 1px;
  flex-direction: column;
  align-items: center;
  gap: 80px;
  min-width: 768px;
  padding: 75px 45px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.error-i .frame-3416-2 {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 120px;
  display: flex;
  position: relative;
}

.error-i .nav-3 {
  justify-content: space-between;
  align-self: center;
  align-items: center;
  min-width: 688px;
  height: 46px;
  padding: 10px 105px;
  display: flex;
  position: relative;
}

.error-i .logo-3 {
  width: 178px;
  height: 33px;
  margin-top: -1px;
  margin-left: -80px;
}

.error-i .my-custom-wrapper {
  border-radius: 12px;
  display: inline-block;
  overflow: hidden;
}

.error-i .my-custom-class {
  border: 2px solid #33a4d8;
  border-radius: 10px;
  cursor: pointer;
  font-family: var(--font-family-bebas_neue_pro-regular) !important; /* Replace this with the actual font family value */
  font-weight: bold;
  position: relative;
}

.error-i .img-wrapper {
  --webkit-backdrop-filter: blur(10px) brightness(100%);
  -webkit-backdrop-filter: blur(10px) brightness();
  backdrop-filter: blur(10px) brightness();
  border-radius: 10px;
  width: 44px;
  height: 44px;
  position: absolute;
  right: 35px;
  cursor: pointer;
}

.img-wrapper:hover {
  animation: shake 5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}

@keyframes shake {
  10%, 90% {
    transform: translateX(-5px); /* Move left */
  }
  20%, 80% {
    transform: translateX(5px); /* Move right */
  }
  30%, 50%, 70% {
    transform: translateX(-3px); /* Move left */
  }
  40%, 60% {
    transform: translateX(3px); /* Move right */
  }
}

.error-i .overlap-b-group.open .burger-menu-container {
  z-index: 9;
  background-color: #d9d9d9;
  border-radius: 1px 1px 10px 10px;
  width: 250px;
  height: 410px;
  position: absolute;
  top: -80px;
  right: -79px;
}

.error-i .frame-3413-4 {
  min-width: 677px;
  height: 342px;
  display: flex;
  position: relative;
  top: 10px;
}

.error-i .flex-row-4 {
  align-items: center;
  gap: 205px;
  min-width: 559px;
  height: 57px;
  display: flex;
}

.error-i .error-404-content { 
  width: 468px;
  height: 650px;
  position: relative;
}

.error-i .error-404-content h1 {
  font: var(--font-family-bebas_neue_pro-regular);
  font-size: 45px;
  color: white;
}

.error-i .error-404-content p {
  font: var(--font-family-bebas_neue_pro-regular);
  color: #fff;
  margin-top: 140px;
  margin-left: -80px;
  font-size: 28px;
}

.error-i .text-wrapper {
  color: #ffffff;
  font-variant: var(--font-family-bebas_neue_pro-regular);
  font-size: 27px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 32.4px;
  position: absolute;
  top: 211px;
  width: 620px;
}

.error-i .go-back-2 {
  cursor: pointer;
  background-color: #7f64fb;
  border: 0.5px solid #7f64fb;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 50px;
  padding: 10px 20px;
  transition: background-color .3s;
  display: flex;
  position: absolute !important;
  top: 420px !important;
  left: 300px !important;
}

.error-i .go-back-2:hover {
  background-color: #439be4; /* Hover background color */
}

.error-i .go-back-2-text {
  color: white;
  font-variant: var(--font-family-bebas_neue_pro-regular);
  font-size: 22px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
}

.error-i .overlap-group {
  width: 706px;
  height: 205px;
  position: absolute;
  top: -100px;
  left: -125px;
}

.error-i .div {
  color: #fff;
  letter-spacing: 0;
  font-variant: var(--font-family-bebas_neue_pro-regular);
  width: 501px;
  font-size: 30px;
  font-weight: 100;
  line-height: normal;
  position: absolute;
  top: 120px;
  left: 40px;
}

.error-i .ABOUT-US {
  color: #fcfcfc;
  letter-spacing: 6.36px;
  font-variant: var(--font-family-bebas_neue_pro-regular);
  width: 706px;
  font-size: 159px;
  font-weight: 400;
  line-height: 141.5px;
  position: absolute;
  top: 150px;
  left: 40px;
}

.error-i .asset {
  height: 148px;
  left: 407px;
  mix-blend-mode: color-dodge;
  object-fit: cover;
  position: absolute;
  top: 15px;
  width: 122px;
}

.error-i .wepik-export {
  object-fit: cover;
  width: 452px;
  height: 692px;
  position: absolute;
  top: 65px;
  left: 450px;
  z-index: 1;
}

.error-i .img {
  object-fit: cover;
  width: 155px;
  height: 244px;
  position: absolute;
  top: 380px;
  left: 14px;
}

.error-i .asset-x {
  height: 148px;
  left: 983px;
  mix-blend-mode: color-dodge;
  object-fit: cover;
  position: absolute;
  top: 111px;
  width: 122px;
}

.error-i .email-link {
  /* No specific styles found in the original CSS */
}

.error-i .footer-3 {
  background-color: #0000;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 30.4px;
  min-width: 763px;
  height: 54px;
  margin-bottom: 5px;
  display: flex;
  position: relative;
  top: 0px;
}

.error-i .line-34-3 {
  height: 1px;
  margin-top: -0.5px;
  width: 690px;
}

.error-i .frame-3399-3 {
  align-items: flex-start;
  display: flex;
  gap: 482px;
  height: 23px;
  justify-content: center;
  margin-left: 1px;
  position: relative;
  width: 685px;
}

.error-i .copyright-3 {
  height: 16px;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
  width: 107px;
}

.error-i .about-us-blog-t-cs-privacy-policy-3 {
  height: 16px;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
  text-align: right;
  width: 96px;
}

.error-i .footer-section {
  width: 768px;
  height: 208px;
  margin-top: 250px;
}

.error-i .footer-section .frame {
  justify-content: space-between;
  align-items: flex-start;
  width: 768px;
  display: flex;
  position: relative;
  top: 31px;
}

.error-i .footer-section .line {
  width: 768px;
  height: 1px;
  margin-top: -0.5px;
}


