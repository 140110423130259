.mobile {
  background-position: initial;
  background-size: cover;
  border: 1px;
  flex-direction: column;
  align-items: center;
  gap: 28px;
  min-width: 358px;
  padding: 68px 8px 120px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.mobile .alphalaunchbanner {
  margin-top: -27px;
  margin-bottom: -68px;
  position: relative;
}

.mobile .alphaimg {
  position: relative;
}

.mobile .frame-3420 {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 75px;
  padding: 0 0 0 2px;
  display: flex;
  position: relative;
}

.mobile .nav-1 {
  align-items: center;
  align-self: center;
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  height: 46px;
  min-width: 340px;
  position: relative;
}

.mobile .logo-1 {
  width: 123px;
  height: 32px;
  margin-left: 20px;
}


.mobile .my-custom-wrapper {
  border-radius: 12px;
  display: inline-block;
  overflow: hidden;
  margin-right: 110px;
}

.mobile .my-custom-class {
  cursor: pointer;
  font-family: var(--font-family-bebas_neue_pro-regular) !important;
  /* Replace this with the actual font family value */
  font-weight: bold;
  position: relative;
}

.mobile .img-wrapper {
  --webkit-backdrop-filter: blur(10px) brightness(100%);
  -webkit-backdrop-filter: blur(10px) brightness();
  backdrop-filter: blur(10px) brightness();
  border-radius: 10px;
  width: 44px;
  height: 44px;
  position: absolute;
  right: 65px;
}

.mobile .group-2 {
  z-index: 9;
  width: 44px;
  height: 44px;
  cursor: pointer;
}

.mobile .overlap-b-group.open .burger-menu-container {
  z-index: 9;
  background-color: #d9d9d9;
  border-radius: 1px 1px 10px 10px;
  width: 250px;
  height: 410px;
  position: absolute;
  top: -5px;
  right: 70px;
}

.mobile .overlap-b-group.open .burger-2 {
  -webkit-backdrop-filter: blur(10px) brightness();
  backdrop-filter: blur(10px) brightness();
  z-index: 9;
  border-radius: 10px;
  width: 33px;
  height: 33px;
  position: absolute;
  top: 16px;
  left: 233px;
}

.mobile .frame-3416 {
  align-self: stretch;
  display: flex;
  height: 323px;
  justify-content: center;
  min-width: 340px;
  position: relative;
}

.mobile .heromenu-box-1 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 55px;
  height: 300px;
  margin-left: -4px;
  position: relative;
  width: 312px;
}

.mobile .hero-box-1 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 58px;
  height: 205px;
  padding: 20px 0px;
  position: relative;
}

.mobile .frame-3413-1 {
  align-self: stretch;
  height: 185px;
  margin-bottom: -20.00px;
  min-width: 312px;
  position: relative;
}

.mobile .overlap-group-15 {
  height: 183px;
  left: -12px;
  position: absolute;
  top: -1px;
  width: 338px;
}

.mobile .title-1 {
  color: var(--ceramic);
  font-family: var(--font-family-bebas_neue_pro-regular);
  font-size: 69px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 61.4px;
  position: absolute;
  text-align: center;
  top: -15px;
  width: 338px;
  margin-bottom: 15px;
}

.mobile .fees-so-low-it-will-1 {
  color: var(--ceramic);
  font-family: var(--font-family-bebas_neue_pro-light);
  font-size: 35px;
  font-weight: 300;
  left: 28px;
  letter-spacing: 0;
  line-height: 31.5px;
  position: absolute;
  text-align: center;
  top: 121px;
  margin-bottom: 5px;
}

.mobile .vector-1-1 {
  width: 182px;
  height: 2px;
  position: absolute;
  top: 194px;
  left: 50%;
  transform: translateX(-50%);
}

.mobile .frame-3417 {
  display: flex;
  height: 551px;
  justify-content: center;
  min-width: 334px;
  position: relative;
}

.mobile .sub-1 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 42px;
  height: 547px;
  margin-left: -2px;
  position: relative;
  width: 318px;
}

.mobile .sub-box-1 {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  padding: 55px 0 0;
  display: flex;
  position: relative;
}

.mobile .join-a-global-community-1 {
  align-self: stretch;
  color: var(--ceramic);
  font-family: var(--font-family-oswald);
  font-size: 19px;
  font-weight: 400;
  height: 27px;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
}

.mobile .line-33-1 {
  height: 1.44px;
  min-width: 148px;
  position: relative;
}

.mobile .learn-more-about-xio-1 {
  align-self: stretch;
  color: var(--hint-of-red);
  font-family: var(--font-family-titillium_web);
  font-size: var(--font-size-xl);
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
}

.mobile .email-box {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  width: 310px;
  height: 76px;
  margin-left: 50px;
  display: flex;
  position: relative;
  bottom: 75px;
  margin-top: 50px;
  z-index: 9;
}

.mobile .social-box-1 {
  height: 284px;
  min-width: 293px;
  position: relative;
}

.mobile .flex-col-4 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 28px;
  left: 4px;
  min-height: 279px;
  position: absolute;
  top: 2px;
  width: 128px;
}

.mobile .flex-col-5 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 28px;
  left: 163px;
  min-height: 279px;
  position: absolute;
  top: 2px;
  width: 128px;
}

.mobile .overlap-group3 {
  align-items: flex-end;
  background-size: 100% 100%;
  display: flex;
  height: 126px;
  justify-content: flex-end;
  margin-left: 0;
  min-width: 126px;
  padding: 7.5px 8.4px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.mobile .overlap-group3:hover {
  transform: scale(1.1);
}

.mobile .follow-xionfinance-for-2 {
  letter-spacing: 0;
  line-height: normal;
  min-height: 0px;
  text-align: center;
  width: 105px;
  font-size: 8px;
}

.mobile .footer-1 {
  background-color: #0000;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 20.4px;
  min-width: 334px;
  height: 87px;
  display: flex;
  position: relative;
  top: 50px;
}

.mobile .line-34-1 {
  height: 1px;
  margin-top: 21.6px;
  width: 337px;
}

.mobile .frame-3399-1 {
  align-items: flex-start;
  display: flex;
  gap: 140px;
  height: 23px;
  justify-content: center;
  margin-left: -2px;
  position: relative;
  width: 329px;
}

.mobile .copyright-1 {
  font-family: "Bebas Neue Pro-Light", Helvetica;
  color: #f9f9f9;
  font-size: 13px;
  font-weight: 400;
  height: 16px;
  letter-spacing: 0;
  line-height: normal;
  margin-left: -4.50px;
  margin-top: -1.00px;
  position: relative;
  width: 105px;
}

.mobile .about-us-blog-t-cs-privacy-policy-1 {
  color: #fff;
  font-family: var(--font-family-titillium_web);
  font-size: 12px;
  font-weight: 400;
  height: 16px;
  letter-spacing: 0;
  line-height: normal;
  margin-right: -4.50px;
  margin-top: -1.00px;
  position: relative;
  text-align: right;
  width: 93px;
}

/* Custom styles for mobile */
.mobile .frame-3396-1 {
  background-color: var(--kingfisher-daisy) !important;
  cursor: pointer !important;
  border-radius: 8px !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 7.18px !important;
  width: 150px !important;
  height: 35px !important;
  margin-top: 100px !important;
  margin-left: -260px !important;
  padding: 15px 20px !important;
  transition: all .2s !important;
  display: flex !important;
  position: relative !important;
}

.mobile .frame-3395-2 {
  background-color: var(--white);
  border: 1.2px solid;
  border-color: var(--kingfisher-daisy);
  border-radius: 8px;
  align-items: center;
  width: 220px;
  height: 35px;
  padding: 14.37px;
  display: flex;
  position: relative;
  margin-left: 150px;
}


.mobile .menu-box-3 {
  border: 1px solid;
  border-color: var(--cerulean);
  border-radius: 7px;
  justify-content: center;
  min-width: 314px;
  height: 34px;
  display: flex;
  position: relative;
  top: 50px;
}

.mobile .eg-bethe1sttoknow-2 {
  /* Your mobile styles here */
  background-color: transparent;
  border: 0;
  height: 22.75px;
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: -7.04px;
  margin-right: -50.15px;
  margin-top: -9.44px;
  min-width: 330.42px;
  opacity: 0.3;
  padding: 0;
  position: relative;
  white-space: nowrap;
  /* Add other mobile-specific styles here */
}

.mobile .frame-34 {
  flex: none;
  justify-content: space-around;
  align-items: flex-start;
  gap: 55px;
  width: 205px;
  display: flex;
  position: relative;
}

.mobile .b-2 {
  align-self: stretch;
  position: relative;
  width: 306px;
}

.mobile .XGT-MARKET-4 {
  color: #35cbfd;
  letter-spacing: 0;
  text-align: center;
  width: 210px;
  font-family: "Bebas Neue Pro-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 23.1px;
  position: absolute;
  top: 30px;

}

.mobile .text-wrapper-62 {
  color: #35cbfd;
  font-family: "Bebas Neue Pro-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 23.1px;
}

.mobile .text-wrapper-63 {
  font-family: "Bebas Neue Pro-Light", Helvetica;
  font-weight: 300;
}

.mobile .terms-link,
.mobile .privacy-link {
  font-family: "Bebas Neue Pro-Light", Helvetica;
  color: #f9f9f9;
  cursor: pointer;
  min-width: 125px;
  margin-left: -30px;
  font-size: 12px;
  transition: color .3s;
  margin-top: 3px;
}

.mobile .terms-link:hover,
.mobile .privacy-link:hover {
  color: #35cbfd;
  /* Change color on hover */
}

.mobile .feebox-mobile {
  position: relative;
  margin-top: 10px;
}

.mobile .fee-box-d-expanded {
  z-index: 9;
  background-color: #0000;
  flex-direction: row;
  justify-content: center;
  width: 338px;
  margin-top: 60px;
  margin-bottom: -200px;
  display: flex;
}

.mobile .fee-box-d-expanded .path {
  cursor: pointer;
  width: 17px;
  height: 11px;
  position: absolute;
  top: 29px;
  left: 287px;
}

.mobile .fee-box-d-expanded .overlap-group {
  width: 621px;
  height: 20px;
  position: absolute;
  top: 75px;
  left: 27px;
}

.mobile .fee-box-d-minimised {
  z-index: 9;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-top: 60px;
  margin-bottom: -390px;
}

.mobile .fee-box-d-minimised .overlap-group-wrapper {
  height: 245px;
  width: 338px;
}

.mobile .fee-box-d-minimised .overlap-group {
  border: 1px solid #35cbfd;
  border-radius: 16px;
  width: 338px;
  height: 63px;
  position: relative;
  top: -128px;
  margin-left: -2px;
}

.mobile .fee-box-d-minimised .path {
  width: 17px;
  height: 11px;
  position: absolute;
  top: 29px;
  left: 287px;
}

.mobile .fee-box-d-minimised .minimize {
  color: #f9f9f9;
  font-family: "Titillium Web", Helvetica;
  font-size: 16px;
  font-weight: 700;
  left: 25px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 21px;
}

.mobile .fee-box-d-expanded .div {
  border: 1px solid #35cbfd;
  border-radius: 22px;
  width: 338px;
  height: 245px;
  position: relative;
  overflow-x: hidden;
  top: -200px;
}

.mobile .fee-box-d-expanded .text-wrapper {
  color: #f9f9f9;
  letter-spacing: 0;
  text-align: right;
  width: 311px;
  font-family: Titillium Web, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 0;
  left: -25px;
}

.mobile .fee-box-d-expanded .line {
  width: 287px;
  height: 2px;
  position: absolute;
  top: 60px;
  left: 25px;
}

.mobile .fee-box-d-expanded .divbox {
  border: 1px solid #35cbfd;
  border-radius: 22px;
  width: 675px;
  height: 300px;
  position: relative;
  top: -125px;
  left: -2px;
}

.mobile .fee-box-d-expanded .information {
  color: #f9f9f9;
  width: 300px;
  height: 20px;
  margin-top: -200px;
  margin-left: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 400;
}

.mobile .titilliumweb-normal-ceramic-9-5px {
  color: var(--ceramic);
  font-family: var(--font-family-titillium_web);
  margin-top: -22px;
  margin-left: 19px;
  font-size: 9.5px;
  font-style: normal;
  font-weight: 400;
}


.mobile .titilliumweb-bold-desert-storm-37-6px {
  color: var(--desert-storm);
  font-family: var(--font-family-titillium_web);
  margin-top: -6px;
  font-size: 25.6px;
  font-style: normal;
  font-weight: 700;
}


.mobile .fee-box-d-expanded .info-popup {
  z-index: 157;
  background-size: 100% 100%;
  width: 263px;
  height: 145px;
  margin-top: -201px;
  margin-left: 14px;
  position: relative;
}

.mobile .fee-box-d-expanded .info-text {
  color: #f9f9f9;
  width: 238px;
  margin-left: 7px;
  padding-top: 10px;
  padding-left: 11px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 400;
}

.mobile .frame-3403 {
  align-items: center;
  height: 20px;
  position: relative;
  top: 123px;
}

.mobile .fdpopup .div {
  background-image: url(../../../static/img/rectangle-2921.png);
  background-size: 100% 100%;
  width: 311px;
  height: 311px;
  margin-top: -325px;
  margin-left: -2px;
  position: absolute;
}

.mobile .fdpopup .close {
  cursor: pointer;
  z-index: 99;
  width: 14px;
  height: 13px;
  position: absolute;
  top: 238px;
  left: 313px;
}

.mobile .sfpopup .div {
  background-image: url(../../../static/img/rectangle-2921.png);
  background-size: 100% 100%;
  width: 311px;
  height: 311px;
  margin-top: -325px;
  margin-left: -2px;
  position: absolute;
}

.mobile .pspopup .div {
  background-image: url(../../../static/img/rectangle-2921.png);
  background-size: 100% 100%;
  width: 311px;
  height: 311px;
  margin-top: -325px;
  margin-left: -2px;
  position: absolute;
}