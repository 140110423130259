.nft-m .mobile-nft {
  background-position: initial;
  background-size: cover;
  border: 1px;
  flex-direction: column;
  align-items: center;
  gap: 28px;
  min-width: 358px;
  padding: 36px 8px 100px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.nft-m .frame-3420 {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 75px;
  padding: 0 0 0 2px;
  display: flex;
  position: relative;
}

.nft-m .nav-1 {
  align-items: center;
  align-self: center;
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  height: 46px;
  min-width: 340px;
  position: relative;
}

.nft-m .logo-1 {
  width: 123px;
  height: 32px;
  margin-left: 20px;
}


.nft-m .my-custom-wrapper {
  border-radius: 12px;
  display: inline-block;
  overflow: hidden;
  margin-right: 110px;
}

.nft-m .my-custom-class {
  cursor: pointer;
  font-family: var(--font-family-bebas_neue_pro-regular) !important;
  /* Replace this with the actual font family value */
  font-weight: bold;
  position: relative;
}

.nft-m .img-wrapper {
  --webkit-backdrop-filter: blur(10px) brightness(100%);
  -webkit-backdrop-filter: blur(10px) brightness();
  backdrop-filter: blur(10px) brightness();
  border-radius: 10px;
  width: 44px;
  height: 44px;
  position: absolute;
  right: 65px;
}

.nft-m .group-2 {
  z-index: 9;
  width: 44px;
  height: 44px;
  cursor: pointer;
}

.nft-m .overlap-b-group.open .burger-menu-container {
  z-index: 9;
  background-color: #d9d9d9;
  border-radius: 1px 1px 10px 10px;
  width: 250px;
  height: 410px;
  position: absolute;
  top: -5px;
  right: 60px;
}

.nft-m .overlap-b-group.open .burger-2 {
  -webkit-backdrop-filter: blur(10px) brightness();
  backdrop-filter: blur(10px) brightness();
  z-index: 9;
  border-radius: 10px;
  width: 33px;
  height: 33px;
  position: absolute;
  top: 16px;
  left: 233px;
}

.nft-m .frame-3416 {
  align-self: stretch;
  display: flex;
  height: 323px;
  justify-content: center;
  min-width: 340px;
  position: relative;
}

.nft-m .heromenu-box-1 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 55px;
  height: 300px;
  margin-left: -4px;
  position: relative;
  width: 312px;
}

.nft-m .hero-box-1 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 58px;
  height: 205px;
  padding: 20px 0px;
  position: relative;
}

.nft-m .frame-3413-1 {
  align-self: stretch;
  height: 185px;
  margin-bottom: -20.00px;
  min-width: 312px;
  position: relative;
}

.nft-m .overlap-group-15 {
  height: 183px;
  left: -12px;
  position: absolute;
  top: -1px;
  width: 338px;
}

.nft-m .title-1 {
  color: var(--ceramic);
  font-family: var(--font-family-bebas_neue_pro-regular);
  font-size: 69px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 61.4px;
  position: absolute;
  text-align: center;
  top: -15px;
  width: 338px;
  margin-bottom: 15px;
}

.nft-m .WAXANF-ts {
  height: 1052px;
  margin-left: -78px;
  margin-right: -78px;
  position: relative;
  width: 354px;
}

.nft-m .overlap {
  height: 513px;
  left: -1px;
  position: relative;
  top: -50px;
  width: 357px;
}

.nft-m .sub {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 56px;
  height: 260px;
  left: 0;
  position: absolute;
  top: 0;
  width: 357px;
}

.nft-m .sub-box {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 13px;
  padding: 20px 0px 0px;
  position: relative;
  width: 357px;
}

.nft-m .JOIN-a-GLOBAL {
  color: #fcfcfc;
  font-family: "Bebas Neue Pro-Regular", Helvetica;
  font-size: 28px;
  font-weight: 400;
  height: 38px;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: 356px;
}

.nft-m .line {
  width: 173.83px;
  height: 1.44px;
  position: relative;
  bottom: 150px;
}

.line-1 {
  width: 173.83px;
  height: 1.44px;
  position: relative;
  bottom: 5px;
}

.line-2 {
  width: 173.83px;
  height: 1.44px;
  position: relative;
  bottom: 10px;
}

.line-3 {
  width: 173.83px;
  height: 1.44px;
  position: relative;
  bottom: 160px;
}

.line-4 {
  width: 173.83px;
  height: 1.44px;
  position: relative;
  bottom: 160px;
}


.nft-m .learn-more-about {
  color: #f9f9f9;
  font-family: "Bebas Neue Pro-Regular", Helvetica;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 1.12px;
  line-height: normal;
  position: relative;
  text-align: center;
  width: 333px;
}

.nft-m .frame-wrapper {
  height: 347px;
  left: 1px;
  position: absolute;
  top: 166px;
  width: 355px;
}

.nft-m .frame-2 {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
  top: -20px;
  left: 29px;
  flex-wrap: nowrap;
}

.nft-m .div-wrapper {
  background-size: 100% 100%;
  height: 315.65px;
  position: relative;
  width: 269.09px;
}

.nft-m .text-wrapper-2a {
  color: #885bea;
  letter-spacing: 0;
  text-align: center;
  width: 204px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-style: italic;
  font-weight: 600;
  line-height: normal;
  position: absolute;
  top: 214px;
  left: 55px;
}

.nft-m .element-unique-avatars-wrapper {
  background-size: 100% 100%;
  width: 296.21px;
  height: 347px;
  position: relative;
  left: -15px;
}

.nft-m .element-unique-avatars {
  color: #0000;
  letter-spacing: 0;
  text-align: center;
  width: 213px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 565px;
  left: 50px;
}

.nft-m .text-wrapper-3 {
  color: #885bea;
  font-style: italic;
  font-weight: 600;
}

.nft-m .text-wrapper-4 {
  color: #3f89d5;
  font-style: italic;
  font-weight: 700;
}

.nft-m .text-wrapper-5 {
  color: #3f89d5;
  font-style: italic;
  font-weight: 600;
}

.nft-m .frame-3 {
  background-size: 100% 100%;
  height: 315.65px;
  position: relative;
  width: 269.09px;
}

.nft-m .overlap-b-group.open .frame-5 {
  cursor: pointer;
  width: 21px;
  height: 24px;
  position: relative;
  top: -95px;
  left: -113px;
}

.nft-m .each-astronaut {
  color: #885bea;
  letter-spacing: 0;
  text-align: center;
  width: 204px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-style: italic;
  font-weight: 600;
  line-height: normal;
  position: absolute;
  top: 940px;
  left: 55px;
}

.nft-m .chillville-small {
  width: 50px;
  height: 10px;
  position: absolute;
  top: 1000px;
  left: 50px;
}

.nft-m .scottybeam-small {
  height: 15px;
  left: 104px;
  position: absolute;
  top: 1000px;
  width: 63px;
}

.nft-m .pawworld-small {
  height: 11px;
  left: 178px;
  position: absolute;
  top: 1000px;
  width: 51px;
}

.nft-m .NF-tcards {
  width: 360px;
  height: 616px;
  margin-left: -81px;
  margin-right: -81px;
  position: relative;
  margin-top: 90px;
}

.nft-m .sub-box-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 56px;
  left: -3px;
  position: absolute;
  top: -49px;
  width: 359px;
}

.nft-m .sub-box-2 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 13px;
  margin-left: -0.5px;
  margin-right: -0.5px;
  padding: 20px 0px 0px;
  position: relative;
  width: 360px;
}

.nft-m .JOIN-a-GLOBAL-2 {
  color: #fcfcfc;
  font-family: "Bebas Neue Pro-Regular", Helvetica;
  font-size: 28px;
  font-weight: 400;
  height: 42px;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: 349px;
}

.nft-m .learn-more-about-2 {
  color: #f9f9f9;
  font-family: "Bebas Neue Pro-Regular", Helvetica;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 1.12px;
  line-height: normal;
  position: relative;
  text-align: center;
  width: 322px;
}

.nft-m .cards {
  height: 512px;
  left: 2px;
  position: absolute;
  top: 104px;
  width: 358px;
}

.nft-m .overlap-2 {
  height: 462px;
  left: -5px;
  position: absolute;
  top: -1px;
  width: 366px;
}

.nft-m .overlap-group-2 {
  height: 462px;
  left: 0;
  position: absolute;
  top: 0;
  width: 366px;
}

.nft-m .the-legend-astronaut {
  color: #885bea;
  letter-spacing: 0;
  text-align: center;
  width: 184px;
  font-family: Titillium Web, Helvetica;
  font-size: 9px;
  font-style: italic;
  font-weight: 600;
  line-height: normal;
  position: absolute;
  top: 350px;
  left: 92px;
}

.nft-m .img {
  width: 277px;
  height: 320px;
  position: absolute;
  top: 15px;
  left: 46px;
  background: black;
  border-radius: 10px;
}

.nft-m .vector-2 {
  height: 75px;
  left: 46px;
  position: absolute;
  top: 387px;
  width: 1px;
}

.nft-m .vector-3 {
  height: 47px;
  left: 22px;
  position: absolute;
  top: 340px;
  width: 47px;
}

.nft-m .vector-4 {
  height: 47px;
  left: 299px;
  position: absolute;
  top: 340px;
  width: 47px;
}

.nft-m .rectangle {
  width: 366px;
  height: 315px;
  position: absolute;
  top: 15px;
  left: -60px;
}

.nft-m .arrow {
  height: 35px;
  left: 29px;
  position: absolute;
  top: 346px;
  width: 35px;
  cursor: pointer;
}

.nft-m .icon-arrow-circle {
  height: 29px;
  left: 308px;
  position: absolute;
  top: 349px;
  width: 29px;
  cursor: pointer;
}

.nft-m .vector-5 {
  height: 75px;
  left: 323px;
  position: absolute;
  top: 387px;
  width: 1px;
}

.nft-m .GS-button {
  all: unset;
  background-color: #ff00ff;
  border-radius: 6px;
  box-sizing: border-box;
  height: 34px;
  left: 81px;
  position: absolute;
  top: 480px;
  width: 196px;
  cursor: pointer;
}

.nft-m .get-started {
  color: #ffffff;
  font-family: "Opinion Pro Extended-Light", Helvetica;
  font-size: 15px;
  font-weight: 300;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 9px;
  width: 196px;
}

.nft-m .CS-button {
  border: 1px solid;
  border-color: #ff00ff;
  border-radius: 6px;
  height: 34px;
  left: 81px;
  position: absolute;
  top: 521px;
  width: 196px;
  cursor: pointer;
}

.nft-m .mysterybox {
  height: 269px;
  left: 0;
  position: absolute;
  top: -10;
  width: 302px;
}

.nft-m .get-started-2 {
  color: #ff00ff;
  font-family: "Opinion Pro Extended-Light", Helvetica;
  font-size: 15px;
  font-weight: 300;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 9px;
  width: 196px;
}

.nft-m .NF-tcards-2 {
  height: 417px;
  margin-left: -79.5px;
  margin-right: -79.5px;
  position: relative;
  width: 357px;
}

.nft-m .overlap-3 {
  height: 345px;
  left: 0;
  position: absolute;
  top: 90px;
  width: 357px;
}

.nft-m .every-successful {
  color: #f9f9f9;
  font-family: "Bebas Neue Pro-Regular", Helvetica;
  font-size: 15px;
  font-weight: 400;
  left: 11px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 309px;
  width: 334px;
}

.nft-m .frame-4 {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  gap: 11px 11px;
  height: 327px;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 357px;
}

.nft-m .overlap-group-wrapper {
  height: 146px;
  position: relative;
  width: 139px;
}

.nft-m .overlap-group-3 {
  height: 146px;
  left: 5px;
  position: relative;
  width: 130px;
}

.nft-m .p-block {
  border-radius: 24px;
  height: 130px;
  left: 0;
  position: absolute;
  top: 11px;
  width: 130px;
}

.nft-m .vector-6 {
  height: 33px;
  left: 36px;
  position: absolute;
  top: 113px;
  width: 57px;
}

.nft-m .JOIN-a-GLOBAL-3 {
  color: #fcfcfc;
  font-family: "Bebas Neue Pro-Regular", Helvetica;
  font-size: 18px;
  font-weight: 400;
  height: 19px;
  left: 5px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 78px;
  white-space: nowrap;
  width: 119px;
}

.nft-m .JOIN-a-GLOBAL-4 {
  color: #f0f;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 119px;
  height: 19px;
  font-family: Bebas Kai-Regular, Helvetica;
  font-size: 23px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 56px;
  left: 5px;
}

.nft-m .JOIN-a-GLOBAL-5 {
  color: #ff00ff;
  font-family: "Bebas Kai-Regular", Helvetica;
  font-size: 7px;
  font-weight: 400;
  height: 19px;
  left: 5px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 118px;
  width: 119px;
}

.nft-m .text-wrapper-6 {
  color: #ff00ff;
  font-family: "Bebas Kai-Regular", Helvetica;
  font-size: 7px;
  font-weight: 400;
  letter-spacing: 0;
}

.nft-m .text-wrapper-7 {
  font-size: 1px;
}

.nft-m .text-wrapper-8 {
  font-size: 21px;
}

.nft-m .vector-7 {
  height: 39px;
  left: 49px;
  position: absolute;
  top: 0;
  width: 30px;
}

.nft-m .ROYALTY {
  height: 147px;
  position: relative;
  width: 139px;
}

.nft-m .overlap-4 {
  height: 147px;
  left: 5px;
  position: relative;
  width: 130px;
}

.nft-m .p-block-2 {
  border-radius: 24px;
  height: 130px;
  left: 0;
  position: absolute;
  top: 12px;
  width: 130px;
}

.nft-m .vector-8 {
  height: 34px;
  left: 36px;
  position: absolute;
  top: 113px;
  width: 57px;
}

.nft-m .JOIN-a-GLOBAL-6 {
  color: #fcfcfc;
  font-family: "Bebas Neue Pro-Regular", Helvetica;
  font-size: 18px;
  font-weight: 400;
  height: 19px;
  left: 5px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 79px;
  white-space: nowrap;
  width: 120px;
}

.nft-m .JOIN-a-GLOBAL-7 {
  color: #ff00ff;
  font-family: "Bebas Kai-Regular", Helvetica;
  font-size: 23px;
  font-weight: 400;
  height: 19px;
  left: 5px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 57px;
  white-space: nowrap;
  width: 120px;
}

.nft-m .JOIN-a-GLOBAL-8 {
  color: #ff00ff;
  font-family: "Bebas Kai-Regular", Helvetica;
  font-size: 21px;
  font-weight: 400;
  height: 19px;
  left: 5px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 119px;
  white-space: nowrap;
  width: 120px;
}

.nft-m .vector-9 {
  height: 39px;
  left: 39px;
  position: absolute;
  top: 0;
  width: 51px;
}

.nft-m .overlap-5 {
  height: 146px;
  left: -8px;
  position: relative;
  width: 155px;
}

.nft-m .p-block-3 {
  border-radius: 24px;
  height: 130px;
  left: 13px;
  position: absolute;
  top: 11px;
  width: 130px;
}

.nft-m .vector-10 {
  height: 33px;
  left: 49px;
  position: absolute;
  top: 113px;
  width: 57px;
}

.nft-m .JOIN-a-GLOBAL-9 {
  color: #fcfcfc;
  font-family: "Bebas Neue Pro-Regular", Helvetica;
  font-size: 18px;
  font-weight: 400;
  height: 19px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 78px;
  white-space: nowrap;
  width: 155px;
}

.nft-m .JOIN-a-GLOBAL-10 {
  color: #ff00ff;
  font-family: "Bebas Kai-Regular", Helvetica;
  font-size: 23px;
  font-weight: 400;
  height: 19px;
  left: 18px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 56px;
  white-space: nowrap;
  width: 119px;
}

.nft-m .JOIN-a-GLOBAL-11 {
  height: 15px;
  left: 66px;
  position: absolute;
  top: 121px;
  width: 24px;
}

.nft-m .vector-11 {
  height: 39px;
  left: 60px;
  position: absolute;
  top: 0;
  width: 36px;
}

.nft-m .sub-2 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 56px;
  left: -2px;
  position: absolute;
  top: -50px;
  width: 359px;
}

.nft-m .sub-box-3 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 13px;
  padding: 20px 0px 0px;
  position: relative;
  width: 355px;
}

.nft-m .JOIN-a-GLOBAL-12 {
  color: #fcfcfc;
  letter-spacing: 0;
  text-align: center;
  align-self: stretch;
  height: 42px;
  margin-top: -1px;
  font-family: Bebas Neue Pro-Regular, Helvetica;
  font-size: 28px;
  font-weight: 400;
  line-height: normal;
  position: relative;
  bottom: 150px;
}

.nft-m .learn-more-about-3 {
  color: #f9f9f9;
  letter-spacing: 1.12px;
  text-align: center;
  align-self: stretch;
  font-family: Bebas Neue Pro-Regular, Helvetica;
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
  position: relative;
  bottom: 160px;
}

.nft-m .MYSTERY-BOX {
  height: 421px;
  margin-left: -78.5px;
  margin-right: -78.5px;
  position: relative;
  width: 355px;
}

.nft-m .sub-3 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 56px;
  left: -1px;
  position: absolute;
  top: -50px;
  width: 356px;
}

.nft-m .MBOX {
  height: 345px;
  left: 27px;
  position: absolute;
  top: 123px;
  width: 301px;
}

.nft-m .overlap-group-4 {
  height: 354px;
  position: relative;
  width: 302px;
}

.nft-m .vector-12 {
  height: 269px;
  left: 0;
  position: absolute;
  top: 0;
  width: 302px;
}

.nft-m .vector-13 {
  height: 29px;
  left: 253px;
  position: absolute;
  top: 20px;
  width: 30px;
  cursor: pointer;
}

.nft-m .vector-14 {
  height: 65px;
  left: 0;
  position: absolute;
  top: 289px;
  width: 302px;
}

.nft-m .JOIN-a-GLOBAL-13 {
  color: #fcfcfc;
  font-family: "Bebas Neue Pro-Regular", Helvetica;
  font-size: 18px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 327px;
  width: 150px;
}

.nft-m .JOIN-a-GLOBAL-14 {
  color: #fcfcfc;
  font-family: "Bebas Neue Pro-Regular", Helvetica;
  font-size: 18px;
  font-weight: 400;
  left: 151px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 327px;
  width: 150px;
}

.nft-m .JOIN-a-GLOBAL-15 {
  color: #ff00ff;
  font-family: "Bebas Neue Pro-SemiExpandedBold", Helvetica;
  font-size: 23px;
  font-weight: 700;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 301px;
  white-space: nowrap;
  width: 150px;
}

.nft-m .JOIN-a-GLOBAL-16 {
  color: #ff00ff;
  font-family: "Bebas Neue Pro-SemiExpandedBold", Helvetica;
  font-size: 23px;
  font-weight: 700;
  left: 151px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 301px;
  white-space: nowrap;
  width: 150px;
}

.nft-m .MI-nt {
  background-color: #ff00ff;
  border-radius: 10px;
  height: 39px;
  left: 38px;
  position: absolute;
  top: 260px;
  width: 226px;
}

.nft-m .get-started-3 {
  color: #ffffff;
  font-family: "Opinion Pro Extended-Light", Helvetica;
  font-size: 18px;
  font-weight: 300;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 11px;
  width: 226px;
}

.nft-m .fees-so-low-it-will-1 {
  color: var(--ceramic);
  font-family: var(--font-family-bebas_neue_pro-light);
  letter-spacing: 0;
  text-align: center;
  margin-bottom: 5px;
  font-size: 35px;
  font-weight: 300;
  line-height: 31.5px;
  position: absolute;
  top: 90px;
  left: 28px;
}

.nft-m .vector-1-1 {
  width: 182px;
  height: 2px;
  position: absolute;
  top: 270px;
  left: 50%;
  transform: translateX(-50%);
}

.nft-m .swap-box-1 {
  height: 547px;
  margin-left: -9.00px;
  margin-right: -9.00px;
  min-width: 358px;
  position: relative;
}

.nft-m .frame-container-1 {
  align-items: center;
  border: 1px solid;
  border-color: var(--scooter);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  left: 9px;
  min-height: 547px;
  padding: 22px 0;
  position: relative;
  width: 338px;
}

.nft-m .frame-1-1 {
  align-items: center;
  display: flex;
  gap: 14px;
  margin-right: 2.0px;
  padding: 0px 0px 0px 27px;
  position: relative;
  width: 338px;
}

.nft-m .place-1 {
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: 54px;
}

.nft-m .bnb-3 {
  height: 45px;
  min-width: 239px;
  position: relative;
  margin-right: 5%;
}

.nft-m .overlap-group-17 {
  align-items: flex-start;
  border: 1px solid;
  border-color: var(--cerulean);
  border-radius: 7px;
  display: flex;
  gap: 6px;
  height: 45px;
  min-width: 237px;
  padding: 5.8px 10.6px;
  cursor: pointer;
}

.nft-m .path-5 {
  height: 31px;
  width: 32px;
}

.nft-m .overlap-group1-7 {
  align-self: center;
  height: 21px;
  margin-bottom: 0%;
  position: relative;
  width: 166px;
}

.nft-m .path-535-3 {
  height: 6px;
  left: 154px;
  position: absolute;
  top: 8px;
  width: 12px;
}

.nft-m .bnb-chain-3 {
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  width: 154px;
}

.nft-m .frame-3409 {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-right: 2.0px;
  margin-top: 19px;
  position: relative;
  width: 326px;
}

.nft-m .tb-1,
.nft-m .bb-1 {
  height: 113px;
  margin-right: -4.00px;
  min-width: 330px;
  position: relative;
}

.nft-m .flex-row-2 {
  align-items: center;
  background-color: var(--black-rock);
  border-radius: 12px;
  display: flex;
  gap: 11px;
  height: 113px;
  min-width: 326px;
  padding: 2px 17px;
}

.nft-m .flex-col-2 {
  align-items: flex-start;
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  gap: 18px;
  min-height: 94px;
  width: 182px;
}

.nft-m .send-1 {
  letter-spacing: 0;
  line-height: normal;
  margin-left: 1.38px;
  min-height: 19px;
  width: 102px;
}

.nft-m .text {
  letter-spacing: 0;
  line-height: normal;
  min-height: 57px;
  width: 182px;
}

.nft-m .flex-col-3 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 33px;
  margin-top: 6.37px;
  min-height: 83px;
  width: 94px;
}

.nft-m .overlap-group-container {
  align-items: flex-start;
  display: flex;
  gap: 5px;
  margin-left: 0.04px;
  min-width: 94px;
}

.nft-m .overlap-group1-8 {
  align-items: flex-start;
  background-size: 100% 100%;
  display: flex;
  height: 19px;
  justify-content: flex-end;
  min-width: 45px;
  padding: 2.1px 11.7px;
}

.nft-m .name-1 {
  letter-spacing: 0;
  line-height: normal;
  min-height: 14px;
  text-align: center;
  width: 21px;
}

.nft-m .overlap-group-18 {
  align-items: flex-start;
  align-self: flex-end;
  background-size: 100% 100%;
  display: flex;
  height: 19px;
  justify-content: flex-end;
  min-width: 45px;
  padding: 1.9px 9.9px;
}

.nft-m .half-1 {
  letter-spacing: 0;
  line-height: normal;
  min-height: 14px;
  text-align: center;
  width: 23px;
}

.nft-m .frame-3404 {
  align-items: center;
  display: flex;
  min-width: 94px;
}

.nft-m .price-1 {
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: 2.95px;
  margin-left: 6px;
  min-height: 21px;
  width: 43px;
}

.nft-m .path-530-1 {
  height: 7px;
  margin-left: 1px;
  margin-top: 3.03px;
  width: 12px;
}

.nft-m .frame-3407 {
  height: 34px;
  margin-right: 2.0px;
  margin-top: 19px;
  width: 35px;
}

.nft-m .frame-2-1 {
  align-items: center;
  display: flex;
  gap: 14px;
  height: 45px;
  margin-right: 2.0px;
  margin-top: 19px;
  padding: 0px 0px 0px 27px;
  position: relative;
  width: 338px;
}

.nft-m .to-1 {
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: 55px;
}

.nft-m .usdt-6 {
  height: 45px;
  min-width: 238px;
  position: relative;
  margin-right: 5%;
}

.nft-m .overlap-group-19 {
  align-items: flex-start;
  border: 1px solid;
  border-color: var(--cerulean);
  border-radius: 7px;
  display: flex;
  gap: 5px;
  height: 45px;
  min-width: 236px;
  padding: 6.8px 11.6px;
}

.nft-m .path-538-3 {
  height: 31px;
  width: 31px;
}

.nft-m .usdt-7 {
  align-self: center;
  height: 21px;
  margin-top: 5%;
  position: relative;
  width: 166px;
}

.nft-m .frame-3410 {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-right: 2.0px;
  margin-top: 22px;
  position: relative;
  width: 326px;
}

.nft-m .overlap-group-20 {
  align-items: flex-start;
  background-color: var(--black-rock);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 18px;
  min-height: 113px;
  padding: 2px 17px;
  width: 326px;
}

.nft-m .send-2 {
  letter-spacing: 0;
  line-height: normal;
  margin-left: 1px;
  margin-top: 15px;
  min-height: 19px;
  width: 150px;
}

.nft-m .frame-6-3 {
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 4px;
  justify-content: center;
  margin-right: 1.0px;
  margin-top: 24px;
  position: relative;
  transition: all 0.2s ease;
  width: fit-content;
}

.nft-m .frame-6-3:hover {
  transform: scale(1.1);
}

.nft-m .ab-3 {
  height: 40.39px;
  margin-right: -2.00px;
  min-width: 289px;
  position: relative;
}

.nft-m .overlap-group-21 {
  align-items: flex-start;
  background-size: 100% 100%;
  display: flex;
  height: 40px;
  min-width: 287px;
  padding: 7.0px 0;
}

.nft-m .approve-3 {
  letter-spacing: 0;
  line-height: normal;
  min-height: 25px;
  text-align: center;
  width: 287px;
}

.nft-m .frame-3417 {
  display: flex;
  height: 551px;
  justify-content: center;
  min-width: 334px;
  position: relative;
}

.nft-m .sub-1 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 42px;
  height: 547px;
  margin-left: -2px;
  position: relative;
  width: 318px;
}

.nft-m .sub-box-1 {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  padding: 20px 0 0;
  display: flex;
  position: relative;
  bottom: -50px;
}

.nft-m .join-a-global-community-1 {
  align-self: stretch;
  color: var(--ceramic);
  font-family: var(--font-family-oswald);
  font-size: 19px;
  font-weight: 400;
  height: 27px;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
  text-align: center;
}

.nft-m .line-33-1 {
  height: 1.44px;
  min-width: 148px;
  position: relative;
}

.nft-m .learn-more-about-xio-1 {
  align-self: stretch;
  color: var(--hint-of-red);
  font-family: var(--font-family-titillium_web);
  font-size: var(--font-size-xl);
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
}

.nft-m .email-box {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  width: 310px;
  height: 76px;
  margin-left: 50px;
  display: flex;
  position: relative;
  bottom: -20px;
}

.nft-m .frame-3395-1 {
  align-items: center;
  background-color: var(--white);
  border: 1.2px solid;
  border-color: var(--kingfisher-daisy);
  border-radius: 8px;
  display: flex;
  height: 35px;
  margin-top: -4.50px;
  padding: 14.37px;
  position: relative;
  width: 309px;
}

.nft-m .eg-bethe1sttoknow-1 {
  background-color: transparent;
  border: 0;
  height: 22px;
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: -6.67px;
  margin-right: -3.73px;
  margin-top: -9.06px;
  min-width: 284px;
  opacity: 0.3;
  padding: 0;
  position: relative;
  text-align: center;
  white-space: nowrap;
}

.nft-m .eg-bethe1sttoknow-1::placeholder {
  color: #2a2a2a99;
}

.nft-m .social-box-1 {
  min-width: 293px;
  height: 284px;
  position: relative;
  top: 80px;
}

.nft-m .flex-col-4 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 28px;
  left: 4px;
  min-height: 279px;
  position: absolute;
  top: 2px;
  width: 128px;
}

.nft-m .flex-col-5 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 28px;
  left: 163px;
  min-height: 279px;
  position: absolute;
  top: 2px;
  width: 128px;
}

.nft-m .overlap-group3 {
  align-items: flex-end;
  background-size: 100% 100%;
  display: flex;
  height: 126px;
  justify-content: flex-end;
  margin-left: 0;
  min-width: 126px;
  padding: 7.5px 8.4px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.nft-m .overlap-group3:hover {
  transform: scale(1.1);
}

.nft-m .follow-xionfinance-for-2 {
  letter-spacing: 0;
  line-height: normal;
  min-height: 0px;
  text-align: center;
  width: 105px;
  font-size: 9px;
}

.nft-m .footer-1 {
  background-color: #0000;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 20.4px;
  min-width: 334px;
  height: 87px;
  display: flex;
  position: relative;
  top: 50px;
}

.nft-m .line-34-1 {
  height: 1px;
  margin-top: 21.6px;
  width: 337px;
}

.nft-m .frame-3399-1 {
  align-items: flex-start;
  display: flex;
  gap: 140px;
  height: 23px;
  justify-content: center;
  margin-left: -2px;
  position: relative;
  width: 329px;
}

.nft-m .copyright-1 {
  font-family: "Bebas Neue Pro-Light", Helvetica;
  color: #f9f9f9;
  font-size: 13px;
  font-weight: 400;
  height: 16px;
  letter-spacing: 0;
  line-height: normal;
  margin-left: -4.50px;
  margin-top: -1.00px;
  position: relative;
  width: 105px;
}

.nft-m .tcs {
  color: #fff;
  font-family: var(--font-family-titillium_web);
  font-size: 12px;
  font-weight: 400;
  height: 16px;
  letter-spacing: 0;
  line-height: normal;
  margin-right: -4.50px;
  margin-top: -1.00px;
  position: relative;
  text-align: right;
  width: 93px;
}

.email-link {
  color: inherit;
  /* Use the color defined by the parent container */
  text-decoration: none;
  /* Remove underline by default */
  transition: color 0.3s ease;
  /* Smooth color transition */
}

.email-link:hover {
  color: #33a4d8;
  /* Change to the desired hover color */
}

.nft-m .b-2 {
  align-self: center;
  width: 360px;
  position: relative;
  top: 55px;
  left: 70px;
}

.nft-m .XGT-MARKET-4 {
  color: #35cbfd;
  letter-spacing: 0;
  text-align: center;
  width: 210px;
  font-family: "Bebas Neue Pro-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 23.1px;
  position: relative;
  top: 30px;
  left: 8px;
}

.nft-m .text-wrapper-62 {
  color: #35cbfd;
  font-family: "Bebas Neue Pro-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 23.1px;
}

.nft-m .text-wrapper-63 {
  font-family: "Bebas Neue Pro-Light", Helvetica;
  font-weight: 300;
}

.nft-m .terms-link,
.nft-m .privacy-link {
  font-family: "Bebas Neue Pro-Light", Helvetica;
  color: #f9f9f9;
  cursor: pointer;
  min-width: 125px;
  margin-left: -30px;
  font-size: 12px;
  transition: color .3s;
  margin-top: 3px;
}

.nft-m .terms-link:hover,
.nft-m .privacy-link:hover {
  color: #35cbfd;
  /* Change color on hover */
}

.nft-m .fdpopup .div {
  background-image: url(../../../static/img/rectangle-2921.png);
  background-size: 100% 100%;
  width: 311px;
  height: 311px;
  margin-top: -67px;
  margin-left: -2px;
  position: absolute;
}

.nft-m .nftfdpopup .close {
  cursor: pointer;
  z-index: 99;
  width: 14px;
  height: 13px;
  position: absolute;
  top: 238px;
  left: 313px;
}

.nft-m .nftsfpopup .div {
  background-image: url(../../../static/img/rectangle-2921.png);
  background-size: 100% 100%;
  width: 311px;
  height: 311px;
  margin-top: -67px;
  margin-left: -2px;
  position: absolute;
}

.nft-m .nftpspopup .div {
  background-image: url(../../../static/img/rectangle-2921.png);
  background-size: 100% 100%;
  width: 311px;
  height: 311px;
  margin-top: -67px;
  margin-left: -2px;
  position: absolute;
}

.nft-m .nftsfpopup .overlap {
  width: 125px;
  height: 19px;
  margin-left: -20px;
  position: relative;
}

.nft-m .nftsfpopup .overlap-group-wrapper {
  height: 10px;
  left: 0;
  position: absolute;
  top: 0;
  width: 60px;
}

.nft-m .nftsfpopup .overlap-wrapper {
  width: 60px;
  height: 10px;
  position: absolute;
  top: 48px;
  left: 103px;
}
.text-wrapper-16 {
  z-index: 10;
}

.div-example {
  z-index: 10;
}