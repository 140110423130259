.desktop-1 {
  background-position: initial;
  background-size: cover;
  border: 1px none;
  flex-direction: column;
  align-items: center;
  gap: 115px;
  min-width: 1440px;
  padding: 81px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.desktop-1 .overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 98;
}

.desktop-1 .open-widget-button,
.desktop-1 .close-widget-button {
  color: #fff;
  text-align: center;
  cursor: pointer;
  z-index: 101;
  background-color: #0000;
  border: 1px solid #f0f;
  border-radius: 10px;
  width: 169px;
  height: 44px;
  font-family: Oswald, Helvetica;
  font-size: 19px;
  font-weight: 400;
  line-height: 41px;
  transition: background-color 0.3s, transform 0.3s;
  position: absolute;
  top: -1393px;
  right: 245px;
}

.desktop-1 .open-widget-button:hover,
.desktop-1 .close-widget-button:hover {
  background-color: #f0f;
  transform: scale(1.05);
}

.widget-container {
  z-index: 99;
  width: 400px;
  position: absolute;
  top: -1508px;
  left: 429px;
}

.desktop-1 .frame-3414 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 150px;
  position: relative;
}

.desktop-1 .win-pop-up-button {
  border: 1px solid #f0f;
  border-radius: 10px;
  width: 193px;
  height: 59px;
  position: absolute;
  top: -121px;
  left: 717px;
}

.desktop-1 .get-started-4 {
  color: #f0f;
  letter-spacing: 0;
  text-align: center;
  width: 193px;
  height: 21px;
  font-family: Oswald, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 15px;
  left: -1px;
}

.desktop-1 .alphalaunchbanner {
  margin-top: -7px;
  margin-bottom: -126px;
  position: relative;
}

.desktop-1 .alphaimg {
  position: relative;
}

.desktop-1 .nav {
  justify-content: space-between;
  align-self: center;
  align-items: center;
  min-width: 1278px;
  height: 46px;
  padding: 10px 50px 10px 40px;
  display: flex;
  position: absolute;
}

.desktop-1 .logo {
  height: 45.81px;
  margin-top: -0.2px;
  width: 246.25px;
}

.desktop-1 .my-custom-wrapper {
  border-radius: 12px;
  display: inline-block;
  overflow: hidden;
}

.desktop-1 .my-custom-class {
  cursor: pointer;
  font-weight: bold;
  position: relative;
  left: 1.2px;
  font-family: var(--font-family-bebas_neue_pro-regular) !important;
}

.desktop-1 .img-wrapper {
  --webkit-backdrop-filter: blur(10px) brightness(100%);
  -webkit-backdrop-filter: blur(10px) brightness();
  backdrop-filter: blur(10px) brightness();
  border-radius: 10px;
  width: 33px;
  height: 33px;
  position: relative;
  left: 600px;
}

.desktop-1 .swap-box-4 {
  border-radius: 25px;
  min-width: 787px;
  height: 547px;
  margin-top: 45px;
  margin-bottom: -150px;
  position: relative;
  overflow-y: auto;
}

.desktop-1 .group-2 {
  z-index: 9;
  width: 44px;
  height: 44px;
  position: relative;
  top: -6px;
  right: 10px;
  cursor: pointer;
}

.group-2:hover {
  animation: shake 5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}

@keyframes shake {
  10%,
  90% {
    transform: translateX(-5px);
    /* Move left */
  }

  20%,
  80% {
    transform: translateX(5px);
    /* Move right */
  }

  30%,
  50%,
  70% {
    transform: translateX(-3px);
    /* Move left */
  }

  40%,
  60% {
    transform: translateX(3px);
    /* Move right */
  }
}

.desktop-1 .frame-3411 {
  display: flex;
  height: 413px;
  min-width: 1278px;
  position: relative;
}

.desktop-1 .heromenu-box {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 55px;
  height: 413px;
  position: relative;
  width: 1278px;
}

.desktop-1 .menu-box {
  border: 1px solid;
  border-color: var(--cerulean);
  border-radius: 9px;
  justify-content: center;
  min-width: 614px;
  height: 43px;
  display: flex;
  position: relative;
  top: 130px;
}

.desktop-1 .hero-box {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 58px;
  height: 277px;
  padding: 20px 0px;
  position: relative;
}

.desktop-1 .frame-3413 {
  height: 296px;
  margin-bottom: -59px;
  min-width: 1278px;
  position: relative;
}

.flex-col {
  flex-direction: column;
  align-items: center;
  width: 1278px;
  min-height: 268px;
  display: flex;
  position: relative;
}

.title {
  color: var(--ceramic);
  font-family: var(--font-family-bebas_neue_pro-regular);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 1278px;
  min-height: 152px;
  margin-bottom: 15px;
  font-size: 170px;
  font-weight: 400;
  line-height: 151.3px;
}

.fees-so-low-it-will {
  color: var(--ceramic);
  font-family: var(--font-family-bebas_neue_pro-light);
  font-size: 76px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 68.4px;
  min-height: 64px;
  text-align: center;
  white-space: nowrap;
  width: 1278px;
  margin-bottom: 5px;
}

.vector-1 {
  height: 3px;
  margin-top: 30px;
  width: 384px;
}

.swap-box {
  height: 536px;
  min-width: 690px;
  position: relative;
}

.frame-container {
  border: 3px solid;
  border-color: var(--scooter);
  border-radius: 22px;
  flex-direction: column;
  align-items: flex-start;
  gap: 26px;
  width: 814px;
  min-height: 636px;
  padding: 47.4px 58.7px;
  display: flex;
  position: relative;
  top: 50px;
}

.frame-1 {
  align-items: center;
  display: flex;
  gap: 30px;
  margin-left: 0.35px;
  padding: 0px 0px 0px 20px;
  position: relative;
  width: 690px;
}

.place {
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: 54px;
}

.frame-3 {
  align-items: center;
  display: flex;
  gap: 10px;
  justify-content: center;
  position: relative;
  width: fit-content;
}

.tb {
  height: 129px;
  margin-right: -4px;
  min-width: 694.69px;
  position: relative;
}

.flex-col-1 {
  align-items: flex-end;
  background-color: var(--black-rock);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 29px;
  min-height: 129px;
  padding: 8px 21.5px;
  width: 691px;
}

.flex-row {
  align-items: flex-end;
  display: flex;
  height: 20px;
  margin-top: 7px;
  min-width: 646px;
}

.desktop-1 .send {
  letter-spacing: 0;
  align-self: flex-end;
  width: 232px;
  min-height: 6px;
  line-height: normal;
  margin-left: -4px;
}

.desktop-1 .overlap-group1 {
  justify-content: space-between;
  min-width: 350px;
  height: 19px;
  padding: 2.5px 11.2px;
  display: flex;
  margin-bottom: -85px;
}

.desktop-1 .usdvalue {
  letter-spacing: 0;
  width: 20px;
  min-height: 14px;
  margin-left: -244px;
  line-height: normal;
}

.desktop-1 .usdvaluerecieve {
  letter-spacing: 0;
  width: 233px;
  line-height: normal;
  margin-top: -25px;
  margin-left: 20px;
}

.overlap-group-1 {
  align-items: flex-start;
  display: flex;
  height: 19px;
  justify-content: flex-end;
  margin-left: 5px;
  margin-top: 0.22px;
  min-width: 43px;
  padding: 2.5px 11.2px;
}

.desktop-1 .walletbalance {
  letter-spacing: 0;
  text-align: center;
  min-height: 14px;
  line-height: normal;
  margin-right: -60px;
}

.flex-row-1 {
  align-self: center;
  align-items: center;
  gap: 320px;
  min-width: 644px;
  height: 57px;
  margin-right: 0.67px;
  display: flex;
}

.desktop-1 .text-1 {
  letter-spacing: 0;
  width: 232px;
  min-height: 57px;
  line-height: normal;
  margin-bottom: 50px;
  white-space: nowrap;
  border: none;
  background-color: transparent;
}

.desktop-1 .text-1::-webkit-inner-spin-button,
.desktop-1 .text-1::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.desktop-1 .text-1 {
  -moz-appearance: textfield;
  /* Firefox */
}

.desktop-1 .frame-3403 {
  cursor: pointer;
  justify-content: center;
  align-items: flex-end;
  min-width: 91px;
  margin-bottom: 130.06px;
  display: flex;
}

.path-529 {
  align-self: flex-start;
  height: 31px;
  margin-top: 0;
  width: 31px;
}

.token {
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: 2.94px;
  margin-left: 6px;
  min-height: 21px;
  width: 41px;
}

.path-530 {
  width: 12px;
  height: 6px;
  margin-top: 0;
  margin-bottom: 12px;
  margin-left: 20px;
}

.frame-4 {
  height: 34px;
  margin-left: 0.35px;
  width: 690px;
}

.frame-2 {
  align-items: center;
  display: flex;
  gap: 30px;
  height: 45px;
  margin-left: 0.85px;
  padding: 0px 0px 0px 21px;
  position: relative;
  width: 689px;
}

.to {
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: 55px;
}

.frame-5 {
  align-items: center;
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-left: 0.02px;
  position: relative;
  width: fit-content;
}

.bb {
  height: 106.6px;
  margin-right: -4px;
  min-width: 694.65px;
  position: relative;
}

.overlap-group-2 {
  align-items: flex-start;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  gap: 18px;
  min-height: 107px;
  padding: 1.5px 21.8px;
  width: 691px;
}

.receive-estimated {
  letter-spacing: 0;
  line-height: normal;
  margin-left: 1px;
  margin-top: 10px;
  min-height: 19px;
  width: 232px;
}

.desktop-1 .text-2 {
  letter-spacing: 0;
  width: 300px;
  min-height: 57px;
  line-height: normal;
  margin-top: -20px;
}

.frame-3412 {
  display: flex;
  height: 445.18px;
  min-width: 1278px;
  position: relative;
}

.desktop-1 .sub {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 56px;
  height: 446.18px;
  position: relative;
  width: 1278px;
}

.desktop-1 .ramp-widget {
  width: 781px;
  height: 541px;
  margin-top: 50px;
  margin-bottom: -150px;
}

@media (min-width: 768px) {
  .ramp-widget {
    width: 400px;
  }
}

@media (min-width: 1025px) {
  .ramp-widget {
    width: 500px;
  }
}

.desktop-1 .sub-box {
  flex-direction: column;
  align-items: center;
  gap: 13px;
  width: 1278px;
  padding: 20px 0 0;
  display: flex;
  position: relative;
  top: 75px;
}

.desktop-1 .join-a-global-community {
  align-self: stretch;
  color: var(--ceramic);
  font-family: var(--font-family-bebas_neue_pro-regular);
  font-size: 39px;
  font-weight: 400;
  height: 53px;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.desktop-1 .line-33 {
  height: 1.44px;
  min-width: 173.83px;
  position: relative;
}

.desktop-1 .learn-more-about-xio {
  align-self: stretch;
  color: var(--hint-of-red);
  font-family: var(--font-family-bebas_neue_pro-regular);
  font-size: var(--font-size-xxxxxl);
  font-weight: 400;
  letter-spacing: 1.72px;
  line-height: normal;
  position: relative;
  text-align: center;
}

.desktop-1 .frame-262 {
  justify-content: center;
  align-items: center;
  gap: 15px;
  width: 1278px;
  display: flex;
  position: relative;
  margin-top: 75px;
}

.desktop-1 .frame-3395 {
  align-items: center;
  background-color: var(--white);
  border: 1.2px solid;
  border-color: var(--kingfisher-daisy);
  border-radius: 8px;
  display: flex;
  height: 50.07px;
  padding: 14.37px;
  position: relative;
  width: 359.15px;
}

.desktop-1 .eg-bethe1sttoknow {
  background-color: transparent;
  border: 0;
  height: 22.75px;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.9px;
  min-width: 330.42px;
  opacity: 0.3;
  padding: 0;
  position: relative;
  white-space: nowrap;
}

.eg-bethe1sttoknow::placeholder {
  color: #2a2a2a99;
}

.frame-3396 {
  align-items: center;
  background-color: var(--kingfisher-daisy);
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  gap: 7.18px;
  height: 49.61px;
  justify-content: center;
  padding: 15px 20px;
  position: relative;
  transition: all 0.2s ease;
  width: 220px;
}

.frame-3396:hover,
.social-box-item:hover,
.social-box-item-1:hover {
  transform: scale(1.1);
}

.submit {
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: -3.5px;
  margin-top: -5.89px;
  position: relative;
  text-align: center;
  width: fit-content;
}

.social-box {
  align-items: center;
  display: flex;
  gap: 51px;
  justify-content: center;
  position: relative;
  width: 1278px;
}

.social-box-item {
  cursor: pointer;
  height: 157.11px;
  min-width: 161.56px;
  position: relative;
  transition: all 0.2s ease;
}

.overlap-group {
  background-size: 100% 100%;
  height: 157px;
  position: relative;
  width: 158px;
}

.join-the-xion-community {
  left: 32px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 112px;
  width: 94px;
  font-size: 9px;
}

.for-updates-and-news {
  left: 37px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 123px;
  width: 84px;
  font-size: 9px;
}

.chat-in-real-time-with-the {
  left: 30px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 113px;
  width: 97px;
  font-size: 9px;
}

.xion-community {
  left: 47px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 124px;
  width: 63px;
  font-size: 9px;
}

.social-box-item-1 {
  cursor: pointer;
  height: 157.1px;
  min-width: 161.56px;
  position: relative;
  transition: all 0.2s ease;
}

.follow-xionfinance-for {
  left: 36px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 113px;
  width: 87px;
  font-size: 9px;
}

.desktop-1 .updates-and-news {
  left: 44px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 124px;
  width: 71px;
  font-size: 9px;
}

.desktop-1 .follow-xionfinance-for-1 {
  left: 32px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 113px;
  width: 94px;
  font-size: 9px;
}

.desktop-1 .footer {
  background-color: #0000;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 37.6px;
  min-width: 1194.56px;
  height: 80px;
  display: flex;
  position: relative;
  top: 45px;
}

.desktop-1 .line-34 {
  height: 1px;
  margin-top: -0.4px;
  width: 1194.56px;
}

.desktop-1 .frame-3399 {
  align-items: flex-start;
  display: flex;
  gap: 984px;
  height: 16px;
  justify-content: center;
  margin-left: -1px;
  position: relative;
  width: 1187px;
}

.desktop-1 .copyright {
  font-size: 14px;
  font-family: "Bebas Neue Pro-Regular", Helvetica;
  color: #f9f9f9;
  height: 16px;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: 107px;
}

.desktop-1 .about-us-blog-t-cs-privacy-policy {
  letter-spacing: 0;
  text-align: right;
  width: 96px;
  margin-top: -1px;
  font-size: 14px;
  line-height: normal;
  position: relative;
  right: 20px;
}

.desktop-1 .approve {
  letter-spacing: 0;
  text-align: center;
  width: 334px;
  min-height: 29px;
  line-height: normal;
  margin-top: 5px;
}

.desktop-1 .b-wrapper {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  gap: 55px;
  justify-content: space-around;
  position: relative;
  width: 1034px;
}

.desktop-1 .frame-34 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  gap: 55px;
  justify-content: space-around;
  position: relative;
  width: 1034px;
}

.desktop-1 .b-2 {
  align-self: stretch;
  position: relative;
  width: 1033px;
}

.desktop-1 .XGT-MARKET-4 {
  color: #35cbfd;
  letter-spacing: 0;
  text-align: center;
  width: 1025px;
  font-family: "Bebas Neue Pro-Regular", Helvetica;
  font-size: 26px;
  font-weight: 400;
  line-height: 23.1px;
  position: absolute;
  top: 60px;
  left: 8px;
}

.desktop-1 .text-wrapper-62 {
  color: #35cbfd;
  font-family: "Bebas Neue Pro-Regular", Helvetica;
  font-size: 26px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 23.1px;
}

.desktop-1 .text-wrapper-63 {
  font-family: "Bebas Neue Pro-Light", Helvetica;
  font-weight: 300;
}

.desktop-1 .terms-link,
.desktop-1 .privacy-link {
  color: #f9f9f9;
  cursor: pointer;
  min-width: 125px;
  margin-top: 3px;
  margin-left: -14px;
  font-family: "Bebas Neue Pro-Light", Helvetica;
  font-size: 12px;
  transition: color 0.3s;
}

.desktop-1 .terms-link:hover,
.desktop-1 .privacy-link:hover {
  color: #35cbfd;
  /* Change color on hover */
}
