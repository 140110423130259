.loading-screen-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.loading-screen {
  background-color: #000000; /* Replace with your desired background color */
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
}

.loading-screen img {
  max-width: 15%;
  max-height: 15%;
}