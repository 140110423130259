.terms-i .terms-and-conditions {
  background-position: initial;
  background-size: cover;
  border: 1px;
  flex-direction: column;
  align-items: center;
  gap: 80px;
  min-width: 768px;
  padding: 75px 45px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.terms-i .frame-3416-2 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 75px;
  position: relative;
}

.terms-i .nav-3 {
  justify-content: space-between;
  align-self: center;
  align-items: center;
  min-width: 688px;
  height: 46px;
  padding: 10px 105px;
  display: flex;
  position: relative;
}

.terms-i .logo-3 {
  width: 178px;
  height: 33px;
  margin-top: -1px;
  margin-left: -80px;
}

.terms-i .my-custom-wrapper {
  border-radius: 12px;
  display: inline-block;
  overflow: hidden;
}

.terms-i .my-custom-class {
  cursor: pointer;
  font-family: var(--font-family-bebas_neue_pro-regular) !important;
  /* Replace this with the actual font family value */
  font-weight: bold;
  position: relative;
}

.terms-i .img-wrapper {
  --webkit-backdrop-filter: blur(10px) brightness(100%);
  -webkit-backdrop-filter: blur(10px) brightness();
  backdrop-filter: blur(10px) brightness();
  border-radius: 10px;
  width: 44px;
  height: 44px;
  position: absolute;
  right: 35px;
  cursor: pointer;
}

.img-wrapper:hover {
  animation: shake 5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}

@keyframes shake {

  10%,
  90% {
    transform: translateX(-5px);
    /* Move left */
  }

  20%,
  80% {
    transform: translateX(5px);
    /* Move right */
  }

  30%,
  50%,
  70% {
    transform: translateX(-3px);
    /* Move left */
  }

  40%,
  60% {
    transform: translateX(3px);
    /* Move right */
  }
}

.terms-i .overlap-b-group.open .burger-menu-container {
  z-index: 9;
  background-color: #d9d9d9;
  border-radius: 1px 1px 10px 10px;
  width: 250px;
  height: 410px;
  position: absolute;
  top: -80px;
  right: -79px;
}

.terms-i .frame-3413-4 {
  min-width: 677px;
  height: 342px;
  display: flex;
  position: relative;
  top: 10px;
}

.terms-i .flex-row-4 {
  align-items: center;
  gap: 205px;
  min-width: 559px;
  height: 57px;
  display: flex;
}

.terms-i .terms-content {
  margin-top: 50px;
  margin-bottom: 50px;
  width: 568px;
}

.terms-i .terms-content h1 {
  font: var(--font-family-bebas_neue_pro-regular);
  font-size: 40px;
  color: white;
}

.terms-i .terms-content p {
  font: var(--font-family-bebas_neue_pro-regular);
  color: #fff;
  font-size: 16px;
  margin-top: 20px;
}

.terms-i .email-link {
  /* No specific styles found in the original CSS */
}

.terms-i .footer-3 {
  background-color: #0000;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 30.4px;
  min-width: 763px;
  height: 54px;
  display: flex;
  position: relative;
  top: 0px;
}

.terms-i .line-34-3 {
  height: 1px;
  margin-top: -0.5px;
  width: 690px;
}

.terms-i .frame-3399-3 {
  align-items: flex-start;
  display: flex;
  gap: 482px;
  height: 23px;
  justify-content: center;
  margin-left: 1px;
  position: relative;
  width: 685px;
}

.terms-i .copyright-3 {
  color: #fff;
  letter-spacing: 0;
  width: 107px;
  margin-top: -1px;
  font-family: "Bebas Neue Pro-Light", Helvetica;
  font-size: 14px;
  line-height: normal;
  position: relative;
}

.terms-i .about-us-blog-t-cs-privacy-policy-3 {
  letter-spacing: 0;
  color: #fff;
  text-align: right;
  width: 96px;
  font-size: 12px;
  margin-top: -1px;
  line-height: normal;
  position: relative;
  right: 3px;
}

.terms-i .terms-link,
.terms-i .privacy-link {
  font-family: "Bebas Neue Pro-Light", Helvetica;
  color: #f9f9f9;
  cursor: pointer;
  min-width: 125px;
  margin-left: -30px;
  font-size: 12px;
  transition: color .3s;
  margin-top: 3px;
}

.terms-i .terms-link:hover,
.terms-i .privacy-link:hover {
  color: #35cbfd;
  /* Change color on hover */
}

.terms-i .footer-section {
  width: 768px;
  height: 208px;
  margin-top: 25px;
}

.terms-i .footer-section .frame {
  justify-content: space-between;
  align-items: flex-start;
  width: 768px;
  display: flex;
  position: relative;
  top: 31px;
}

.terms-i .footer-section .line {
  width: 768px;
  height: 1px;
  margin-top: -0.5px;
}