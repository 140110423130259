@import "https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css";
@import "https://fonts.googleapis.com/css?family=Titillium+Web:400,700|Roboto:400|Oswald:400";
@import "https://px.animaapp.com/647b47800355d4480479b720.647b47823710fef232e24ad8.GVjCGqt.rcp.png";
@font-face {
  font-family: Bebas Neue Pro-Regular;
  font-style: normal;
  font-weight: 400;
  src: url("Dharma Type - Bebas Neue Pro Regular.69abc8e2.otf") format("opentype");
}

@font-face {
  font-family: Bebas Neue Pro-Light;
  font-style: normal;
  font-weight: 300;
  src: url("Dharma Type - Bebas Neue Pro Light.5e2dbef0.otf") format("opentype");
}

@font-face {
  font-family: Bebas Neue Pro-Bold;
  font-style: normal;
  font-weight: 700;
  src: url("Dharma Type - Bebas Neue Pro Bold.1f0536ae.otf") format("opentype");
}

@font-face {
  font-family: Bebas Neue Pro-Book;
  font-style: normal;
  font-weight: 400;
  src: url("Dharma Type - Bebas Neue Pro Book.7caefaf1.otf") format("opentype");
}

.screen a {
  text-decoration: none;
  display: contents;
}

input:focus {
  outline: none;
}

* {
  box-sizing: border-box;
}

:root {
  --alto: #d9d9d9;
  --black: #000;
  --black-rock: #0a0930;
  --ceramic: #fcfcfc;
  --cerulean: #33a4d8;
  --cerulean-2: #29a5dc;
  --desert-storm: #f7f8f9;
  --electric-violet: #6300ff;
  --havelock-blue: #3f89d5;
  --hint-of-red: #f9f9f9;
  --kingfisher-daisy: #41009e;
  --scooter: #35cbfd;
  --shark: #2a2a2a;
  --white: #fff;
  --font-size-l: 10.8px;
  --font-size-m: 9.2px;
  --font-size-xl: 12px;
  --font-size-xxl: 12.6px;
  --font-size-xxxl: 14px;
  --font-size-xxxxl: 15px;
  --font-size-xxxxxl: 23px;
  --font-family-bebas_neue_pro-bold: "Bebas Neue Pro-Bold", Helvetica;
  --font-family-bebas_neue_pro-book: "Bebas Neue Pro-Book", Helvetica;
  --font-family-bebas_neue_pro-light: "Bebas Neue Pro-Light", Helvetica;
  --font-family-bebas_neue_pro-regular: "Bebas Neue Pro-Regular", Helvetica;
  --font-family-oswald: "Oswald", Helvetica;
  --font-family-roboto: "Roboto", Helvetica;
  --font-family-titillium_web: "Titillium Web", Helvetica;
}

.titilliumweb-normal-white-9-2px {
  color: var(--white);
  font-family: var(--font-family-titillium_web);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.titilliumweb-normal-hint-of-red-12-6px {
  color: var(--hint-of-red);
  font-family: var(--font-family-titillium_web);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 400;
}

.titilliumweb-normal-havelock-blue-14px {
  color: var(--havelock-blue);
  font-family: var(--font-family-titillium_web);
  font-size: var(--font-size-xxxl);
  font-style: normal;
  font-weight: 400;
}

.titilliumweb-bold-desert-storm-37-6px {
  color: var(--desert-storm);
  font-family: var(--font-family-titillium_web);
  font-size: 37.6px;
  font-style: normal;
  font-weight: 700;
}

.bebasneuepro-book-normal-white-29px {
  color: var(--white);
  font-family: var(--font-family-bebas_neue_pro-book);
  font-size: 29px;
  font-style: normal;
  font-weight: 400;
}

.titilliumweb-normal-hint-of-red-7-3px {
  color: var(--hint-of-red);
  font-family: var(--font-family-titillium_web);
  font-size: 7.3px;
  font-style: normal;
  font-weight: 400;
}

.titilliumweb-bold-cerulean-9-5px {
  color: var(--cerulean-2);
  font-family: var(--font-family-titillium_web);
  font-size: 9.5px;
  font-style: normal;
  font-weight: 700;
}

.titilliumweb-normal-ceramic-9-5px {
  color: var(--ceramic);
  font-family: var(--font-family-titillium_web);
  font-size: 9.5px;
  font-style: normal;
  font-weight: 400;
}

.titilliumweb-bold-havelock-blue-14px {
  color: var(--havelock-blue);
  font-family: var(--font-family-titillium_web);
  font-size: var(--font-size-xxxl);
  font-style: normal;
  font-weight: 700;
}

.titilliumweb-bold-ceramic-18-8px {
  color: var(--ceramic);
  font-family: var(--font-family-titillium_web);
  font-size: 18.8px;
  font-style: normal;
  font-weight: 700;
}

.roboto-normal-shark-19-2px {
  color: var(--shark);
  font-family: var(--font-family-roboto);
  font-size: 19.2px;
  font-style: normal;
  font-weight: 400;
}

.titilliumweb-normal-white-19-2px {
  color: var(--white);
  font-family: var(--font-family-titillium_web);
  font-size: 19.2px;
  font-style: normal;
  font-weight: 400;
}

.bebasneuepro-bold-cerulean-29px {
  color: var(--cerulean-2);
  font-family: var(--font-family-bebas_neue_pro-bold);
  font-size: 29px;
  font-style: normal;
  font-weight: 700;
}

.bebasneuepro-bold-cerulean-19px {
  color: var(--cerulean-2);
  font-family: var(--font-family-bebas_neue_pro-bold);
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
}

.titilliumweb-normal-electric-violet-10-8px {
  color: var(--electric-violet);
  font-family: var(--font-family-titillium_web);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 400;
}

.titilliumweb-normal-hint-of-red-10-8px {
  color: var(--hint-of-red);
  font-family: var(--font-family-titillium_web);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 400;
}

.bebasneuepro-book-normal-white-19px {
  color: var(--white);
  font-family: var(--font-family-bebas_neue_pro-book);
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
}

.titilliumweb-normal-white-14px {
  font-family: var(--font-family-titillium_web);
  font-size: var(--font-size-xxxl);
  font-style: normal;
  font-weight: 400;
}

.titilliumweb-normal-hint-of-red-14px {
  color: var(--hint-of-red);
  font-family: var(--font-family-titillium_web);
  font-size: var(--font-size-xxxl);
  font-style: normal;
  font-weight: 400;
}

.oswald-normal-ceramic-25px {
  color: var(--ceramic);
  font-family: var(--font-family-oswald);
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
}

.titilliumweb-bold-hint-of-red-15px {
  color: var(--hint-of-red);
  font-family: var(--font-family-titillium_web);
  font-size: var(--font-size-xxxxl);
  font-style: normal;
  font-weight: 700;
}

.menu-box {
  border: 1px solid;
  border-color: var(--cerulean);
  border-radius: 9px;
  justify-content: center;
  min-width: 614px;
  height: 43px;
  display: flex;
  position: relative;
}

.frame-15 {
  justify-content: center;
  align-items: flex-start;
  gap: 6px;
  width: 606px;
  height: 40.44px;
  margin-top: 3px;
  margin-left: -3px;
  display: flex;
  position: relative;
}

.group-259 {
  min-width: 200px;
  height: 40.44px;
  position: relative;
}

.overlap-group-3 {
  width: 198px;
  height: 40px;
  position: relative;
}

.w-sb-line {
  background-color: var(--alto);
  border-radius: 7px;
  width: 195px;
  height: 35px;
  position: absolute;
  top: 0;
  left: 4px;
}

.swapbridge {
  letter-spacing: 0;
  text-align: center;
  cursor: pointer;
  width: 138px;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 30px;
}

.group-260 {
  min-width: 200px;
  height: 37px;
  display: flex;
  position: relative;
}

.buy {
  letter-spacing: 0;
  text-align: center;
  cursor: pointer;
  flex: 1;
  width: 41px;
  margin-left: 41.5%;
  margin-right: 38%;
  line-height: normal;
}

.group-261 {
  min-width: 200px;
  height: 38.12px;
  margin-right: -2px;
  position: relative;
}

.withdraw {
  letter-spacing: -.29px;
  text-align: center;
  cursor: pointer;
  width: 93px;
  line-height: normal;
  position: absolute;
  top: -1px;
  left: 53px;
  transform: rotate(-.12deg);
}

.menu-box.menu-box-1, .menu-box.menu-box-2 {
  margin-bottom: -2px;
}

.active {
  opacity: 1;
}

.inactive {
  opacity: .2;
}

.swap-box-4 {
  border-radius: 25px;
  min-width: 787px;
  height: 547px;
  margin-top: 75px;
  margin-bottom: 0;
  position: relative;
  overflow-y: auto;
}

.frame-container-4 {
  border-radius: 22px;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  padding: 1px;
  display: flex;
  position: relative;
}

.frame-container-4 .wert-internal-class {
  border: none;
  outline: none;
}

.overlap-b-group.open {
  display: contents;

  & .overlap-b-group-wrapper {
    width: 230px;
    height: 464px;
    position: relative;
    top: 24px;
    left: 1144px;
  }

  & .MENU {
    flex-flow: column wrap;
    place-content: space-around center;
    width: 100%;
    display: flex;
  }

  & .MENU .div {
    background-image: url("rectangle-2920.6a74dbc1.png");
    background-size: 100% 100%;
    width: 293.53px;
    height: 485.73px;
    position: relative;
    top: -5px;
  }

  & .MENU .xionpay {
    object-fit: cover;
    cursor: pointer;
    width: 113px;
    height: 24px;
    position: absolute;
    top: 141px;
    left: 148px;
  }

  & .MENU .overlap-group {
    width: 350px;
    height: 319px;
    position: absolute;
    top: 198px;
    left: -89px;
  }

  & .MENU .frame {
    width: 227px;
    height: 240px;
    position: absolute;
    top: 0;
    left: 123px;
  }

  & .MENU .text-wrapper {
    color: #fff;
    letter-spacing: 0;
    text-align: right;
    height: 34px;
    font-family: Titillium Web, Helvetica;
    font-size: 22.4px;
    font-weight: 600;
    line-height: normal;
    position: absolute;
    top: -1px;
    left: 106px;
  }

  & .MENU .vector {
    cursor: pointer;
    width: 150px;
    height: 26px;
    position: absolute;
    top: 89px;
    left: 198px;
  }

  & .MENU .group-42 {
    cursor: pointer;
    width: 116px;
    height: 31px;
    position: absolute;
    top: 46px;
    left: 107px;
  }

  & .MENU .imgmenu {
    cursor: pointer;
    width: 116px;
    height: 26px;
    position: absolute;
    top: 131px;
    left: 106px;
  }

  & .MENU .group-21 {
    cursor: pointer;
    width: 103px;
    height: 27px;
    position: absolute;
    top: 172px;
    left: 119px;
  }

  & .MENU .line {
    object-fit: cover;
    width: 99px;
    height: 1px;
    position: absolute;
    top: 24px;
    left: 0;
  }

  & .MENU .vector-2 {
    cursor: pointer;
    width: 157px;
    height: 26px;
    position: absolute;
    top: 219px;
    left: 67px;
  }

  & .MENU .spaceman {
    object-fit: cover;
    width: 195px;
    height: 239px;
    position: absolute;
    top: 80px;
    left: 0;
  }

  & .MENU .group-3 {
    width: 193px;
    height: 34px;
    position: absolute;
    top: 74px;
    left: 70px;
  }

  & .MENU .text-wrapper-2 {
    color: #6300ff;
    letter-spacing: 0;
    text-align: right;
    height: 34px;
    font-family: Titillium Web, Helvetica;
    font-size: 22.4px;
    font-weight: 600;
    line-height: normal;
    position: absolute;
    top: 0;
    left: 26px;
  }

  & .MENU .nftimage {
    color: #6300ff;
    z-index: 9;
    width: 21px;
    height: 24px;
    position: absolute;
    top: 6px;
    left: -3px;
  }

  & .burger {
    cursor: pointer;
    z-index: 9;
    width: 14px;
    height: 13px;
    position: absolute;
    top: -5px;
    left: 10px;
  }

  & .burger-menu-container {
    z-index: 9;
    position: absolute;
    top: 0;
    right: -70px;
  }

  & .overlap-b-group {
    background-color: #d9d9d9;
    width: 295px;
    height: 488px;
    position: relative;
    top: -24px;
  }

  & .burger-2 {
    backdrop-filter: blur(10px) brightness();
    z-index: 9;
    border-radius: 10px;
    width: 33px;
    height: 33px;
    position: absolute;
    top: 16px;
    left: 255px;
  }

  & .burger-outline {
    width: 30px;
    height: 30px;
    margin-top: -14px;
    margin-left: 2px;
  }

  & .text-wrapper-2 {
    color: #74639f;
    letter-spacing: -1.46px;
    text-align: right;
    cursor: pointer;
    height: 34px;
    font-family: Titillium Web, Helvetica;
    font-size: 22.4px;
    font-weight: 600;
    line-height: normal;
    position: absolute;
    top: 143px;
    left: 66px;
  }

  & .xionpay-2 {
    object-fit: cover;
    cursor: pointer;
    width: 113px;
    height: 24px;
    position: absolute;
    top: 197px;
    left: 32px;
  }

  & .frame-4 {
    width: 155px;
    height: 196px;
    position: absolute;
    top: 242px;
    left: 79px;
  }

  & .vector-5 {
    z-index: 9;
    cursor: pointer;
    width: 123px;
    height: 33px;
    position: absolute;
    top: 0;
    left: -50px;
    right: 0;
  }

  & .vector-6 {
    cursor: pointer;
    width: 189px;
    height: 33px;
    position: absolute;
    top: 50px;
    left: -49px;
  }

  & .vector-7 {
    cursor: pointer;
    width: 171px;
    height: 25px;
    position: absolute;
    top: 100px;
    left: -47px;
  }

  & .vector-8 {
    cursor: pointer;
    width: 153px;
    height: 22px;
    position: absolute;
    top: 203px;
    left: -48px;
  }

  & .bscscan-2 {
    object-fit: cover;
    cursor: pointer;
    width: 140px;
    height: 32px;
    position: absolute;
    top: 158px;
    left: -50px;
  }

  & .frame-5 {
    cursor: pointer;
    width: 26px;
    height: 29px;
    position: relative;
    top: 147px;
    left: 28px;
  }

  & .line-2 {
    object-fit: cover;
    width: 130px;
    height: 1px;
    position: relative;
    top: 4px;
    left: -150px;
  }
}

.overlap-b-group {
  display: none;
}

.fee-box-d-expanded {
  z-index: 9;
  background-color: #0000;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-top: 60px;
  margin-bottom: -130px;
  display: flex;
}

.fee-box-d-expanded .divbox {
  border: 3px solid #35cbfd;
  border-radius: 22px;
  width: 675px;
  height: 300px;
  position: relative;
}

.fee-box-d-expanded .path {
  cursor: pointer;
  width: 17px;
  height: 11px;
  position: absolute;
  top: 29px;
  left: 630px;
}

.fee-box-d-expanded .line {
  width: 623px;
  height: 2px;
  position: absolute;
  top: 60px;
  left: 25px;
}

.fee-box-d-expanded .maximize {
  color: #f9f9f9;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 21px;
  left: 26px;
}

.fee-box-d-expanded .overlap-group {
  width: 621px;
  height: 20px;
  position: absolute;
  top: 75px;
  left: 27px;
}

.fee-box-d-expanded .FROM {
  color: #f9f9f9;
  letter-spacing: 0;
  width: 311px;
  font-family: Titillium Web, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.fee-box-d-expanded .text-wrapper {
  color: #f9f9f9;
  letter-spacing: 0;
  text-align: right;
  width: 311px;
  font-family: Titillium Web, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 310px;
}

.fee-box-d-expanded .overlap {
  width: 621px;
  height: 20px;
  position: absolute;
  top: 97px;
  left: 27px;
}

.fee-box-d-expanded .overlap-2 {
  width: 621px;
  height: 20px;
  position: absolute;
  top: 142px;
  left: 27px;
}

.fee-box-d-expanded .overlap-3 {
  width: 621px;
  height: 20px;
  position: absolute;
  top: 166px;
  left: 27px;
}

.fee-box-d-expanded .overlap-4 {
  width: 621px;
  height: 20px;
  position: absolute;
  top: 190px;
  left: 27px;
}

.fee-box-d-expanded .overlap-6 {
  width: 621px;
  height: 20px;
  position: absolute;
  top: 119px;
  left: 27px;
}

.fee-box-d-expanded .overlap-7 {
  width: 621px;
  height: 20px;
  position: absolute;
  top: 214px;
  left: 27px;
}

.fee-box-d-expanded .overlap-8 {
  width: 621px;
  height: 20px;
  position: absolute;
  top: 450px;
  left: 27px;
}

.fee-box-d-expanded .info-popup {
  background-color: #000;
  background-size: 100% 100%;
  border: 3px solid #35cbfd;
  border-radius: 18px;
  width: 284px;
  height: 143px;
  margin-top: -19px;
  margin-left: 361px;
}

.fee-box-d-expanded .info-text {
  color: #f9f9f9;
  width: 238px;
  margin-left: 7px;
  padding-top: 10px;
  padding-left: 11px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 400;
}

.fee-box-d-expanded .info-close {
  margin-left: 5px;
}

.fee-box-d-expanded .info-content {
  width: 250px;
}

.fee-box-d-expanded .learn-more {
  color: #35cbfd;
  cursor: pointer;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 400;
}

.fee-box-d-expanded .information {
  color: #f9f9f9;
  width: 374px;
  height: 20px;
  margin-top: -200px;
  margin-left: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 400;
}

.fee-box-d-minimised {
  z-index: 9;
  background-color: #0000;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-top: 60px;
  margin-bottom: -320px;
  display: flex;
}

.fee-box-d-minimised .overlap-group-wrapper {
  width: 675px;
  height: 245px;
}

.fee-box-d-minimised .overlap-group {
  border: 3px solid #35cbfd;
  border-radius: 18px;
  width: 675px;
  height: 63px;
  position: relative;
}

.fee-box-d-minimised .path {
  cursor: pointer;
  width: 17px;
  height: 11px;
  position: absolute;
  top: 26px;
  left: 629px;
}

.fee-box-d-minimised .minimize {
  color: #f9f9f9;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 18px;
  left: 25px;
}

.footer-section {
  width: 1194px;
  height: 208px;
  margin-top: 150px;
}

.footer-section .frame {
  justify-content: space-between;
  align-items: flex-start;
  width: 1194px;
  display: flex;
  position: relative;
  top: 31px;
}

.footer-section .group {
  width: 210.75px;
  height: 150.35px;
  position: relative;
}

.footer-section .LOGO-instance {
  width: 156px !important;
  height: 29px !important;
  left: 1px !important;
}

.footer-section .div-12 {
  width: 211px;
  height: 27px;
  position: absolute;
  top: 123px;
  left: 0;
}

.footer-section .text-wrapper-29 {
  color: #fff;
  letter-spacing: 0;
  width: 67px;
  font-family: Bebas Neue Pro-Bold, Helvetica;
  font-size: 14px;
  font-weight: 700;
  line-height: 12.5px;
  position: absolute;
  top: 7px;
  left: 32px;
}

.footer-section .overlap-group-wrapper-65 {
  cursor: pointer;
  width: 112px;
  height: 27px;
  position: absolute;
  top: 0;
  left: 99px;
}

.footer-section .overlap-group-65 {
  width: 110px;
  height: 27px;
  position: relative;
}

.footer-section .rectangle {
  background-color: #3f89d5;
  border-radius: 27px;
  width: 110px;
  height: 26px;
  position: absolute;
  top: 1px;
  left: 0;
}

.footer-section .rectangle:hover:hover {
  transform: scale(1.1);
}

.footer-section .text-wrapper-2 {
  color: #fff;
  letter-spacing: .7px;
  text-align: center;
  width: 77px;
  height: 26px;
  font-family: Bebas Neue Pro-Bold, Helvetica;
  font-size: 18px;
  font-weight: 700;
  line-height: 12.5px;
  display: inline-flex;
  position: absolute;
  top: 6px;
  left: 15px;
}

.footer-section .next-next-arrow {
  width: 12px;
  height: 8px;
  position: absolute;
  top: 11px;
  left: 88px;
}

.footer-section .imgfooter {
  width: 26px;
  height: 26px;
  position: absolute;
  top: 0;
  left: 0;
}

.footer-section .element-mercx-group-LTD {
  color: #6300ff;
  letter-spacing: 0;
  width: 107px;
  font-family: Titillium Web, Helvetica;
  font-size: 10.8px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 30px;
}

.footer-section .sections {
  flex: none;
  align-items: flex-start;
  gap: 80px;
  display: inline-flex;
  position: relative;
}

.footer-section .div-2 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 15px;
  display: inline-flex;
  position: relative;
}

.footer-section .text-wrapper-3 {
  color: #f0f;
  letter-spacing: 0;
  width: fit-content;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.footer-section .text-wrapper-4 {
  color: #e8e8e8;
  letter-spacing: 0;
  cursor: pointer;
  width: fit-content;
  font-family: Titillium Web, Helvetica;
  font-size: 8px;
  font-weight: 400;
  line-height: normal;
  display: inline-flex;
  position: relative;
}

.footer-section .line {
  width: 1200px;
  height: 1px;
  margin-top: -.5px;
}

.network {
  position: flex;
  cursor: pointer;
  min-width: 180.43px;
  height: 45px;
}

.overlap-group-61 {
  border: 1px solid;
  border-color: var(--cerulean);
  border-radius: 7px;
  align-items: center;
  min-width: 176px;
  height: 45px;
  margin-right: auto;
  padding: 5.8px 4.4px;
  display: flex;
}

.path-534, .path-534-1 {
  align-self: center;
  width: 31px;
  height: 31px;
}

.overlap-group1-1 {
  width: 115px;
  height: 26px;
  margin-top: 1px;
  margin-left: 6px;
  position: relative;
}

.mainnet {
  letter-spacing: 0;
  width: 65px;
  margin-top: 2px;
  line-height: normal;
  position: absolute;
  top: 15px;
  left: 1px;
}

.chain {
  letter-spacing: 0;
  width: 115px;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.path-535, .path-535-1 {
  width: 12px;
  height: 6px;
  margin-top: 1.78px;
  margin-left: -1px;
}

.chain-box-desktop {
  z-index: 9999;
  background-color: #0000;
  border: 1px;
  justify-content: center;
  align-items: center;
  min-width: 623px;
  height: 649px;
  position: fixed;
  top: 50%;
  left: 50%;
  overflow-x: hidden;
  transform: translate(-50%, -50%);
}

.chain-box-desktop .overlap-group-189 {
  z-index: 1;
  background-size: 100% 100%;
  flex-direction: column;
  align-items: flex-end;
  gap: 29px;
  width: 624px;
  min-height: 649px;
  padding: 21.6px 23px;
  display: flex;
}

.chain-box-desktop .flex-row-112 {
  align-items: flex-start;
  gap: 195px;
  min-width: 357px;
  height: 40px;
  display: flex;
}

.chain-box-desktop .select-source-chain-5 {
  color: #f9f9f9;
  font-family: var(--font-family-titillium_web);
  font-size: var(--font-size-s);
  letter-spacing: 0;
  z-index: 103;
  align-self: flex-end;
  min-width: 136px;
  min-height: 24px;
  margin-right: -393px;
  font-weight: 700;
  line-height: normal;
}

.chain-box-desktop .x {
  cursor: pointer;
  width: 26px;
  height: 25px;
}

.chain-box-desktop .overlap-group-114 {
  border: 1px solid #33a4d8;
  border-radius: 7px;
  align-items: flex-start;
  min-width: 577px;
  height: 45px;
  margin-right: .01px;
  padding: 11px 17px;
  display: flex;
}

.chain-box-desktop .search-chain-by-name-or-chain-id {
  color: #fff;
  font-family: var(--font-family-titillium_web);
  font-size: var(--font-size-xs);
  letter-spacing: 0;
  background-color: #0000;
  border: 0;
  width: 542px;
  height: 21px;
  padding: 0;
  font-weight: 400;
  line-height: normal;
}

.chain-box-desktop .search-chain-by-name-or-chain-id::placeholder {
  color: #7b7b7b99;
}

.chain-box-desktop .scroll-group-1::-webkit-scrollbar {
  width: 0;
  display: none;
}

.chain-box-desktop .scroll-group-1 {
  z-index: 2;
  flex-wrap: wrap;
  align-content: flex-start;
  align-items: flex-start;
  gap: 17px;
  width: 577px;
  height: 467px;
  display: flex;
  position: fixed;
  top: 162px;
  left: 23px;
  overflow-y: scroll;
}

.chain-box-desktop .overlap-group-container-115 {
  flex-direction: column;
  align-items: flex-start;
  gap: 13px;
  width: 280px;
  display: flex;
}

.chain-box-desktop .overlap-group-116 {
  background-color: var(--onyx);
  cursor: pointer;
  border-radius: 7px;
  align-items: center;
  gap: 16px;
  min-width: 280px;
  height: 62px;
  padding: 0 13px;
  display: flex;
}

.chain-box-desktop .polygonLogo, .chain-box-desktop .bnbLogo, .chain-box-desktop .ethLogo, .chain-box-desktop .optimismLogo, .chain-box-desktop .path-5341 {
  width: 38px;
  height: 38px;
}

.chain-box-desktop .x-mainnet {
  letter-spacing: 0;
  width: 196px;
  min-height: 25px;
  margin-top: 1px;
  line-height: normal;
}

.chain-box-desktop .overlap-group210 {
  background-color: var(--onyx);
  cursor: pointer;
  border-radius: 7px;
  align-items: center;
  gap: 14px;
  min-width: 280px;
  height: 62px;
  padding: 11px 13px;
  display: flex;
}

.chain-box-desktop .avalancheLogo, .chain-box-desktop .arbitrumLogo, .chain-box-desktop .optimismLogo, .chain-box-desktop .bnbLogo, .chain-box-desktop .ethereumLogo, .chain-box-desktop .lineaLogo {
  align-self: center;
  width: 38px;
  height: 38px;
}

.chain-box-desktop .avalanche-mainnet {
  letter-spacing: 0;
  width: 199px;
  min-height: 26px;
  margin-top: 2px;
  line-height: normal;
}

.chain-box-desktop .bnb-mainnet {
  letter-spacing: 0;
  width: 195px;
  min-height: 26px;
  line-height: normal;
}

.chain-box-desktop .overlap-group154 {
  background-color: var(--onyx);
  cursor: pointer;
  border-radius: 7px;
  align-items: center;
  gap: 17px;
  min-width: 280px;
  height: 62px;
  padding: 0 12px;
  display: flex;
}

.chain-box-desktop .overlap-group310 {
  background-color: var(--onyx);
  cursor: pointer;
  border-radius: 7px;
  align-items: center;
  gap: 16px;
  min-width: 280px;
  height: 62px;
  padding: 0 12px;
  display: flex;
}

.chain-box-desktop .optimism-mainnet {
  letter-spacing: 0;
  width: 197px;
  min-height: 26px;
  line-height: normal;
}

.chain-box-desktop .overlap-group555 {
  background-color: var(--onyx);
  cursor: pointer;
  border-radius: 7px;
  align-items: center;
  gap: 16px;
  min-width: 280px;
  height: 62px;
  padding: 0 11.6px;
  display: flex;
}

.chain-box-desktop .text-1 {
  letter-spacing: 0;
  width: 146px;
  min-height: 26px;
  line-height: normal;
}

.chain-box-desktop .info-token-selection {
  color: #f9f9f9;
  font-family: var(--font-family-titillium_web);
  letter-spacing: 0;
  width: 300px;
  margin-top: 48px;
  margin-right: -112px;
  font-size: 9px;
}

.overlap-group-116.active, .overlap-group210.active {
  background-color: #727278;
}

.overlap-group-116, .overlap-group210 {
  transition: background-color .3s;
}

.overlap-group-116:hover, .overlap-group210:hover {
  background-color: #727278;
}

.usdt, .usdt-2, .usdt-4 {
  min-width: 180.43px;
  height: 45px;
  position: relative;
}

.overlap-group-9, .overlap-group-10, .overlap-group-11 {
  border: 1px solid;
  border-color: var(--cerulean);
  border-radius: 7px;
  align-items: flex-start;
  gap: 2px;
  min-width: 176px;
  height: 45px;
  padding: 6px 5.5px;
  display: flex;
}

.path-538, .path-538-1, .path-538-2 {
  width: 31px;
  height: 31px;
}

.overlap-group1-4, .overlap-group1-5, .overlap-group1-6 {
  align-self: center;
  width: 71px;
  height: 26px;
  margin-top: 1px;
  position: relative;
}

.usdt-1, .usdt-3, .usdt-5 {
  letter-spacing: 0;
  width: 71px;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 1px;
}

.polygon, .polygon-1, .polygon-2 {
  letter-spacing: 0;
  width: 46px;
  line-height: normal;
  position: absolute;
  top: 17px;
  left: 2px;
}

.desktop-1 .frame-6 {
  cursor: pointer;
  justify-content: center;
  align-self: center;
  align-items: center;
  gap: 10px;
  width: fit-content;
  margin-right: 6px;
  transition: all .2s;
  display: flex;
  position: relative;
}

.desktop-1 .frame-6:hover {
  transform: scale(1.1);
}

.desktop-1 .frame-6 .ab {
  min-width: 336px;
  margin-right: -2px;
  position: relative;
}

.desktop-1 .frame-6 .overlap-group-12 {
  background-size: 100% 100%;
  min-width: 334px;
  height: 47px;
  margin-top: 15px;
  padding: 4.2px 0;
}

.desktop-1 .frame-6 .approve {
  letter-spacing: 0;
  text-align: center;
  width: 334px;
  min-height: 29px;
  line-height: normal;
}

.desktop-1 .frame-6 .frame-6.frame-6-1, .desktop-1 .frame-6 .frame-6.frame-6-2 {
  margin-top: 21px;
  margin-right: 4px;
}

.ipad-3 .frame-6 {
  cursor: pointer;
  justify-content: center;
  align-self: center;
  align-items: center;
  gap: 10px;
  width: fit-content;
  margin-right: 6px;
  transition: all .2s;
  display: flex;
  position: relative;
}

.ipad-3 .frame-6:hover {
  transform: scale(1.1);
}

.ipad-3 .frame-6 .ab {
  min-width: 336px;
  margin-right: -2px;
  position: relative;
}

.ipad-3 .frame-6 .overlap-group-12 {
  background-size: 100% 100%;
  min-width: 334px;
  height: 47px;
  margin-top: 15px;
  padding: 9.2px 0;
}

.ipad-3 .frame-6 .approve {
  letter-spacing: 0;
  text-align: center;
  width: 334px;
  min-height: 29px;
  line-height: normal;
}

.ipad-3 .frame-6 .frame-6.frame-6-1, .ipad-3 .frame-6 .frame-6.frame-6-2 {
  margin-top: 21px;
  margin-right: 4px;
}

.token-box {
  z-index: 99;
  background-color: #0000;
  border: 1px;
  justify-content: center;
  align-items: center;
  min-width: 623px;
  height: 649px;
  display: flex;
  position: fixed;
  top: 50%;
  left: 50%;
  overflow-x: hidden;
  transform: translate(-50%, -50%);
}

.overlap-group45 {
  background-size: 100% 100%;
  flex-direction: column;
  align-items: flex-end;
  width: 624px;
  min-height: 649px;
  padding: 21.6px 23px;
  display: flex;
}

.flex-row27 {
  align-items: flex-start;
  gap: 213px;
  min-width: 338px;
  height: 40px;
  display: flex;
}

.select-source-token {
  color: #f9f9f9;
  font-family: var(--font-family-titillium_web);
  font-size: var(--font-size-s);
  letter-spacing: 0;
  align-self: flex-end;
  min-width: 99px;
  min-height: 24px;
  margin-right: -410px;
  font-weight: 700;
  line-height: normal;
}

.x {
  cursor: pointer;
  width: 26px;
  height: 25px;
}

.overlap-group205 {
  border: 1px solid #33a4d8;
  border-radius: 7px;
  align-items: flex-start;
  min-width: 577px;
  height: 45px;
  margin-top: 29px;
  margin-right: .01px;
  padding: 11px 17px;
  display: flex;
}

.search-token-by-name-or-address {
  color: #fff;
  font-family: var(--font-family-titillium_web);
  font-size: var(--font-size-xs);
  letter-spacing: 0;
  background-color: #0000;
  border: 0;
  width: 542px;
  height: 21px;
  padding: 0;
  font-weight: 400;
  line-height: normal;
}

.search-token-by-name-or-address::placeholder {
  color: #7b7b7b99;
}

.overlap-group58 {
  background-color: #212124;
  border-radius: 7px;
  align-self: center;
  align-items: center;
  gap: 27px;
  min-width: 576px;
  height: 62px;
  margin-top: 26px;
  margin-left: .04px;
  padding: 11px 22px;
  display: flex;
}

.group-299 {
  width: 19px;
  height: 22px;
  margin-top: 4.35px;
}

.below-is-the-support {
  align-self: flex-end;
  width: 372px;
  height: 36px;
  display: flex;
}

.below-is-the-support-1 {
  color: #fff;
  font-family: var(--font-family-titillium_web);
  font-size: var(--font-size-m);
  letter-spacing: 0;
  flex: 1;
  width: 370px;
  font-size: 12px;
  font-weight: 400;
  line-height: 18.4px;
}

.scroll-group-10::-webkit-scrollbar {
  width: 0;
  display: none;
}

.scroll-group-10 {
  flex-direction: column;
  align-self: center;
  align-items: flex-start;
  gap: 13px;
  width: 576px;
  height: 387px;
  margin-top: 14px;
  display: flex;
  position: relative;
  overflow: hidden scroll;
}

.scroll-group-10-item {
  cursor: pointer;
  min-width: 576px;
  height: 62px;
  margin-right: -4px;
  position: relative;
}

.overlap-group-181 {
  background-color: var(--onyx);
  border-radius: 7px;
  align-items: center;
  min-width: 576px;
  height: 62px;
  padding: 11.1px 13.1px;
  display: flex;
}

.overlap-group-181.active {
  background-color: #727278;
  transition: background-color .3s;
}

.overlap-group-181:hover {
  background-color: #727278;
}

.group {
  align-self: flex-start;
  width: 38px;
  height: 38px;
}

.tether-usd, .wrapped-matic {
  letter-spacing: 0;
  width: 227px;
  min-height: 25px;
  margin-bottom: 1.17px;
  margin-left: 16px;
  line-height: normal;
}

.usdt1 {
  letter-spacing: 0;
  text-align: right;
  width: 159px;
  min-height: 25px;
  margin-bottom: 2.96px;
  margin-left: 97px;
  line-height: normal;
}

.overlap-group172 {
  background-color: var(--onyx);
  border-radius: 7px;
  align-items: center;
  min-width: 576px;
  height: 62px;
  padding: 11.1px 13.7px;
  display: flex;
}

.xgt, .usd {
  letter-spacing: 0;
  width: 227px;
  min-height: 25px;
  margin-bottom: 1.17px;
  margin-left: 15px;
  line-height: normal;
}

.xgt-1 {
  letter-spacing: 0;
  text-align: right;
  width: 133px;
  min-height: 25px;
  margin-bottom: .96px;
  margin-left: 123px;
  line-height: normal;
}

.overlap-group206 {
  background-color: var(--onyx);
  border-radius: 7px;
  align-items: center;
  min-width: 576px;
  height: 62px;
  padding: 11px 13.9px;
  display: flex;
}

.usdc {
  letter-spacing: 0;
  text-align: right;
  width: 127px;
  min-height: 25px;
  margin-bottom: .96px;
  margin-left: 129px;
  line-height: normal;
}

.overlap-group399 {
  background-color: var(--onyx);
  border-radius: 7px;
  align-items: center;
  min-width: 576px;
  height: 62px;
  padding: 11.3px 13.4px;
  display: flex;
}

.w-matic {
  letter-spacing: 0;
  text-align: right;
  width: 119px;
  min-height: 25px;
  margin-bottom: .96px;
  margin-left: 137px;
  line-height: normal;
}

.token-box .info-selection {
  color: #f9f9f9;
  font-family: var(--font-family-titillium_web);
  letter-spacing: 0;
  width: 307px;
  margin-top: 46px;
  margin-right: -100px;
  font-size: 9px;
}

.desktop-1 {
  background-position: initial;
  background-size: cover;
  border: 1px;
  flex-direction: column;
  align-items: center;
  gap: 115px;
  min-width: 1440px;
  padding: 81px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.desktop-1 .overlay {
  z-index: 98;
  background: #00000080;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.desktop-1 .open-widget-button, .desktop-1 .close-widget-button {
  color: #fff;
  text-align: center;
  cursor: pointer;
  z-index: 101;
  background-color: #0000;
  border: 1px solid #f0f;
  border-radius: 10px;
  width: 169px;
  height: 44px;
  font-family: Oswald, Helvetica;
  font-size: 19px;
  font-weight: 400;
  line-height: 41px;
  transition: background-color .3s, transform .3s;
  position: absolute;
  top: -1393px;
  right: 245px;
}

.desktop-1 .open-widget-button:hover, .desktop-1 .close-widget-button:hover {
  background-color: #f0f;
  transform: scale(1.05);
}

.widget-container {
  z-index: 99;
  width: 400px;
  position: absolute;
  top: -1508px;
  left: 429px;
}

.desktop-1 .frame-3414 {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 150px;
  display: flex;
  position: relative;
}

.desktop-1 .win-pop-up-button {
  border: 1px solid #f0f;
  border-radius: 10px;
  width: 193px;
  height: 59px;
  position: absolute;
  top: -121px;
  left: 717px;
}

.desktop-1 .get-started-4 {
  color: #f0f;
  letter-spacing: 0;
  text-align: center;
  width: 193px;
  height: 21px;
  font-family: Oswald, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 15px;
  left: -1px;
}

.desktop-1 .alphalaunchbanner {
  margin-top: -7px;
  margin-bottom: -126px;
  position: relative;
}

.desktop-1 .alphaimg {
  position: relative;
}

.desktop-1 .nav {
  justify-content: space-between;
  align-self: center;
  align-items: center;
  min-width: 1278px;
  height: 46px;
  padding: 10px 50px 10px 40px;
  display: flex;
  position: absolute;
}

.desktop-1 .logo {
  width: 246.25px;
  height: 45.81px;
  margin-top: -.2px;
}

.desktop-1 .my-custom-wrapper {
  border-radius: 12px;
  display: inline-block;
  overflow: hidden;
}

.desktop-1 .my-custom-class {
  cursor: pointer;
  font-weight: bold;
  position: relative;
  left: 1.2px;
  font-family: var(--font-family-bebas_neue_pro-regular) !important;
}

.desktop-1 .img-wrapper {
  --webkit-backdrop-filter: blur(10px) brightness(100%);
  backdrop-filter: blur(10px) brightness();
  border-radius: 10px;
  width: 33px;
  height: 33px;
  position: relative;
  left: 600px;
}

.desktop-1 .swap-box-4 {
  border-radius: 25px;
  min-width: 787px;
  height: 547px;
  margin-top: 45px;
  margin-bottom: -150px;
  position: relative;
  overflow-y: auto;
}

.desktop-1 .group-2 {
  z-index: 9;
  cursor: pointer;
  width: 44px;
  height: 44px;
  position: relative;
  top: -6px;
  right: 10px;
}

@keyframes shake {
  10%, 90% {
    transform: translateX(-5px);
  }

  20%, 80% {
    transform: translateX(5px);
  }

  30%, 50%, 70% {
    transform: translateX(-3px);
  }

  40%, 60% {
    transform: translateX(3px);
  }
}

.desktop-1 .frame-3411 {
  min-width: 1278px;
  height: 413px;
  display: flex;
  position: relative;
}

.desktop-1 .heromenu-box {
  flex-direction: column;
  align-items: center;
  gap: 55px;
  width: 1278px;
  height: 413px;
  display: flex;
  position: relative;
}

.desktop-1 .menu-box {
  border: 1px solid;
  border-color: var(--cerulean);
  border-radius: 9px;
  justify-content: center;
  min-width: 614px;
  height: 43px;
  display: flex;
  position: relative;
  top: 130px;
}

.desktop-1 .hero-box {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 58px;
  height: 277px;
  padding: 20px 0;
  display: flex;
  position: relative;
}

.desktop-1 .frame-3413 {
  min-width: 1278px;
  height: 296px;
  margin-bottom: -59px;
  position: relative;
}

.place {
  letter-spacing: 0;
  width: 54px;
  line-height: normal;
  position: relative;
}

.frame-3 {
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: fit-content;
  display: flex;
  position: relative;
}

.tb {
  min-width: 694.69px;
  height: 129px;
  margin-right: -4px;
  position: relative;
}

.flex-col-1 {
  background-color: var(--black-rock);
  border-radius: 12px;
  flex-direction: column;
  align-items: flex-end;
  gap: 29px;
  width: 691px;
  min-height: 129px;
  padding: 8px 21.5px;
  display: flex;
}

.flex-row {
  align-items: flex-end;
  min-width: 646px;
  height: 20px;
  margin-top: 7px;
  display: flex;
}

.desktop-1 .send {
  letter-spacing: 0;
  align-self: flex-end;
  width: 232px;
  min-height: 6px;
  margin-left: -4px;
  line-height: normal;
}

.desktop-1 .overlap-group1 {
  justify-content: space-between;
  min-width: 350px;
  height: 19px;
  margin-bottom: -85px;
  padding: 2.5px 11.2px;
  display: flex;
}

.desktop-1 .usdvalue {
  letter-spacing: 0;
  width: 20px;
  min-height: 14px;
  margin-left: -244px;
  line-height: normal;
}

.desktop-1 .usdvaluerecieve {
  letter-spacing: 0;
  width: 233px;
  margin-top: -25px;
  margin-left: 20px;
  line-height: normal;
}

.overlap-group-1 {
  justify-content: flex-end;
  align-items: flex-start;
  min-width: 43px;
  height: 19px;
  margin-top: .22px;
  margin-left: 5px;
  padding: 2.5px 11.2px;
  display: flex;
}

.desktop-1 .walletbalance {
  letter-spacing: 0;
  text-align: center;
  min-height: 14px;
  margin-right: -60px;
  line-height: normal;
}

.flex-row-1 {
  align-self: center;
  align-items: center;
  gap: 320px;
  min-width: 644px;
  height: 57px;
  margin-right: .67px;
  display: flex;
}

.desktop-1 .text-1 {
  letter-spacing: 0;
  white-space: nowrap;
  background-color: #0000;
  border: none;
  width: 232px;
  min-height: 57px;
  margin-bottom: 50px;
  line-height: normal;
}

.desktop-1 .text-1::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.desktop-1 .text-1::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.desktop-1 .text-1 {
  -moz-appearance: textfield;
}

.desktop-1 .frame-3403 {
  cursor: pointer;
  justify-content: center;
  align-items: flex-end;
  min-width: 91px;
  margin-bottom: 130.06px;
  display: flex;
}

.path-529 {
  align-self: flex-start;
  width: 31px;
  height: 31px;
  margin-top: 0;
}

.token {
  letter-spacing: 0;
  width: 41px;
  min-height: 21px;
  margin-bottom: 2.94px;
  margin-left: 6px;
  line-height: normal;
}

.path-530 {
  width: 12px;
  height: 6px;
  margin-top: 0;
  margin-bottom: 12px;
  margin-left: 20px;
}

.frame-4 {
  width: 690px;
  height: 34px;
  margin-left: .35px;
}

.frame-2 {
  align-items: center;
  gap: 30px;
  width: 689px;
  height: 45px;
  margin-left: .85px;
  padding: 0 0 0 21px;
  display: flex;
  position: relative;
}

.to {
  letter-spacing: 0;
  width: 55px;
  line-height: normal;
  position: relative;
}

.frame-5 {
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: fit-content;
  margin-left: .02px;
  display: flex;
  position: relative;
}

.bb {
  min-width: 694.65px;
  height: 106.6px;
  margin-right: -4px;
  position: relative;
}

.overlap-group-2 {
  background-size: 100% 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 18px;
  width: 691px;
  min-height: 107px;
  padding: 1.5px 21.8px;
  display: flex;
}

.receive-estimated {
  letter-spacing: 0;
  width: 232px;
  min-height: 19px;
  margin-top: 10px;
  margin-left: 1px;
  line-height: normal;
}

.desktop-1 .text-2 {
  letter-spacing: 0;
  width: 300px;
  min-height: 57px;
  margin-top: -20px;
  line-height: normal;
}

.frame-3412 {
  min-width: 1278px;
  height: 445.18px;
  display: flex;
  position: relative;
}

.desktop-1 .sub {
  flex-direction: column;
  align-items: center;
  gap: 56px;
  width: 1278px;
  height: 446.18px;
  display: flex;
  position: relative;
}

.desktop-1 .ramp-widget {
  width: 781px;
  height: 541px;
  margin-top: 50px;
  margin-bottom: -150px;
}

@media (width >= 768px) {
  .ramp-widget {
    width: 400px;
  }
}

@media (width >= 1025px) {
  .ramp-widget {
    width: 500px;
  }
}

.desktop-1 .sub-box {
  flex-direction: column;
  align-items: center;
  gap: 13px;
  width: 1278px;
  padding: 20px 0 0;
  display: flex;
  position: relative;
  top: 75px;
}

.desktop-1 .join-a-global-community {
  color: var(--ceramic);
  font-family: var(--font-family-bebas_neue_pro-regular);
  letter-spacing: 0;
  text-align: center;
  align-self: stretch;
  height: 53px;
  margin-top: -1px;
  font-size: 39px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.desktop-1 .line-33 {
  min-width: 173.83px;
  height: 1.44px;
  position: relative;
}

.desktop-1 .learn-more-about-xio {
  color: var(--hint-of-red);
  font-family: var(--font-family-bebas_neue_pro-regular);
  font-size: var(--font-size-xxxxxl);
  letter-spacing: 1.72px;
  text-align: center;
  align-self: stretch;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.desktop-1 .frame-262 {
  justify-content: center;
  align-items: center;
  gap: 15px;
  width: 1278px;
  margin-top: 75px;
  display: flex;
  position: relative;
}

.desktop-1 .frame-3395 {
  background-color: var(--white);
  border: 1.2px solid;
  border-color: var(--kingfisher-daisy);
  border-radius: 8px;
  align-items: center;
  width: 359.15px;
  height: 50.07px;
  padding: 14.37px;
  display: flex;
  position: relative;
}

.desktop-1 .eg-bethe1sttoknow {
  letter-spacing: 0;
  opacity: .3;
  white-space: nowrap;
  background-color: #0000;
  border: 0;
  min-width: 330.42px;
  height: 22.75px;
  margin-top: -1.9px;
  padding: 0;
  line-height: normal;
  position: relative;
}

.frame-3396 {
  background-color: var(--kingfisher-daisy);
  cursor: pointer;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  gap: 7.18px;
  width: 220px;
  height: 49.61px;
  padding: 15px 20px;
  transition: all .2s;
  display: flex;
  position: relative;
}

.frame-3396:hover, .social-box-item:hover, .social-box-item-1:hover {
  transform: scale(1.1);
}

.submit {
  letter-spacing: 0;
  text-align: center;
  width: fit-content;
  margin-top: -5.89px;
  margin-bottom: -3.5px;
  line-height: normal;
  position: relative;
}

.social-box {
  justify-content: center;
  align-items: center;
  gap: 51px;
  width: 1278px;
  display: flex;
  position: relative;
}

.social-box-item {
  cursor: pointer;
  min-width: 161.56px;
  height: 157.11px;
  transition: all .2s;
  position: relative;
}

.overlap-group {
  background-size: 100% 100%;
  width: 158px;
  height: 157px;
  position: relative;
}

.join-the-xion-community {
  letter-spacing: 0;
  width: 94px;
  font-size: 9px;
  line-height: normal;
  position: absolute;
  top: 112px;
  left: 32px;
}

.for-updates-and-news {
  letter-spacing: 0;
  width: 84px;
  font-size: 9px;
  line-height: normal;
  position: absolute;
  top: 123px;
  left: 37px;
}

.chat-in-real-time-with-the {
  letter-spacing: 0;
  width: 97px;
  font-size: 9px;
  line-height: normal;
  position: absolute;
  top: 113px;
  left: 30px;
}

.xion-community {
  letter-spacing: 0;
  width: 63px;
  font-size: 9px;
  line-height: normal;
  position: absolute;
  top: 124px;
  left: 47px;
}

.social-box-item-1 {
  cursor: pointer;
  min-width: 161.56px;
  height: 157.1px;
  transition: all .2s;
  position: relative;
}

.follow-xionfinance-for {
  letter-spacing: 0;
  width: 87px;
  font-size: 9px;
  line-height: normal;
  position: absolute;
  top: 113px;
  left: 36px;
}

.desktop-1 .updates-and-news {
  letter-spacing: 0;
  width: 71px;
  font-size: 9px;
  line-height: normal;
  position: absolute;
  top: 124px;
  left: 44px;
}

.desktop-1 .follow-xionfinance-for-1 {
  letter-spacing: 0;
  width: 94px;
  font-size: 9px;
  line-height: normal;
  position: absolute;
  top: 113px;
  left: 32px;
}

.desktop-1 .footer {
  background-color: #0000;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 37.6px;
  min-width: 1194.56px;
  height: 80px;
  display: flex;
  position: relative;
  top: 45px;
}

.desktop-1 .line-34 {
  width: 1194.56px;
  height: 1px;
  margin-top: -.4px;
}

.desktop-1 .frame-3399 {
  justify-content: center;
  align-items: flex-start;
  gap: 984px;
  width: 1187px;
  height: 16px;
  margin-left: -1px;
  display: flex;
  position: relative;
}

.desktop-1 .copyright {
  color: #f9f9f9;
  letter-spacing: 0;
  width: 107px;
  height: 16px;
  margin-top: -1px;
  font-family: Bebas Neue Pro-Regular, Helvetica;
  font-size: 14px;
  line-height: normal;
  position: relative;
}

.desktop-1 .about-us-blog-t-cs-privacy-policy {
  letter-spacing: 0;
  text-align: right;
  width: 96px;
  margin-top: -1px;
  font-size: 14px;
  line-height: normal;
  position: relative;
  right: 20px;
}

.desktop-1 .approve {
  letter-spacing: 0;
  text-align: center;
  width: 334px;
  min-height: 29px;
  margin-top: 5px;
  line-height: normal;
}

.desktop-1 .b-wrapper, .desktop-1 .frame-34 {
  flex: none;
  justify-content: space-around;
  align-items: flex-start;
  gap: 55px;
  width: 1034px;
  display: flex;
  position: relative;
}

.desktop-1 .b-2 {
  align-self: stretch;
  width: 1033px;
  position: relative;
}

.desktop-1 .XGT-MARKET-4 {
  color: #35cbfd;
  letter-spacing: 0;
  text-align: center;
  width: 1025px;
  font-family: Bebas Neue Pro-Regular, Helvetica;
  font-size: 26px;
  font-weight: 400;
  line-height: 23.1px;
  position: absolute;
  top: 60px;
  left: 8px;
}

.desktop-1 .text-wrapper-62 {
  color: #35cbfd;
  letter-spacing: 0;
  font-family: Bebas Neue Pro-Regular, Helvetica;
  font-size: 26px;
  font-weight: 400;
  line-height: 23.1px;
}

.desktop-1 .text-wrapper-63 {
  font-family: Bebas Neue Pro-Light, Helvetica;
  font-weight: 300;
}

.desktop-1 .terms-link, .desktop-1 .privacy-link {
  color: #f9f9f9;
  cursor: pointer;
  min-width: 125px;
  margin-top: 3px;
  margin-left: -14px;
  font-family: Bebas Neue Pro-Light, Helvetica;
  font-size: 12px;
  transition: color .3s;
}

.desktop-1 .terms-link:hover, .desktop-1 .privacy-link:hover {
  color: #35cbfd;
}

.swap-box-2 {
  min-width: 690.69px;
  height: 535.6px;
  position: relative;
}

.frame-container-2 {
  border: 2px solid;
  border-color: var(--scooter);
  border-radius: 22px;
  flex-direction: column;
  align-items: flex-start;
  width: 690px;
  min-height: 636px;
  padding: 48px 44px;
  display: flex;
  position: relative;
  top: -50px;
}

.frame-1-2 {
  align-items: center;
  gap: 30px;
  width: 597px;
  padding: 0 0 0 20px;
  display: flex;
  position: relative;
}

.place-2 {
  letter-spacing: 0;
  width: 54px;
  line-height: normal;
  position: relative;
}

.frame-3406 {
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: fit-content;
  margin-top: 42px;
  display: flex;
  position: relative;
}

.tb-2, .bb-2 {
  min-width: 601px;
  height: 113px;
  margin-right: -4px;
  position: relative;
}

.flex-col-7 {
  background-color: var(--black-rock);
  border-radius: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 18px;
  width: 597px;
  min-height: 113px;
  padding: 2px 17.6px;
  display: flex;
}

.flex-row-3 {
  align-self: flex-end;
  align-items: flex-end;
  min-width: 557px;
  height: 20px;
  margin-top: 14px;
  margin-right: 1.21px;
  display: flex;
}

.send-3 {
  letter-spacing: 0;
  align-self: flex-start;
  width: 248px;
  min-height: 19px;
  line-height: normal;
}

.overlap-group1-9 {
  background-image: url("path-532-2@2x.24bd8fb9.png");
  background-size: 100% 100%;
  justify-content: flex-end;
  align-items: flex-start;
  min-width: 46px;
  height: 19px;
  margin-bottom: .22px;
  margin-left: 212px;
  padding: 2.1px 12px;
  display: flex;
}

.name-2 {
  letter-spacing: 0;
  text-align: center;
  width: 21px;
  min-height: 14px;
  line-height: normal;
}

.overlap-group-25 {
  background-image: url("path-531-2@2x.efeaacf0.png");
  background-size: 100% 100%;
  justify-content: flex-end;
  align-items: flex-start;
  min-width: 46px;
  height: 19px;
  margin-left: 5px;
  padding: 1.9px 10.2px;
  display: flex;
}

.half-2 {
  letter-spacing: 0;
  text-align: center;
  width: 24px;
  min-height: 14px;
  line-height: normal;
}

.flex-row-4 {
  align-items: center;
  gap: 210px;
  min-width: 559px;
  height: 57px;
  display: flex;
}

.text-5 {
  letter-spacing: 0;
  width: 248px;
  min-height: 57px;
  line-height: normal;
}

.frame-3404-1 {
  align-items: center;
  min-width: 97px;
  margin-top: 5.94px;
  padding: 0;
  display: flex;
}

.path-529-1 {
  align-self: flex-start;
  width: 33px;
  height: 31px;
  margin-top: 0;
}

.price-2 {
  letter-spacing: 0;
  width: 44px;
  min-height: 21px;
  margin-bottom: 2.94px;
  margin-left: 6px;
  line-height: normal;
}

.path-530-2 {
  width: 13px;
  height: 6px;
  margin-top: 3.01px;
  margin-left: 10px;
}

.frame-3407-1 {
  align-self: center;
  width: 35px;
  height: 34px;
  margin-top: 26px;
  margin-right: 4px;
}

.frame-2-2 {
  align-items: center;
  gap: 28px;
  width: 597px;
  height: 45px;
  margin-top: 26px;
  padding: 0 0 0 21px;
  display: flex;
  position: relative;
}

.to-2 {
  letter-spacing: 0;
  width: 55px;
  line-height: normal;
  position: relative;
}

.frame-3405 {
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: fit-content;
  margin-top: 25px;
  display: flex;
  position: relative;
}

.overlap-group-26 {
  background-color: var(--black-rock);
  border-radius: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 19px;
  width: 597px;
  min-height: 113px;
  padding: 2px 20px;
  display: flex;
}

.send-4 {
  letter-spacing: 0;
  width: 229px;
  min-height: 19px;
  margin-top: 14px;
  line-height: normal;
}

.text-6 {
  letter-spacing: 0;
  width: 229px;
  min-height: 57px;
  line-height: normal;
}

.swap-box-2.swap-box-3 {
  margin-left: -1.35px;
  margin-right: -1.35px;
}

.powered-by {
  font-family: var(--font-family-titillium_web);
  color: #fff;
  width: 100px;
  padding-right: 10px;
  font-size: 8px;
  font-style: normal;
  position: absolute;
  bottom: 27px;
  right: 199px;
}

.squid {
  width: 15px;
  position: absolute;
  bottom: 19px;
  right: 239px;
}

.fdpopup {
  z-index: 99;
  background-color: #0000;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.fdpopup .div {
  background-image: url("rectangle-2921.03b61ec1.png");
  background-size: 100% 100%;
  width: 311px;
  height: 311px;
  margin-top: -278px;
  margin-left: -3px;
  position: absolute;
}

.fdpopup .contact-details {
  width: 172px;
  height: 18px;
  position: absolute;
  top: 220px;
  left: 65px;
}

.fdpopup .transaction-needs {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 232px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 0;
  left: -27px;
}

.fdpopup .group {
  width: 154px;
  height: 154px;
  position: absolute;
  top: 50px;
  left: 76px;
}

.fdpopup .RB-button {
  width: 118px;
  height: 10px;
  position: absolute;
  top: 94px;
  left: 26px;
}

.fdpopup .overlap-group-wrapper {
  width: 60px;
  height: 10px;
  position: absolute;
  top: 0;
  left: 0;
}

.fdpopup .overlap-group {
  background-color: #3a9300;
  border-radius: 2px;
  width: 58px;
  height: 10px;
  position: relative;
}

.fdpopup .vew-transaction {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 58px;
  height: 10px;
  font-family: Titillium Web, Helvetica;
  font-size: 5px;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.fdpopup .overlap-wrapper {
  width: 60px;
  height: 10px;
  position: absolute;
  top: 0;
  left: 60px;
}

.fdpopup .overlap {
  border: .5px solid #cf5159;
  border-radius: 2px;
  width: 58px;
  height: 10px;
  position: relative;
}

.fdpopup .increase-gas {
  color: #cf5159;
  letter-spacing: 0;
  text-align: center;
  width: 58px;
  height: 10px;
  font-family: Titillium Web, Helvetica;
  font-size: 5px;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.fdpopup .text-wrapper {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 170px;
  font-family: Titillium Web, Helvetica;
  font-size: 5px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 112px;
  left: 0;
}

.sfpopup {
  z-index: 99;
  background-color: #0000;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.sfpopup .div {
  background-image: url("rectangle-2921.03b61ec1.png");
  background-size: 100% 100%;
  width: 311px;
  height: 311px;
  margin-top: -278px;
  margin-left: -3px;
  position: absolute;
}

.sfpopup .contact-details {
  width: 172px;
  height: 18px;
  position: absolute;
  top: 195px;
  left: 71px;
}

.sfpopup .transaction {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 170px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.sfpopup .group {
  width: 154px;
  height: 154px;
  position: absolute;
  top: 32px;
  left: 79px;
}

.sfpopup .RB-button {
  position: absolute;
  top: 226px;
  left: 93px;
}

.sfpopup .overlap-group-wrapper {
  width: 60px;
  height: 10px;
  position: absolute;
  top: 0;
  left: 0;
}

.sfpopup .overlap-group {
  width: 116px;
  height: 20px;
  position: relative;
  right: 69px;
}

.sfpopup .view-transaction {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  cursor: pointer;
  width: 115px;
  height: 10px;
  font-family: Titillium Web, Helvetica;
  font-size: 12px;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 0;
  left: -1px;
}

.sfpopup .overlap-wrapper {
  width: 60px;
  height: 10px;
  position: absolute;
  top: 0;
  left: 85px;
}

.sfpopup .overlap {
  width: 125px;
  height: 19px;
  margin-left: -20px;
  position: relative;
}

.sfpopup .element-recived {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 91px;
  height: 10px;
  font-family: Titillium Web, Helvetica;
  font-size: 12px;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 6px;
}

.pspopup {
  z-index: 99;
  justify-content: center;
  width: 100%;
  display: flex;
}

.pspopup .div {
  background-image: url("rectangle-2921.03b61ec1.png");
  background-size: 100% 100%;
  width: 311px;
  height: 311px;
  margin-top: -278px;
  margin-left: -3px;
  position: absolute;
}

.pspopup .contact-details {
  width: 172px;
  height: 18px;
  position: absolute;
  top: 216px;
  left: 72px;
}

.pspopup .transaction {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 170px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: -10px;
  left: 0;
}

.pspopup .group {
  width: 154px;
  height: 154px;
  position: absolute;
  top: 36px;
  left: 80px;
}

.pspopup .element-sec-wrapper {
  width: 60px;
  height: 10px;
  position: absolute;
  top: 240px;
  left: 124px;
}

.pspopup .element-sec {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 180px;
  height: 10px;
  font-family: Titillium Web, Helvetica;
  font-size: 12px;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 24px;
  left: -59px;
}

.pspopup .close {
  cursor: pointer;
  z-index: 9;
  width: 14px;
  height: 13px;
  position: absolute;
  top: 13px;
  left: 284px;
}

.fdpopup .close {
  cursor: pointer;
  z-index: 99;
  width: 14px;
  height: 13px;
  position: absolute;
  top: 392px;
  left: 530px;
}

.sfpopup .close {
  cursor: pointer;
  z-index: 99;
  width: 14px;
  height: 13px;
  position: absolute;
  top: -205px;
  left: 101px;
}

.sfpopup .usdt-logo {
  width: 20px;
  height: 20px;
  margin-top: -2px;
  margin-left: 1px;
}

.fdpopup .failed-reason {
  color: #fff;
  width: 295px;
  margin-top: 29px;
  margin-left: -47px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 400;
}

.fdpopup .failed-reason a, .fdpopup .failed-reason a:hover {
  color: #35cbfd;
}

.sfpopup .twitter-share-button {
  color: #fff;
  cursor: pointer;
  margin-top: 33px;
  margin-left: -11px;
  padding: 10px 20px;
  font-family: Titillium Web, Helvetica;
  font-size: 16px;
  font-weight: 400;
  display: inline-block;
}

.sfpopup .twitter-share-button:hover {
  animation: .5s ease-in-out shake;
}

.sfpopup .share-icon {
  z-index: 99;
  cursor: pointer;
  width: 20px;
  margin-top: 0;
  margin-left: 77px;
  position: absolute;
}

.sfpopup .link-icon {
  z-index: 99;
  cursor: pointer;
  width: 20px;
  margin-top: 0;
  margin-left: 102px;
  position: absolute;
}

.pspopup .onway {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 170px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 13px;
  left: 0;
}

@media (width <= 768px) {
  .button {
    width: 125px;
    font-size: 12px;
  }
}

@media (width <= 480px) {
  .button {
    width: 100px;
    font-size: 10px;
  }
}

.network-mobile {
  position: flex;
  cursor: pointer;
  min-width: 180.43px;
  height: 45px;
}

.network-mobile .overlap-group-61 {
  border: 1px solid;
  border-color: var(--cerulean);
  border-radius: 7px;
  align-items: center;
  min-width: 176px;
  height: 45px;
  margin-right: auto;
  padding: 5.8px 4.4px;
  display: flex;
}

.network-mobile .path-534, .network-mobile .path-534-1 {
  align-self: center;
  width: 31px;
  height: 31px;
}

.network-mobile .overlap-group1-1 {
  width: 115px;
  height: 26px;
  margin-top: 1px;
  margin-left: 6px;
  position: relative;
}

.network-mobile .mainnet {
  letter-spacing: 0;
  width: 65px;
  margin-top: 2px;
  line-height: normal;
  position: absolute;
  top: 15px;
  left: 1px;
}

.network-mobile .chain {
  letter-spacing: 0;
  width: 115px;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.network-mobile .path-535, .network-mobile .path-535-1 {
  width: 12px;
  height: 6px;
  margin-top: 1.78px;
  margin-left: -1px;
}

.chain-box-mobile {
  z-index: 103;
  background-color: #0000;
  border: 1px;
  justify-content: center;
  align-items: center;
  min-width: 623px;
  height: 649px;
  margin-left: 143px;
  position: fixed;
  top: 50%;
  left: 50%;
  overflow-x: hidden;
  transform: translate(-50%, -50%);
}

.chain-box-mobile .overlap-group-189 {
  z-index: 1;
  background-color: #0f0f0f;
  background-size: 100% 100%;
  border: 3px solid #35cbfd;
  border-radius: 18px;
  flex-direction: column;
  align-items: flex-end;
  gap: 29px;
  width: 338px;
  min-height: 649px;
  padding: 21.6px 23px;
  display: flex;
}

.chain-box-mobile .flex-row-112 {
  flex-flow: row;
  justify-content: flex-end;
  gap: 55px;
  min-width: 357px;
  height: 40px;
  display: flex;
}

.chain-box-mobile .select-source-chain-5 {
  color: #f9f9f9;
  font-family: var(--font-family-titillium_web);
  font-size: var(--font-size-s);
  letter-spacing: 0;
  align-self: flex-end;
  min-width: 136px;
  min-height: 24px;
  margin-right: -248px;
  font-weight: 700;
  line-height: normal;
}

.chain-box-mobile .x {
  cursor: pointer;
  width: 26px;
  height: 25px;
}

.chain-box-mobile .overlap-group-114 {
  border: 1px solid #33a4d8;
  border-radius: 7px;
  align-items: flex-start;
  min-width: 306px;
  height: 45px;
  margin-right: -11px;
  padding: 11px 17px;
  display: flex;
}

@media only screen and (width >= 306px) {
  .chain-box-mobile .overlap-group-114 {
    max-width: 50px;
  }
}

.chain-box-mobile .search-chain-by-name-or-chain-id {
  color: #fff;
  font-family: var(--font-family-titillium_web);
  font-size: var(--font-size-xs);
  letter-spacing: 0;
  background-color: #0000;
  border: 0;
  width: 306px;
  height: 21px;
  margin-left: 0;
  padding: 0;
  font-weight: 400;
  line-height: normal;
}

.chain-box-mobile .search-chain-by-name-or-chain-id::placeholder {
  color: #7b7b7b99;
}

.chain-box-mobile .scroll-group-1::-webkit-scrollbar {
  width: 0;
  display: none;
}

.chain-box-mobile .scroll-group-11 {
  z-index: 2;
  flex-flow: column;
  place-content: stretch center;
  align-items: center;
  gap: 10px;
  width: 307px;
  height: 488px;
  padding-bottom: 7px;
  display: flex;
  position: absolute;
  top: 149px;
  left: 16px;
  overflow-y: scroll;
}

.chain-box-mobile .overlap-group-container-1150 {
  background-color: var(--onyx);
  z-index: 99;
  border-radius: 7px;
  flex-direction: column;
  align-items: flex-start;
  gap: 13px;
  width: 307px;
  min-height: 21px;
  display: flex;
}

.chain-box-mobile .overlap-group-1160 {
  background-color: var(--onyx);
  cursor: pointer;
  z-index: 101;
  border-radius: 7px;
  align-items: center;
  gap: 16px;
  min-width: 307px;
  height: 62px;
  padding: 0 13px;
  display: flex;
}

.chain-box-mobile .polygonLogo, .chain-box-mobile .bnbLogo, .chain-box-mobile .ethLogo, .chain-box-mobile .optimismLogo, .chain-box-mobile .path-5341 {
  width: 38px;
  height: 38px;
}

.chain-box-mobile .x-mainnet {
  letter-spacing: 0;
  width: 196px;
  min-height: 25px;
  margin-top: 1px;
  line-height: normal;
}

.chain-box-mobile .overlap-group2101 {
  background-color: var(--onyx);
  cursor: pointer;
  z-index: 101;
  border-radius: 7px;
  align-items: center;
  gap: 14px;
  min-width: 307px;
  height: 62px;
  padding: 11px 13px;
  display: flex;
}

.chain-box-mobile .overlap-group154 {
  background-color: var(--onyx);
  cursor: pointer;
  border-radius: 7px;
  align-items: center;
  gap: 17px;
  min-width: 280px;
  height: 62px;
  padding: 0 12px;
  display: flex;
}

.chain-box-mobile .overlap-group310 {
  background-color: var(--onyx);
  cursor: pointer;
  border-radius: 7px;
  align-items: center;
  gap: 16px;
  min-width: 280px;
  height: 62px;
  padding: 0 12px;
  display: flex;
}

.chain-box-mobile .optimism-mainnet {
  letter-spacing: 0;
  width: 197px;
  min-height: 26px;
  line-height: normal;
}

.chain-box-mobile .overlap-group555 {
  background-color: var(--onyx);
  cursor: pointer;
  border-radius: 7px;
  align-items: center;
  gap: 16px;
  min-width: 280px;
  height: 62px;
  padding: 0 11.6px;
  display: flex;
}

.chain-box-mobile .text-1 {
  letter-spacing: 0;
  width: 146px;
  min-height: 26px;
  line-height: normal;
}

.chain-box-mobile .avalancheLogo, .chain-box-mobile .arbitrumLogo, .chain-box-mobile .optimismLogo, .chain-box-mobile .bnbLogo, .chain-box-mobile .ethereumLogo, .chain-box-mobile .lineaLogo {
  align-self: center;
  width: 38px;
  height: 38px;
}

.chain-box-mobile .avalanche-mainnet {
  letter-spacing: 0;
  width: 199px;
  min-height: 26px;
  margin-top: 2px;
  line-height: normal;
}

.chain-box-mobile .bnb-mainnet {
  letter-spacing: 0;
  width: 195px;
  min-height: 26px;
  line-height: normal;
}

.chain-box-mobile .info-token-selection {
  color: #f9f9f9;
  font-family: var(--font-family-titillium_web);
  letter-spacing: 0;
  width: 300px;
  margin-top: 48px;
  margin-right: -112px;
  font-size: 9px;
}

.overlap-group-1160.active, .overlap-group2101.active {
  background-color: #727278;
}

.overlap-group-1160, .overlap-group2101 {
  transition: background-color .3s;
}

.overlap-group-1160:hover, .overlap-group2101:hover {
  background-color: #727278;
}

:root {
  --black: #000;
  --onyx: #333338;
  --white: #fff;
  --font-size-m: 17px;
  --font-size-s: 16px;
  --font-size-xs: 14px;
  --font-family-titillium_web: "Titillium Web", Helvetica;
}

.titilliumweb-normal-white-17px {
  color: var(--white);
  font-family: var(--font-family-titillium_web);
  font-size: 17px(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.token-box-mobile {
  z-index: 9999;
  background-color: #0000;
  border: 1px;
  justify-content: center;
  align-items: center;
  min-width: 623px;
  height: 649px;
  margin-left: 143px;
  position: fixed;
  top: 50%;
  left: 50%;
  overflow-x: hidden;
  transform: translate(-50%, -50%);
}

.token-box-mobile .overlap-group45 {
  z-index: 1;
  background-color: #0f0f0f;
  background-size: 100% 100%;
  border: 3px solid #35cbfd;
  border-radius: 18px;
  flex-direction: column;
  align-items: flex-end;
  gap: 29px;
  width: 336px;
  min-height: 649px;
  padding: 21.6px 23px;
  display: flex;
}

.token-box-mobile .flex-row27 {
  flex-flow: row;
  justify-content: flex-end;
  gap: 35px;
  min-width: 357px;
  height: 40px;
  display: flex;
}

.token-box-mobile .select-source-token {
  color: #f9f9f9;
  font-family: var(--font-family-titillium_web);
  font-size: var(--font-size-s);
  letter-spacing: 0;
  align-self: flex-end;
  min-width: 136px;
  min-height: 24px;
  margin-bottom: -28px;
  margin-right: -265px;
  font-weight: 700;
  line-height: normal;
}

.token-box-mobile .x {
  cursor: pointer;
  width: 26px;
  height: 25px;
}

.token-box-mobile .overlap-group205 {
  border: 1px solid #33a4d8;
  border-radius: 7px;
  align-items: flex-start;
  min-width: 306px;
  height: 45px;
  margin-right: -12px;
  padding: 11px 17px;
  display: flex;
}

@media only screen and (width >= 306px) {
  .token-box-mobile .overlap-group205 {
    max-width: 50px;
  }
}

.token-box-mobile .search-token-by-name-or-address {
  color: #fff;
  font-family: var(--font-family-titillium_web);
  font-size: var(--font-size-xs);
  letter-spacing: 0;
  background-color: #0000;
  border: 0;
  width: 306px;
  height: 21px;
  margin-left: 0;
  padding: 0;
  font-weight: 400;
  line-height: normal;
}

.token-box-mobile .search-token-by-name-or-address::placeholder {
  color: #7b7b7b99;
}

.token-box-mobile .overlap-group58 {
  background-color: #212124;
  border-radius: 7px;
  align-self: center;
  align-items: center;
  gap: 27px;
  width: 200px;
  min-width: 307px;
  height: 62px;
  margin-top: -20px;
  margin-left: 1px;
  padding: 10px 1px 15px 10px;
  display: flex;
}

.token-box-mobile .group-299 {
  width: 19px;
  height: 22px;
  margin-top: 4.35px;
}

.token-box-mobile .below-is-the-support {
  align-self: flex-end;
  width: 300px;
  height: 36px;
  margin-bottom: -3px;
  display: flex;
}

.token-box-mobile .below-is-the-support-1 {
  color: #fff;
  font-family: var(--font-family-titillium_web);
  font-size: var(--font-size-m);
  letter-spacing: 0;
  flex: 1;
  width: 280px;
  font-size: 10px;
  font-weight: 400;
  line-height: 18.4px;
}

.token-box-mobile .scroll-group-10::-webkit-scrollbar {
  width: 0;
  display: none;
}

.token-box-mobile .scroll-group-10 {
  z-index: 2;
  flex-direction: column;
  align-items: flex-start;
  gap: 17px;
  width: 307px;
  height: 150px;
  display: flex;
  position: fixed;
  top: 162px;
  left: 15px;
  overflow-y: scroll;
}

.token-box-mobile .scroll-group-10-item {
  cursor: pointer;
  min-width: 570px;
  height: 62px;
  margin-right: -4px;
  position: relative;
}

.token-box-mobile .overlap-group-181 {
  background-color: var(--onyx);
  cursor: pointer;
  border-radius: 7px;
  align-items: center;
  gap: 16px;
  width: 307px;
  height: 62px;
  margin-top: 70px;
  padding: 12px 13px;
  display: flex;
}

.token-box-mobile .overlap-group-181.active {
  background-color: #727278;
  transition: background-color .3s;
}

.token-box-mobile .overlap-group-181:hover {
  background-color: #727278;
}

.token-box-mobile .group {
  width: 38px;
  height: 38px;
}

.token-box-mobile .tether-usd, .token-box-mobile .wrapped-matic {
  letter-spacing: 0;
  width: 196px;
  min-height: 25px;
  margin-top: 1px;
  margin-left: -5px;
  line-height: normal;
}

.token-box-mobile .usdt1 {
  letter-spacing: 0;
  text-align: right;
  width: 159px;
  min-height: 25px;
  margin-bottom: 2.96px;
  margin-left: 97px;
  line-height: normal;
}

.token-box-mobile .overlap-group172 {
  background-color: var(--onyx);
  cursor: pointer;
  border-radius: 7px;
  align-items: center;
  gap: 14px;
  min-width: 280px;
  height: 62px;
  padding: 11px 13px;
  display: flex;
}

.token-box-mobile .xgt, .token-box-mobile .usd {
  letter-spacing: 0;
  width: 60px;
  min-height: 25px;
  margin-bottom: 1.17px;
  margin-left: 15px;
  line-height: normal;
}

.token-box-mobile .xgt-1 {
  letter-spacing: 0;
  text-align: right;
  width: 133px;
  min-height: 25px;
  margin-bottom: .96px;
  margin-left: 123px;
  line-height: normal;
}

.token-box-mobile .overlap-group206 {
  background-color: var(--onyx);
  border-radius: 7px;
  align-items: center;
  min-width: 125px;
  height: 62px;
  padding: 11px 13.9px;
  display: flex;
}

.token-box-mobile .usdc {
  letter-spacing: 0;
  text-align: right;
  width: 127px;
  min-height: 25px;
  margin-bottom: .96px;
  margin-left: 129px;
  line-height: normal;
}

.token-box-mobile .overlap-group399 {
  background-color: var(--onyx);
  border-radius: 7px;
  align-items: center;
  min-width: 125px;
  height: 62px;
  padding: 11.3px 13.4px;
  display: flex;
}

.token-box-mobile .w-matic {
  letter-spacing: 0;
  text-align: right;
  width: 119px;
  min-height: 25px;
  margin-bottom: .96px;
  margin-left: -100px;
  line-height: normal;
}

.token-box-mobile .info-selection {
  color: #f9f9f9;
  font-family: var(--font-family-titillium_web);
  letter-spacing: 0;
  width: 304px;
  margin-top: 76px;
  margin-right: -76px;
  font-size: 9px;
}

.mobile-swap-box {
  min-width: 358px;
  height: 547px;
  margin-left: -9px;
  margin-right: -9px;
  position: relative;
}

.mobile-swap-box .frame-container-1 {
  border: 1px solid;
  border-color: var(--scooter);
  border-radius: 16px;
  flex-direction: column;
  align-items: center;
  width: 338px;
  min-height: 547px;
  padding: 22px 0;
  display: flex;
  position: relative;
  left: 17px;
}

.mobile-swap-box .frame-1-1 {
  align-items: center;
  gap: 14px;
  width: 338px;
  margin-right: 2px;
  padding: 0 0 0 27px;
  display: flex;
  position: relative;
}

.mobile-swap-box .place-1 {
  letter-spacing: 0;
  width: 54px;
  line-height: normal;
  position: relative;
}

.mobile-swap-box .bnb-3 {
  min-width: 239px;
  height: 45px;
  margin-right: 5%;
  position: relative;
}

.mobile-swap-box .overlap-group-61 {
  border: 1px solid;
  border-color: var(--cerulean);
  border-radius: 7px;
  align-items: center;
  min-width: 235px;
  height: 45px;
  margin-left: -9px;
  margin-right: 15px;
  padding: 5.8px 4.4px;
  display: flex;
}

.ramp-widget {
  width: 368px;
  height: 600px;
  margin-top: 5px;
}

@media (width >= 368px) {
  .ramp-widget {
    width: 340px;
  }
}

@media (width >= 1025px) {
  .ramp-widget {
    width: 500px;
  }
}

.mobile-swap-box .path-535-1, .mobile-swap-box .path-535 {
  width: 12px;
  height: 6px;
  margin-top: 1.78px;
  margin-left: 50px;
}

.mobile-swap-box .overlap-group-17 {
  border: 1px solid;
  border-color: var(--cerulean);
  cursor: pointer;
  border-radius: 7px;
  align-items: flex-start;
  gap: 6px;
  min-width: 237px;
  height: 45px;
  padding: 5.8px 10.6px;
  display: flex;
}

.mobile-swap-box .path-5 {
  width: 32px;
  height: 31px;
}

.mobile-swap-box .overlap-group1-7 {
  align-self: center;
  width: 166px;
  height: 21px;
  margin-bottom: 0%;
  position: relative;
}

.mobile-swap-box .path-535-3 {
  width: 12px;
  height: 6px;
  position: absolute;
  top: 8px;
  left: 154px;
}

.mobile-swap-box .bnb-chain-3 {
  letter-spacing: 0;
  width: 154px;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.mobile-swap-box .frame-3409 {
  justify-content: center;
  align-items: center;
  width: 326px;
  margin-top: 19px;
  margin-right: 2px;
  display: flex;
  position: relative;
}

.mobile-swap-box .tb-1, .mobile-swap-box .bb-1 {
  min-width: 330px;
  height: 113px;
  margin-right: -4px;
  position: relative;
}

.mobile-swap-box .flex-row-2 {
  background-color: var(--black-rock);
  border-radius: 12px;
  align-items: center;
  gap: 11px;
  min-width: 326px;
  height: 113px;
  padding: 2px 17px;
  display: flex;
}

.mobile-swap-box .flex-col-2 {
  flex-direction: column;
  align-self: flex-end;
  align-items: flex-start;
  gap: 18px;
  width: 182px;
  min-height: 94px;
  display: flex;
}

.mobile-swap-box .send-1 {
  letter-spacing: 0;
  width: 102px;
  min-height: 19px;
  margin-left: 1.38px;
  line-height: normal;
}

.mobile-swap-box .text-1 {
  letter-spacing: 0;
  white-space: nowrap;
  background-color: #0000;
  border: none;
  width: 182px;
  min-height: 57px;
  margin-bottom: 50px;
  font-size: 28px;
  line-height: normal;
}

.mobile-swap-box .text-1::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.mobile-swap-box .text-1::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.mobile-swap-box .text-1 {
  -moz-appearance: textfield;
}

.mobile-swap-box .flex-col-3 {
  flex-direction: column;
  align-items: flex-start;
  gap: 33px;
  width: 94px;
  min-height: 83px;
  margin-top: -60.63px;
  display: flex;
}

.mobile-swap-box .overlap-group-container {
  align-items: flex-start;
  gap: 5px;
  min-width: 94px;
  margin-top: -15px;
  margin-left: 14.04px;
  display: flex;
}

.mobile-swap-box .overlap-group1-8 {
  background-size: 100% 100%;
  justify-content: flex-end;
  align-items: flex-start;
  min-width: 45px;
  height: 19px;
  padding: 2.1px 11.7px;
  display: flex;
}

.mobile-swap-box .name-1 {
  letter-spacing: 0;
  text-align: center;
  width: 21px;
  min-height: 14px;
  line-height: normal;
}

.mobile-swap-box .overlap-group-18 {
  background-size: 100% 100%;
  justify-content: flex-end;
  align-self: flex-end;
  align-items: flex-start;
  min-width: 45px;
  height: 19px;
  padding: 1.9px 9.9px;
  display: flex;
}

.mobile-swap-box .half-1 {
  letter-spacing: 0;
  text-align: center;
  width: 23px;
  min-height: 14px;
  line-height: normal;
}

.mobile-swap-box .frame-3404 {
  align-items: center;
  min-width: 94px;
  margin-top: 14px;
  margin-left: 36px;
  display: flex;
}

.mobile-swap-box .usdvalue {
  position: absolute;
  top: 113px;
  left: 0;
}

.mobile-swap-box .price-1 {
  letter-spacing: 0;
  width: 43px;
  min-height: 21px;
  margin-bottom: 2.95px;
  margin-left: 6px;
  line-height: normal;
}

.mobile-swap-box .path-530-1 {
  width: 12px;
  height: 7px;
  margin-top: 3.03px;
  margin-left: 1px;
}

.mobile-swap-box .frame-3407 {
  z-index: 9;
  width: 35px;
  height: 34px;
  margin-top: 19px;
  margin-right: 2px;
}

.mobile-swap-box .frame-4 {
  width: 690px;
  height: 34px;
  margin-top: 15px;
  margin-left: .35px;
}

.mobile-swap-box .titilliumweb-normal-hint-of-red-14px {
  color: var(--hint-of-red);
  font-family: var(--font-family-titillium_web);
  font-size: var(--font-size-xxxl);
  margin-left: 3px;
  font-style: normal;
  font-weight: 400;
}

.mobile-swap-box .token {
  letter-spacing: 0;
  width: 41px;
  min-height: 21px;
  margin-top: -158px;
  margin-left: 6px;
  line-height: normal;
}

.mobile-swap-box .frame-2-1 {
  align-items: center;
  gap: 14px;
  width: 338px;
  height: 45px;
  margin-top: 19px;
  margin-right: 2px;
  padding: 0 0 0 27px;
  display: flex;
  position: relative;
}

.mobile-swap-box .to-1 {
  letter-spacing: 0;
  width: 55px;
  line-height: normal;
  position: relative;
}

.mobile-swap-box .walletbalance {
  position: absolute;
  top: 114px;
  right: 15px;
}

.mobile-swap-box .usdt-6 {
  min-width: 238px;
  height: 45px;
  margin-right: 5%;
  position: relative;
}

.mobile-swap-box .path-530 {
  cursor: pointer;
  margin-top: -17px;
  margin-bottom: 53px;
  margin-left: 67px;
}

.mobile-swap-box .overlap-group-19 {
  border: 1px solid;
  border-color: var(--cerulean);
  border-radius: 7px;
  align-items: flex-start;
  gap: 5px;
  min-width: 236px;
  height: 45px;
  padding: 6.8px 11.6px;
  display: flex;
}

.mobile-swap-box .path-529 {
  width: 31px;
  height: 31px;
  margin-top: -20px;
  margin-left: -31px;
  position: absolute;
  top: -143px;
}

.mobile-swap-box .path-538-3 {
  width: 31px;
  height: 31px;
}

.mobile-swap-box .usdt-7 {
  align-self: center;
  width: 166px;
  height: 21px;
  margin-top: 5%;
  position: relative;
}

.mobile-swap-box .frame-3410 {
  justify-content: center;
  align-items: center;
  width: 326px;
  margin-top: 22px;
  margin-right: 2px;
  display: flex;
  position: relative;
}

.mobile-swap-box .overlap-group-20 {
  background-color: var(--black-rock);
  border-radius: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 18px;
  width: 326px;
  min-height: 113px;
  padding: 2px 17px;
  display: flex;
}

.mobile-swap-box .send-2 {
  letter-spacing: 0;
  width: 150px;
  min-height: 19px;
  margin-top: 15px;
  margin-left: 1px;
  line-height: normal;
}

.mobile-swap-box .frame-6-3 {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  gap: 4px;
  width: fit-content;
  margin-top: 24px;
  margin-right: 1px;
  transition: all .2s;
  display: flex;
  position: relative;
}

.mobile-swap-box .frame-6-3:hover {
  transform: scale(1.1);
}

.mobile-swap-box .ab-3 {
  min-width: 289px;
  height: 40.39px;
  margin-right: -2px;
  position: relative;
}

.mobile-swap-box .overlap-group-21 {
  background-size: 100% 100%;
  align-items: flex-start;
  min-width: 287px;
  height: 40px;
  padding: 7px 0;
  display: flex;
}

.mobile-swap-box .approve-3 {
  letter-spacing: 0;
  text-align: center;
  width: 287px;
  min-height: 25px;
  line-height: normal;
}

.mobile-swap-box .frame-3417 {
  justify-content: center;
  min-width: 334px;
  height: 551px;
  display: flex;
  position: relative;
}

.mobile-swap-box .sub-1 {
  flex-direction: column;
  align-items: center;
  gap: 42px;
  width: 318px;
  height: 547px;
  margin-left: -2px;
  display: flex;
  position: relative;
}

.mobile-swap-box .sub-box-1 {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  padding: 20px 0 0;
  display: flex;
  position: relative;
}

.mobile-swap-box .squid {
  width: 15px;
  position: absolute;
  bottom: 7px;
  right: 23px;
}

.mobile-swap-box .powered-by {
  font-family: var(--font-family-titillium_web);
  color: #fff;
  width: 100px;
  padding-right: 10px;
  font-size: 8px;
  font-style: normal;
  position: absolute;
  bottom: 15px;
  right: -18px;
}

.frame-3396-1 {
  background-color: var(--kingfisher-daisy);
  cursor: pointer;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  gap: 7.18px;
  width: 236px;
  height: 35px;
  margin-left: 30px;
  padding: 15px 20px;
  transition: all .2s;
  display: flex;
  position: relative;
}

.frame-3396-1:hover {
  transform: scale(1.1);
}

.submit-1 {
  letter-spacing: 0;
  text-align: center;
  width: 161px;
  height: 26px;
  margin-top: -11.7px;
  margin-bottom: -9.3px;
  line-height: normal;
  position: relative;
}

.frame-3396-1 {
  margin-bottom: unset;
}

@media (width <= 767px) {
  .frame-3396-1 {
    background-color: var(--kingfisher-daisy);
    cursor: pointer;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    gap: 7.18px;
    width: 150px;
    height: 35px;
    margin-top: 100px;
    margin-left: -260px;
    padding: 15px 20px;
    transition: all .2s;
    display: flex;
    position: relative;
  }
}

.frame-3395-2 {
  background-color: var(--white);
  border: 1.2px solid;
  border-color: var(--kingfisher-daisy);
  border-radius: 8px;
  align-items: center;
  width: 309px;
  height: 35px;
  margin-right: 200px;
  padding: 14.37px;
  display: flex;
  position: relative;
}

.eg-bethe1sttoknow-2 {
  letter-spacing: 0;
  opacity: .3;
  white-space: nowrap;
  background-color: #0000;
  border: 0;
  min-width: 330.42px;
  height: 22.75px;
  margin-top: -9.44px;
  margin-bottom: -7.04px;
  margin-right: -50.15px;
  padding: 0;
  line-height: normal;
  position: relative;
}

.eg-bethe1sttoknow-2::placeholder {
  color: #2a2a2a99;
}

@media (width <= 767px) {
  .frame-3395-2 {
    background-color: var(--white);
    border: 1.2px solid;
    border-color: var(--kingfisher-daisy);
    border-radius: 8px;
    align-items: center;
    width: 220px;
    height: 35px;
    margin-left: 150px;
    padding: 14.37px;
    display: flex;
    position: relative;
  }

  .eg-bethe1sttoknow-2 {
    letter-spacing: 0;
    opacity: .3;
    white-space: nowrap;
    background-color: #0000;
    border: 0;
    min-width: 330.42px;
    height: 22.75px;
    margin-top: -9.44px;
    margin-bottom: -7.04px;
    margin-right: -50.15px;
    padding: 0;
    line-height: normal;
    position: relative;
  }
}

.telegram {
  cursor: pointer;
  align-items: center;
  min-width: 128px;
  height: 126px;
  transition: all .2s;
  display: flex;
}

.telegram:hover {
  transform: scale(1.1);
}

.overlap-group-22 {
  background-size: 100% 100%;
  align-items: flex-end;
  min-width: 126px;
  height: 126px;
  padding: 6.1px 5.3px;
  display: flex;
}

.join-the-xion-community-1 {
  letter-spacing: 0;
  text-align: center;
  width: 114px;
  min-height: 30px;
  font-size: 8px;
  line-height: normal;
}

.telegram.telegram-1, .telegram.telegram-2 {
  align-items: unset;
  display: unset;
  min-width: 127.89px;
  height: 125.52px;
  position: relative;
}

.medium {
  cursor: pointer;
  align-items: center;
  min-width: 128px;
  height: 126px;
  transition: all .2s;
  display: flex;
}

.medium:hover, .medium.social-box-item-2:hover, .medium.social-box-item-3:hover {
  transform: scale(1.1);
}

.overlap-group2 {
  background-size: 100% 100%;
  align-items: flex-end;
  min-width: 126px;
  height: 126px;
  padding: 7.5px 8.4px;
  display: flex;
}

.follow-xionfinance-for-3 {
  letter-spacing: 0;
  text-align: center;
  width: 105px;
  min-height: 28px;
  font-size: 8px;
  line-height: normal;
}

.medium.discord .overlap-group2, .medium.discord-1 .overlap-group2, .medium.discord-2 .overlap-group2 {
  justify-content: flex-end;
  padding: 6.1px 7.5px;
}

.medium.discord .follow-xionfinance-for-3, .medium.discord-1 .follow-xionfinance-for-3, .medium.discord-2 .follow-xionfinance-for-3 {
  width: 110px;
  min-height: 29px;
}

.medium.discord-1, .medium.discord-2, .medium.social-box-item-2, .medium.social-box-item-2-1, .medium.social-box-item-3, .medium.social-box-item-3-1 {
  align-items: unset;
  display: unset;
  min-width: 127.89px;
  height: 125.52px;
  position: relative;
}

.medium.social-box-item-2-1 .overlap-group2, .medium.social-box-item-3-1 .overlap-group2 {
  justify-content: flex-end;
  padding: 7.1px 6.9px;
}

.medium.social-box-item-2-1 .follow-xionfinance-for-3, .medium.social-box-item-3-1 .follow-xionfinance-for-3 {
  width: 112px;
}

.medium.social-box-item-2 .overlap-group2, .medium.social-box-item-3 .overlap-group2 {
  justify-content: flex-end;
  padding: 6.1px 5.8px;
}

.medium.social-box-item-2 .follow-xionfinance-for-3, .medium.social-box-item-3 .follow-xionfinance-for-3 {
  width: 113px;
  min-height: 29px;
}

.mobile {
  background-position: initial;
  background-size: cover;
  border: 1px;
  flex-direction: column;
  align-items: center;
  gap: 28px;
  min-width: 358px;
  padding: 68px 8px 120px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.mobile .alphalaunchbanner {
  margin-top: -27px;
  margin-bottom: -68px;
  position: relative;
}

.mobile .alphaimg {
  position: relative;
}

.mobile .frame-3420 {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 75px;
  padding: 0 0 0 2px;
  display: flex;
  position: relative;
}

.mobile .nav-1 {
  justify-content: space-between;
  align-self: center;
  align-items: center;
  min-width: 340px;
  height: 46px;
  padding: 10px 20px;
  display: flex;
  position: relative;
}

.mobile .logo-1 {
  width: 123px;
  height: 32px;
  margin-left: 20px;
}

.mobile .my-custom-wrapper {
  border-radius: 12px;
  margin-right: 110px;
  display: inline-block;
  overflow: hidden;
}

.mobile .my-custom-class {
  cursor: pointer;
  font-weight: bold;
  position: relative;
  font-family: var(--font-family-bebas_neue_pro-regular) !important;
}

.mobile .img-wrapper {
  --webkit-backdrop-filter: blur(10px) brightness(100%);
  backdrop-filter: blur(10px) brightness();
  border-radius: 10px;
  width: 44px;
  height: 44px;
  position: absolute;
  right: 65px;
}

.mobile .group-2 {
  z-index: 9;
  cursor: pointer;
  width: 44px;
  height: 44px;
}

.mobile .overlap-b-group.open .burger-menu-container {
  z-index: 9;
  background-color: #d9d9d9;
  border-radius: 1px 1px 10px 10px;
  width: 250px;
  height: 410px;
  position: absolute;
  top: -5px;
  right: 70px;
}

.mobile .overlap-b-group.open .burger-2 {
  backdrop-filter: blur(10px) brightness();
  z-index: 9;
  border-radius: 10px;
  width: 33px;
  height: 33px;
  position: absolute;
  top: 16px;
  left: 233px;
}

.mobile .frame-3416 {
  justify-content: center;
  align-self: stretch;
  min-width: 340px;
  height: 323px;
  display: flex;
  position: relative;
}

.mobile .heromenu-box-1 {
  flex-direction: column;
  align-items: center;
  gap: 55px;
  width: 312px;
  height: 300px;
  margin-left: -4px;
  display: flex;
  position: relative;
}

.mobile .hero-box-1 {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 58px;
  height: 205px;
  padding: 20px 0;
  display: flex;
  position: relative;
}

.mobile .frame-3413-1 {
  align-self: stretch;
  min-width: 312px;
  height: 185px;
  margin-bottom: -20px;
  position: relative;
}

.mobile .overlap-group-15 {
  width: 338px;
  height: 183px;
  position: absolute;
  top: -1px;
  left: -12px;
}

.mobile .title-1 {
  color: var(--ceramic);
  font-family: var(--font-family-bebas_neue_pro-regular);
  letter-spacing: 0;
  text-align: center;
  width: 338px;
  margin-bottom: 15px;
  font-size: 69px;
  font-weight: 400;
  line-height: 61.4px;
  position: absolute;
  top: -15px;
  left: 0;
}

.mobile .fees-so-low-it-will-1 {
  color: var(--ceramic);
  font-family: var(--font-family-bebas_neue_pro-light);
  letter-spacing: 0;
  text-align: center;
  margin-bottom: 5px;
  font-size: 35px;
  font-weight: 300;
  line-height: 31.5px;
  position: absolute;
  top: 121px;
  left: 28px;
}

.mobile .vector-1-1 {
  width: 182px;
  height: 2px;
  position: absolute;
  top: 194px;
  left: 50%;
  transform: translateX(-50%);
}

.mobile .frame-3417 {
  justify-content: center;
  min-width: 334px;
  height: 551px;
  display: flex;
  position: relative;
}

.mobile .sub-1 {
  flex-direction: column;
  align-items: center;
  gap: 42px;
  width: 318px;
  height: 547px;
  margin-left: -2px;
  display: flex;
  position: relative;
}

.mobile .sub-box-1 {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  padding: 55px 0 0;
  display: flex;
  position: relative;
}

.mobile .join-a-global-community-1 {
  color: var(--ceramic);
  font-family: var(--font-family-oswald);
  letter-spacing: 0;
  text-align: center;
  align-self: stretch;
  height: 27px;
  margin-top: -1px;
  font-size: 19px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.mobile .line-33-1 {
  min-width: 148px;
  height: 1.44px;
  position: relative;
}

.mobile .learn-more-about-xio-1 {
  color: var(--hint-of-red);
  font-family: var(--font-family-titillium_web);
  font-size: var(--font-size-xl);
  letter-spacing: 0;
  text-align: center;
  align-self: stretch;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.mobile .email-box {
  z-index: 9;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  width: 310px;
  height: 76px;
  margin-top: 50px;
  margin-left: 50px;
  display: flex;
  position: relative;
  bottom: 75px;
}

.mobile .social-box-1 {
  min-width: 293px;
  height: 284px;
  position: relative;
}

.mobile .flex-col-4 {
  flex-direction: column;
  align-items: flex-start;
  gap: 28px;
  width: 128px;
  min-height: 279px;
  display: flex;
  position: absolute;
  top: 2px;
  left: 4px;
}

.mobile .flex-col-5 {
  flex-direction: column;
  align-items: flex-start;
  gap: 28px;
  width: 128px;
  min-height: 279px;
  display: flex;
  position: absolute;
  top: 2px;
  left: 163px;
}

.mobile .overlap-group3 {
  cursor: pointer;
  background-size: 100% 100%;
  justify-content: flex-end;
  align-items: flex-end;
  min-width: 126px;
  height: 126px;
  margin-left: 0;
  padding: 7.5px 8.4px;
  transition: all .2s;
  display: flex;
}

.mobile .overlap-group3:hover {
  transform: scale(1.1);
}

.mobile .follow-xionfinance-for-2 {
  letter-spacing: 0;
  text-align: center;
  width: 105px;
  min-height: 0;
  font-size: 8px;
  line-height: normal;
}

.mobile .footer-1 {
  background-color: #0000;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 20.4px;
  min-width: 334px;
  height: 87px;
  display: flex;
  position: relative;
  top: 50px;
}

.mobile .line-34-1 {
  width: 337px;
  height: 1px;
  margin-top: 21.6px;
}

.mobile .frame-3399-1 {
  justify-content: center;
  align-items: flex-start;
  gap: 140px;
  width: 329px;
  height: 23px;
  margin-left: -2px;
  display: flex;
  position: relative;
}

.mobile .copyright-1 {
  color: #f9f9f9;
  letter-spacing: 0;
  width: 105px;
  height: 16px;
  margin-top: -1px;
  margin-left: -4.5px;
  font-family: Bebas Neue Pro-Light, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.mobile .about-us-blog-t-cs-privacy-policy-1 {
  color: #fff;
  font-family: var(--font-family-titillium_web);
  letter-spacing: 0;
  text-align: right;
  width: 93px;
  height: 16px;
  margin-top: -1px;
  margin-right: -4.5px;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.mobile .frame-3396-1 {
  background-color: var(--kingfisher-daisy) !important;
  cursor: pointer !important;
  border-radius: 8px !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 7.18px !important;
  width: 150px !important;
  height: 35px !important;
  margin-top: 100px !important;
  margin-left: -260px !important;
  padding: 15px 20px !important;
  transition: all .2s !important;
  display: flex !important;
  position: relative !important;
}

.mobile .frame-3395-2 {
  background-color: var(--white);
  border: 1.2px solid;
  border-color: var(--kingfisher-daisy);
  border-radius: 8px;
  align-items: center;
  width: 220px;
  height: 35px;
  margin-left: 150px;
  padding: 14.37px;
  display: flex;
  position: relative;
}

.mobile .menu-box-3 {
  border: 1px solid;
  border-color: var(--cerulean);
  border-radius: 7px;
  justify-content: center;
  min-width: 314px;
  height: 34px;
  display: flex;
  position: relative;
  top: 50px;
}

.mobile .eg-bethe1sttoknow-2 {
  letter-spacing: 0;
  opacity: .3;
  white-space: nowrap;
  background-color: #0000;
  border: 0;
  min-width: 330.42px;
  height: 22.75px;
  margin-top: -9.44px;
  margin-bottom: -7.04px;
  margin-right: -50.15px;
  padding: 0;
  line-height: normal;
  position: relative;
}

.mobile .frame-34 {
  flex: none;
  justify-content: space-around;
  align-items: flex-start;
  gap: 55px;
  width: 205px;
  display: flex;
  position: relative;
}

.mobile .b-2 {
  align-self: stretch;
  width: 306px;
  position: relative;
}

.mobile .XGT-MARKET-4 {
  color: #35cbfd;
  letter-spacing: 0;
  text-align: center;
  width: 210px;
  font-family: Bebas Neue Pro-Regular, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 23.1px;
  position: absolute;
  top: 30px;
}

.mobile .text-wrapper-62 {
  color: #35cbfd;
  letter-spacing: 0;
  font-family: Bebas Neue Pro-Regular, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 23.1px;
}

.mobile .text-wrapper-63 {
  font-family: Bebas Neue Pro-Light, Helvetica;
  font-weight: 300;
}

.mobile .terms-link, .mobile .privacy-link {
  color: #f9f9f9;
  cursor: pointer;
  min-width: 125px;
  margin-top: 3px;
  margin-left: -30px;
  font-family: Bebas Neue Pro-Light, Helvetica;
  font-size: 12px;
  transition: color .3s;
}

.mobile .terms-link:hover, .mobile .privacy-link:hover {
  color: #35cbfd;
}

.mobile .feebox-mobile {
  margin-top: 10px;
  position: relative;
}

.mobile .fee-box-d-expanded {
  z-index: 9;
  background-color: #0000;
  flex-direction: row;
  justify-content: center;
  width: 338px;
  margin-top: 60px;
  margin-bottom: -200px;
  display: flex;
}

.mobile .fee-box-d-expanded .path {
  cursor: pointer;
  width: 17px;
  height: 11px;
  position: absolute;
  top: 29px;
  left: 287px;
}

.mobile .fee-box-d-expanded .overlap-group {
  width: 621px;
  height: 20px;
  position: absolute;
  top: 75px;
  left: 27px;
}

.mobile .fee-box-d-minimised {
  z-index: 9;
  background-color: #0000;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-top: 60px;
  margin-bottom: -390px;
  display: flex;
}

.mobile .fee-box-d-minimised .overlap-group-wrapper {
  width: 338px;
  height: 245px;
}

.mobile .fee-box-d-minimised .overlap-group {
  border: 1px solid #35cbfd;
  border-radius: 16px;
  width: 338px;
  height: 63px;
  margin-left: -2px;
  position: relative;
  top: -128px;
}

.mobile .fee-box-d-minimised .path {
  width: 17px;
  height: 11px;
  position: absolute;
  top: 29px;
  left: 287px;
}

.mobile .fee-box-d-minimised .minimize {
  color: #f9f9f9;
  letter-spacing: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 21px;
  left: 25px;
}

.mobile .fee-box-d-expanded .div {
  border: 1px solid #35cbfd;
  border-radius: 22px;
  width: 338px;
  height: 245px;
  position: relative;
  top: -200px;
  overflow-x: hidden;
}

.mobile .fee-box-d-expanded .text-wrapper {
  color: #f9f9f9;
  letter-spacing: 0;
  text-align: right;
  width: 311px;
  font-family: Titillium Web, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 0;
  left: -25px;
}

.mobile .fee-box-d-expanded .line {
  width: 287px;
  height: 2px;
  position: absolute;
  top: 60px;
  left: 25px;
}

.mobile .fee-box-d-expanded .divbox {
  border: 1px solid #35cbfd;
  border-radius: 22px;
  width: 675px;
  height: 300px;
  position: relative;
  top: -125px;
  left: -2px;
}

.mobile .fee-box-d-expanded .information {
  color: #f9f9f9;
  width: 300px;
  height: 20px;
  margin-top: -200px;
  margin-left: 0;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 400;
}

.mobile .titilliumweb-normal-ceramic-9-5px {
  color: var(--ceramic);
  font-family: var(--font-family-titillium_web);
  margin-top: -22px;
  margin-left: 19px;
  font-size: 9.5px;
  font-style: normal;
  font-weight: 400;
}

.mobile .titilliumweb-bold-desert-storm-37-6px {
  color: var(--desert-storm);
  font-family: var(--font-family-titillium_web);
  margin-top: -6px;
  font-size: 25.6px;
  font-style: normal;
  font-weight: 700;
}

.mobile .fee-box-d-expanded .info-popup {
  z-index: 157;
  background-size: 100% 100%;
  width: 263px;
  height: 145px;
  margin-top: -201px;
  margin-left: 14px;
  position: relative;
}

.mobile .fee-box-d-expanded .info-text {
  color: #f9f9f9;
  width: 238px;
  margin-left: 7px;
  padding-top: 10px;
  padding-left: 11px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 400;
}

.mobile .frame-3403 {
  align-items: center;
  height: 20px;
  position: relative;
  top: 123px;
}

.mobile .fdpopup .div {
  background-image: url("rectangle-2921.03b61ec1.png");
  background-size: 100% 100%;
  width: 311px;
  height: 311px;
  margin-top: -325px;
  margin-left: -2px;
  position: absolute;
}

.mobile .fdpopup .close {
  cursor: pointer;
  z-index: 99;
  width: 14px;
  height: 13px;
  position: absolute;
  top: 238px;
  left: 313px;
}

.mobile .sfpopup .div, .mobile .pspopup .div {
  background-image: url("rectangle-2921.03b61ec1.png");
  background-size: 100% 100%;
  width: 311px;
  height: 311px;
  margin-top: -325px;
  margin-left: -2px;
  position: absolute;
}

.mobile .menu-box-3 {
  border: 1px solid;
  border-color: var(--cerulean);
  border-radius: 7px;
  justify-content: center;
  min-width: 314px;
  height: 34px;
  display: flex;
  position: relative;
}

.mobile .frame-16 {
  justify-content: center;
  align-items: flex-start;
  gap: 5px;
  width: 322px;
  height: 30px;
  margin-top: 1px;
  margin-left: -2px;
  display: flex;
  position: relative;
}

.mobile .m-sb-line {
  background-color: var(--alto);
  border-radius: 7px;
  width: 102px;
  height: 28px;
  position: absolute;
  inset: 1px 2px 2px 1px;
}

.mobile .group-259-3 {
  min-width: 100.75px;
  height: 30px;
  position: relative;
}

.mobile .overlap-group-16 {
  height: 28x;
  width: 104px;
  position: absolute;
}

.mobile .swapbridge-3 {
  letter-spacing: -.19px;
  opacity: .4;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  width: 88px;
  line-height: normal;
  position: absolute;
  top: 3px;
  left: 10px;
  transform: rotate(-.14deg);
}

.mobile .group-260-3 {
  min-width: 99.84px;
  height: 30px;
  display: flex;
  position: relative;
}

.mobile .buy-3 {
  letter-spacing: -.19px;
  opacity: .4;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  width: 88px;
  line-height: normal;
  position: absolute;
  top: 3px;
  left: 10px;
  transform: rotate(-.14deg);
}

.mobile .group-261-3 {
  min-width: 104px;
  height: 30px;
  position: relative;
}

.mobile .withdraw-3 {
  letter-spacing: -.19px;
  opacity: .4;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  width: 88px;
  line-height: normal;
  position: absolute;
  top: 3px;
  left: 10px;
  transform: rotate(-.14deg);
}

.mobile .menu-box.menu-box-3 {
  margin-bottom: -2px;
}

.mobile .active {
  opacity: 1;
}

.mobile .inactive {
  opacity: .5;
}

.swap-box-9 {
  border-radius: 25px;
  min-width: 368px;
  height: 600px;
  margin-top: 5px;
  margin-bottom: 0;
  position: relative;
  overflow-y: auto;
}

.frame-container-9 {
  border-radius: 22px;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  padding: 1px;
  display: flex;
  position: relative;
}

.frame-container-9 .wert-internal-class {
  border: none;
  outline: none;
}

.mobile-footer-section {
  width: 338px;
  height: 208px;
  margin-top: 50px;
  margin-bottom: 25px;
}

.mobile-footer-section .frame {
  justify-content: space-between;
  align-items: flex-start;
  width: 380px;
  display: flex;
  position: relative;
  top: 31px;
}

.mobile-footer-section .pinkline {
  width: 338px;
  height: 1px;
  margin-top: -.5px;
}

.mobile-footer-section .sections {
  flex: none;
  align-items: flex-start;
  gap: 10px;
  display: inline-flex;
  position: relative;
  right: 60px;
}

.mobile-footer-section .div-12 {
  width: 211px;
  height: 27px;
  position: absolute;
  top: 220px;
  left: 2px;
}

.mobile-footer-section .LOGO-instance {
  width: 32px !important;
  height: 32px !important;
}

.mobile-footer-section .group {
  width: 210.75px;
  height: 150.35px;
  position: relative;
}

.mobile-footer-section .text-wrapper-29 {
  color: #fff;
  letter-spacing: 0;
  width: 67px;
  font-family: Bebas Neue Pro-Bold, Helvetica;
  font-size: 14px;
  font-weight: 700;
  line-height: 12.5px;
  position: absolute;
  top: 7px;
  left: 32px;
}

.mobile-footer-section .overlap-group-wrapper-65 {
  cursor: pointer;
  width: 112px;
  height: 27px;
  position: absolute;
  top: 0;
  left: 99px;
}

.mobile-footer-section .overlap-group-65 {
  width: 110px;
  height: 27px;
  position: relative;
}

.mobile-footer-section .rectangle-130 {
  background-color: #3f89d5;
  border-radius: 27px;
  width: 110px;
  height: 26px;
  position: absolute;
  top: 1px;
  left: 0;
}

.mobile-footer-section .text-wrapper-2 {
  color: #fff;
  letter-spacing: .7px;
  text-align: center;
  width: 77px;
  height: 26px;
  font-family: Bebas Neue Pro-Bold, Helvetica;
  font-size: 18px;
  font-weight: 700;
  line-height: 12.5px;
  display: inline-flex;
  position: absolute;
  top: 6px;
  left: 15px;
}

.mobile-footer-section .next-next-arrow {
  width: 12px;
  height: 8px;
  position: absolute;
  top: 11px;
  left: 88px;
}

.mobile-footer-section .imgfooter {
  width: 26px;
  height: 26px;
  position: absolute;
  top: 0;
  left: 0;
}

.mobile-footer-section .element-mercx-group-LTD {
  color: #6300ff;
  letter-spacing: 0;
  width: 107px;
  font-family: Titillium Web, Helvetica;
  font-size: 10.8px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 30px;
}

.mobile-footer-section .div-2 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 15px;
  display: inline-flex;
  position: relative;
}

.mobile-footer-section .text-wrapper-32 {
  color: #f0f;
  letter-spacing: 0;
  width: fit-content;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.mobile-footer-section .text-wrapper-46 {
  color: #e8e8e8;
  letter-spacing: 0;
  cursor: pointer;
  width: fit-content;
  font-family: Titillium Web, Helvetica;
  font-size: 8px;
  font-weight: 400;
  line-height: normal;
  display: inline-flex;
  position: relative;
}

.button {
  cursor: pointer;
  color: #14a5ed;
  text-align: center;
  background: none;
  border: 1px solid;
  border-radius: 10px;
  width: 150px;
  height: 35px;
  margin-left: 10px;
  font-family: Opinion Pro Extended-Light, Helvetica;
  font-size: 12px;
  font-weight: 300;
  line-height: normal;
  position: relative;
}

.button:hover {
  color: green;
  border-color: green;
}

@media (width <= 768px) {
  .button {
    width: 125px;
    font-size: 12px;
  }
}

@media (width <= 480px) {
  .button {
    width: 100px;
    font-size: 10px;
  }
}

.declineButton {
  cursor: pointer;
  color: #14a5ed;
  text-align: center;
  background: none;
  border: 1px solid;
  border-radius: 10px;
  width: 150px;
  height: 35px;
  font-family: Opinion Pro Extended-Light, Helvetica;
  font-size: 12px;
  font-weight: 300;
  line-height: normal;
  position: relative;
}

.declineButton:hover {
  color: red;
  border-color: red;
}

@media (width <= 768px) {
  .declineButton {
    width: 125px;
    font-size: 12px;
  }
}

@media (width <= 480px) {
  .declineButton {
    width: 100px;
    font-size: 10px;
  }
}

.ipad-3 {
  background-position: initial;
  background-size: cover;
  border: 1px;
  flex-direction: column;
  align-items: center;
  gap: 80px;
  min-width: 768px;
  padding: 75px 45px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.ipad-3 .overlay {
  z-index: 98;
  background: #00000080;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.ipad-3 .open-widget-button, .ipad-3 .close-widget-button {
  color: #fff;
  text-align: center;
  cursor: pointer;
  z-index: 101;
  background-color: #0000;
  border: 1px solid #f0f;
  border-radius: 10px;
  width: 125px;
  height: 44px;
  font-family: Oswald, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 41px;
  transition: background-color .3s, transform .3s;
  position: absolute;
  top: -1175px;
  right: 270px;
}

.ipad-3 .open-widget-button:hover, .ipad-3 .close-widget-button:hover {
  background-color: #f0f;
  transform: scale(1.05);
}

.ipad-3 .widget-container {
  z-index: 99;
  width: 400px;
  position: absolute;
  top: -1250px;
  left: 122px;
}

.ipad-3 .alphalaunchbanner {
  margin-top: -20px;
  margin-bottom: -99px;
  position: relative;
}

.ipad-3 .alphaimg {
  width: 650px;
  position: relative;
}

.ipad-3 .frame-3416-2 {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 75px;
  display: flex;
  position: relative;
}

.ipad-3 .send {
  letter-spacing: 0;
  align-self: flex-end;
  width: 232px;
  min-height: 6px;
  margin-left: -4px;
  line-height: normal;
}

.ipad-3 .overlap-group1 {
  justify-content: space-between;
  min-width: 350px;
  height: 19px;
  margin-bottom: -85px;
  padding: 2.5px 11.2px;
  display: flex;
}

.ipad-3 .usdvalue {
  letter-spacing: 0;
  width: 20px;
  min-height: 14px;
  margin-left: -244px;
  line-height: normal;
}

.ipad-3 .usdvaluerecieve {
  letter-spacing: 0;
  width: 233px;
  margin-top: -25px;
  margin-left: 20px;
  line-height: normal;
}

.ipad-3 .nav-3 {
  justify-content: space-between;
  align-self: center;
  align-items: center;
  min-width: 688px;
  height: 46px;
  padding: 10px 105px;
  display: flex;
  position: relative;
}

.ipad-3 .ramp-widget {
  width: 781px;
  height: 541px;
  margin-top: 80px;
  margin-bottom: -200px;
}

@media (width >= 768px) {
  .ramp-widget {
    width: 400px;
  }
}

@media (width >= 1025px) {
  .ramp-widget {
    width: 500px;
  }
}

.ipad-3 .logo-3 {
  width: 178px;
  height: 33px;
  margin-top: -1px;
  margin-left: -80px;
}

.ipad-3 .my-custom-wrapper {
  border-radius: 12px;
  display: inline-block;
  overflow: hidden;
}

.ipad-3 .my-custom-class {
  cursor: pointer;
  font-weight: bold;
  position: relative;
  font-family: var(--font-family-bebas_neue_pro-regular) !important;
}

.ipad-3 .img-wrapper {
  --webkit-backdrop-filter: blur(10px) brightness(100%);
  backdrop-filter: blur(10px) brightness();
  cursor: pointer;
  border-radius: 10px;
  width: 44px;
  height: 44px;
  position: absolute;
  right: 35px;
}

.ipad-3 .overlap-b-group.open .burger-menu-container {
  z-index: 9;
  background-color: #d9d9d9;
  border-radius: 1px 1px 10px 10px;
  width: 250px;
  height: 410px;
  position: absolute;
  top: -5px;
  right: 0;
}

.ipad-3 .overlap-b-group.open .burger-2 {
  backdrop-filter: blur(10px) brightness();
  z-index: 9;
  border-radius: 10px;
  width: 33px;
  height: 33px;
  position: absolute;
  top: 16px;
  left: 233px;
}

.ipad-3 .frame-3413-4 {
  min-width: 677px;
  height: 342px;
  display: flex;
  position: relative;
  top: 10px;
}

.ipad-3 .flex-row-4 {
  align-items: center;
  gap: 205px;
  min-width: 559px;
  height: 57px;
  display: flex;
}

.ipad-3 .swap-box-2 {
  min-width: 690.69px;
  height: 535.6px;
  position: relative;
  top: 50px;
}

.ipad-3 .swap-box-2 .frame-container {
  border: 3px solid;
  border-color: var(--scooter);
  border-radius: 22px;
  flex-direction: column;
  align-items: center;
  gap: 26px;
  width: 768px;
  min-height: 636px;
  padding: 47.4px 58.7px;
  display: flex;
  position: relative;
  top: 50px;
}

.ipad-3 .buy {
  letter-spacing: 0;
  text-align: center;
  cursor: pointer;
  flex: 1;
  width: 41px;
  margin-left: 41.5%;
  margin-right: 38%;
  line-height: normal;
}

.ipad-3 .swap-box-4 {
  border-radius: 25px;
  min-width: 787px;
  height: 547px;
  margin-top: 75px;
  margin-bottom: -210px;
  position: relative;
  overflow-y: auto;
}

.ipad-3 .heromenu-box-3 {
  flex-direction: column;
  align-items: center;
  gap: 55px;
  width: 677px;
  height: 300px;
  margin-left: .5px;
  display: flex;
  position: relative;
}

.ipad-3 .hero-box-3 {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 58px;
  height: 205px;
  padding: 20px 0;
  display: flex;
  position: relative;
}

.ipad-3 .frame-3413-5 {
  min-width: 676px;
  height: 185px;
  margin-bottom: -20px;
  position: relative;
}

.ipad-3 .menu-box {
  border: 1px solid;
  border-color: var(--cerulean);
  border-radius: 9px;
  justify-content: center;
  min-width: 614px;
  height: 43px;
  display: flex;
  position: relative;
  top: 120px;
}

.flex-col-9 {
  flex-direction: column;
  align-items: center;
  width: 676px;
  min-height: 158px;
  display: flex;
  position: relative;
}

.ipad-3 .frame-container-2 {
  border: 2px solid;
  border-color: var(--scooter);
  border-radius: 22px;
  flex-direction: column;
  align-items: flex-start;
  width: 690px;
  min-height: 636px;
  padding: 48px 44px;
  display: flex;
  position: relative;
  top: 110px;
}

.title-3 {
  color: var(--ceramic);
  font-family: var(--font-family-bebas_neue_pro-regular);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 676px;
  min-height: 83px;
  margin-bottom: 15px;
  font-size: 96px;
  font-weight: 400;
  line-height: 85.4px;
}

.fees-so-low-it-will-3 {
  color: var(--ceramic);
  font-family: var(--font-family-bebas_neue_pro-light);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 675px;
  min-height: 43px;
  margin-top: 1px;
  margin-right: 1px;
  font-size: 46px;
  font-weight: 300;
  line-height: 41.4px;
}

.vector-1-3 {
  width: 281px;
  height: 3px;
  margin-top: 28px;
}

.frame-3415-1 {
  justify-content: center;
  align-self: stretch;
  min-width: 688px;
  height: 415.52px;
  display: flex;
  position: relative;
  top: 0;
}

.sub-3 {
  flex-direction: column;
  align-items: center;
  gap: 56px;
  width: 686px;
  height: 415.52px;
  padding: 21px 0 0;
  display: flex;
  position: relative;
}

.sub-box-3 {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 13px;
  padding: 20px 0 0;
  display: flex;
  position: relative;
  top: 160px;
}

.join-a-global-community-3 {
  letter-spacing: 0;
  text-align: center;
  align-self: stretch;
  height: 53px;
  margin-top: -1px;
  line-height: normal;
  position: relative;
}

.line-33-3 {
  min-width: 173.83px;
  height: 1.44px;
  margin-right: 1px;
  position: relative;
}

.learn-more-about-xio-3 {
  letter-spacing: 0;
  text-align: center;
  align-self: stretch;
  line-height: normal;
  position: relative;
}

.email-box-2 {
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 15px;
  display: flex;
  position: relative;
  top: 160px;
}

.social-box-3 {
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 33px;
  display: flex;
  position: relative;
  top: 175px;
}

.footer-3 {
  background-color: #0000;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 30.4px;
  min-width: 763px;
  height: 54px;
  margin-left: -37.5px;
  margin-right: -37.5px;
  display: flex;
  position: relative;
  top: 180px;
}

.line-34-3 {
  width: 690px;
  height: 1px;
  margin-top: -.5px;
}

.ipad-3 .text-1 {
  letter-spacing: 0;
  white-space: nowrap;
  background-color: #0000;
  border: none;
  width: 232px;
  min-height: 57px;
  margin-bottom: 50px;
  line-height: normal;
}

.ipad-3 .text-1::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.ipad-3 .text-1::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.ipad-3 .text-1 {
  -moz-appearance: textfield;
}

.ipad-3 .text-2 {
  letter-spacing: 0;
  width: 300px;
  min-height: 57px;
  margin-top: -20px;
  line-height: normal;
}

.ipad-3 .walletbalance {
  letter-spacing: 0;
  text-align: center;
  min-height: 14px;
  margin-right: -70px;
  line-height: normal;
}

.frame-3399-3 {
  justify-content: center;
  align-items: flex-start;
  gap: 482px;
  width: 685px;
  height: 23px;
  margin-left: 1px;
  display: flex;
  position: relative;
}

.ipad-3 .frame-3403 {
  cursor: pointer;
  justify-content: center;
  align-items: flex-end;
  min-width: 91px;
  margin-bottom: 130.06px;
  display: flex;
}

.ipad-3 .copyright-3 {
  color: #fff;
  letter-spacing: 0;
  width: 107px;
  margin-top: -1px;
  font-family: Bebas Neue Pro-Light, Helvetica;
  font-size: 14px;
  line-height: normal;
  position: relative;
}

.ipad-3 .about-us-blog-t-cs-privacy-policy-3 {
  letter-spacing: 0;
  color: #fff;
  text-align: right;
  width: 96px;
  margin-top: -1px;
  font-size: 12px;
  line-height: normal;
  position: relative;
  right: 3px;
}

.ipad-3 .overlap-group1-9 {
  background-image: url("path-532-2@2x.24bd8fb9.png");
  background-size: 100% 100%;
  justify-content: flex-end;
  align-items: flex-start;
  min-width: 46px;
  height: 19px;
  margin-bottom: .22px;
  margin-left: 212px;
  padding: 2px 12px;
  display: flex;
}

.ipad-3 .overlap-group-25 {
  background-image: url("path-531-2@2x.efeaacf0.png");
  background-size: 100% 100%;
  justify-content: flex-end;
  align-items: flex-start;
  min-width: 46px;
  height: 19px;
  margin-left: 5px;
  padding: 2px 10.2px;
  display: flex;
}

.ipad-3 .overlap-group-12 {
  background-size: 100% 100%;
  align-items: flex-start;
  min-width: 334px;
  height: 47px;
  padding: 13px 0;
  display: flex;
}

.ipad-3 .frame-34 {
  flex: none;
  justify-content: space-around;
  align-items: flex-start;
  gap: 55px;
  width: 1034px;
  display: flex;
  position: relative;
}

.ipad-3 .b-2 {
  align-self: stretch;
  width: 1033px;
  position: relative;
}

.ipad-3 .XGT-MARKET-4 {
  color: #35cbfd;
  letter-spacing: 0;
  text-align: center;
  width: 1025px;
  font-family: Bebas Neue Pro-Regular, Helvetica;
  font-size: 26px;
  font-weight: 400;
  line-height: 23.1px;
  position: absolute;
  top: 30px;
  left: 8px;
}

.ipad-3 .text-wrapper-62 {
  color: #35cbfd;
  letter-spacing: 0;
  font-family: Bebas Neue Pro-Regular, Helvetica;
  font-size: 26px;
  font-weight: 400;
  line-height: 23.1px;
}

.ipad-3 .text-wrapper-63 {
  font-family: Bebas Neue Pro-Light, Helvetica;
  font-weight: 300;
}

.ipad-3 .terms-link, .ipad-3 .privacy-link {
  color: #f9f9f9;
  cursor: pointer;
  min-width: 125px;
  margin-top: 3px;
  margin-left: -30px;
  font-family: Bebas Neue Pro-Light, Helvetica;
  font-size: 12px;
  transition: color .3s;
}

.ipad-3 .terms-link:hover, .ipad-3 .privacy-link:hover {
  color: #35cbfd;
}

.ipad-3 .feebox-ipad {
  margin-top: 10px;
  position: relative;
}

.ipad-3 .fee-box-d-expanded {
  z-index: 9;
  background-color: #0000;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-top: 97px;
  margin-bottom: -200px;
  display: flex;
}

.ipad-3 .fee-box-d-minimised {
  z-index: 9;
  background-color: #0000;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-top: 97px;
  margin-bottom: -390px;
  display: flex;
}

.ipad-3 .fee-box-d-expanded .div {
  border: 2px solid #35cbfd;
  border-radius: 22px;
  width: 675px;
  height: 245px;
  position: relative;
  overflow-x: hidden;
}

.ipad-3 .fee-box-d-minimised .overlap-group {
  border: 3px solid #35cbfd;
  border-radius: 18px;
  width: 675px;
  height: 63px;
  position: relative;
}

.ipad-3 .footer-section {
  width: 768px;
  height: 208px;
  margin-top: 250px;
}

.ipad-3 .footer-section .frame {
  justify-content: space-between;
  align-items: flex-start;
  width: 768px;
  display: flex;
  position: relative;
  top: 31px;
}

.ipad-3 .footer-section .line {
  width: 768px;
  height: 1px;
  margin-top: -.5px;
}

.loading-screen-container {
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
}

.loading-screen {
  background-color: #000;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  display: flex;
}

.loading-screen img {
  max-width: 15%;
  max-height: 15%;
}

.nft-d .desktop-nft {
  background-position: initial;
  background-size: cover;
  border: 1px;
  flex-direction: column;
  align-items: center;
  gap: 115px;
  min-width: 1440px;
  padding: 81px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.nft-d .frame-3414 {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 78px;
  display: flex;
  position: relative;
}

.nft-d .nav {
  justify-content: space-between;
  align-self: center;
  align-items: center;
  min-width: 1278px;
  height: 46px;
  padding: 10px 50px 10px 40px;
  display: flex;
  position: absolute;
}

.nft-d .logo {
  width: 246.25px;
  height: 45.81px;
  margin-top: -.2px;
}

.nft-d .my-custom-wrapper {
  border-radius: 12px;
  display: inline-block;
  overflow: hidden;
}

.nft-d .my-custom-class {
  cursor: pointer;
  font-weight: bold;
  position: relative;
  font-family: var(--font-family-bebas_neue_pro-regular) !important;
}

.nft-d .img-wrapper {
  --webkit-backdrop-filter: blur(10px) brightness(100%);
  backdrop-filter: blur(10px) brightness();
  cursor: pointer;
  border-radius: 10px;
  width: 33px;
  height: 33px;
  position: relative;
  left: 600px;
}

.nft-d .group-2 {
  z-index: 9;
  cursor: pointer;
  width: 44px;
  height: 44px;
  position: relative;
  top: -6px;
  right: 10px;
}

.nft-d .frame-3411 {
  min-width: 1278px;
  height: 413px;
  margin-top: 150px;
  display: flex;
  position: relative;
}

.nft-d .heromenu-box {
  flex-direction: column;
  align-items: center;
  gap: 55px;
  width: 1278px;
  height: 413px;
  display: flex;
  position: relative;
}

.nft-d .menu-box {
  border: 1px solid;
  border-color: var(--cerulean);
  border-radius: 9px;
  justify-content: center;
  min-width: 614px;
  height: 43px;
  display: flex;
  position: relative;
  top: 130px;
}

.nft-d .hero-box {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 58px;
  height: 277px;
  padding: 20px 0;
  display: flex;
  position: relative;
}

.nft-d .frame-3413 {
  min-width: 1278px;
  height: 296px;
  margin-bottom: -59px;
  position: relative;
}

.flex-col {
  flex-direction: column;
  align-items: center;
  width: 1278px;
  min-height: 268px;
  display: flex;
  position: relative;
}

.title {
  color: var(--ceramic);
  font-family: var(--font-family-bebas_neue_pro-regular);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 1278px;
  min-height: 152px;
  margin-bottom: 15px;
  font-size: 170px;
  font-weight: 400;
  line-height: 151.3px;
}

.fees-so-low-it-will {
  color: var(--ceramic);
  font-family: var(--font-family-bebas_neue_pro-light);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 1278px;
  min-height: 64px;
  margin-bottom: 5px;
  font-size: 76px;
  font-weight: 300;
  line-height: 68.4px;
}

.vector-1 {
  width: 384px;
  height: 3px;
  margin-top: 30px;
}

.swap-box {
  min-width: 690px;
  height: 536px;
  position: relative;
}

.frame-container {
  border: 3px solid;
  border-color: var(--scooter);
  border-radius: 22px;
  flex-direction: column;
  align-items: flex-start;
  gap: 26px;
  width: 814px;
  min-height: 636px;
  padding: 47.4px 58.7px;
  display: flex;
  position: relative;
  top: 50px;
}

.frame-1 {
  align-items: center;
  gap: 30px;
  width: 690px;
  margin-left: .35px;
  padding: 0 0 0 20px;
  display: flex;
  position: relative;
}

.nft-d .overlap-wrapper {
  width: 1278px;
  height: 607px;
  margin-bottom: 85px;
  position: relative;
}

.nft-d .overlap-6 {
  height: 689px;
  position: relative;
  top: -50px;
}

.nft-d .sub-5 {
  flex-direction: column;
  align-items: center;
  gap: 56px;
  width: 1278px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.nft-d .sub-box-5 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 13px;
  width: 1278px;
  padding: 20px 0 0;
  display: flex;
  position: relative;
}

.nft-d .text-wrapper-15 {
  color: #fcfcfc;
  letter-spacing: 0;
  text-align: center;
  align-self: stretch;
  height: 53px;
  margin-top: -1px;
  font-family: Bebas Neue Pro-Regular, Helvetica;
  font-size: 39px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.nft-d .line-4 {
  width: 173.83px;
  height: 1.44px;
  position: relative;
  top: 0;
}

.nft-d .learn-more-about-5 {
  color: #f9f9f9;
  letter-spacing: 1.8px;
  text-align: center;
  align-self: stretch;
  font-family: Bebas Neue Pro-Regular, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.nft-d .frame-8 {
  flex: none;
  width: 1278px;
  position: relative;
}

.nft-d .frame-9 {
  width: 1139px;
  height: 515px;
  position: absolute;
  top: 174px;
  left: 78px;
}

.nft-d .overlap-group-5 {
  height: 515px;
  position: relative;
}

.nft-d .rectangle-2 {
  width: 399px;
  height: 468px;
  position: absolute;
  top: 15px;
  left: 0;
}

.nft-d .rectangle-3 {
  width: 399px;
  height: 468px;
  position: absolute;
  top: 15px;
  left: 740px;
}

.nft-d .rectangle-4 {
  width: 439px;
  height: 515px;
  position: absolute;
  top: 0;
  left: 350px;
}

.nft-d .text-wrapper-16 {
  color: #885bea;
  letter-spacing: 0;
  text-align: center;
  width: 303px;
  font-family: Titillium Web, Helvetica;
  font-size: 16px;
  font-style: italic;
  font-weight: 600;
  line-height: normal;
  position: absolute;
  top: 321px;
  left: 49px;
}

.nft-d .element-unique-avatars-2 {
  color: #0000;
  letter-spacing: 0;
  text-align: center;
  width: 303px;
  font-family: Titillium Web, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 321px;
  left: 418px;
}

.nft-d .text-wrapper-17 {
  color: #885bea;
  font-size: 16px;
  font-style: italic;
  font-weight: 600;
}

.nft-d .text-wrapper-18 {
  color: #3f89d5;
  font-style: italic;
  font-weight: 700;
}

.nft-d .text-wrapper-19 {
  color: #3f89d5;
  font-style: italic;
  font-weight: 600;
}

.nft-d .each-d-astronaut {
  color: #885bea;
  letter-spacing: 0;
  text-align: center;
  width: 303px;
  font-family: Titillium Web, Helvetica;
  font-size: 16px;
  font-style: italic;
  font-weight: 600;
  line-height: normal;
  position: absolute;
  top: 321px;
  left: 788px;
}

.nft-d .chillville-small-2 {
  width: 74px;
  height: 15px;
  position: absolute;
  top: 413px;
  left: 803px;
}

.nft-d .scottybeam-small-2 {
  width: 93px;
  height: 22px;
  position: absolute;
  top: 409px;
  left: 894px;
}

.nft-d .pawworld-small-2 {
  width: 75px;
  height: 16px;
  position: absolute;
  top: 412px;
  left: 1004px;
}

.nft-d .NF-tcards-3 {
  width: 1278px;
  height: 974px;
  position: relative;
}

.nft-d .overlap-7 {
  height: 876px;
  position: relative;
  top: -50px;
}

.nft-d .cards-2 {
  width: 1258px;
  height: 702px;
  position: absolute;
  top: 174px;
  left: 10px;
}

.nft-d .overlap-8 {
  width: 1223px;
  height: 721px;
  position: absolute;
  top: 0;
  left: 20px;
}

.nft-d .overlap-group-6 {
  width: 1223px;
  height: 687px;
  position: absolute;
  top: 0;
  left: 0;
}

.nft-d .the-legend-astronaut-2 {
  color: #885bea;
  letter-spacing: 0;
  text-align: center;
  width: 368px;
  font-family: Titillium Web, Helvetica;
  font-size: 12.4px;
  font-style: italic;
  font-weight: 600;
  line-height: normal;
  position: absolute;
  top: 610px;
  left: 425px;
}

.nft-d .vector-16 {
  background: #000;
  width: 488px;
  height: 569px;
  position: absolute;
  top: 26px;
  left: 366px;
}

.nft-d .vector-17 {
  width: 82px;
  height: 82px;
  position: absolute;
  top: 594px;
  left: 324px;
}

.nft-d .vector-18 {
  width: 82px;
  height: 82px;
  position: absolute;
  top: 594px;
  left: 811px;
}

.nft-d .rectangle-5 {
  width: 164px;
  height: 164px;
  position: absolute;
  top: 226px;
  left: 0;
}

.nft-d .rectangle-6 {
  width: 227px;
  height: 228px;
  position: absolute;
  top: 102px;
  left: 121px;
}

.nft-d .rectangle-7 {
  width: 282px;
  height: 282px;
  position: absolute;
  top: 305px;
  left: 83px;
}

.nft-d .rectangle-8 {
  width: 175px;
  height: 175px;
  position: absolute;
  top: 228px;
  left: 1048px;
}

.nft-d .rectangle-9 {
  width: 226px;
  height: 226px;
  position: absolute;
  top: 107px;
  left: 870px;
}

.nft-d .rectangle-10 {
  width: 285px;
  height: 285px;
  position: absolute;
  top: 309px;
  left: 852px;
}

.nft-d .rectangle-11 {
  width: 596px;
  height: 537px;
  position: absolute;
  top: 30px;
  left: 311px;
}

.nft-d .arrow-2 {
  cursor: pointer;
  width: 61px;
  height: 61px;
  position: absolute;
  top: 605px;
  left: 335px;
}

.nft-d .icon-arrow-circle-2 {
  cursor: pointer;
  width: 51px;
  height: 51px;
  position: absolute;
  top: 610px;
  left: 827px;
}

.nft-d .vector-19 {
  width: 1px;
  height: 44px;
  position: absolute;
  top: 677px;
  left: 852px;
}

.nft-d .vector-20 {
  width: 1px;
  height: 44px;
  position: absolute;
  top: 677px;
  left: 365px;
}

.nft-d .get-started-wrapper {
  all: unset;
  box-sizing: border-box;
  background-color: #f0f;
  border-radius: 10px;
  width: 226px;
  height: 39px;
  position: absolute;
  top: 730px;
  left: 385px;
}

.nft-d .get-started-4 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  cursor: pointer;
  width: 226px;
  font-family: Opinion Pro Extended-Light, Helvetica;
  font-size: 18px;
  font-weight: 300;
  line-height: normal;
  position: absolute;
  top: 11px;
  left: 0;
}

.nft-d .CS-button-2 {
  border: 1px solid #f0f;
  border-radius: 10px;
  width: 226px;
  height: 39px;
  position: absolute;
  top: 730px;
  left: 648px;
}

.nft-d .get-started-5 {
  color: #f0f;
  letter-spacing: 0;
  text-align: center;
  cursor: pointer;
  width: 226px;
  font-family: Opinion Pro Extended-Light, Helvetica;
  font-size: 18px;
  font-weight: 300;
  line-height: normal;
  position: absolute;
  top: 11px;
  left: 0;
}

.nft-d .NF-tcards-4 {
  width: 1278px;
  height: 605px;
  position: relative;
}

.nft-d .every-successful-2 {
  color: #f9f9f9;
  letter-spacing: 0;
  text-align: center;
  font-family: Bebas Neue Pro-Regular, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 480px;
  left: 326px;
}

.nft-d .overlap-9 {
  width: 1278px;
  height: 508px;
  position: absolute;
  top: -50px;
  left: 0;
}

.nft-d .frame-10 {
  justify-content: center;
  align-items: flex-start;
  gap: 48px;
  display: inline-flex;
  position: absolute;
  top: 150px;
  left: 224px;
}

.nft-d .overlap-group-wrapper-3 {
  width: 245px;
  height: 311px;
  position: relative;
}

.nft-d .overlap-group-7 {
  height: 301px;
  position: relative;
  top: 10px;
}

.nft-d .p-block-4 {
  border-radius: 24px;
  width: 245px;
  height: 285px;
  position: absolute;
  top: 23px;
  left: 0;
}

.nft-d .vector-21 {
  width: 117px;
  height: 69px;
  position: absolute;
  top: 232px;
  left: 64px;
}

.nft-d .JOIN-a-GLOBAL-17 {
  color: #fcfcfc;
  letter-spacing: 0;
  text-align: center;
  width: 245px;
  height: 39px;
  font-family: Bebas Neue Pro-Regular, Helvetica;
  font-size: 25px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 153px;
  left: 0;
}

.nft-d .JOIN-a-GLOBAL-18 {
  color: #f0f;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 245px;
  height: 39px;
  font-family: Bebas Kai-Regular, Helvetica;
  font-size: 46px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 111px;
  left: 0;
}

.nft-d .JOIN-a-GLOBAL-19 {
  color: #f0f;
  letter-spacing: 0;
  text-align: center;
  width: 245px;
  height: 39px;
  font-family: Bebas Kai-Regular, Helvetica;
  font-size: 11px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 244px;
  left: 0;
}

.nft-d .text-wrapper-20 {
  color: #f0f;
  letter-spacing: 0;
  font-family: Bebas Kai-Regular, Helvetica;
  font-size: 11px;
  font-weight: 400;
}

.nft-d .text-wrapper-21 {
  font-size: 1px;
}

.nft-d .text-wrapper-22 {
  font-size: 37px;
}

.nft-d .vector-22 {
  width: 62px;
  height: 80px;
  position: absolute;
  top: 0;
  left: 91px;
}

.nft-d .JOIN-a-GLOBAL-20 {
  color: #f0f;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 245px;
  height: 39px;
  font-family: Bebas Kai-Regular, Helvetica;
  font-size: 28px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 250px;
  left: 0;
}

.nft-d .vector-23 {
  width: 104px;
  height: 80px;
  position: absolute;
  top: 0;
  left: 71px;
}

.nft-d .JOIN-a-GLOBAL-21 {
  width: 43px;
  height: 27px;
  position: absolute;
  top: 251px;
  left: 101px;
}

.nft-d .vector-24 {
  width: 73px;
  height: 80px;
  position: absolute;
  top: 0;
  left: 86px;
}

.nft-d .MYSTERY-BOX-2 {
  width: 1278px;
  height: 736px;
  position: relative;
}

.nft-d .overlap-10 {
  height: 758px;
  position: relative;
  top: -65px;
}

.nft-d .text-wrapper-23 {
  color: #f9f9f9;
  letter-spacing: 1.72px;
  text-align: center;
  align-self: stretch;
  font-family: Bebas Neue Pro-Regular, Helvetica;
  font-size: 23px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.nft-d .MBOX-2 {
  width: 458px;
  height: 525px;
  position: absolute;
  top: 210px;
  left: 410px;
}

.nft-d .overlap-group-8 {
  width: 459px;
  height: 526px;
  position: relative;
}

.nft-d .vector-25 {
  width: 459px;
  height: 408px;
  position: absolute;
  top: 0;
  left: 0;
}

.nft-d .vector-26 {
  cursor: pointer;
  width: 47px;
  height: 45px;
  position: absolute;
  top: 20px;
  left: 393px;
}

.nft-d .vector-27 {
  width: 459px;
  height: 98px;
  position: absolute;
  top: 428px;
  left: 0;
}

.nft-d .MI-nt-2 {
  background-color: #f0f;
  border-radius: 10px;
  width: 226px;
  height: 39px;
  position: absolute;
  top: 400px;
  left: 118px;
}

.nft-d .JOIN-a-GLOBAL-22 {
  color: #fcfcfc;
  letter-spacing: 0;
  text-align: center;
  width: 229px;
  font-family: Bebas Neue Pro-Regular, Helvetica;
  font-size: 25px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 486px;
  left: 0;
}

.nft-d .JOIN-a-GLOBAL-23 {
  color: #fcfcfc;
  letter-spacing: 0;
  text-align: center;
  width: 229px;
  font-family: Bebas Neue Pro-Regular, Helvetica;
  font-size: 25px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 486px;
  left: 230px;
}

.nft-d .JOIN-a-GLOBAL-24 {
  color: #f0f;
  letter-spacing: 0;
  text-align: center;
  width: 229px;
  font-family: Bebas Neue Pro-SemiExpandedBold, Helvetica;
  font-size: 30px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 446px;
  left: 0;
}

.nft-d .JOIN-a-GLOBAL-25 {
  color: #f0f;
  letter-spacing: 0;
  text-align: center;
  width: 229px;
  font-family: Bebas Neue Pro-SemiExpandedBold, Helvetica;
  font-size: 30px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 446px;
  left: 230px;
}

.nft-d .place {
  letter-spacing: 0;
  width: 54px;
  line-height: normal;
  position: relative;
}

.nft-d .frame-3 {
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: fit-content;
  display: flex;
  position: relative;
}

.nft-d .tb {
  min-width: 694.69px;
  height: 129px;
  margin-right: -4px;
  position: relative;
}

.nft-d .flex-col-1 {
  background-color: var(--black-rock);
  border-radius: 12px;
  flex-direction: column;
  align-items: flex-end;
  gap: 29px;
  width: 691px;
  min-height: 129px;
  padding: 8px 21.5px;
  display: flex;
}

.nft-d .flex-row {
  align-items: flex-end;
  min-width: 646px;
  height: 20px;
  margin-top: 7px;
  display: flex;
}

.nft-d .send {
  letter-spacing: 0;
  align-self: flex-end;
  width: 232px;
  min-height: 19px;
  line-height: normal;
}

.nft-d .overlap-group1 {
  background-size: 100% 100%;
  justify-content: flex-end;
  align-items: flex-start;
  min-width: 43px;
  height: 19px;
  margin-left: 324px;
  padding: 5px 11.2px;
  display: flex;
}

.nft-d .name {
  letter-spacing: 0;
  text-align: center;
  width: 20px;
  min-height: 14px;
  line-height: normal;
}

.nft-d .overlap-group-1 {
  background-size: 100% 100%;
  justify-content: flex-end;
  align-items: flex-start;
  min-width: 43px;
  height: 19px;
  margin-top: .22px;
  margin-left: 5px;
  padding: 5px 9.5px;
  display: flex;
}

.nft-d .half {
  letter-spacing: 0;
  text-align: center;
  width: 22px;
  min-height: 14px;
  line-height: normal;
}

.nft-d .flex-row-1 {
  align-self: center;
  align-items: center;
  gap: 400px;
  min-width: 644px;
  height: 57px;
  margin-right: .67px;
  display: flex;
}

.nft-d .text-1 {
  letter-spacing: 0;
  width: 232px;
  min-height: 57px;
  line-height: normal;
}

.nft-d .frame-3403 {
  cursor: pointer;
  justify-content: center;
  align-items: flex-end;
  min-width: 91px;
  margin-bottom: 5.06px;
  display: flex;
}

.nft-d .path-529 {
  align-self: flex-start;
  width: 31px;
  height: 31px;
  margin-top: 0;
}

.nft-d .token {
  letter-spacing: 0;
  width: 41px;
  min-height: 21px;
  margin-bottom: 2.94px;
  margin-left: 6px;
  line-height: normal;
}

.nft-d .path-530 {
  width: 12px;
  height: 6px;
  margin-top: 0;
  margin-bottom: 12px;
  margin-left: 20px;
}

.nft-d .frame-4 {
  width: 690px;
  height: 34px;
  margin-left: .35px;
}

.nft-d .frame-2 {
  align-items: center;
  gap: 30px;
  width: 689px;
  height: 45px;
  margin-left: .85px;
  padding: 0 0 0 21px;
  display: flex;
  position: relative;
}

.nft-d .to {
  letter-spacing: 0;
  width: 55px;
  line-height: normal;
  position: relative;
}

.nft-d .frame-5 {
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: fit-content;
  margin-left: .02px;
  display: flex;
  position: relative;
}

.nft-d .bb {
  min-width: 694.65px;
  height: 106.6px;
  margin-right: -4px;
  position: relative;
}

.nft-d .overlap-group-2 {
  background-size: 100% 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 18px;
  width: 691px;
  min-height: 107px;
  padding: 1.5px 21.8px;
  display: flex;
}

.nft-d .receive-estimated {
  letter-spacing: 0;
  width: 232px;
  min-height: 19px;
  margin-top: 10px;
  margin-left: 1px;
  line-height: normal;
}

.nft-d .text-2 {
  letter-spacing: 0;
  width: 233px;
  min-height: 57px;
  line-height: normal;
}

.nft-d .frame-3412 {
  min-width: 1278px;
  height: 445.18px;
  display: flex;
  position: relative;
  top: -45px;
}

.nft-d .sub {
  flex-direction: column;
  align-items: center;
  gap: 56px;
  width: 1278px;
  height: 446.18px;
  display: flex;
  position: relative;
}

.nft-d .sub-box {
  flex-direction: column;
  align-items: center;
  gap: 13px;
  width: 1278px;
  padding: 20px 0 0;
  display: flex;
  position: relative;
  top: 75px;
}

.nft-d .join-a-global-community {
  color: var(--ceramic);
  font-family: var(--font-family-bebas_neue_pro-regular);
  letter-spacing: 0;
  text-align: center;
  align-self: stretch;
  height: 53px;
  margin-top: -1px;
  font-size: 39px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.nft-d .line-33 {
  min-width: 173.83px;
  height: 1.44px;
  position: relative;
}

.nft-d .learn-more-about-xio {
  color: var(--hint-of-red);
  font-family: var(--font-family-bebas_neue_pro-regular);
  font-size: var(--font-size-xxxxxl);
  letter-spacing: 1.72px;
  text-align: center;
  align-self: stretch;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.nft-d .frame-262 {
  justify-content: center;
  align-items: center;
  gap: 15px;
  width: 1278px;
  margin-top: 75px;
  display: flex;
  position: relative;
}

.nft-d .frame-3395 {
  background-color: var(--white);
  border: 1.2px solid;
  border-color: var(--kingfisher-daisy);
  border-radius: 8px;
  align-items: center;
  width: 359.15px;
  height: 50.07px;
  padding: 14.37px;
  display: flex;
  position: relative;
}

.nft-d .eg-bethe1sttoknow {
  letter-spacing: 0;
  opacity: .3;
  white-space: nowrap;
  background-color: #0000;
  border: 0;
  min-width: 330.42px;
  height: 22.75px;
  margin-top: -1.9px;
  padding: 0;
  line-height: normal;
  position: relative;
}

.eg-bethe1sttoknow::placeholder {
  color: #2a2a2a99;
}

.nft-d .frame-3396 {
  background-color: var(--kingfisher-daisy);
  cursor: pointer;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  gap: 7.18px;
  width: 220px;
  height: 49.61px;
  padding: 15px 20px;
  transition: all .2s;
  display: flex;
  position: relative;
}

.nft-d .frame-3396:hover, .social-box-item:hover, .social-box-item-1:hover {
  transform: scale(1.1);
}

.nft-d .submit {
  letter-spacing: 0;
  text-align: center;
  width: fit-content;
  margin-top: -5.89px;
  margin-bottom: -3.5px;
  line-height: normal;
  position: relative;
}

.nft-d .social-box {
  justify-content: center;
  align-items: center;
  gap: 51px;
  width: 1278px;
  display: flex;
  position: relative;
}

.nft-d .social-box-item {
  cursor: pointer;
  min-width: 161.56px;
  height: 157.11px;
  transition: all .2s;
  position: relative;
}

.nft-d .overlap-group {
  background-size: 100% 100%;
  width: 158px;
  height: 157px;
  position: relative;
}

.nft-d .join-the-xion-community {
  letter-spacing: 0;
  width: 94px;
  line-height: normal;
  position: absolute;
  top: 112px;
  left: 32px;
}

.nft-d .for-updates-and-news {
  letter-spacing: 0;
  width: 84px;
  font-size: 9px;
  line-height: normal;
  position: absolute;
  top: 123px;
  left: 37px;
}

.nft-d .chat-in-real-time-with-the {
  letter-spacing: 0;
  width: 97px;
  font-size: 9px;
  line-height: normal;
  position: absolute;
  top: 113px;
  left: 30px;
}

.nft-d .xion-community {
  letter-spacing: 0;
  width: 63px;
  font-size: 9px;
  line-height: normal;
  position: absolute;
  top: 124px;
  left: 47px;
}

.nft-d .social-box-item-1 {
  cursor: pointer;
  min-width: 161.56px;
  height: 157.1px;
  transition: all .2s;
  position: relative;
}

.nft-d .follow-xionfinance-for {
  letter-spacing: 0;
  width: 87px;
  font-size: 9px;
  line-height: normal;
  position: absolute;
  top: 113px;
  left: 36px;
}

.nft-d .updates-and-news {
  letter-spacing: 0;
  width: 71px;
  font-size: 9px;
  line-height: normal;
  position: absolute;
  top: 124px;
  left: 44px;
}

.nft-d .follow-xionfinance-for-1 {
  letter-spacing: 0;
  width: 94px;
  font-size: 9px;
  line-height: normal;
  position: absolute;
  top: 113px;
  left: 32px;
}

.nft-d .footer {
  background-color: #0000;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 37.6px;
  min-width: 1194.56px;
  height: 54.22px;
  margin-top: 600px;
  display: flex;
  position: relative;
}

.nft-d .line-34 {
  width: 1194.56px;
  height: 1px;
  margin-top: -.4px;
}

.nft-d .frame-3399 {
  justify-content: center;
  align-items: flex-start;
  gap: 984px;
  width: 1187px;
  height: 16px;
  margin-left: -1px;
  display: flex;
  position: relative;
}

.nft-d .copyright {
  color: #f9f9f9;
  letter-spacing: 0;
  width: 117px;
  height: 16px;
  margin-top: -1px;
  font-family: Bebas Neue Pro-Regular, Helvetica;
  font-size: 14px;
  line-height: normal;
  position: relative;
  right: 530px;
}

.nft-d .tcs {
  letter-spacing: 0;
  text-align: right;
  width: 95px;
  height: 16px;
  margin-top: -15px;
  font-size: 14px;
  line-height: normal;
  position: relative;
  right: -1070px;
}

.nft-d .approve {
  letter-spacing: 0;
  text-align: center;
  width: 334px;
  min-height: 29px;
  margin-top: 5px;
  line-height: normal;
}

.nft-d .b-wrapper, .nft-d .frame-34 {
  flex: none;
  justify-content: space-around;
  align-items: flex-start;
  gap: 55px;
  width: 1034px;
  display: flex;
  position: relative;
}

.nft-d .b-2 {
  align-self: stretch;
  width: 1033px;
  position: relative;
}

.nft-d .XGT-MARKET-4 {
  color: #35cbfd;
  letter-spacing: 0;
  text-align: center;
  width: 1025px;
  font-family: Bebas Neue Pro-Regular, Helvetica;
  font-size: 26px;
  font-weight: 400;
  line-height: 23.1px;
  position: absolute;
  top: 60px;
  left: 8px;
}

.nft-d .text-wrapper-62 {
  color: #35cbfd;
  letter-spacing: 0;
  font-family: Bebas Neue Pro-Regular, Helvetica;
  font-size: 26px;
  font-weight: 400;
  line-height: 23.1px;
}

.nft-d .text-wrapper-63 {
  font-family: Bebas Neue Pro-Light, Helvetica;
  font-weight: 300;
}

.nft-d .mysterybox {
  width: 459px;
  height: 408px;
  position: absolute;
  top: 0;
  left: 0;
}

.nft-d .about-us-blog-t-cs-privacy-policy {
  letter-spacing: 0;
  text-align: right;
  width: 96px;
  height: 16px;
  margin-top: -1px;
  line-height: normal;
  position: relative;
}

.nft-d .terms-link, .nft-d .privacy-link {
  color: #f9f9f9;
  cursor: pointer;
  min-width: 125px;
  margin-top: 3px;
  margin-left: -14px;
  font-family: Bebas Neue Pro-Light, Helvetica;
  font-size: 12px;
  transition: color .3s;
}

.nft-d .terms-link:hover, .nft-d .privacy-link:hover {
  color: #35cbfd;
}

.nft-d .footer-section {
  width: 1194px;
  height: 208px;
  margin-top: 600px;
}

.nftfdpopup {
  z-index: 99;
  background-color: #0000;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.nftfdpopup .div {
  background-image: url("rectangle-2921.03b61ec1.png");
  background-size: 100% 100%;
  width: 311px;
  height: 311px;
  margin-top: 67px;
  margin-left: -3px;
  position: absolute;
}

.nftfdpopup .contact-details {
  width: 172px;
  height: 18px;
  position: absolute;
  top: 220px;
  left: 65px;
}

.nftfdpopup .transaction-needs {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 232px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 0;
  left: -27px;
}

.nftfdpopup .group {
  width: 154px;
  height: 154px;
  position: absolute;
  top: 50px;
  left: 76px;
}

.nftfdpopup .RB-button {
  width: 118px;
  height: 10px;
  position: absolute;
  top: 94px;
  left: 26px;
}

.nftfdpopup .overlap-group-wrapper {
  width: 60px;
  height: 10px;
  position: absolute;
  top: 0;
  left: 0;
}

.nftfdpopup .overlap-group {
  background-color: #3a9300;
  border-radius: 2px;
  width: 58px;
  height: 10px;
  position: relative;
}

.nftfdpopup .vew-transaction {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 58px;
  height: 10px;
  font-family: Titillium Web, Helvetica;
  font-size: 5px;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.nftfdpopup .overlap-wrapper {
  width: 60px;
  height: 10px;
  position: absolute;
  top: 0;
  left: 60px;
}

.nftfdpopup .overlap {
  border: .5px solid #cf5159;
  border-radius: 2px;
  width: 58px;
  height: 10px;
  position: relative;
}

.nftfdpopup .increase-gas {
  color: #cf5159;
  letter-spacing: 0;
  text-align: center;
  width: 58px;
  height: 10px;
  font-family: Titillium Web, Helvetica;
  font-size: 5px;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.nftfdpopup .text-wrapper {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 170px;
  font-family: Titillium Web, Helvetica;
  font-size: 5px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 112px;
  left: 0;
}

.nftsfpopup {
  z-index: 99;
  background-color: #0000;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.nftsfpopup .div {
  background-image: url("rectangle-2921.03b61ec1.png");
  background-size: 100% 100%;
  width: 311px;
  height: 311px;
  margin-top: 67px;
  margin-left: -3px;
  position: absolute;
}

.nftsfpopup .contact-details {
  width: 172px;
  height: 18px;
  position: absolute;
  top: 195px;
  left: 71px;
}

.nftsfpopup .transaction {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 170px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.nftsfpopup .group {
  width: 154px;
  height: 154px;
  position: absolute;
  top: 32px;
  left: 79px;
}

.nftsfpopup .RB-button {
  position: absolute;
  top: 226px;
  left: 93px;
}

.nftsfpopup .overlap-group-wrapper {
  width: 60px;
  height: 10px;
  position: absolute;
  top: 0;
  left: 0;
}

.nftsfpopup .overlap-group {
  width: 116px;
  height: 20px;
  position: relative;
  right: 69px;
}

.nftsfpopup .view-transaction {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  cursor: pointer;
  width: 115px;
  height: 10px;
  font-family: Titillium Web, Helvetica;
  font-size: 12px;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 0;
  left: -1px;
}

.nftsfpopup .overlap-wrapper {
  width: 60px;
  height: 10px;
  position: absolute;
  top: 0;
  left: 85px;
}

.nftsfpopup .overlap {
  width: 125px;
  height: 19px;
  margin-left: -20px;
  position: relative;
}

.nftsfpopup .element-recived {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 114px;
  height: 10px;
  font-family: Titillium Web, Helvetica;
  font-size: 12px;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 2px;
  left: -11px;
}

.nftpspopup {
  z-index: 99;
  justify-content: center;
  width: 100%;
  display: flex;
}

.nftpspopup .div {
  background-image: url("rectangle-2921.03b61ec1.png");
  background-size: 100% 100%;
  width: 311px;
  height: 311px;
  margin-top: 67px;
  margin-left: -3px;
  position: absolute;
}

.nftpspopup .contact-details {
  width: 172px;
  height: 18px;
  position: absolute;
  top: 216px;
  left: 72px;
}

.nftpspopup .transaction {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 170px;
  font-family: Titillium Web, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: -10px;
  left: 0;
}

.nftpspopup .group {
  width: 154px;
  height: 154px;
  position: absolute;
  top: 36px;
  left: 80px;
}

.nftpspopup .element-sec-wrapper {
  width: 60px;
  height: 10px;
  position: absolute;
  top: 240px;
  left: 124px;
}

.nftpspopup .element-sec {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 180px;
  height: 10px;
  font-family: Titillium Web, Helvetica;
  font-size: 12px;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 24px;
  left: -59px;
}

.nftpspopup .close {
  cursor: pointer;
  z-index: 9;
  width: 14px;
  height: 13px;
  position: absolute;
  top: 13px;
  left: 284px;
}

.nftnftfdpopup .close {
  cursor: pointer;
  z-index: 99;
  width: 14px;
  height: 13px;
  position: absolute;
  top: 392px;
  left: 530px;
}

.nftsfpopup .close {
  cursor: pointer;
  z-index: 99;
  width: 14px;
  height: 13px;
  position: absolute;
  top: -205px;
  left: 101px;
}

.nftsfpopup .usdt-logo {
  width: 20px;
  height: 20px;
  margin-top: -2px;
  margin-left: 1px;
}

.nftfdpopup .failed-reason {
  color: #fff;
  width: 295px;
  margin-top: 29px;
  margin-left: -47px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 400;
}

.nftfdpopup .failed-reason a, .nftfdpopup .failed-reason a:hover {
  color: #35cbfd;
}

.nftsfpopup .twitter-share-button {
  color: #fff;
  cursor: pointer;
  margin-top: 33px;
  margin-left: -11px;
  padding: 10px 20px;
  font-family: Titillium Web, Helvetica;
  font-size: 16px;
  font-weight: 400;
  display: inline-block;
}

.nftsfpopup .twitter-share-button:hover {
  animation: .5s ease-in-out shake;
}

.nftsfpopup .share-icon {
  z-index: 99;
  cursor: pointer;
  width: 20px;
  margin-top: 0;
  margin-left: 77px;
  position: absolute;
}

.nftsfpopup .link-icon {
  z-index: 99;
  cursor: pointer;
  width: 20px;
  margin-top: 0;
  margin-left: 102px;
  position: absolute;
}

.nftpspopup .onway {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 170px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 13px;
  left: 0;
}

.nft-i .ipad-nft {
  background-position: initial;
  background-size: cover;
  border: 1px;
  flex-direction: column;
  align-items: center;
  gap: 75px;
  min-width: 768px;
  padding: 81px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.nft-i .frame-3416-2 {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 75px;
  display: flex;
  position: relative;
  bottom: 30px;
}

.nft-i .nav-3 {
  justify-content: space-between;
  align-self: center;
  align-items: center;
  min-width: 688px;
  height: 46px;
  padding: 10px 105px;
  display: flex;
  position: relative;
}

.nft-i .logo-3 {
  width: 178px;
  height: 33px;
  margin-top: -1px;
  margin-left: -80px;
}

.nft-i .my-custom-wrapper {
  border-radius: 12px;
  display: inline-block;
  overflow: hidden;
}

.nft-i .my-custom-class {
  cursor: pointer;
  font-weight: bold;
  position: relative;
  font-family: var(--font-family-bebas_neue_pro-regular) !important;
}

.nft-i .img-wrapper {
  --webkit-backdrop-filter: blur(10px) brightness(100%);
  backdrop-filter: blur(10px) brightness();
  cursor: pointer;
  border-radius: 10px;
  width: 44px;
  height: 44px;
  position: absolute;
  right: 35px;
}

.nft-i .overlap-b-group.open .burger-menu-container {
  z-index: 9;
  background-color: #d9d9d9;
  border-radius: 1px 1px 10px 10px;
  width: 250px;
  height: 410px;
  position: absolute;
  top: -5px;
  right: 0;
}

.nft-i .overlap-b-group.open .burger-2 {
  backdrop-filter: blur(10px) brightness();
  z-index: 9;
  border-radius: 10px;
  width: 33px;
  height: 33px;
  position: absolute;
  top: 16px;
  left: 233px;
}

.nft-i .frame-3413-4 {
  min-width: 677px;
  height: 342px;
  display: flex;
  position: relative;
  top: 10px;
}

.nft-i .heromenu-box-3 {
  flex-direction: column;
  align-items: center;
  gap: 55px;
  width: 677px;
  height: 300px;
  margin-left: .5px;
  display: flex;
  position: relative;
}

.nft-i .hero-box-3 {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 58px;
  height: 205px;
  padding: 20px 0;
  display: flex;
  position: relative;
}

.nft-i .frame-3413-5 {
  min-width: 676px;
  height: 185px;
  margin-bottom: -20px;
  position: relative;
}

.nft-i .flex-col-9 {
  flex-direction: column;
  align-items: center;
  width: 676px;
  min-height: 158px;
  display: flex;
  position: relative;
}

.nft-i .frame-container-2 {
  border: 2px solid;
  border-color: var(--scooter);
  border-radius: 22px;
  flex-direction: column;
  align-items: flex-start;
  width: 690px;
  min-height: 636px;
  padding: 48px 44px;
  display: flex;
  position: relative;
  top: 110px;
}

.nft-i .title-3 {
  color: var(--ceramic);
  font-family: var(--font-family-bebas_neue_pro-regular);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 676px;
  min-height: 83px;
  margin-bottom: 15px;
  font-size: 96px;
  font-weight: 400;
  line-height: 85.4px;
}

.nft-i .WAXANF-ts-2 {
  width: 768px;
  height: 471px;
  margin-left: -81px;
  margin-right: -81px;
  position: relative;
}

.nft-i .sub-7 {
  flex-direction: column;
  align-items: center;
  gap: 56px;
  width: 768px;
  display: flex;
  position: absolute;
  top: -50px;
  left: 0;
}

.nft-i .sub-box-6 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 13px;
  width: 1278px;
  margin-left: -255px;
  margin-right: -255px;
  padding: 20px 0 0;
  display: flex;
  position: relative;
}

.nft-i .JOIN-a-GLOBAL-26 {
  color: #fcfcfc;
  letter-spacing: 0;
  text-align: center;
  width: 1102px;
  height: 53px;
  margin-top: -1px;
  font-family: Bebas Neue Pro-Regular, Helvetica;
  font-size: 39px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.nft-i .line-6 {
  width: 173.83px;
  height: 1.44px;
  position: relative;
}

.nft-i .learn-more-about-6 {
  color: #f9f9f9;
  letter-spacing: 1.8px;
  text-align: center;
  align-self: stretch;
  font-family: Bebas Neue Pro-Regular, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.nft-i .frame-17 {
  width: 768px;
  height: 347px;
  position: absolute;
  top: 138px;
  left: 0;
}

.nft-i .mysterybox {
  width: 459px;
  height: 408px;
  position: absolute;
  top: -15px;
  left: 0;
}

.nft-i .rectangle-12 {
  width: 269px;
  height: 316px;
  position: absolute;
  top: 10px;
  left: 0;
}

.nft-i .rectangle-13 {
  width: 269px;
  height: 316px;
  position: absolute;
  top: 10px;
  left: 499px;
}

.nft-i .rectangle-14 {
  width: 296px;
  height: 347px;
  position: absolute;
  top: 0;
  left: 236px;
}

.nft-i .text-wrapper-36 {
  color: #885bea;
  letter-spacing: 0;
  text-align: center;
  width: 204px;
  font-family: Titillium Web, Helvetica;
  font-size: 11px;
  font-style: italic;
  font-weight: 600;
  line-height: normal;
  position: absolute;
  top: 218px;
  left: 33px;
}

.nft-i .element-unique-avatars-3 {
  color: #0000;
  letter-spacing: 0;
  text-align: center;
  width: 213px;
  font-family: Titillium Web, Helvetica;
  font-size: 9px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 218px;
  left: 278px;
}

.nft-i .text-wrapper-37 {
  color: #885bea;
  font-size: 11px;
  font-style: italic;
  font-weight: 600;
}

.nft-i .text-wrapper-38 {
  color: #3f89d5;
  font-style: italic;
  font-weight: 700;
}

.nft-i .text-wrapper-39 {
  color: #3f89d5;
  font-style: italic;
  font-weight: 600;
}

.nft-i .each-astronaut-2 {
  color: #885bea;
  letter-spacing: 0;
  text-align: center;
  width: 204px;
  font-family: Titillium Web, Helvetica;
  font-size: 11px;
  font-style: italic;
  font-weight: 600;
  line-height: normal;
  position: absolute;
  top: 218px;
  left: 531px;
}

.nft-i .chillville-small-3 {
  width: 50px;
  height: 10px;
  position: absolute;
  top: 278px;
  left: 541px;
}

.nft-i .scottybeam-small-3 {
  width: 63px;
  height: 15px;
  position: absolute;
  top: 276px;
  left: 603px;
}

.nft-i .pawworld-small-3 {
  width: 51px;
  height: 11px;
  position: absolute;
  top: 278px;
  left: 677px;
}

.nft-i .NF-tcards-5 {
  width: 768px;
  height: 616px;
  margin-top: 40px;
  margin-left: -81px;
  margin-right: -81px;
  position: relative;
}

.nft-i .JOIN-a-GLOBAL-27 {
  color: #fcfcfc;
  letter-spacing: 0;
  text-align: center;
  align-self: stretch;
  height: 53px;
  margin-top: -1px;
  font-family: Bebas Neue Pro-Regular, Helvetica;
  font-size: 39px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.nft-i .cards-3 {
  width: 771px;
  height: 431px;
  position: absolute;
  top: 124px;
  left: 0;
}

.nft-i .overlap-14 {
  width: 708px;
  height: 444px;
  position: absolute;
  top: -1px;
  left: 31px;
}

.nft-i .overlap-group-12 {
  width: 708px;
  height: 444px;
  position: absolute;
  top: auto;
  bottom: 10px;
  left: 0;
}

.nft-i .the-legend-astronaut-3 {
  color: #885bea;
  letter-spacing: 0;
  text-align: center;
  width: 231px;
  font-family: Titillium Web, Helvetica;
  font-size: 9px;
  font-style: italic;
  font-weight: 600;
  line-height: normal;
  position: absolute;
  top: 380px;
  left: 239px;
}

.nft-i .vector-29 {
  background: #000;
  border-radius: 10px;
  width: 299px;
  height: 350px;
  position: absolute;
  top: 17px;
  left: 205px;
}

.nft-i .vector-30 {
  width: 1px;
  height: 27px;
  position: absolute;
  top: 417px;
  left: 205px;
}

.nft-i .vector-31 {
  width: 51px;
  height: 51px;
  position: absolute;
  top: 366px;
  left: 179px;
}

.nft-i .vector-32 {
  width: 51px;
  height: 51px;
  position: absolute;
  top: 366px;
  left: 478px;
}

.nft-i .rectangle-15 {
  width: 92px;
  height: 92px;
  position: absolute;
  top: 146px;
  left: 0;
}

.nft-i .rectangle-16 {
  width: 127px;
  height: 128px;
  position: absolute;
  top: 76px;
  left: 68px;
}

.nft-i .rectangle-17 {
  width: 158px;
  height: 159px;
  position: absolute;
  top: 190px;
  left: 47px;
}

.nft-i .rectangle-18 {
  width: 96px;
  height: 96px;
  position: absolute;
  top: 149px;
  left: 612px;
}

.nft-i .rectangle-19 {
  width: 125px;
  height: 125px;
  position: absolute;
  top: 82px;
  left: 513px;
}

.nft-i .rectangle-20 {
  width: 158px;
  height: 158px;
  position: absolute;
  top: 193px;
  left: 503px;
}

.nft-i .rectangle-21 {
  width: 366px;
  height: 326px;
  position: absolute;
  top: 15px;
  left: 130px;
}

.nft-i .arrow-3 {
  cursor: pointer;
  width: 37px;
  height: 37px;
  position: absolute;
  top: 373px;
  left: 186px;
}

.nft-i .icon-arrow-circle-3 {
  cursor: pointer;
  width: 31px;
  height: 31px;
  position: absolute;
  top: 376px;
  left: 488px;
}

.nft-i .vector-33 {
  width: 1px;
  height: 27px;
  position: absolute;
  top: 417px;
  left: 503px;
}

.nft-i .button {
  all: unset;
  box-sizing: border-box;
  cursor: pointer;
  background-color: #f0f;
  border-radius: 6px;
  width: 196px;
  height: 34px;
  position: absolute;
  top: 458px;
  left: 184px;
}

.nft-i .get-started-6 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 196px;
  font-family: Opinion Pro Extended-Light, Helvetica;
  font-size: 15px;
  font-weight: 300;
  line-height: normal;
  position: absolute;
  top: 9px;
  left: 0;
}

.nft-i .CS-button-3 {
  cursor: pointer;
  border: 1px solid #f0f;
  border-radius: 6px;
  width: 196px;
  height: 34px;
  position: absolute;
  top: 458px;
  left: 387px;
}

.nft-i .get-started-7 {
  color: #f0f;
  letter-spacing: 0;
  text-align: center;
  width: 196px;
  font-family: Opinion Pro Extended-Light, Helvetica;
  font-size: 15px;
  font-weight: 300;
  line-height: normal;
  position: absolute;
  top: 9px;
  left: 0;
}

.nft-i .NF-tcards-6 {
  width: 767px;
  height: 426px;
  margin-top: 50px;
  margin-left: -80.5px;
  margin-right: -80.5px;
  position: relative;
}

.nft-i .every-successful-3 {
  color: #f9f9f9;
  letter-spacing: 0;
  text-align: center;
  width: 748px;
  font-family: Bebas Neue Pro-Regular, Helvetica;
  font-size: 22px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 399px;
  left: 10px;
}

.nft-i .sub-8 {
  flex-direction: column;
  align-items: center;
  gap: 56px;
  width: 767px;
  display: flex;
  position: absolute;
  top: -50px;
  left: 0;
}

.nft-i .sub-box-7 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 13px;
  width: 1096px;
  margin-left: -164.5px;
  margin-right: -164.5px;
  padding: 20px 0 0;
  display: flex;
  position: relative;
}

.nft-i .frame-18 {
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  width: 602px;
  height: 199px;
  display: flex;
  position: absolute;
  top: 150px;
  left: 82px;
}

.nft-i .POWER {
  width: 188px;
  height: 199px;
  margin-left: -5px;
  position: relative;
}

.nft-i .overlap-group-13 {
  width: 161px;
  height: 199px;
  position: relative;
  left: 14px;
}

.nft-i .p-block-5 {
  border-radius: 24px;
  width: 160px;
  height: 160px;
  position: absolute;
  top: 15px;
  left: 0;
}

.nft-i .vector-34 {
  width: 77px;
  height: 46px;
  position: absolute;
  top: 153px;
  left: 42px;
}

.nft-i .JOIN-a-GLOBAL-28 {
  color: #fcfcfc;
  letter-spacing: 0;
  text-align: center;
  width: 161px;
  height: 26px;
  font-family: Bebas Neue Pro-Regular, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 107px;
  left: 0;
}

.nft-i .JOIN-a-GLOBAL-29 {
  color: #f0f;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 161px;
  height: 26px;
  font-family: Bebas Kai-Regular, Helvetica;
  font-size: 33px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 77px;
  left: 0;
}

.nft-i .JOIN-a-GLOBAL-30 {
  color: #f0f;
  letter-spacing: 0;
  text-align: center;
  width: 161px;
  height: 26px;
  font-family: Bebas Kai-Regular, Helvetica;
  font-size: 7px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 161px;
  left: 0;
}

.nft-i .text-wrapper-40 {
  color: #f0f;
  letter-spacing: 0;
  font-family: Bebas Kai-Regular, Helvetica;
  font-size: 7px;
  font-weight: 400;
}

.nft-i .text-wrapper-41 {
  font-size: 1px;
}

.nft-i .text-wrapper-42 {
  font-size: 21px;
}

.nft-i .vector-35 {
  width: 41px;
  height: 53px;
  position: absolute;
  top: 0;
  left: 60px;
}

.nft-i .ROYALTY-2 {
  width: 188px;
  height: 199px;
  position: relative;
}

.nft-i .overlap-15 {
  width: 162px;
  height: 199px;
  position: relative;
  left: 13px;
}

.nft-i .p-block-6 {
  border-radius: 24px;
  width: 160px;
  height: 160px;
  position: absolute;
  top: 15px;
  left: 1px;
}

.nft-i .JOIN-a-GLOBAL-31 {
  color: #fcfcfc;
  letter-spacing: 0;
  text-align: center;
  width: 162px;
  height: 26px;
  font-family: Bebas Neue Pro-Regular, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 108px;
  left: 0;
}

.nft-i .JOIN-a-GLOBAL-32 {
  color: #f0f;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 162px;
  height: 26px;
  font-family: Bebas Kai-Regular, Helvetica;
  font-size: 33px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 77px;
  left: 0;
}

.nft-i .JOIN-a-GLOBAL-33 {
  color: #f0f;
  letter-spacing: 0;
  text-align: center;
  width: 162px;
  height: 26px;
  font-family: Bebas Kai-Regular, Helvetica;
  font-size: 21px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 161px;
  left: 0;
}

.nft-i .vector-36 {
  width: 69px;
  height: 53px;
  position: absolute;
  top: 0;
  left: 47px;
}

.nft-i .LOYALTY {
  width: 188px;
  height: 199px;
  margin-right: -5px;
  position: relative;
}

.nft-i .overlap-16 {
  width: 210px;
  height: 199px;
  position: relative;
  left: -11px;
}

.nft-i .p-block-7 {
  border-radius: 24px;
  width: 160px;
  height: 160px;
  position: absolute;
  top: 15px;
  left: 25px;
}

.nft-i .vector-37 {
  width: 77px;
  height: 46px;
  position: absolute;
  top: 153px;
  left: 67px;
}

.nft-i .JOIN-a-GLOBAL-34 {
  color: #fcfcfc;
  letter-spacing: 0;
  text-align: center;
  width: 210px;
  height: 26px;
  font-family: Bebas Neue Pro-Regular, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 107px;
  left: 0;
}

.nft-i .JOIN-a-GLOBAL-35 {
  color: #f0f;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 161px;
  height: 26px;
  font-family: Bebas Kai-Regular, Helvetica;
  font-size: 33px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 77px;
  left: 25px;
}

.nft-i .JOIN-a-GLOBAL-36 {
  width: 24px;
  height: 15px;
  position: absolute;
  top: 167px;
  left: 93px;
}

.nft-i .vector-38 {
  width: 48px;
  height: 53px;
  position: absolute;
  top: 0;
  left: 81px;
}

.nft-i .MYSTERY-BOX-3 {
  width: 762px;
  height: 669px;
  margin-top: 50px;
  margin-left: -78px;
  margin-right: -78px;
  position: relative;
}

.nft-i .sub-9 {
  flex-direction: column;
  align-items: center;
  gap: 56px;
  width: 763px;
  display: flex;
  position: absolute;
  top: -50px;
  left: -1px;
}

.nft-i .sub-box-8 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 13px;
  width: 1278px;
  margin-top: 50px;
  margin-left: -257.5px;
  margin-right: -257.5px;
  padding: 20px 0 0;
  display: flex;
  position: relative;
}

.nft-i .learn-more-about-7 {
  color: #f9f9f9;
  letter-spacing: 1.72px;
  text-align: center;
  align-self: stretch;
  font-family: Bebas Neue Pro-Regular, Helvetica;
  font-size: 23px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.nft-i .MBOX-3 {
  width: 458px;
  height: 525px;
  position: absolute;
  top: 183px;
  left: 152px;
}

.nft-i .overlap-group-14 {
  width: 459px;
  height: 526px;
  position: relative;
  left: -1px;
}

.nft-i .vector-39 {
  width: 459px;
  height: 408px;
  position: absolute;
  top: 0;
  left: 0;
}

.nft-i .vector-40 {
  cursor: pointer;
  width: 47px;
  height: 45px;
  position: absolute;
  top: 20px;
  left: 393px;
}

.nft-i .vector-41 {
  width: 459px;
  height: 98px;
  position: absolute;
  top: 428px;
  left: 0;
}

.nft-i .MI-nt-3 {
  cursor: pointer;
  background-color: #f0f;
  border-radius: 10px;
  width: 226px;
  height: 39px;
  position: absolute;
  top: 400px;
  left: 118px;
}

.nft-i .get-started-8 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 226px;
  font-family: Opinion Pro Extended-Light, Helvetica;
  font-size: 18px;
  font-weight: 300;
  line-height: normal;
  position: absolute;
  top: 11px;
  left: 0;
}

.nft-i .JOIN-a-GLOBAL-37 {
  color: #fcfcfc;
  letter-spacing: 0;
  text-align: center;
  width: 229px;
  font-family: Bebas Neue Pro-Regular, Helvetica;
  font-size: 25px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 486px;
  left: 1px;
}

.nft-i .JOIN-a-GLOBAL-38 {
  color: #fcfcfc;
  letter-spacing: 0;
  text-align: center;
  width: 229px;
  font-family: Bebas Neue Pro-Regular, Helvetica;
  font-size: 25px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 486px;
  left: 230px;
}

.nft-i .JOIN-a-GLOBAL-39 {
  color: #f0f;
  letter-spacing: 0;
  text-align: center;
  width: 229px;
  font-family: Bebas Neue Pro-SemiExpandedBold, Helvetica;
  font-size: 30px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 446px;
  left: 1px;
}

.nft-i .JOIN-a-GLOBAL-40 {
  color: #f0f;
  letter-spacing: 0;
  text-align: center;
  width: 229px;
  font-family: Bebas Neue Pro-SemiExpandedBold, Helvetica;
  font-size: 30px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 446px;
  left: 230px;
}

.nft-i .fees-so-low-it-will-3 {
  color: var(--ceramic);
  font-family: var(--font-family-bebas_neue_pro-light);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 675px;
  min-height: 43px;
  margin-top: 1px;
  margin-right: 1px;
  font-size: 46px;
  font-weight: 300;
  line-height: 41.4px;
}

.nft-i .vector-1-3 {
  width: 281px;
  height: 3px;
  margin-top: 28px;
}

.nft-i .frame-3415-1 {
  justify-content: center;
  align-self: stretch;
  min-width: 688px;
  height: 620px;
  display: flex;
  position: relative;
}

.nft-i .sub-3 {
  flex-direction: column;
  align-items: center;
  gap: 56px;
  width: 686px;
  height: 415.52px;
  padding: 21px 0 0;
  display: flex;
  position: relative;
}

.nft-i .sub-3 > :first-child {
  top: 370px;
}

.nft-i .sub-box-3 {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 13px;
  padding: 20px 0 0;
  display: flex;
  position: relative;
}

.nft-i .sub-box-3 > :first-child {
  margin-top: -300px;
}

.nft-i .join-a-global-community-3 {
  letter-spacing: 0;
  text-align: center;
  align-self: stretch;
  height: 53px;
  margin-top: -1px;
  line-height: normal;
  position: relative;
}

.nft-i .line-33-3 {
  min-width: 173.83px;
  height: 1.44px;
  position: relative;
}

.nft-i .learn-more-about-xio-3 {
  letter-spacing: 0;
  text-align: center;
  align-self: stretch;
  line-height: normal;
  position: relative;
}

.nft-i .email-box-2 {
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 15px;
  display: flex;
  position: relative;
  bottom: 25px;
}

.nft-i .social-box-3 {
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 33px;
  display: flex;
  position: relative;
}

.nft-i .footer-3 {
  background-color: #0000;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 30.4px;
  min-width: 763px;
  height: 84px;
  margin-left: -37.5px;
  margin-right: -37.5px;
  display: flex;
  position: absolute;
}

.nft-i .line-34-3 {
  width: 690px;
  height: 1px;
  margin-top: -.5px;
}

.nft-i .frame-3399-3 {
  justify-content: center;
  align-items: flex-start;
  gap: 482px;
  width: 685px;
  height: 23px;
  margin-left: 1px;
  display: flex;
  position: relative;
}

.nft-i .copyright-3 {
  color: #fff;
  letter-spacing: 0;
  width: 185px;
  height: 16px;
  margin-top: -1px;
  font-size: 14px;
  line-height: normal;
  position: relative;
}

.nft-i .tcs {
  color: #fff;
  letter-spacing: 0;
  text-align: right;
  width: 96px;
  height: 16px;
  margin-top: -1px;
  font-size: 12px;
  line-height: normal;
  position: relative;
}

.nft-i .frame-34 {
  flex: none;
  justify-content: space-around;
  align-items: flex-start;
  gap: 55px;
  width: 1034px;
  display: flex;
  position: relative;
}

.nft-i .b-2 {
  align-self: stretch;
  width: 1033px;
  position: relative;
}

.nft-i .XGT-MARKET-4 {
  color: #35cbfd;
  letter-spacing: 0;
  text-align: center;
  width: 1025px;
  font-family: Bebas Neue Pro-Regular, Helvetica;
  font-size: 26px;
  font-weight: 400;
  line-height: 23.1px;
  position: absolute;
  top: 30px;
  left: 8px;
}

.nft-i .text-wrapper-62 {
  color: #35cbfd;
  letter-spacing: 0;
  font-family: Bebas Neue Pro-Regular, Helvetica;
  font-size: 26px;
  font-weight: 400;
  line-height: 23.1px;
}

.nft-i .text-wrapper-63 {
  font-family: Bebas Neue Pro-Light, Helvetica;
  font-weight: 300;
}

.nft-i .terms-link, .nft-i .privacy-link {
  color: #f9f9f9;
  cursor: pointer;
  min-width: 125px;
  margin-top: 3px;
  margin-left: -30px;
  font-family: Bebas Neue Pro-Light, Helvetica;
  font-size: 12px;
  transition: color .3s;
}

.nft-i .terms-link:hover, .nft-i .privacy-link:hover {
  color: #35cbfd;
}

.nft-i .footer-section {
  width: 768px;
  height: 208px;
  margin-top: -50px;
}

.nft-i .footer-section .frame {
  justify-content: space-between;
  align-items: flex-start;
  width: 768px;
  display: flex;
  position: relative;
  top: 31px;
}

.nft-i .footer-section .line {
  width: 768px;
  height: 1px;
  margin-top: -.5px;
}

.nft-m .mobile-nft {
  background-position: initial;
  background-size: cover;
  border: 1px;
  flex-direction: column;
  align-items: center;
  gap: 28px;
  min-width: 358px;
  padding: 36px 8px 100px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.nft-m .frame-3420 {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 75px;
  padding: 0 0 0 2px;
  display: flex;
  position: relative;
}

.nft-m .nav-1 {
  justify-content: space-between;
  align-self: center;
  align-items: center;
  min-width: 340px;
  height: 46px;
  padding: 10px 20px;
  display: flex;
  position: relative;
}

.nft-m .logo-1 {
  width: 123px;
  height: 32px;
  margin-left: 20px;
}

.nft-m .my-custom-wrapper {
  border-radius: 12px;
  margin-right: 110px;
  display: inline-block;
  overflow: hidden;
}

.nft-m .my-custom-class {
  cursor: pointer;
  font-weight: bold;
  position: relative;
  font-family: var(--font-family-bebas_neue_pro-regular) !important;
}

.nft-m .img-wrapper {
  --webkit-backdrop-filter: blur(10px) brightness(100%);
  backdrop-filter: blur(10px) brightness();
  border-radius: 10px;
  width: 44px;
  height: 44px;
  position: absolute;
  right: 65px;
}

.nft-m .group-2 {
  z-index: 9;
  cursor: pointer;
  width: 44px;
  height: 44px;
}

.nft-m .overlap-b-group.open .burger-menu-container {
  z-index: 9;
  background-color: #d9d9d9;
  border-radius: 1px 1px 10px 10px;
  width: 250px;
  height: 410px;
  position: absolute;
  top: -5px;
  right: 60px;
}

.nft-m .overlap-b-group.open .burger-2 {
  backdrop-filter: blur(10px) brightness();
  z-index: 9;
  border-radius: 10px;
  width: 33px;
  height: 33px;
  position: absolute;
  top: 16px;
  left: 233px;
}

.nft-m .frame-3416 {
  justify-content: center;
  align-self: stretch;
  min-width: 340px;
  height: 323px;
  display: flex;
  position: relative;
}

.nft-m .heromenu-box-1 {
  flex-direction: column;
  align-items: center;
  gap: 55px;
  width: 312px;
  height: 300px;
  margin-left: -4px;
  display: flex;
  position: relative;
}

.nft-m .hero-box-1 {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 58px;
  height: 205px;
  padding: 20px 0;
  display: flex;
  position: relative;
}

.nft-m .frame-3413-1 {
  align-self: stretch;
  min-width: 312px;
  height: 185px;
  margin-bottom: -20px;
  position: relative;
}

.nft-m .overlap-group-15 {
  width: 338px;
  height: 183px;
  position: absolute;
  top: -1px;
  left: -12px;
}

.nft-m .title-1 {
  color: var(--ceramic);
  font-family: var(--font-family-bebas_neue_pro-regular);
  letter-spacing: 0;
  text-align: center;
  width: 338px;
  margin-bottom: 15px;
  font-size: 69px;
  font-weight: 400;
  line-height: 61.4px;
  position: absolute;
  top: -15px;
  left: 0;
}

.nft-m .WAXANF-ts {
  width: 354px;
  height: 1052px;
  margin-left: -78px;
  margin-right: -78px;
  position: relative;
}

.nft-m .overlap {
  width: 357px;
  height: 513px;
  position: relative;
  top: -50px;
  left: -1px;
}

.nft-m .sub {
  flex-direction: column;
  align-items: center;
  gap: 56px;
  width: 357px;
  height: 260px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.nft-m .sub-box {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 13px;
  width: 357px;
  padding: 20px 0 0;
  display: flex;
  position: relative;
}

.nft-m .JOIN-a-GLOBAL {
  color: #fcfcfc;
  letter-spacing: 0;
  text-align: center;
  width: 356px;
  height: 38px;
  margin-top: -1px;
  font-family: Bebas Neue Pro-Regular, Helvetica;
  font-size: 28px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.nft-m .line {
  width: 173.83px;
  height: 1.44px;
  position: relative;
  bottom: 150px;
}

.line-1 {
  width: 173.83px;
  height: 1.44px;
  position: relative;
  bottom: 5px;
}

.line-2 {
  width: 173.83px;
  height: 1.44px;
  position: relative;
  bottom: 10px;
}

.line-3, .line-4 {
  width: 173.83px;
  height: 1.44px;
  position: relative;
  bottom: 160px;
}

.nft-m .learn-more-about {
  color: #f9f9f9;
  letter-spacing: 1.12px;
  text-align: center;
  width: 333px;
  font-family: Bebas Neue Pro-Regular, Helvetica;
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.nft-m .frame-wrapper {
  width: 355px;
  height: 347px;
  position: absolute;
  top: 166px;
  left: 1px;
}

.nft-m .frame-2 {
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
  top: -20px;
  left: 29px;
}

.nft-m .div-wrapper {
  background-size: 100% 100%;
  width: 269.09px;
  height: 315.65px;
  position: relative;
}

.nft-m .text-wrapper-2a {
  color: #885bea;
  letter-spacing: 0;
  text-align: center;
  width: 204px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-style: italic;
  font-weight: 600;
  line-height: normal;
  position: absolute;
  top: 214px;
  left: 55px;
}

.nft-m .element-unique-avatars-wrapper {
  background-size: 100% 100%;
  width: 296.21px;
  height: 347px;
  position: relative;
  left: -15px;
}

.nft-m .element-unique-avatars {
  color: #0000;
  letter-spacing: 0;
  text-align: center;
  width: 213px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 565px;
  left: 50px;
}

.nft-m .text-wrapper-3 {
  color: #885bea;
  font-style: italic;
  font-weight: 600;
}

.nft-m .text-wrapper-4 {
  color: #3f89d5;
  font-style: italic;
  font-weight: 700;
}

.nft-m .text-wrapper-5 {
  color: #3f89d5;
  font-style: italic;
  font-weight: 600;
}

.nft-m .frame-3 {
  background-size: 100% 100%;
  width: 269.09px;
  height: 315.65px;
  position: relative;
}

.nft-m .overlap-b-group.open .frame-5 {
  cursor: pointer;
  width: 21px;
  height: 24px;
  position: relative;
  top: -95px;
  left: -113px;
}

.nft-m .each-astronaut {
  color: #885bea;
  letter-spacing: 0;
  text-align: center;
  width: 204px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-style: italic;
  font-weight: 600;
  line-height: normal;
  position: absolute;
  top: 940px;
  left: 55px;
}

.nft-m .chillville-small {
  width: 50px;
  height: 10px;
  position: absolute;
  top: 1000px;
  left: 50px;
}

.nft-m .scottybeam-small {
  width: 63px;
  height: 15px;
  position: absolute;
  top: 1000px;
  left: 104px;
}

.nft-m .pawworld-small {
  width: 51px;
  height: 11px;
  position: absolute;
  top: 1000px;
  left: 178px;
}

.nft-m .NF-tcards {
  width: 360px;
  height: 616px;
  margin-top: 90px;
  margin-left: -81px;
  margin-right: -81px;
  position: relative;
}

.nft-m .sub-box-wrapper {
  flex-direction: column;
  align-items: center;
  gap: 56px;
  width: 359px;
  display: flex;
  position: absolute;
  top: -49px;
  left: -3px;
}

.nft-m .sub-box-2 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 13px;
  width: 360px;
  margin-left: -.5px;
  margin-right: -.5px;
  padding: 20px 0 0;
  display: flex;
  position: relative;
}

.nft-m .JOIN-a-GLOBAL-2 {
  color: #fcfcfc;
  letter-spacing: 0;
  text-align: center;
  width: 349px;
  height: 42px;
  margin-top: -1px;
  font-family: Bebas Neue Pro-Regular, Helvetica;
  font-size: 28px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.nft-m .learn-more-about-2 {
  color: #f9f9f9;
  letter-spacing: 1.12px;
  text-align: center;
  width: 322px;
  font-family: Bebas Neue Pro-Regular, Helvetica;
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.nft-m .cards {
  width: 358px;
  height: 512px;
  position: absolute;
  top: 104px;
  left: 2px;
}

.nft-m .overlap-2 {
  width: 366px;
  height: 462px;
  position: absolute;
  top: -1px;
  left: -5px;
}

.nft-m .overlap-group-2 {
  width: 366px;
  height: 462px;
  position: absolute;
  top: 0;
  left: 0;
}

.nft-m .the-legend-astronaut {
  color: #885bea;
  letter-spacing: 0;
  text-align: center;
  width: 184px;
  font-family: Titillium Web, Helvetica;
  font-size: 9px;
  font-style: italic;
  font-weight: 600;
  line-height: normal;
  position: absolute;
  top: 350px;
  left: 92px;
}

.nft-m .img {
  background: #000;
  border-radius: 10px;
  width: 277px;
  height: 320px;
  position: absolute;
  top: 15px;
  left: 46px;
}

.nft-m .vector-2 {
  width: 1px;
  height: 75px;
  position: absolute;
  top: 387px;
  left: 46px;
}

.nft-m .vector-3 {
  width: 47px;
  height: 47px;
  position: absolute;
  top: 340px;
  left: 22px;
}

.nft-m .vector-4 {
  width: 47px;
  height: 47px;
  position: absolute;
  top: 340px;
  left: 299px;
}

.nft-m .rectangle {
  width: 366px;
  height: 315px;
  position: absolute;
  top: 15px;
  left: -60px;
}

.nft-m .arrow {
  cursor: pointer;
  width: 35px;
  height: 35px;
  position: absolute;
  top: 346px;
  left: 29px;
}

.nft-m .icon-arrow-circle {
  cursor: pointer;
  width: 29px;
  height: 29px;
  position: absolute;
  top: 349px;
  left: 308px;
}

.nft-m .vector-5 {
  width: 1px;
  height: 75px;
  position: absolute;
  top: 387px;
  left: 323px;
}

.nft-m .GS-button {
  all: unset;
  box-sizing: border-box;
  cursor: pointer;
  background-color: #f0f;
  border-radius: 6px;
  width: 196px;
  height: 34px;
  position: absolute;
  top: 480px;
  left: 81px;
}

.nft-m .get-started {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 196px;
  font-family: Opinion Pro Extended-Light, Helvetica;
  font-size: 15px;
  font-weight: 300;
  line-height: normal;
  position: absolute;
  top: 9px;
  left: 0;
}

.nft-m .CS-button {
  cursor: pointer;
  border: 1px solid #f0f;
  border-radius: 6px;
  width: 196px;
  height: 34px;
  position: absolute;
  top: 521px;
  left: 81px;
}

.nft-m .mysterybox {
  width: 302px;
  height: 269px;
  position: absolute;
  top: -10px;
  left: 0;
}

.nft-m .get-started-2 {
  color: #f0f;
  letter-spacing: 0;
  text-align: center;
  width: 196px;
  font-family: Opinion Pro Extended-Light, Helvetica;
  font-size: 15px;
  font-weight: 300;
  line-height: normal;
  position: absolute;
  top: 9px;
  left: 0;
}

.nft-m .NF-tcards-2 {
  width: 357px;
  height: 417px;
  margin-left: -79.5px;
  margin-right: -79.5px;
  position: relative;
}

.nft-m .overlap-3 {
  width: 357px;
  height: 345px;
  position: absolute;
  top: 90px;
  left: 0;
}

.nft-m .every-successful {
  color: #f9f9f9;
  letter-spacing: 0;
  text-align: center;
  width: 334px;
  font-family: Bebas Neue Pro-Regular, Helvetica;
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 309px;
  left: 11px;
}

.nft-m .frame-4 {
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  gap: 11px;
  width: 357px;
  height: 327px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.nft-m .overlap-group-wrapper {
  width: 139px;
  height: 146px;
  position: relative;
}

.nft-m .overlap-group-3 {
  width: 130px;
  height: 146px;
  position: relative;
  left: 5px;
}

.nft-m .p-block {
  border-radius: 24px;
  width: 130px;
  height: 130px;
  position: absolute;
  top: 11px;
  left: 0;
}

.nft-m .vector-6 {
  width: 57px;
  height: 33px;
  position: absolute;
  top: 113px;
  left: 36px;
}

.nft-m .JOIN-a-GLOBAL-3 {
  color: #fcfcfc;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 119px;
  height: 19px;
  font-family: Bebas Neue Pro-Regular, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 78px;
  left: 5px;
}

.nft-m .JOIN-a-GLOBAL-4 {
  color: #f0f;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 119px;
  height: 19px;
  font-family: Bebas Kai-Regular, Helvetica;
  font-size: 23px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 56px;
  left: 5px;
}

.nft-m .JOIN-a-GLOBAL-5 {
  color: #f0f;
  letter-spacing: 0;
  text-align: center;
  width: 119px;
  height: 19px;
  font-family: Bebas Kai-Regular, Helvetica;
  font-size: 7px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 118px;
  left: 5px;
}

.nft-m .text-wrapper-6 {
  color: #f0f;
  letter-spacing: 0;
  font-family: Bebas Kai-Regular, Helvetica;
  font-size: 7px;
  font-weight: 400;
}

.nft-m .text-wrapper-7 {
  font-size: 1px;
}

.nft-m .text-wrapper-8 {
  font-size: 21px;
}

.nft-m .vector-7 {
  width: 30px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 49px;
}

.nft-m .ROYALTY {
  width: 139px;
  height: 147px;
  position: relative;
}

.nft-m .overlap-4 {
  width: 130px;
  height: 147px;
  position: relative;
  left: 5px;
}

.nft-m .p-block-2 {
  border-radius: 24px;
  width: 130px;
  height: 130px;
  position: absolute;
  top: 12px;
  left: 0;
}

.nft-m .vector-8 {
  width: 57px;
  height: 34px;
  position: absolute;
  top: 113px;
  left: 36px;
}

.nft-m .JOIN-a-GLOBAL-6 {
  color: #fcfcfc;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 120px;
  height: 19px;
  font-family: Bebas Neue Pro-Regular, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 79px;
  left: 5px;
}

.nft-m .JOIN-a-GLOBAL-7 {
  color: #f0f;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 120px;
  height: 19px;
  font-family: Bebas Kai-Regular, Helvetica;
  font-size: 23px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 57px;
  left: 5px;
}

.nft-m .JOIN-a-GLOBAL-8 {
  color: #f0f;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 120px;
  height: 19px;
  font-family: Bebas Kai-Regular, Helvetica;
  font-size: 21px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 119px;
  left: 5px;
}

.nft-m .vector-9 {
  width: 51px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 39px;
}

.nft-m .overlap-5 {
  width: 155px;
  height: 146px;
  position: relative;
  left: -8px;
}

.nft-m .p-block-3 {
  border-radius: 24px;
  width: 130px;
  height: 130px;
  position: absolute;
  top: 11px;
  left: 13px;
}

.nft-m .vector-10 {
  width: 57px;
  height: 33px;
  position: absolute;
  top: 113px;
  left: 49px;
}

.nft-m .JOIN-a-GLOBAL-9 {
  color: #fcfcfc;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 155px;
  height: 19px;
  font-family: Bebas Neue Pro-Regular, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 78px;
  left: 0;
}

.nft-m .JOIN-a-GLOBAL-10 {
  color: #f0f;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 119px;
  height: 19px;
  font-family: Bebas Kai-Regular, Helvetica;
  font-size: 23px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 56px;
  left: 18px;
}

.nft-m .JOIN-a-GLOBAL-11 {
  width: 24px;
  height: 15px;
  position: absolute;
  top: 121px;
  left: 66px;
}

.nft-m .vector-11 {
  width: 36px;
  height: 39px;
  position: absolute;
  top: 0;
  left: 60px;
}

.nft-m .sub-2 {
  flex-direction: column;
  align-items: center;
  gap: 56px;
  width: 359px;
  display: flex;
  position: absolute;
  top: -50px;
  left: -2px;
}

.nft-m .sub-box-3 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 13px;
  width: 355px;
  padding: 20px 0 0;
  display: flex;
  position: relative;
}

.nft-m .JOIN-a-GLOBAL-12 {
  color: #fcfcfc;
  letter-spacing: 0;
  text-align: center;
  align-self: stretch;
  height: 42px;
  margin-top: -1px;
  font-family: Bebas Neue Pro-Regular, Helvetica;
  font-size: 28px;
  font-weight: 400;
  line-height: normal;
  position: relative;
  bottom: 150px;
}

.nft-m .learn-more-about-3 {
  color: #f9f9f9;
  letter-spacing: 1.12px;
  text-align: center;
  align-self: stretch;
  font-family: Bebas Neue Pro-Regular, Helvetica;
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
  position: relative;
  bottom: 160px;
}

.nft-m .MYSTERY-BOX {
  width: 355px;
  height: 421px;
  margin-left: -78.5px;
  margin-right: -78.5px;
  position: relative;
}

.nft-m .sub-3 {
  flex-direction: column;
  align-items: center;
  gap: 56px;
  width: 356px;
  display: flex;
  position: absolute;
  top: -50px;
  left: -1px;
}

.nft-m .MBOX {
  width: 301px;
  height: 345px;
  position: absolute;
  top: 123px;
  left: 27px;
}

.nft-m .overlap-group-4 {
  width: 302px;
  height: 354px;
  position: relative;
}

.nft-m .vector-12 {
  width: 302px;
  height: 269px;
  position: absolute;
  top: 0;
  left: 0;
}

.nft-m .vector-13 {
  cursor: pointer;
  width: 30px;
  height: 29px;
  position: absolute;
  top: 20px;
  left: 253px;
}

.nft-m .vector-14 {
  width: 302px;
  height: 65px;
  position: absolute;
  top: 289px;
  left: 0;
}

.nft-m .JOIN-a-GLOBAL-13 {
  color: #fcfcfc;
  letter-spacing: 0;
  text-align: center;
  width: 150px;
  font-family: Bebas Neue Pro-Regular, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 327px;
  left: 0;
}

.nft-m .JOIN-a-GLOBAL-14 {
  color: #fcfcfc;
  letter-spacing: 0;
  text-align: center;
  width: 150px;
  font-family: Bebas Neue Pro-Regular, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 327px;
  left: 151px;
}

.nft-m .JOIN-a-GLOBAL-15 {
  color: #f0f;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 150px;
  font-family: Bebas Neue Pro-SemiExpandedBold, Helvetica;
  font-size: 23px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 301px;
  left: 0;
}

.nft-m .JOIN-a-GLOBAL-16 {
  color: #f0f;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  width: 150px;
  font-family: Bebas Neue Pro-SemiExpandedBold, Helvetica;
  font-size: 23px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 301px;
  left: 151px;
}

.nft-m .MI-nt {
  background-color: #f0f;
  border-radius: 10px;
  width: 226px;
  height: 39px;
  position: absolute;
  top: 260px;
  left: 38px;
}

.nft-m .get-started-3 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 226px;
  font-family: Opinion Pro Extended-Light, Helvetica;
  font-size: 18px;
  font-weight: 300;
  line-height: normal;
  position: absolute;
  top: 11px;
  left: 0;
}

.nft-m .fees-so-low-it-will-1 {
  color: var(--ceramic);
  font-family: var(--font-family-bebas_neue_pro-light);
  letter-spacing: 0;
  text-align: center;
  margin-bottom: 5px;
  font-size: 35px;
  font-weight: 300;
  line-height: 31.5px;
  position: absolute;
  top: 90px;
  left: 28px;
}

.nft-m .vector-1-1 {
  width: 182px;
  height: 2px;
  position: absolute;
  top: 270px;
  left: 50%;
  transform: translateX(-50%);
}

.nft-m .swap-box-1 {
  min-width: 358px;
  height: 547px;
  margin-left: -9px;
  margin-right: -9px;
  position: relative;
}

.nft-m .frame-container-1 {
  border: 1px solid;
  border-color: var(--scooter);
  border-radius: 16px;
  flex-direction: column;
  align-items: center;
  width: 338px;
  min-height: 547px;
  padding: 22px 0;
  display: flex;
  position: relative;
  left: 9px;
}

.nft-m .frame-1-1 {
  align-items: center;
  gap: 14px;
  width: 338px;
  margin-right: 2px;
  padding: 0 0 0 27px;
  display: flex;
  position: relative;
}

.nft-m .place-1 {
  letter-spacing: 0;
  width: 54px;
  line-height: normal;
  position: relative;
}

.nft-m .bnb-3 {
  min-width: 239px;
  height: 45px;
  margin-right: 5%;
  position: relative;
}

.nft-m .overlap-group-17 {
  border: 1px solid;
  border-color: var(--cerulean);
  cursor: pointer;
  border-radius: 7px;
  align-items: flex-start;
  gap: 6px;
  min-width: 237px;
  height: 45px;
  padding: 5.8px 10.6px;
  display: flex;
}

.nft-m .path-5 {
  width: 32px;
  height: 31px;
}

.nft-m .overlap-group1-7 {
  align-self: center;
  width: 166px;
  height: 21px;
  margin-bottom: 0%;
  position: relative;
}

.nft-m .path-535-3 {
  width: 12px;
  height: 6px;
  position: absolute;
  top: 8px;
  left: 154px;
}

.nft-m .bnb-chain-3 {
  letter-spacing: 0;
  width: 154px;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.nft-m .frame-3409 {
  justify-content: center;
  align-items: center;
  width: 326px;
  margin-top: 19px;
  margin-right: 2px;
  display: flex;
  position: relative;
}

.nft-m .tb-1, .nft-m .bb-1 {
  min-width: 330px;
  height: 113px;
  margin-right: -4px;
  position: relative;
}

.nft-m .flex-row-2 {
  background-color: var(--black-rock);
  border-radius: 12px;
  align-items: center;
  gap: 11px;
  min-width: 326px;
  height: 113px;
  padding: 2px 17px;
  display: flex;
}

.nft-m .flex-col-2 {
  flex-direction: column;
  align-self: flex-end;
  align-items: flex-start;
  gap: 18px;
  width: 182px;
  min-height: 94px;
  display: flex;
}

.nft-m .send-1 {
  letter-spacing: 0;
  width: 102px;
  min-height: 19px;
  margin-left: 1.38px;
  line-height: normal;
}

.nft-m .text {
  letter-spacing: 0;
  width: 182px;
  min-height: 57px;
  line-height: normal;
}

.nft-m .flex-col-3 {
  flex-direction: column;
  align-items: flex-start;
  gap: 33px;
  width: 94px;
  min-height: 83px;
  margin-top: 6.37px;
  display: flex;
}

.nft-m .overlap-group-container {
  align-items: flex-start;
  gap: 5px;
  min-width: 94px;
  margin-left: .04px;
  display: flex;
}

.nft-m .overlap-group1-8 {
  background-size: 100% 100%;
  justify-content: flex-end;
  align-items: flex-start;
  min-width: 45px;
  height: 19px;
  padding: 2.1px 11.7px;
  display: flex;
}

.nft-m .name-1 {
  letter-spacing: 0;
  text-align: center;
  width: 21px;
  min-height: 14px;
  line-height: normal;
}

.nft-m .overlap-group-18 {
  background-size: 100% 100%;
  justify-content: flex-end;
  align-self: flex-end;
  align-items: flex-start;
  min-width: 45px;
  height: 19px;
  padding: 1.9px 9.9px;
  display: flex;
}

.nft-m .half-1 {
  letter-spacing: 0;
  text-align: center;
  width: 23px;
  min-height: 14px;
  line-height: normal;
}

.nft-m .frame-3404 {
  align-items: center;
  min-width: 94px;
  display: flex;
}

.nft-m .price-1 {
  letter-spacing: 0;
  width: 43px;
  min-height: 21px;
  margin-bottom: 2.95px;
  margin-left: 6px;
  line-height: normal;
}

.nft-m .path-530-1 {
  width: 12px;
  height: 7px;
  margin-top: 3.03px;
  margin-left: 1px;
}

.nft-m .frame-3407 {
  width: 35px;
  height: 34px;
  margin-top: 19px;
  margin-right: 2px;
}

.nft-m .frame-2-1 {
  align-items: center;
  gap: 14px;
  width: 338px;
  height: 45px;
  margin-top: 19px;
  margin-right: 2px;
  padding: 0 0 0 27px;
  display: flex;
  position: relative;
}

.nft-m .to-1 {
  letter-spacing: 0;
  width: 55px;
  line-height: normal;
  position: relative;
}

.nft-m .usdt-6 {
  min-width: 238px;
  height: 45px;
  margin-right: 5%;
  position: relative;
}

.nft-m .overlap-group-19 {
  border: 1px solid;
  border-color: var(--cerulean);
  border-radius: 7px;
  align-items: flex-start;
  gap: 5px;
  min-width: 236px;
  height: 45px;
  padding: 6.8px 11.6px;
  display: flex;
}

.nft-m .path-538-3 {
  width: 31px;
  height: 31px;
}

.nft-m .usdt-7 {
  align-self: center;
  width: 166px;
  height: 21px;
  margin-top: 5%;
  position: relative;
}

.nft-m .frame-3410 {
  justify-content: center;
  align-items: center;
  width: 326px;
  margin-top: 22px;
  margin-right: 2px;
  display: flex;
  position: relative;
}

.nft-m .overlap-group-20 {
  background-color: var(--black-rock);
  border-radius: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 18px;
  width: 326px;
  min-height: 113px;
  padding: 2px 17px;
  display: flex;
}

.nft-m .send-2 {
  letter-spacing: 0;
  width: 150px;
  min-height: 19px;
  margin-top: 15px;
  margin-left: 1px;
  line-height: normal;
}

.nft-m .frame-6-3 {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  gap: 4px;
  width: fit-content;
  margin-top: 24px;
  margin-right: 1px;
  transition: all .2s;
  display: flex;
  position: relative;
}

.nft-m .frame-6-3:hover {
  transform: scale(1.1);
}

.nft-m .ab-3 {
  min-width: 289px;
  height: 40.39px;
  margin-right: -2px;
  position: relative;
}

.nft-m .overlap-group-21 {
  background-size: 100% 100%;
  align-items: flex-start;
  min-width: 287px;
  height: 40px;
  padding: 7px 0;
  display: flex;
}

.nft-m .approve-3 {
  letter-spacing: 0;
  text-align: center;
  width: 287px;
  min-height: 25px;
  line-height: normal;
}

.nft-m .frame-3417 {
  justify-content: center;
  min-width: 334px;
  height: 551px;
  display: flex;
  position: relative;
}

.nft-m .sub-1 {
  flex-direction: column;
  align-items: center;
  gap: 42px;
  width: 318px;
  height: 547px;
  margin-left: -2px;
  display: flex;
  position: relative;
}

.nft-m .sub-box-1 {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  padding: 20px 0 0;
  display: flex;
  position: relative;
  bottom: -50px;
}

.nft-m .join-a-global-community-1 {
  color: var(--ceramic);
  font-family: var(--font-family-oswald);
  letter-spacing: 0;
  text-align: center;
  align-self: stretch;
  height: 27px;
  margin-top: -1px;
  font-size: 19px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.nft-m .line-33-1 {
  min-width: 148px;
  height: 1.44px;
  position: relative;
}

.nft-m .learn-more-about-xio-1 {
  color: var(--hint-of-red);
  font-family: var(--font-family-titillium_web);
  font-size: var(--font-size-xl);
  letter-spacing: 0;
  text-align: center;
  align-self: stretch;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.nft-m .email-box {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  width: 310px;
  height: 76px;
  margin-left: 50px;
  display: flex;
  position: relative;
  bottom: -20px;
}

.nft-m .frame-3395-1 {
  background-color: var(--white);
  border: 1.2px solid;
  border-color: var(--kingfisher-daisy);
  border-radius: 8px;
  align-items: center;
  width: 309px;
  height: 35px;
  margin-top: -4.5px;
  padding: 14.37px;
  display: flex;
  position: relative;
}

.nft-m .eg-bethe1sttoknow-1 {
  letter-spacing: 0;
  opacity: .3;
  text-align: center;
  white-space: nowrap;
  background-color: #0000;
  border: 0;
  min-width: 284px;
  height: 22px;
  margin-top: -9.06px;
  margin-bottom: -6.67px;
  margin-right: -3.73px;
  padding: 0;
  line-height: normal;
  position: relative;
}

.nft-m .eg-bethe1sttoknow-1::placeholder {
  color: #2a2a2a99;
}

.nft-m .social-box-1 {
  min-width: 293px;
  height: 284px;
  position: relative;
  top: 80px;
}

.nft-m .flex-col-4 {
  flex-direction: column;
  align-items: flex-start;
  gap: 28px;
  width: 128px;
  min-height: 279px;
  display: flex;
  position: absolute;
  top: 2px;
  left: 4px;
}

.nft-m .flex-col-5 {
  flex-direction: column;
  align-items: flex-start;
  gap: 28px;
  width: 128px;
  min-height: 279px;
  display: flex;
  position: absolute;
  top: 2px;
  left: 163px;
}

.nft-m .overlap-group3 {
  cursor: pointer;
  background-size: 100% 100%;
  justify-content: flex-end;
  align-items: flex-end;
  min-width: 126px;
  height: 126px;
  margin-left: 0;
  padding: 7.5px 8.4px;
  transition: all .2s;
  display: flex;
}

.nft-m .overlap-group3:hover {
  transform: scale(1.1);
}

.nft-m .follow-xionfinance-for-2 {
  letter-spacing: 0;
  text-align: center;
  width: 105px;
  min-height: 0;
  font-size: 9px;
  line-height: normal;
}

.nft-m .footer-1 {
  background-color: #0000;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 20.4px;
  min-width: 334px;
  height: 87px;
  display: flex;
  position: relative;
  top: 50px;
}

.nft-m .line-34-1 {
  width: 337px;
  height: 1px;
  margin-top: 21.6px;
}

.nft-m .frame-3399-1 {
  justify-content: center;
  align-items: flex-start;
  gap: 140px;
  width: 329px;
  height: 23px;
  margin-left: -2px;
  display: flex;
  position: relative;
}

.nft-m .copyright-1 {
  color: #f9f9f9;
  letter-spacing: 0;
  width: 105px;
  height: 16px;
  margin-top: -1px;
  margin-left: -4.5px;
  font-family: Bebas Neue Pro-Light, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.nft-m .tcs {
  color: #fff;
  font-family: var(--font-family-titillium_web);
  letter-spacing: 0;
  text-align: right;
  width: 93px;
  height: 16px;
  margin-top: -1px;
  margin-right: -4.5px;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.email-link {
  color: inherit;
  text-decoration: none;
  transition: color .3s;
}

.email-link:hover {
  color: #33a4d8;
}

.nft-m .b-2 {
  align-self: center;
  width: 360px;
  position: relative;
  top: 55px;
  left: 70px;
}

.nft-m .XGT-MARKET-4 {
  color: #35cbfd;
  letter-spacing: 0;
  text-align: center;
  width: 210px;
  font-family: Bebas Neue Pro-Regular, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 23.1px;
  position: relative;
  top: 30px;
  left: 8px;
}

.nft-m .text-wrapper-62 {
  color: #35cbfd;
  letter-spacing: 0;
  font-family: Bebas Neue Pro-Regular, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 23.1px;
}

.nft-m .text-wrapper-63 {
  font-family: Bebas Neue Pro-Light, Helvetica;
  font-weight: 300;
}

.nft-m .terms-link, .nft-m .privacy-link {
  color: #f9f9f9;
  cursor: pointer;
  min-width: 125px;
  margin-top: 3px;
  margin-left: -30px;
  font-family: Bebas Neue Pro-Light, Helvetica;
  font-size: 12px;
  transition: color .3s;
}

.nft-m .terms-link:hover, .nft-m .privacy-link:hover {
  color: #35cbfd;
}

.nft-m .fdpopup .div {
  background-image: url("rectangle-2921.03b61ec1.png");
  background-size: 100% 100%;
  width: 311px;
  height: 311px;
  margin-top: -67px;
  margin-left: -2px;
  position: absolute;
}

.nft-m .nftfdpopup .close {
  cursor: pointer;
  z-index: 99;
  width: 14px;
  height: 13px;
  position: absolute;
  top: 238px;
  left: 313px;
}

.nft-m .nftsfpopup .div, .nft-m .nftpspopup .div {
  background-image: url("rectangle-2921.03b61ec1.png");
  background-size: 100% 100%;
  width: 311px;
  height: 311px;
  margin-top: -67px;
  margin-left: -2px;
  position: absolute;
}

.nft-m .nftsfpopup .overlap {
  width: 125px;
  height: 19px;
  margin-left: -20px;
  position: relative;
}

.nft-m .nftsfpopup .overlap-group-wrapper {
  width: 60px;
  height: 10px;
  position: absolute;
  top: 0;
  left: 0;
}

.nft-m .nftsfpopup .overlap-wrapper {
  width: 60px;
  height: 10px;
  position: absolute;
  top: 48px;
  left: 103px;
}

.text-wrapper-16, .div-example {
  z-index: 10;
}

.terms-d .terms-and-conditions {
  background-position: initial;
  background-size: cover;
  border: 1px;
  flex-direction: column;
  align-items: center;
  gap: 115px;
  min-width: 1440px;
  padding: 81px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.terms-d .frame-3414 {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  display: flex;
  position: relative;
}

.terms-d .nav {
  justify-content: space-between;
  align-self: center;
  align-items: center;
  min-width: 1278px;
  height: 46px;
  padding: 10px 50px 10px 40px;
  display: flex;
  position: absolute;
}

.terms-d .logo {
  width: 246.25px;
  height: 45.81px;
  margin-top: -.2px;
}

.terms-d .img-wrapper {
  --webkit-backdrop-filter: blur(10px) brightness(100%);
  backdrop-filter: blur(10px) brightness();
  border-radius: 10px;
  width: 33px;
  height: 33px;
  position: relative;
  left: 600px;
}

.terms-d .my-custom-wrapper {
  border-radius: 12px;
  display: inline-block;
  overflow: hidden;
}

.terms-d .my-custom-class {
  cursor: pointer;
  font-weight: bold;
  position: relative;
  font-family: var(--font-family-bebas_neue_pro-regular) !important;
}

.terms-d .group-2 {
  z-index: 9;
  cursor: pointer;
  width: 44px;
  height: 44px;
  position: relative;
  top: -6px;
  right: 10px;
}

.terms-d .terms-content {
  width: 678px;
  margin-top: 150px;
  margin-bottom: 50px;
  margin-left: 70px;
}

.terms-content h1 {
  font: var(--font-family-bebas_neue_pro-regular);
  color: #fff;
  font-size: 45px;
}

.terms-content p {
  font: var(--font-family-bebas_neue_pro-regular);
  color: #fff;
  margin-top: 20px;
  font-size: 18px;
}

.terms-d .footer {
  background-color: #0000;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 37.6px;
  min-width: 1194.56px;
  height: 104.22px;
  display: flex;
  position: relative;
  top: 45px;
}

.terms-d .line-34 {
  width: 1194.56px;
  height: 1px;
  margin-top: -.4px;
}

.terms-d .frame-3399 {
  justify-content: center;
  align-items: flex-start;
  gap: 984px;
  width: 1187px;
  height: 16px;
  margin-left: -1px;
  display: flex;
  position: relative;
}

.terms-d .copyright {
  color: #f9f9f9;
  letter-spacing: 0;
  width: 107px;
  height: 16px;
  margin-top: -1px;
  font-family: Bebas Neue Pro-Regular, Helvetica;
  font-size: 14px;
  line-height: normal;
  position: relative;
}

.terms-d .about-us-blog-t-cs-privacy-policy {
  letter-spacing: 0;
  text-align: right;
  width: 96px;
  margin-top: -1px;
  font-size: 14px;
  line-height: normal;
  position: relative;
  right: 20px;
}

.terms-d .terms-link, .terms-d .privacy-link {
  color: #f9f9f9;
  cursor: pointer;
  min-width: 125px;
  margin-top: 3px;
  margin-left: -14px;
  font-family: Bebas Neue Pro-Light, Helvetica;
  font-size: 12px;
  transition: color .3s;
}

.terms-d .terms-link:hover, .terms-d .privacy-link:hover {
  color: #35cbfd;
}

.terms-i .terms-and-conditions {
  background-position: initial;
  background-size: cover;
  border: 1px;
  flex-direction: column;
  align-items: center;
  gap: 80px;
  min-width: 768px;
  padding: 75px 45px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.terms-i .frame-3416-2 {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 75px;
  display: flex;
  position: relative;
}

.terms-i .nav-3 {
  justify-content: space-between;
  align-self: center;
  align-items: center;
  min-width: 688px;
  height: 46px;
  padding: 10px 105px;
  display: flex;
  position: relative;
}

.terms-i .logo-3 {
  width: 178px;
  height: 33px;
  margin-top: -1px;
  margin-left: -80px;
}

.terms-i .my-custom-wrapper {
  border-radius: 12px;
  display: inline-block;
  overflow: hidden;
}

.terms-i .my-custom-class {
  cursor: pointer;
  font-weight: bold;
  position: relative;
  font-family: var(--font-family-bebas_neue_pro-regular) !important;
}

.terms-i .img-wrapper {
  --webkit-backdrop-filter: blur(10px) brightness(100%);
  backdrop-filter: blur(10px) brightness();
  cursor: pointer;
  border-radius: 10px;
  width: 44px;
  height: 44px;
  position: absolute;
  right: 35px;
}

.terms-i .overlap-b-group.open .burger-menu-container {
  z-index: 9;
  background-color: #d9d9d9;
  border-radius: 1px 1px 10px 10px;
  width: 250px;
  height: 410px;
  position: absolute;
  top: -80px;
  right: -79px;
}

.terms-i .frame-3413-4 {
  min-width: 677px;
  height: 342px;
  display: flex;
  position: relative;
  top: 10px;
}

.terms-i .flex-row-4 {
  align-items: center;
  gap: 205px;
  min-width: 559px;
  height: 57px;
  display: flex;
}

.terms-i .terms-content {
  width: 568px;
  margin-top: 50px;
  margin-bottom: 50px;
}

.terms-i .terms-content h1 {
  font: var(--font-family-bebas_neue_pro-regular);
  color: #fff;
  font-size: 40px;
}

.terms-i .terms-content p {
  font: var(--font-family-bebas_neue_pro-regular);
  color: #fff;
  margin-top: 20px;
  font-size: 16px;
}

.terms-i .footer-3 {
  background-color: #0000;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 30.4px;
  min-width: 763px;
  height: 54px;
  display: flex;
  position: relative;
  top: 0;
}

.terms-i .line-34-3 {
  width: 690px;
  height: 1px;
  margin-top: -.5px;
}

.terms-i .frame-3399-3 {
  justify-content: center;
  align-items: flex-start;
  gap: 482px;
  width: 685px;
  height: 23px;
  margin-left: 1px;
  display: flex;
  position: relative;
}

.terms-i .copyright-3 {
  color: #fff;
  letter-spacing: 0;
  width: 107px;
  margin-top: -1px;
  font-family: Bebas Neue Pro-Light, Helvetica;
  font-size: 14px;
  line-height: normal;
  position: relative;
}

.terms-i .about-us-blog-t-cs-privacy-policy-3 {
  letter-spacing: 0;
  color: #fff;
  text-align: right;
  width: 96px;
  margin-top: -1px;
  font-size: 12px;
  line-height: normal;
  position: relative;
  right: 3px;
}

.terms-i .terms-link, .terms-i .privacy-link {
  color: #f9f9f9;
  cursor: pointer;
  min-width: 125px;
  margin-top: 3px;
  margin-left: -30px;
  font-family: Bebas Neue Pro-Light, Helvetica;
  font-size: 12px;
  transition: color .3s;
}

.terms-i .terms-link:hover, .terms-i .privacy-link:hover {
  color: #35cbfd;
}

.terms-i .footer-section {
  width: 768px;
  height: 208px;
  margin-top: 25px;
}

.terms-i .footer-section .frame {
  justify-content: space-between;
  align-items: flex-start;
  width: 768px;
  display: flex;
  position: relative;
  top: 31px;
}

.terms-i .footer-section .line {
  width: 768px;
  height: 1px;
  margin-top: -.5px;
}

.terms-m .terms-and-conditions {
  background-position: initial;
  background-size: cover;
  border: 1px;
  flex-direction: column;
  align-items: center;
  gap: 28px;
  min-width: 358px;
  padding: 66px 8px 100px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.terms-m .frame-3420 {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 75px;
  padding: 0 0 0 2px;
  display: flex;
  position: relative;
}

.terms-m .nav-1 {
  justify-content: space-between;
  align-self: center;
  align-items: center;
  min-width: 340px;
  height: 46px;
  padding: 10px 20px;
  display: flex;
  position: relative;
}

.terms-m .logo-1 {
  width: 123px;
  height: 32px;
  margin-left: 20px;
}

.terms-m .my-custom-wrapper {
  border-radius: 12px;
  margin-right: 110px;
  display: inline-block;
  overflow: hidden;
}

.terms-m .my-custom-class {
  cursor: pointer;
  font-weight: bold;
  position: relative;
  font-family: var(--font-family-bebas_neue_pro-regular) !important;
}

.terms-m .img-wrapper {
  --webkit-backdrop-filter: blur(10px) brightness(100%);
  backdrop-filter: blur(10px) brightness();
  border-radius: 10px;
  width: 44px;
  height: 44px;
  position: absolute;
  right: 65px;
}

.terms-m .group-2 {
  z-index: 9;
  cursor: pointer;
  width: 44px;
  height: 44px;
}

.terms-m .overlap-b-group.open .burger-menu-container {
  z-index: 9;
  background-color: #d9d9d9;
  border-radius: 1px 1px 10px 10px;
  width: 250px;
  height: 410px;
  position: absolute;
  top: -65px;
  right: 60px;
}

.terms-m .terms-content {
  width: 300px;
  margin-top: 5px;
  margin-bottom: -50px;
}

.terms-m .terms-content h1 {
  font: var(--font-family-bebas_neue_pro-regular);
  color: #fff;
  font-size: 25px;
}

.terms-m .terms-content p {
  font: var(--font-family-bebas_neue_pro-regular);
  color: #fff;
  margin-top: 20px;
  font-size: 12px;
}

.terms-m .footer-1 {
  background-color: #0000;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 20.4px;
  min-width: 334px;
  height: 87px;
  display: flex;
  position: relative;
  top: 50px;
}

.terms-m .line-34-1 {
  width: 337px;
  height: 1px;
  margin-top: 21.6px;
}

.terms-m .frame-3399-1 {
  justify-content: center;
  align-items: flex-start;
  gap: 140px;
  width: 329px;
  height: 23px;
  margin-left: -2px;
  display: flex;
  position: relative;
}

.terms-m .copyright-1 {
  color: #f9f9f9;
  letter-spacing: 0;
  width: 105px;
  height: 16px;
  margin-top: -1px;
  margin-left: -4.5px;
  font-family: Bebas Neue Pro-Light, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.terms-m .about-us-blog-t-cs-privacy-policy-1 {
  color: #fff;
  font-family: var(--font-family-titillium_web);
  letter-spacing: 0;
  text-align: right;
  width: 93px;
  height: 16px;
  margin-top: -1px;
  margin-right: -4.5px;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.terms-m .terms-link, .terms-m .privacy-link {
  color: #f9f9f9;
  cursor: pointer;
  min-width: 125px;
  margin-top: 3px;
  margin-left: -30px;
  font-family: Bebas Neue Pro-Light, Helvetica;
  font-size: 12px;
  transition: color .3s;
}

.terms-m .terms-link:hover, .terms-m .privacy-link:hover {
  color: #35cbfd;
}

.privacy-d .privacy-policy {
  background-position: initial;
  background-size: cover;
  border: 1px;
  flex-direction: column;
  align-items: center;
  gap: 115px;
  min-width: 1440px;
  padding: 81px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.privacy-d .frame-3414 {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  display: flex;
  position: relative;
}

.privacy-d .nav {
  justify-content: space-between;
  align-self: center;
  align-items: center;
  min-width: 1278px;
  height: 46px;
  padding: 10px 50px 10px 40px;
  display: flex;
  position: absolute;
}

.privacy-d .logo {
  width: 246.25px;
  height: 45.81px;
  margin-top: -.2px;
}

.privacy-d .img-wrapper {
  --webkit-backdrop-filter: blur(10px) brightness(100%);
  backdrop-filter: blur(10px) brightness();
  border-radius: 10px;
  width: 33px;
  height: 33px;
  position: relative;
  left: 600px;
}

.privacy-d .my-custom-wrapper {
  border-radius: 12px;
  display: inline-block;
  overflow: hidden;
}

.privacy-d .my-custom-class {
  cursor: pointer;
  font-weight: bold;
  position: relative;
  font-family: var(--font-family-bebas_neue_pro-regular) !important;
}

.privacy-d .group-2 {
  z-index: 9;
  cursor: pointer;
  width: 44px;
  height: 44px;
  position: relative;
  top: -6px;
  right: 10px;
}

.privacy-d .terms-content {
  width: 678px;
  margin-top: 150px;
  margin-bottom: 50px;
  margin-left: 70px;
}

.privacy-d .terms-content h1 {
  font: var(--font-family-bebas_neue_pro-regular);
  color: #fff;
  font-size: 45px;
}

.privacy-d .terms-content p {
  font: var(--font-family-bebas_neue_pro-regular);
  color: #fff;
  margin-top: 20px;
  font-size: 18px;
}

.privacy-d .footer {
  background-color: #0000;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 37.6px;
  min-width: 1194.56px;
  height: 104.22px;
  display: flex;
  position: relative;
  top: 45px;
}

.privacy-d .line-34 {
  width: 1194.56px;
  height: 1px;
  margin-top: -.4px;
}

.privacy-d .frame-3399 {
  justify-content: center;
  align-items: flex-start;
  gap: 984px;
  width: 1187px;
  height: 16px;
  margin-left: -1px;
  display: flex;
  position: relative;
}

.privacy-d .copyright {
  color: #f9f9f9;
  letter-spacing: 0;
  width: 107px;
  height: 16px;
  margin-top: -1px;
  font-family: Bebas Neue Pro-Regular, Helvetica;
  font-size: 14px;
  line-height: normal;
  position: relative;
}

.privacy-d .about-us-blog-t-cs-privacy-policy {
  letter-spacing: 0;
  text-align: right;
  width: 96px;
  margin-top: -1px;
  font-size: 14px;
  line-height: normal;
  position: relative;
  right: 20px;
}

.privacy-d .terms-link, .privacy-d .privacy-link {
  color: #f9f9f9;
  cursor: pointer;
  min-width: 125px;
  margin-top: 3px;
  margin-left: -14px;
  font-family: Bebas Neue Pro-Light, Helvetica;
  font-size: 12px;
  transition: color .3s;
}

.privacy-d .terms-link:hover, .privacy-d .privacy-link:hover {
  color: #35cbfd;
}

.privacy-i .privacy-policy {
  background-position: initial;
  background-size: cover;
  border: 1px;
  flex-direction: column;
  align-items: center;
  gap: 80px;
  min-width: 768px;
  padding: 75px 45px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.privacy-i .frame-3416-2 {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 75px;
  display: flex;
  position: relative;
}

.privacy-i .nav-3 {
  justify-content: space-between;
  align-self: center;
  align-items: center;
  min-width: 688px;
  height: 46px;
  padding: 10px 105px;
  display: flex;
  position: relative;
}

.privacy-i .logo-3 {
  width: 178px;
  height: 33px;
  margin-top: -1px;
  margin-left: -80px;
}

.privacy-i .my-custom-wrapper {
  border-radius: 12px;
  display: inline-block;
  overflow: hidden;
}

.privacy-i .my-custom-class {
  cursor: pointer;
  font-weight: bold;
  position: relative;
  font-family: var(--font-family-bebas_neue_pro-regular) !important;
}

.privacy-i .img-wrapper {
  --webkit-backdrop-filter: blur(10px) brightness(100%);
  backdrop-filter: blur(10px) brightness();
  cursor: pointer;
  border-radius: 10px;
  width: 44px;
  height: 44px;
  position: absolute;
  right: 35px;
}

.privacy-i .overlap-b-group.open .burger-menu-container {
  z-index: 9;
  background-color: #d9d9d9;
  border-radius: 1px 1px 10px 10px;
  width: 250px;
  height: 410px;
  position: absolute;
  top: -5px;
  right: 0;
}

.privacy-i .overlap-b-group.open .burger-2 {
  backdrop-filter: blur(10px) brightness();
  z-index: 9;
  border-radius: 10px;
  width: 33px;
  height: 33px;
  position: absolute;
  top: 16px;
  left: 233px;
}

.privacy-i .frame-3413-4 {
  min-width: 677px;
  height: 342px;
  display: flex;
  position: relative;
  top: 10px;
}

.privacy-i .flex-row-4 {
  align-items: center;
  gap: 205px;
  min-width: 559px;
  height: 57px;
  display: flex;
}

.privacy-i .terms-content {
  width: 568px;
  margin-top: 50px;
  margin-bottom: 50px;
}

.privacy-i .terms-content h1 {
  font: var(--font-family-bebas_neue_pro-regular);
  color: #fff;
  font-size: 40px;
}

.privacy-i .terms-content p {
  font: var(--font-family-bebas_neue_pro-regular);
  color: #fff;
  margin-top: 20px;
  font-size: 16px;
}

.privacy-i .footer-3 {
  background-color: #0000;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 30.4px;
  min-width: 763px;
  height: 84px;
  margin-left: -37.5px;
  margin-right: -37.5px;
  display: flex;
  position: absolute;
  top: 3770px;
}

.privacy-i .line-34-3 {
  width: 690px;
  height: 1px;
  margin-top: -.5px;
}

.privacy-i .frame-3399-3 {
  justify-content: center;
  align-items: flex-start;
  gap: 482px;
  width: 685px;
  height: 23px;
  margin-left: 1px;
  display: flex;
  position: relative;
}

.privacy-i .copyright-3 {
  color: #fff;
  letter-spacing: 0;
  width: 107px;
  margin-top: -1px;
  font-family: Bebas Neue Pro-Light, Helvetica;
  font-size: 14px;
  line-height: normal;
  position: relative;
}

.privacy-i .about-us-blog-t-cs-privacy-policy-3 {
  letter-spacing: 0;
  color: #fff;
  text-align: right;
  width: 96px;
  margin-top: -1px;
  font-size: 12px;
  line-height: normal;
  position: relative;
  right: 3px;
}

.privacy-i .terms-link, .privacy-i .privacy-link {
  color: #f9f9f9;
  cursor: pointer;
  min-width: 125px;
  margin-top: 3px;
  margin-left: -30px;
  font-family: Bebas Neue Pro-Light, Helvetica;
  font-size: 12px;
  transition: color .3s;
}

.privacy-i .terms-link:hover, .privacy-i .privacy-link:hover {
  color: #35cbfd;
}

.privacy-i .footer-section {
  width: 768px;
  height: 208px;
  margin-top: 25px;
}

.privacy-i .footer-section .frame {
  justify-content: space-between;
  align-items: flex-start;
  width: 768px;
  display: flex;
  position: relative;
  top: 31px;
}

.privacy-i .footer-section .line {
  width: 768px;
  height: 1px;
  margin-top: -.5px;
}

.privacy-m .privacy-policy {
  background-position: initial;
  background-size: cover;
  border: 1px;
  flex-direction: column;
  align-items: center;
  gap: 28px;
  min-width: 358px;
  padding: 66px 8px 100px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.privacy-m .frame-3420 {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 75px;
  padding: 0 0 0 2px;
  display: flex;
  position: relative;
  bottom: 25px;
}

.privacy-m .nav-1 {
  justify-content: space-between;
  align-self: center;
  align-items: center;
  min-width: 340px;
  height: 46px;
  padding: 10px 20px;
  display: flex;
  position: relative;
}

.privacy-m .logo-1 {
  width: 123px;
  height: 32px;
  margin-left: 20px;
}

.privacy-m .my-custom-wrapper {
  border-radius: 12px;
  margin-right: 110px;
  display: inline-block;
  overflow: hidden;
}

.privacy-m .my-custom-class {
  cursor: pointer;
  font-weight: bold;
  position: relative;
  font-family: var(--font-family-bebas_neue_pro-regular) !important;
}

.privacy-m .img-wrapper {
  --webkit-backdrop-filter: blur(10px) brightness(100%);
  backdrop-filter: blur(10px) brightness();
  border-radius: 10px;
  width: 44px;
  height: 44px;
  position: absolute;
  right: 65px;
}

.privacy-m .group-2 {
  z-index: 9;
  cursor: pointer;
  width: 44px;
  height: 44px;
}

.privacy-m .overlap-b-group.open .burger-menu-container {
  z-index: 9;
  background-color: #d9d9d9;
  border-radius: 1px 1px 10px 10px;
  width: 250px;
  height: 410px;
  position: absolute;
  top: -5px;
  right: 70px;
}

.privacy-m .overlap-b-group.open .burger-2 {
  backdrop-filter: blur(10px) brightness();
  z-index: 9;
  border-radius: 10px;
  width: 33px;
  height: 33px;
  position: absolute;
  top: 16px;
  left: 233px;
}

.privacy-m .terms-content {
  width: 300px;
  margin-top: 5px;
  margin-bottom: -50px;
}

.privacy-m .terms-content h1 {
  font: var(--font-family-bebas_neue_pro-regular);
  color: #fff;
  font-size: 25px;
}

.privacy-m .terms-content p {
  font: var(--font-family-bebas_neue_pro-regular);
  color: #fff;
  margin-top: 20px;
  font-size: 12px;
}

.privacy-m .footer-1 {
  background-color: #0000;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 20.4px;
  min-width: 334px;
  height: 57px;
  display: flex;
  position: relative;
  top: 50px;
}

.privacy-m .line-34-1 {
  width: 337px;
  height: 1px;
  margin-top: 21.6px;
}

.privacy-m .frame-3399-1 {
  justify-content: center;
  align-items: flex-start;
  gap: 140px;
  width: 329px;
  height: 23px;
  margin-left: -2px;
  display: flex;
  position: relative;
}

.privacy-m .copyright-1 {
  color: #f9f9f9;
  letter-spacing: 0;
  width: 105px;
  height: 16px;
  margin-top: -1px;
  margin-left: -4.5px;
  font-family: Bebas Neue Pro-Light, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.privacy-m .about-us-blog-t-cs-privacy-policy-1 {
  color: #fff;
  font-family: var(--font-family-titillium_web);
  letter-spacing: 0;
  text-align: right;
  width: 93px;
  height: 16px;
  margin-top: -1px;
  margin-right: -4.5px;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.privacy-m .terms-link, .privacy-m .privacy-link {
  color: #f9f9f9;
  cursor: pointer;
  min-width: 125px;
  margin-top: 3px;
  margin-left: -30px;
  font-family: Bebas Neue Pro-Light, Helvetica;
  font-size: 12px;
  transition: color .3s;
}

.privacy-m .terms-link:hover, .privacy-m .privacy-link:hover {
  color: #35cbfd;
}

.error-d .error-404 {
  background-position: initial;
  background-size: cover;
  border: 1px;
  flex-direction: column;
  align-items: center;
  gap: 115px;
  min-width: 1440px;
  padding: 81px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.error-d .frame-3414 {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  display: flex;
  position: relative;
}

.error-d .nav {
  justify-content: space-between;
  align-self: center;
  align-items: center;
  min-width: 1278px;
  height: 46px;
  padding: 10px 50px 10px 40px;
  display: flex;
  position: absolute;
}

.error-d .logo {
  width: 246.25px;
  height: 45.81px;
  margin-top: -.2px;
}

.error-d .img-wrapper {
  --webkit-backdrop-filter: blur(10px) brightness(100%);
  backdrop-filter: blur(10px) brightness();
  border-radius: 10px;
  width: 33px;
  height: 33px;
  position: relative;
  left: 600px;
}

.error-d .my-custom-wrapper {
  border-radius: 12px;
  display: inline-block;
  overflow: hidden;
}

.error-d .my-custom-class {
  cursor: pointer;
  border: 2px solid #33a4d8;
  border-radius: 10px;
  font-weight: bold;
  position: relative;
  font-family: var(--font-family-bebas_neue_pro-regular) !important;
}

.error-d .group-2 {
  z-index: 9;
  cursor: pointer;
  width: 44px;
  height: 44px;
  position: relative;
  top: -6px;
  right: 10px;
}

.group-2:hover {
  animation: 5s cubic-bezier(.36, .07, .19, .97) both shake;
}

.error-d .error-404-content {
  width: 1440px;
  height: 750px;
}

.error-d .error-404-content h1 {
  font: var(--font-family-bebas_neue_pro-regular);
  color: #fff;
  font-size: 45px;
}

.error-d .error-404-content p {
  font: var(--font-family-bebas_neue_pro-regular);
  color: #fff;
  margin-top: 290px;
  margin-left: 320px;
  font-size: 28px;
}

.error-d .text-wrapper {
  color: #fff;
  font-variant: var(--font-family-bebas_neue_pro-regular);
  letter-spacing: 0;
  width: 620px;
  font-size: 27px;
  font-weight: 400;
  line-height: 32.4px;
  position: absolute;
  top: 211px;
  left: 0;
}

.error-d .go-back-2 {
  cursor: pointer;
  background-color: #7f64fb;
  border: .5px solid #7f64fb;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 50px;
  padding: 10px 20px;
  transition: background-color .3s;
  display: flex;
  position: absolute !important;
  top: 570px !important;
  left: 400px !important;
}

.error-d .go-back-2:hover {
  background-color: #439be4;
}

.error-d .go-back-2-text {
  color: #fff;
  font-variant: var(--font-family-bebas_neue_pro-regular);
  font-size: 22px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
}

.error-d .overlap-group {
  width: 706px;
  height: 205px;
  position: absolute;
  top: 50px;
  left: -100px;
}

.error-d .div {
  color: #fff;
  letter-spacing: 0;
  font-variant: var(--font-family-bebas_neue_pro-regular);
  width: 501px;
  font-size: 50px;
  font-weight: 100;
  line-height: normal;
  position: absolute;
  top: 80px;
  left: 400px;
}

.error-d .ABOUT-US {
  color: #fcfcfc;
  letter-spacing: 6.36px;
  font-variant: var(--font-family-bebas_neue_pro-regular);
  width: 706px;
  font-size: 159px;
  font-weight: 400;
  line-height: 141.5px;
  position: absolute;
  top: 150px;
  left: 400px;
}

.error-d .asset {
  mix-blend-mode: color-dodge;
  object-fit: cover;
  width: 122px;
  height: 148px;
  position: absolute;
  top: 15px;
  left: 407px;
}

.error-d .wepik-export {
  object-fit: cover;
  width: 452px;
  height: 692px;
  position: absolute;
  top: 65px;
  left: 931px;
}

.error-d .img {
  object-fit: cover;
  width: 155px;
  height: 244px;
  position: absolute;
  top: 471px;
  left: 164px;
}

.error-d .asset-x {
  mix-blend-mode: color-dodge;
  object-fit: cover;
  width: 122px;
  height: 148px;
  position: absolute;
  top: 111px;
  left: 983px;
}

.error-d .footer {
  background-color: #0000;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 37.6px;
  min-width: 1194.56px;
  height: 94.22px;
  display: flex;
  position: relative;
  top: 45px;
}

.error-d .line-34 {
  width: 1194.56px;
  height: 1px;
  margin-top: -.4px;
}

.error-d .frame-3399 {
  justify-content: center;
  align-items: flex-start;
  gap: 984px;
  width: 1187px;
  height: 16px;
  margin-left: -1px;
  display: flex;
  position: relative;
}

.error-d .copyright {
  letter-spacing: 0;
  width: 107px;
  height: 16px;
  margin-top: -1px;
  line-height: normal;
  position: relative;
}

.error-i .error-404 {
  background-position: initial;
  background-size: cover;
  border: 1px;
  flex-direction: column;
  align-items: center;
  gap: 80px;
  min-width: 768px;
  padding: 75px 45px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.error-i .frame-3416-2 {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 120px;
  display: flex;
  position: relative;
}

.error-i .nav-3 {
  justify-content: space-between;
  align-self: center;
  align-items: center;
  min-width: 688px;
  height: 46px;
  padding: 10px 105px;
  display: flex;
  position: relative;
}

.error-i .logo-3 {
  width: 178px;
  height: 33px;
  margin-top: -1px;
  margin-left: -80px;
}

.error-i .my-custom-wrapper {
  border-radius: 12px;
  display: inline-block;
  overflow: hidden;
}

.error-i .my-custom-class {
  cursor: pointer;
  border: 2px solid #33a4d8;
  border-radius: 10px;
  font-weight: bold;
  position: relative;
  font-family: var(--font-family-bebas_neue_pro-regular) !important;
}

.error-i .img-wrapper {
  --webkit-backdrop-filter: blur(10px) brightness(100%);
  backdrop-filter: blur(10px) brightness();
  cursor: pointer;
  border-radius: 10px;
  width: 44px;
  height: 44px;
  position: absolute;
  right: 35px;
}

.img-wrapper:hover {
  animation: 5s cubic-bezier(.36, .07, .19, .97) both shake;
}

.error-i .overlap-b-group.open .burger-menu-container {
  z-index: 9;
  background-color: #d9d9d9;
  border-radius: 1px 1px 10px 10px;
  width: 250px;
  height: 410px;
  position: absolute;
  top: -80px;
  right: -79px;
}

.error-i .frame-3413-4 {
  min-width: 677px;
  height: 342px;
  display: flex;
  position: relative;
  top: 10px;
}

.error-i .flex-row-4 {
  align-items: center;
  gap: 205px;
  min-width: 559px;
  height: 57px;
  display: flex;
}

.error-i .error-404-content {
  width: 468px;
  height: 650px;
  position: relative;
}

.error-i .error-404-content h1 {
  font: var(--font-family-bebas_neue_pro-regular);
  color: #fff;
  font-size: 45px;
}

.error-i .error-404-content p {
  font: var(--font-family-bebas_neue_pro-regular);
  color: #fff;
  margin-top: 140px;
  margin-left: -80px;
  font-size: 28px;
}

.error-i .text-wrapper {
  color: #fff;
  font-variant: var(--font-family-bebas_neue_pro-regular);
  letter-spacing: 0;
  width: 620px;
  font-size: 27px;
  font-weight: 400;
  line-height: 32.4px;
  position: absolute;
  top: 211px;
  left: 0;
}

.error-i .go-back-2 {
  cursor: pointer;
  background-color: #7f64fb;
  border: .5px solid #7f64fb;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 50px;
  padding: 10px 20px;
  transition: background-color .3s;
  display: flex;
  position: absolute !important;
  top: 420px !important;
  left: 300px !important;
}

.error-i .go-back-2:hover {
  background-color: #439be4;
}

.error-i .go-back-2-text {
  color: #fff;
  font-variant: var(--font-family-bebas_neue_pro-regular);
  font-size: 22px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
}

.error-i .overlap-group {
  width: 706px;
  height: 205px;
  position: absolute;
  top: -100px;
  left: -125px;
}

.error-i .div {
  color: #fff;
  letter-spacing: 0;
  font-variant: var(--font-family-bebas_neue_pro-regular);
  width: 501px;
  font-size: 30px;
  font-weight: 100;
  line-height: normal;
  position: absolute;
  top: 120px;
  left: 40px;
}

.error-i .ABOUT-US {
  color: #fcfcfc;
  letter-spacing: 6.36px;
  font-variant: var(--font-family-bebas_neue_pro-regular);
  width: 706px;
  font-size: 159px;
  font-weight: 400;
  line-height: 141.5px;
  position: absolute;
  top: 150px;
  left: 40px;
}

.error-i .asset {
  mix-blend-mode: color-dodge;
  object-fit: cover;
  width: 122px;
  height: 148px;
  position: absolute;
  top: 15px;
  left: 407px;
}

.error-i .wepik-export {
  object-fit: cover;
  z-index: 1;
  width: 452px;
  height: 692px;
  position: absolute;
  top: 65px;
  left: 450px;
}

.error-i .img {
  object-fit: cover;
  width: 155px;
  height: 244px;
  position: absolute;
  top: 380px;
  left: 14px;
}

.error-i .asset-x {
  mix-blend-mode: color-dodge;
  object-fit: cover;
  width: 122px;
  height: 148px;
  position: absolute;
  top: 111px;
  left: 983px;
}

.error-i .footer-3 {
  background-color: #0000;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 30.4px;
  min-width: 763px;
  height: 54px;
  margin-bottom: 5px;
  display: flex;
  position: relative;
  top: 0;
}

.error-i .line-34-3 {
  width: 690px;
  height: 1px;
  margin-top: -.5px;
}

.error-i .frame-3399-3 {
  justify-content: center;
  align-items: flex-start;
  gap: 482px;
  width: 685px;
  height: 23px;
  margin-left: 1px;
  display: flex;
  position: relative;
}

.error-i .copyright-3 {
  letter-spacing: 0;
  width: 107px;
  height: 16px;
  margin-top: -1px;
  line-height: normal;
  position: relative;
}

.error-i .about-us-blog-t-cs-privacy-policy-3 {
  letter-spacing: 0;
  text-align: right;
  width: 96px;
  height: 16px;
  margin-top: -1px;
  line-height: normal;
  position: relative;
}

.error-i .footer-section {
  width: 768px;
  height: 208px;
  margin-top: 250px;
}

.error-i .footer-section .frame {
  justify-content: space-between;
  align-items: flex-start;
  width: 768px;
  display: flex;
  position: relative;
  top: 31px;
}

.error-i .footer-section .line {
  width: 768px;
  height: 1px;
  margin-top: -.5px;
}

.error-m .error-404 {
  background-position: initial;
  background-size: cover;
  border: 1px;
  flex-direction: column;
  align-items: center;
  gap: 28px;
  min-width: 358px;
  padding: 66px 8px 100px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.error-m .frame-3420 {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 45px;
  padding: 0 0 0 2px;
  display: flex;
  position: relative;
}

.error-m .nav-1 {
  justify-content: space-between;
  align-self: center;
  align-items: center;
  min-width: 340px;
  height: 46px;
  padding: 10px 20px;
  display: flex;
  position: relative;
}

.error-m .logo-1 {
  width: 123px;
  height: 32px;
  margin-left: 20px;
}

.error-m .my-custom-wrapper {
  border-radius: 12px;
  margin-right: 110px;
  display: inline-block;
  overflow: hidden;
}

.error-m .my-custom-class {
  cursor: pointer;
  border: 2px solid #33a4d8;
  border-radius: 10px;
  font-weight: bold;
  position: relative;
  font-family: var(--font-family-bebas_neue_pro-regular) !important;
}

.error-m .img-wrapper {
  --webkit-backdrop-filter: blur(10px) brightness(100%);
  backdrop-filter: blur(10px) brightness();
  border-radius: 10px;
  width: 44px;
  height: 44px;
  position: absolute;
  right: 65px;
}

.error-m .group-2 {
  z-index: 9;
  cursor: pointer;
  width: 44px;
  height: 44px;
}

.error-m .overlap-b-group.open .burger-menu-container {
  z-index: 9;
  background-color: #d9d9d9;
  border-radius: 1px 1px 10px 10px;
  width: 250px;
  height: 410px;
  position: absolute;
  top: -65px;
  right: 60px;
}

.error-m .error-404-content {
  width: 300px;
  height: 650px;
  position: relative;
}

.error-m .error-404-content h1 {
  font: var(--font-family-bebas_neue_pro-regular);
  color: #fff;
  font-size: 25px;
}

.error-m .error-404-content p {
  font: var(--font-family-bebas_neue_pro-regular);
  color: #fff;
  width: 300px;
  margin-top: 50px;
  margin-left: -10px;
  font-size: 18px;
}

.error-m .text-wrapper {
  color: #fff;
  font-variant: var(--font-family-bebas_neue_pro-regular);
  letter-spacing: 0;
  width: 620px;
  font-size: 17px;
  font-weight: 400;
  line-height: 32.4px;
  position: absolute;
  top: 211px;
  left: 0;
}

.error-m .go-back-2 {
  cursor: pointer;
  background-color: #7f64fb;
  border: .5px solid #7f64fb;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 50px;
  padding: 10px 20px;
  transition: background-color .3s;
  display: flex;
  position: absolute !important;
  top: 320px !important;
  left: 100px !important;
}

.error-m .go-back-2:hover {
  background-color: #439be4;
}

.error-m .go-back-2-text {
  color: #fff;
  font-variant: var(--font-family-bebas_neue_pro-regular);
  font-size: 22px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
}

.error-m .overlap-group {
  width: 300px;
  height: 205px;
  position: absolute;
  top: -100px;
  left: -125px;
}

.error-m .div {
  color: #fff;
  letter-spacing: 0;
  font-variant: var(--font-family-bebas_neue_pro-regular);
  width: 300px;
  font-size: 20px;
  font-weight: 100;
  line-height: normal;
  position: absolute;
  top: 120px;
  left: 120px;
}

.error-m .ABOUT-US {
  color: #fcfcfc;
  letter-spacing: 6.36px;
  font-variant: var(--font-family-bebas_neue_pro-regular);
  width: 300px;
  font-size: 90px;
  font-weight: 400;
  line-height: 101.5px;
  position: absolute;
  top: 150px;
  left: 115px;
}

.error-m .asset {
  mix-blend-mode: color-dodge;
  object-fit: cover;
  width: 122px;
  height: 148px;
  position: absolute;
  top: 15px;
  left: 0;
}

.error-m .wepik-export {
  object-fit: cover;
  z-index: 1;
  width: 452px;
  height: 692px;
  position: absolute;
  top: 65px;
  left: 250px;
}

.error-m .img {
  object-fit: cover;
  width: 155px;
  height: 244px;
  position: absolute;
  top: 380px;
  left: -54px;
}

.error-m .asset-x {
  mix-blend-mode: color-dodge;
  object-fit: cover;
  width: 122px;
  height: 148px;
  position: absolute;
  top: 111px;
  left: 983px;
}

.error-m .footer-1 {
  background-color: #0000;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 20.4px;
  min-width: 334px;
  height: 57px;
  display: flex;
  position: relative;
  top: 50px;
}

.error-m .line-34-1 {
  width: 337px;
  height: 1px;
  margin-top: 21.6px;
}

.error-m .frame-3399-1 {
  justify-content: center;
  align-items: flex-start;
  gap: 140px;
  width: 329px;
  height: 23px;
  margin-left: -2px;
  display: flex;
  position: relative;
}

.error-m .copyright-1 {
  color: var(--electric-violet);
  font-family: var(--font-family-titillium_web);
  letter-spacing: 0;
  width: 105px;
  height: 16px;
  margin-top: -1px;
  margin-left: -4.5px;
  font-size: 9.8px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.error-m .about-us-blog-t-cs-privacy-policy-1 {
  color: var(--hint-of-red);
  font-family: var(--font-family-titillium_web);
  letter-spacing: 0;
  text-align: right;
  width: 93px;
  height: 16px;
  margin-top: -1px;
  margin-right: -4.5px;
  font-size: 9.8px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}
/*# sourceMappingURL=index.1f153675.css.map */
