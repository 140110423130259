.error-d .error-404 {
  background-position: initial;
  background-size: cover;
  border: 1px none;
  flex-direction: column;
  align-items: center;
  gap: 115px;
  min-width: 1440px;
  padding: 81px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.error-d .frame-3414 {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  display: flex;
  position: relative;
}

.error-d .nav {
  justify-content: space-between;
  align-self: center;
  align-items: center;
  min-width: 1278px;
  height: 46px;
  padding: 10px 50px 10px 40px;
  display: flex;
  position: absolute;
}

.error-d .logo {
  height: 45.81px;
  margin-top: -0.2px;
  width: 246.25px;
}

.error-d .img-wrapper {
  --webkit-backdrop-filter: blur(10px) brightness(100%);
  -webkit-backdrop-filter: blur(10px) brightness();
  backdrop-filter: blur(10px) brightness();
  border-radius: 10px;
  width: 33px;
  height: 33px;
  position: relative;
  left: 600px;
}

.error-d .my-custom-wrapper {
  border-radius: 12px;
  display: inline-block;
  overflow: hidden;
}

.error-d .my-custom-class {
  border: 2px solid #33a4d8; 
  border-radius: 10px;
  cursor: pointer;
  font-family: var(--font-family-bebas_neue_pro-regular) !important;
  font-weight: bold;
  position: relative;
}

.error-d .group-2 {
  z-index: 9;
  width: 44px;
  height: 44px;
  position: relative;
  top: -6px;
  right: 10px;
  cursor: pointer;
}

.group-2:hover {
  animation: shake 5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}

@keyframes shake {
  10%, 90% {
    transform: translateX(-5px); /* Move left */
  }
  20%, 80% {
    transform: translateX(5px); /* Move right */
  }
  30%, 50%, 70% {
    transform: translateX(-3px); /* Move left */
  }
  40%, 60% {
    transform: translateX(3px); /* Move right */
  }
}

.error-d .error-404-content { 
  width: 1440px;
  height: 750px;
}

.error-d .error-404-content h1 {
  font: var(--font-family-bebas_neue_pro-regular);
  font-size: 45px;
  color: white;
}

.error-d .error-404-content p {
  font: var(--font-family-bebas_neue_pro-regular);
  color: #fff;
  margin-top: 290px;
  margin-left: 320px;
  font-size: 28px;
}

.error-d .text-wrapper {
  color: #ffffff;
  font-variant: var(--font-family-bebas_neue_pro-regular);
  font-size: 27px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 32.4px;
  position: absolute;
  top: 211px;
  width: 620px;
}

.error-d .go-back-2 {
  cursor: pointer;
  background-color: #7f64fb;
  border: 0.5px solid #7f64fb;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 50px;
  padding: 10px 20px;
  transition: background-color .3s;
  display: flex;
  position: absolute !important;
  top: 570px !important;
  left: 400px !important;
}

.error-d .go-back-2:hover {
  background-color: #439be4; /* Hover background color */
}

.error-d .go-back-2-text {
  color: white;
  font-variant: var(--font-family-bebas_neue_pro-regular);
  font-size: 22px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
}

.error-d .overlap-group {
  width: 706px;
  height: 205px;
  position: absolute;
  top: 50px;
  left: -100px;
}

.error-d .div {
  color: #fff;
  letter-spacing: 0;
  width: 501px;
  font-variant: var(--font-family-bebas_neue_pro-regular);
  font-size: 50px;
  font-weight: 100;
  line-height: normal;
  position: absolute;
  top: 80px;
  left: 400px;
}

.error-d .ABOUT-US {
  color: #fcfcfc;
  letter-spacing: 6.36px;
  width: 706px;
  font-variant: var(--font-family-bebas_neue_pro-regular);
  font-size: 159px;
  font-weight: 400;
  line-height: 141.5px;
  position: absolute;
  top: 150px;
  left: 400px;
}

.error-d .asset {
  height: 148px;
  left: 407px;
  mix-blend-mode: color-dodge;
  object-fit: cover;
  position: absolute;
  top: 15px;
  width: 122px;
}

.error-d .wepik-export {
  height: 692px;
  left: 931px;
  object-fit: cover;
  position: absolute;
  top: 65px;
  width: 452px;
}

.error-d .img {
  height: 244px;
  left: 164px;
  object-fit: cover;
  position: absolute;
  top: 471px;
  width: 155px;
}

.error-d .asset-x {
  height: 148px;
  left: 983px;
  mix-blend-mode: color-dodge;
  object-fit: cover;
  position: absolute;
  top: 111px;
  width: 122px;
}



.error-d .footer {
  background-color: #0000;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 37.6px;
  min-width: 1194.56px;
  height: 94.22px;
  display: flex;
  position: relative;
  top: 45px;
}

.error-d .line-34 {
  height: 1px;
  margin-top: -0.4px;
  width: 1194.56px;
}

.error-d .frame-3399 {
  align-items: flex-start;
  display: flex;
  gap: 984px;
  height: 16px;
  justify-content: center;
  margin-left: -1px;
  position: relative;
  width: 1187px;
}

.error-d .copyright {
  height: 16px;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.00px;
  position: relative;
  width: 107px;
}

.error-d .email-link {
  /* No specific styles found in the original CSS */
}
