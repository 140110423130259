.token-box-mobile {
  align-items: center;
  border: 1px none;
  justify-content: center;
  height: 649px;
  min-width: 623px;
  background-color: rgba(0, 0, 0, 0);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow-x: hidden;
  z-index: 9999;
  margin-left: 143px;
}

.token-box-mobile .overlap-group45 {
  background-color: #0f0f0f;
  background-size: 100% 100%;
  border: 3px solid #35cbfd;
  border-radius: 18px;
  align-items: flex-end;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  gap: 29px;
  min-height: 649px;
  padding: 21.6px 23.0px;
  width: 336px;
  z-index: 1;
}

.token-box-mobile .flex-row27 {
  flex-flow: row;
  justify-content: flex-end;
  gap: 35px;
  min-width: 357px;
  height: 40px;
  display: flex;
}

.token-box-mobile .select-source-token {
  color: #f9f9f9;
  font-family: var(--font-family-titillium_web);
  font-size: var(--font-size-s);
  letter-spacing: 0;
  align-self: flex-end;
  min-width: 136px;
  min-height: 24px;
  margin-bottom: -28px;
  margin-right: -265px;
  font-weight: 700;
  line-height: normal;
}

.token-box-mobile .x {
  height: 25px;
  width: 26px;
  cursor: pointer;
  /* Add cursor pointer */
}

.token-box-mobile .overlap-group205 {
  border: 1px solid #33a4d8;
  border-radius: 7px;
  align-items: flex-start;
  height: 45px;
  margin-right: -8px;
  padding: 11px 17px;
  display: flex;
  min-width: 306px;
  margin-right: -12px;
}


/* Mobile-specific style */
@media only screen and (min-width: 306px) {
  .token-box-mobile .overlap-group205 {
    max-width: 50px;
  }
}

.token-box-mobile .search-token-by-name-or-address {
  color: #fff;
  font-family: var(--font-family-titillium_web);
  font-size: var(--font-size-xs);
  letter-spacing: 0;
  background-color: #0000;
  border: 0;
  width: 306px;
  height: 21px;
  padding: 0;
  font-weight: 400;
  line-height: normal;
  margin-left: 0px;
}

.token-box-mobile .search-token-by-name-or-address::placeholder {
  color: #7b7b7b99;
}

.token-box-mobile .overlap-group58 {
  background-color: #212124;
  border-radius: 7px;
  align-self: center;
  align-items: center;
  gap: 27px;
  width: 200px;
  min-width: 307px;
  height: 62px;
  margin-top: -20px;
  margin-left: 1px;
  padding: 10px 1px 15px 10px;
  display: flex;
}

.token-box-mobile .group-299 {
  height: 22px;
  margin-top: 4.35px;
  width: 19px;
}

.token-box-mobile .below-is-the-support {
  align-self: flex-end;
  width: 300px;
  height: 36px;
  display: flex;
  margin-bottom: -3px;
}

.token-box-mobile .below-is-the-support-1 {
  color: white;
  flex: 1;
  font-family: var(--font-family-titillium_web);
  font-size: var(--font-size-m);
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18.4px;
  width: 280px;
  font-size: 10px;
}

.token-box-mobile .scroll-group-10::-webkit-scrollbar {
  display: none;
  width: 0;
}

.token-box-mobile .scroll-group-10 {
  z-index: 2;
  flex-direction: column;
  align-items: flex-start;
  gap: 17px;
  width: 307px;
  height: 150px;
  display: flex;
  position: fixed;
  top: 162px;
  left: 15px;
  overflow-y: scroll;
}

.token-box-mobile .scroll-group-10-item {
  cursor: pointer;
  min-width: 570px;
  height: 62px;
  margin-right: -4px;
  position: relative;
}

.token-box-mobile .overlap-group-181 {
  background-color: var(--onyx);
  cursor: pointer;
  border-radius: 7px;
  align-items: center;
  gap: 16px;
  width: 307px;
  height: 62px;
  margin-top: 70px;
  padding: 12px 13px;
  display: flex;
}

.token-box-mobile .overlap-group-181.active {
  background-color: #727278; /* Lighter tone of your choice */
}

.token-box-mobile .overlap-group-181.active  {
  transition: background-color 0.3s ease;
}

.token-box-mobile .overlap-group-181:hover {
  background-color: #727278; /* Lighter tone for hover, same as the active state */
}

.token-box-mobile .group {
  height: 38px;
  width: 38px;
}

.token-box-mobile .tether-usd,
.token-box-mobile .wrapped-matic {
  letter-spacing: 0;
  width: 196px;
  min-height: 25px;
  margin-top: 1px;
  line-height: normal;
  margin-left: -5px;
}

.token-box-mobile .usdt1 {
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: 2.96px;
  margin-left: 97px;
  min-height: 25px;
  text-align: right;
  width: 159px;
}

.token-box-mobile .overlap-group172 {
  align-items: center;
  background-color: var(--onyx);
  border-radius: 7px;
  display: flex;
  gap: 14px;
  height: 62px;
  min-width: 280px;
  padding: 11px 13px;
  cursor: pointer;
}

.token-box-mobile .xgt,
.token-box-mobile .usd {
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: 1.17px;
  margin-left: 15px;
  min-height: 25px;
  width: 60px;
}

.token-box-mobile .xgt-1 {
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: 0.96px;
  margin-left: 123px;
  min-height: 25px;
  text-align: right;
  width: 133px;
}

.token-box-mobile .overlap-group206 {
  align-items: center;
  background-color: var(--onyx);
  border-radius: 7px;
  display: flex;
  height: 62px;
  min-width: 125px;
  padding: 11.0px 13.9px;
}

.token-box-mobile .usdc {
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: 0.96px;
  margin-left: 129px;
  min-height: 25px;
  text-align: right;
  width: 127px;
}

.token-box-mobile .overlap-group399 {
  align-items: center;
  background-color: var(--onyx);
  border-radius: 7px;
  display: flex;
  height: 62px;
  min-width: 125px;
  padding: 11.3px 13.4px;
}

.token-box-mobile .w-matic {
  letter-spacing: 0;
  text-align: right;
  width: 119px;
  min-height: 25px;
  margin-bottom: 0.96px;
  margin-left: -100px;
  line-height: normal;
}


.token-box-mobile .info-selection {
  color: #f9f9f9;
  font-family: var(--font-family-titillium_web);
  letter-spacing: 0;
  width: 304px;
  margin-top: 76px;
  margin-right: -76px;
  font-size: 9px;
}