.chain-box-mobile {
  align-items: center;
  border: 1px none;
  justify-content: center;
  height: 649px;
  min-width: 623px;
  background-color: rgba(0, 0, 0, 0);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow-x: hidden;
  z-index: 103;
  margin-left: 143px;
}

.chain-box-mobile .overlap-group-189 {
  z-index: 1;
  background-color: #0f0f0f;
  background-size: 100% 100%;
  border: 3px solid #35cbfd;
  border-radius: 18px;
  flex-direction: column;
  align-items: flex-end;
  gap: 29px;
  width: 338px;
  min-height: 649px;
  padding: 21.6px 23px;
  display: flex;
}

.chain-box-mobile .flex-row-112 {
  flex-flow: row;
  justify-content: flex-end;
  gap: 55px;
  min-width: 357px;
  height: 40px;
  display: flex;
}

.chain-box-mobile .select-source-chain-5 {
  color: #f9f9f9;
  font-family: var(--font-family-titillium_web);
  font-size: var(--font-size-s);
  letter-spacing: 0;
  align-self: flex-end;
  min-width: 136px;
  min-height: 24px;
  margin-right: -248px;
  font-weight: 700;
  line-height: normal;
}

.chain-box-mobile .x {
  height: 25px;
  width: 26px;
  cursor: pointer;
}

.chain-box-mobile .overlap-group-114 {
  border: 1px solid #33a4d8;
  border-radius: 7px;
  align-items: flex-start;
  min-width: 306px;
  height: 45px;
  margin-right: -8px;
  padding: 11px 17px;
  display: flex;
  margin-right: -11px;
}

/* Mobile-specific style */
@media only screen and (min-width: 306px) {
  .chain-box-mobile .overlap-group-114 {
    max-width: 50px;
  }
}

.chain-box-mobile .search-chain-by-name-or-chain-id {
  color: #fff;
  font-family: var(--font-family-titillium_web);
  font-size: var(--font-size-xs);
  letter-spacing: 0;
  background-color: #0000;
  border: 0;
  width: 306px;
  height: 21px;
  padding: 0;
  font-weight: 400;
  line-height: normal;
  margin-left: 0px;
}

.chain-box-mobile .search-chain-by-name-or-chain-id::placeholder {
  color: #7b7b7b99;
}

.chain-box-mobile .scroll-group-1::-webkit-scrollbar {
  display: none;
  width: 0;
}

.chain-box-mobile .scroll-group-11 {
  z-index: 2;
  flex-flow: column;
  place-content: stretch center;
  align-items: center;
  gap: 10px;
  width: 307px;
  height: 488px;
  display: flex;
  position: absolute;
  top: 149px;
  left: 16px;
  padding-bottom: 7px;
  overflow-y: scroll;
}


.chain-box-mobile .overlap-group-container-1150 {
  background-color: var(--onyx);
  flex-direction: column;
  align-items: flex-start;
  gap: 13px;
  width: 307px;
  min-height: 21px;
  display: flex;
  border-radius: 7px;
  z-index: 99;
}

.chain-box-mobile .overlap-group-1160 {
  align-items: center;
  background-color: var(--onyx);
  border-radius: 7px;
  display: flex;
  gap: 16px;
  height: 62px;
  min-width: 307px;
  padding: 0 13px;
  cursor: pointer;
  z-index: 101;
}

.chain-box-mobile .polygonLogo,
.chain-box-mobile .bnbLogo,
.chain-box-mobile .ethLogo,
.chain-box-mobile .optimismLogo,
.chain-box-mobile .path-5341 {
  height: 38px;
  width: 38px;
}

.chain-box-mobile .x-mainnet {
  letter-spacing: 0;
  line-height: normal;
  margin-top: 1.0px;
  min-height: 25px;
  width: 196px;
}

.chain-box-mobile .overlap-group2101 {
  background-color: var(--onyx);
  cursor: pointer;
  border-radius: 7px;
  align-items: center;
  gap: 14px;
  min-width: 307px;
  height: 62px;
  padding: 11px 13px;
  display: flex;
  z-index: 101;
}

.chain-box-mobile .avalancheLogo {
  align-self: flex-end;
  height: 38px;
  width: 38px;
}

.chain-box-mobile .avalanche-mainnet {
  letter-spacing: 0;
  line-height: normal;
  margin-top: 2.0px;
  min-height: 26px;
  width: 199px;
}

.chain-box-mobile .bnb-mainnet {
  letter-spacing: 0;
  line-height: normal;
  min-height: 26px;
  width: 195px;
}

.chain-box-mobile .overlap-group154 {
  align-items: center;
  background-color: var(--onyx);
  border-radius: 7px;
  display: flex;
  gap: 17px;
  height: 62px;
  min-width: 280px;
  padding: 0 12px;
  cursor: pointer;
}

.chain-box-mobile .overlap-group310 {
  align-items: center;
  background-color: var(--onyx);
  border-radius: 7px;
  display: flex;
  gap: 16px;
  height: 62px;
  min-width: 280px;
  padding: 0 12px;
  cursor: pointer;
}

.chain-box-mobile .optimism-mainnet {
  letter-spacing: 0;
  line-height: normal;
  min-height: 26px;
  width: 197px;
}

.chain-box-mobile .overlap-group555 {
  align-items: center;
  background-color: var(--onyx);
  border-radius: 7px;
  display: flex;
  gap: 16px;
  height: 62px;
  min-width: 280px;
  padding: 0 11.6px;
  cursor: pointer;
}

.chain-box-mobile .text-1 {
  letter-spacing: 0;
  line-height: normal;
  min-height: 26px;
  width: 146px;
}

.chain-box-mobile .avalancheLogo {
  align-self: center;
  height: 38px;
  width: 38px;
}

.chain-box-mobile .arbitrumLogo {
  align-self: center;
  height: 38px;
  width: 38px;
}

.chain-box-mobile .optimismLogo {
  align-self: center;
  height: 38px;
  width: 38px;
}

.chain-box-mobile .bnbLogo {
  align-self: center;
  height: 38px;
  width: 38px;
}

.chain-box-mobile .ethereumLogo {
  align-self: center;
  height: 38px;
  width: 38px;
}

.chain-box-mobile .lineaLogo {
  align-self: center;
  height: 38px;
  width: 38px;
}

.chain-box-mobile .avalanche-mainnet {
  letter-spacing: 0;
  line-height: normal;
  margin-top: 2.0px;
  min-height: 26px;
  width: 199px;
}

.chain-box-mobile .bnb-mainnet {
  letter-spacing: 0;
  line-height: normal;
  min-height: 26px;
  width: 195px;
}

.chain-box-mobile .info-token-selection {
  color: #f9f9f9;
  font-family: var(--font-family-titillium_web);
  font-size: 9px;
  letter-spacing: 0;
  margin-top: 48px;
  width: 300px;
  margin-right: -112px;
}

.overlap-group-1160.active, .overlap-group2101.active {
  background-color: #727278; /* Lighter tone of your choice */
}

.overlap-group-1160, .overlap-group2101 {
  transition: background-color 0.3s ease;
}

.overlap-group-1160:hover, .overlap-group2101:hover {
  background-color: #727278; /* Lighter tone for hover, same as the active state */
}