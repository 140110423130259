.frame-3395-2 {
  background-color: var(--white);
  border: 1.2px solid;
  border-color: var(--kingfisher-daisy);
  border-radius: 8px;
  align-items: center;
  width: 309px;
  height: 35px;
  padding: 14.37px;
  display: flex;
  position: relative;
  margin-right: 200px;
}

.eg-bethe1sttoknow-2 {
  background-color: transparent;
  border: 0;
  height: 22.75px;
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: -7.04px;
  margin-right: -50.15px;
  margin-top: -9.44px;
  min-width: 330.42px;
  opacity: 0.3;
  padding: 0;
  position: relative;
  white-space: nowrap;
}

.eg-bethe1sttoknow-2::placeholder  {
  color: #2a2a2a99;
}

/* For mobile */
@media (max-width: 767px) {
  .frame-3395-2 {
    background-color: var(--white);
    border: 1.2px solid;
    border-color: var(--kingfisher-daisy);
    border-radius: 8px;
    align-items: center;
    width: 220px;
    height: 35px;
    padding: 14.37px;
    display: flex;
    position: relative;
    margin-left: 150px;
}

  .eg-bethe1sttoknow-2 {
    /* Your mobile styles here */
    background-color: transparent;
    border: 0;
    height: 22.75px;
    letter-spacing: 0;
    line-height: normal;
    margin-bottom: -7.04px;
    margin-right: -50.15px;
    margin-top: -9.44px;
    min-width: 330.42px;
    opacity: 0.3;
    padding: 0;
    position: relative;
    white-space: nowrap;
    /* Add other mobile-specific styles here */
  }
}